<mat-card>
  <mat-card-content>
    <mat-sidenav-container class="example-container">
      <mat-sidenav mode="side" opened>
        <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" vertical [value]="submenu">
          <mat-button-toggle value="admin-orders" *appShowAuthed="['EMPLOYEE', 'COPYWRITER_ADMIN']">Zlecenia</mat-button-toggle>
          <mat-button-toggle value="copywritersList" *appShowAuthed="['EMPLOYEE', 'COPYWRITER_ADMIN']">Lista copywriterów</mat-button-toggle>
          <mat-button-toggle value="authorList" *appShowAuthed="['EMPLOYEE', 'COPYWRITER_ADMIN']">Lista autorów</mat-button-toggle>
          <mat-button-toggle value="openAiOrders" *appShowAuthed="['EMPLOYEE', 'COPYWRITER_ADMIN']">Zlecenia OpenAI</mat-button-toggle>
          <mat-button-toggle value="orders" *appShowAuthed="'COPYWRITER'">Moje zlecenia</mat-button-toggle>
          <mat-button-toggle value="myPanel" *appShowAuthed="'COPYWRITER'">Mój panel</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-sidenav>
      <mat-sidenav-content>
        <app-copywriter-list *ngIf="group.value == 'copywritersList'"></app-copywriter-list>
        <app-copywriter-panel *ngIf="group.value == 'myPanel'" [copywriterId]="copywriterId" (goToOrdersClicked)="onGoToOrdersClicked()"></app-copywriter-panel>
        <app-copywriter-order-list *ngIf="group.value == 'orders'"></app-copywriter-order-list>
        <app-open-aiorders *ngIf="group.value == 'openAiOrders'"></app-open-aiorders>
        <app-admin-order-list *ngIf="group.value == 'admin-orders'"></app-admin-order-list>
        <app-authors-list *ngIf="group.value == 'authorList'"></app-authors-list>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>
</mat-card>
