<h2 mat-dialog-title>Status publikacji</h2>
<mat-dialog-content class="mat-typography">
  <mat-card>
    <div class="full-width-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="articleUrl">
          <th mat-header-cell *matHeaderCellDef>Adres publikacji</th>
          <td mat-cell *matCellDef="let element">
            {{element.articleUrl}}
            <a href="{{element.articleUrl}}" rel="nofollow noreferrer noopener"
               target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
              <button mat-icon-button color="primary">
                <mat-icon>launch</mat-icon>
              </button>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="published">
          <th mat-header-cell *matHeaderCellDef>Opublikowane</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [checked]="element.published" disabled="true"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="publicationDate">
          <th mat-header-cell *matHeaderCellDef>Data publikacji</th>
          <td mat-cell *matCellDef="let element">
            {{element.publicationDate.replace('T', ' ').split('.')[0]}}
          </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef>Url</th>
          <td mat-cell *matCellDef="let element">
            {{element.url}}
          </td>
        </ng-container>
        <ng-container matColumnDef="invalid">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="!element.invalid">Poprawny</p>
            <p *ngIf="element.invalid">Uszkodzony</p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Zamknij</button>
</mat-dialog-actions>
