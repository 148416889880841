<div class="orders-copywriter-list-container">
<p>
  <button mat-button (click)="createPublisher()" matTooltip="Dodaj nowego wydawcę">
    <mat-icon>add_circle</mat-icon>
    Dodaj nowego wydawcę
  </button>
</p>

  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [(ngModel)]="filterValue"
           matTooltip="Filtruj po nazwie domeny, kategorii, wydawcy...">
  </mat-form-field>
</div>

<div class="orders-copywriter-list-container">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortStart="asc" matSortDirection="asc" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.name}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.email}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.phone}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="contactPerson">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Osoba kontaktowa</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.contactPerson}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="ndaSigned">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NDA</th>
      <td mat-cell *matCellDef="let element">
        <p><input type="checkbox" [checked]="element.ndaSigned" disabled="disabled" class="nda"></p>
      </td>
    </ng-container>

    <ng-container matColumnDef="offerLink">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Link do oferty</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.offerLink}}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="orderingType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Forma zlecania</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.orderingType}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Uwagi</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.comments}}</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button (click)="edit(element)" matTooltip="Edytuj"
                *appShowAuthed="['ADMINISTRATOR', 'COPYWRITER_ADMIN', 'EMPLOYEE']">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(element)" matTooltip="Usuń" color="warn"
                *appShowAuthed="['ADMINISTRATOR', 'COPYWRITER_ADMIN', 'EMPLOYEE']">
          <mat-icon>delete</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>

</div>

