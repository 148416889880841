import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ArticleService} from '../../../client/api/article.service';
import {ArticleDetails} from '../../../client/model/articleDetails';
import {ArticleRevision} from '../../../client/model/articleRevision';
import {MatTableDataSource} from '@angular/material/table';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MatSort} from '@angular/material/sort';
import {UserService} from '../../../client/api/user.service';
import {User} from '../../../client/model/user';
import {ArticleRevisionPreviewComponent} from '../article-revision-preview/article-revision-preview.component';

@Component({
  selector: 'app-article-revisions',
  templateUrl: './article-revisions.component.html',
  styleUrls: ['./article-revisions.component.css']
})
export class ArticleRevisionsComponent implements OnInit {

  article: ArticleDetails;
  private revisions: Array<ArticleRevision>;
  displayedColumns: string[] = ['revisionNumber', 'date', 'author', 'actions'];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<ArticleRevision>;
  private users: Array<User>;
  private articleId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private progresIndicatorService: ProgressIndicatorService,
              private userService: UserService,
              private dialog: MatDialog) {
    this.article = data.article;
    this.articleId = data.articleId;
    if (this.article == null || this.article === '') {
      this.articleService.getArticle(this.articleId)
        .subscribe(value => this.article = value);
    }
  }

  ngOnInit(): void {
    this.loadRevisions();
    this.loadUsers();
  }

  private loadRevisions() {
    this.progresIndicatorService.progress = true;
    let articleIdentifier;
    if (this.article == null || this.article === '') {
      articleIdentifier = this.articleId;
    } else {
      articleIdentifier = this.article.id;
    }
    this.articleService.getArticleRevisions(articleIdentifier)
      .subscribe(value => {
        this.revisions = value;
        this.dataSource = new MatTableDataSource(this.revisions);
        this.dataSource.sort = this.sort;
        this.progresIndicatorService.progress = false;
      });
  }

  preview(element: ArticleRevision) {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      data: {
        article: element.articleContent
      }
    };
    const dialogRef = this.dialog.open(ArticleRevisionPreviewComponent, dialogConfig);

  }

  private loadUsers() {
    this.userService.getUsers()
      .subscribe(value => this.users = value);
  }

  getName(revisionAuthor: any) {
    const user = this.users.filter(value => value.id === revisionAuthor).pop();
    if (user != null) {
      return user.firstName + ' ' + user.lastName;
    } else {
      return 'Autor';
    }
  }

  revert(element: ArticleRevision) {
    this.progresIndicatorService.progress = true;
    const articleDetails = this.article;
    articleDetails.content = element.articleContent;
    this.articleService.updateArticle(this.article.id, articleDetails)
      .subscribe(value => {
        this.progresIndicatorService.progress = false;
        this.loadRevisions();
      });
  }
}
