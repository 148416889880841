import {Component, OnInit, ViewChild} from '@angular/core';
import {OrderEditComponent} from '../../orders/order-edit/order-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {OrdersAdminListComponent} from '../../orders/orders-admin-list/orders-admin-list.component';
import {ImportOrdersComponent} from '../../orders/import-orders/import-orders.component';

@Component({
  selector: 'app-admin-order-list',
  templateUrl: './admin-order-list.component.html',
  styleUrls: ['./admin-order-list.component.css']
})
export class AdminOrderListComponent implements OnInit {

  @ViewChild('newOrders', {static: true}) newOrders: OrdersAdminListComponent;

  submenu: string;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    this.submenu = 'active';
  }

  onAddOrderClick() {
    const orderRequest = {};

    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderRequest: orderRequest
      }
    };

    const dialogRef = this.dialog.open(OrderEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onImportOrderClick() {
    const orderRequest = {};

    const dialogConfig = {
      width: '99%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderRequest: orderRequest
      }
    };

    const dialogRef = this.dialog.open(ImportOrdersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
