import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslationsService} from '../../../client/api/translations.service';
import {Translation} from '../../../client/model/translation';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TranslationModalComponent} from '../translation-modal/translation-modal.component';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  total: number;
  dataSource: MatTableDataSource<Translation>;
  private translations: Array<Translation>;
  displayedColumns: string[] = ['label', 'language', 'translation', 'actions'];
  private pageNo = 0;
  private pageSize = 10;

  searchForm: UntypedFormGroup = this.fb.group({
    keyword: ''
  });
  private keyword: string;

  constructor(private translationService: TranslationsService,
              private fb: UntypedFormBuilder,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getInitalTranslationList();
  }

  private getInitalTranslationList() {
    this.getTranslations('', this.pageNo, this.pageSize);
  }

  private getTranslations(keyword: string, pageNo: number, pageSize: number) {
    this.translationService.getTranslations(keyword, pageNo, pageSize).subscribe(value => {
        this.translations = value.items;
        this.total = value.total;
        this.dataSource = new MatTableDataSource<Translation>(this.translations);
        this.dataSource.sort = this.sort;
      }
    );
  }

  changePage($event: PageEvent) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.getTranslations(this.keyword, this.pageNo, this.pageSize);
  }

  onTranslationEdit(element: Translation) {
    const dialogConfig = {
      width: '50%',
      data: {
        translation: element
      }
    };

    const dialogRef = this.matDialog.open(
      TranslationModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getInitalTranslationList();
    });
  }

  onDeleteClick(element: Translation) {
    const dialogRef = this.matDialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.translationService.deleteTranslationByLabelAndLanguage(element.label, element.language).subscribe(value => {
          this.searchTranslation();
        });
      }
    });
  }

  resetForm() {
    this.searchForm.get('keyword').setValue('');
    this.keyword = '';
    this.getInitalTranslationList();
  }

  searchTranslation() {
    const keywordControl = this.searchForm.get('keyword');
    if (keywordControl && keywordControl.value) {
      this.keyword = keywordControl.value;
      this.getTranslations(this.keyword, this.pageNo, this.pageSize);
    } else {
      this.getInitalTranslationList();
    }
  }

  createTranslation() {
    const dialogConfig = {
      width: '50%',
      data: {
        translation: null
      }
    };

    const dialogRef = this.matDialog.open(
      TranslationModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getInitalTranslationList();
    });
  }
}
