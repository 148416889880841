import {Component, Inject, OnInit} from '@angular/core';
import {OrderListItem} from '../../../client/model/orderListItem';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {OrderService} from '../../../client/api/order.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  order: OrderListItem;
  keywords: string = '';
  private errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private confirmDialog: MatDialog,
              private orderService: OrderService,
              private dialog: MatDialogRef<OrderDetailsComponent>) {
    this.order = data.order;
    this.keywords = this.order.tags.map(x => x.name.trim()).join(', ');
  }

  ngOnInit() {
  }

  onDeleteClick(order) {
    let dialogRef = this.confirmDialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.deleteOrder(order.id).subscribe(response => {
          this.dialog.close({deleted: true});
        }, error => {
          this.errorMessage = <any>error;
        });
      }
    });
  }
}
