<h2 mat-dialog-title>Upload pliku z zewnętrznymi domenami</h2>
<mat-dialog-content>
  <mat-form-field class="full-width-container">
    <input type="text" matInput placeholder="Wydawca" formControlName="publisherGroup"
           [matAutocomplete]="publisherAutoGroup"  class="full-width-container">
    <mat-autocomplete #publisherAutoGroup="matAutocomplete" class="full-width-container">
      <mat-optgroup *ngFor="let group of publisherGroupOptions | async" [label]="group.letter">
        <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setPublisher(name)">
          {{name.name}}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>

  <div class="file-drop-area">
    <span class="fake-btn">Wybierz plik</span>
    <span class="file-msg">lub przeciągnij tutaj</span>
    <input class="file-input" type="file" multiple (change)="upload($event)">
    <mat-progress-bar *ngIf="showProgressBar" mode="determinate" value="progressPercentage"></mat-progress-bar>
    {{message}}
  </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close(false)">Zamknij</button>
</mat-dialog-actions>
