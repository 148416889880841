import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchArticlesService} from '../../../client/api/searchArticles.service';

import {ArticleSearchResult} from '../../../client/model/articleSearchResult';
import {DomainCategories} from '../../../client/model/domainCategories';
import {Tag} from '../../../client/model/tag';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-article-search',
  templateUrl: './article-search.component.html',
  styleUrls: ['./article-search.component.css']
})
export class ArticleSearchComponent implements OnInit {

  @ViewChild('articleSearchSort', {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() selected = [];
  @Input() word = '';
  @Input() count = 1;
  @Input() maxAssignments;
  private availableArticles: ArticleSearchResult[];
  displayedColumns: string[] = ['select', 'domainName', 'domainCategory', 'categories', 'title', 'externalId', 'assignedLinksCount'];
  dataSource: MatTableDataSource<ArticleSearchResult>;

  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<ArticleSearchResult> = new SelectionModel<ArticleSearchResult>();

  @Output() selectionChanged = new EventEmitter<Array<ArticleSearchResult>>();
  @Output() replaceKeyWord = new EventEmitter<String>();
  loading: boolean;

  constructor(private searchArticlesService: SearchArticlesService) {
  }

  ngOnInit() {
    this.loadArticles();
  }

  private loadArticles() {
    this.loading = true;
    if (this.count == null) {
      this.count = 3;
    }
    this.searchArticlesService.getArticles(this.count, this.word).subscribe(value => {
      this.availableArticles = value;

      if (this.selected != null && this.selected.length > 0) {
        this.selected.forEach(value => {
          var domainIndex = this.getArticleIndexById(value.id);
          this.availableArticles.splice(domainIndex, 1);
        });
      }

      this.dataSource = new MatTableDataSource<ArticleSearchResult>(this.availableArticles);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
        switch (sortHeaderId) {
          case 'select':
            return data.selected;
          default:
            return data[sortHeaderId];
        }
      };


      this.selection = new SelectionModel<ArticleSearchResult>(this.allowMultiSelect, this.initialSelection);
      this.loading = false;

    });
  }

  getArticleIndexById(id: string) {
    return this.availableArticles.findIndex(value => value.articleId == id);
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.selectionChanged.emit(this.selection.selected);
  }

  onSelectAll($event) {
    if ($event) {
      this.masterToggle();
    }
  }

  onSelectionChange($event, row) {
    if ($event) {
      this.selection.toggle(row);
    }
    this.selectionChanged.emit(this.selection.selected);
  }

  updateList() {
    this.loadArticles();
    this.replaceKeyWord.emit(this.word);
  }

  getCategories(categories: DomainCategories) {
    let categoriesString = '';
    categories.map(value => value.name).forEach(value => categoriesString += value + ', ');
    return categoriesString.substring(0, categoriesString.length - 2);
  }

  getTags(tags: Tag[]) {
    let tagsString = '';
    tags.map(value => value.name).forEach(value => tagsString += value + ', ');
    return tagsString.substring(0, tagsString.length - 2);
  }
}
