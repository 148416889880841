import {Component, OnInit, ViewChild} from '@angular/core';
import {ContactService} from '../../../client/api/contact.service';
import {ContactPage} from '../../../client/model/contactPage';
import {EditContactPageComponent} from '../edit-contact-page/edit-contact-page.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-contact-page-list',
  templateUrl: './contact-page-list.component.html',
  styleUrls: ['./contact-page-list.component.css']
})
export class ContactPageListComponent implements OnInit {
  private pages: Array<ContactPage>;
  dataSource: MatTableDataSource<ContactPage>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['countryCode', 'fileName', 'title', 'actions'];

  constructor(private contactService: ContactService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getContactPages();
  }

  private getContactPages() {
    this.contactService.getContactPages().subscribe(value => {
      this.pages = value.pages;
      this.dataSource = new MatTableDataSource(this.pages);
      this.dataSource.sort = this.sort;
    });
  }

  onNewClick() {
    const contactPage: ContactPage = {
      countryCode: 'pl'
    };
    const dialogConfig = {
      width: '90%',
      height: '80%',
      autoFocus: false,
      disableClose: true,
      data: {
        contactPage: contactPage,
        edit: false
      }
    };

    const dialogRef = this.dialog.open(
      EditContactPageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getContactPages();
    });
  }

  onEditClick(element: any) {
    const dialogConfig = {
      width: '90%',
      height: '80%',
      autoFocus: false,
      disableClose: true,
      data: {
        contactPage: element,
        edit: true
      }
    };

    const dialogRef = this.dialog.open(
      EditContactPageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getContactPages();
    });

  }

  delete(element: any) {

  }
}
