<div class="article-list-container">
  <h1>Polityka prywatności</h1>
  <p>
    <button mat-button (click)="onNewClick()">
      <mat-icon>add_circle</mat-icon>
    </button>
  </p>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="countryCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Jezyk</th>
      <td mat-cell *matCellDef="let element">
        {{element.countryCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa pliku</th>
      <td mat-cell *matCellDef="let element">
        {{element.fileName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tytuł</th>
      <td mat-cell *matCellDef="let element">
        {{element.title}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button (click)="onEditClick(element)" matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="delete(element)" matTooltip="Usuń">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
