import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TemplateService} from '../../../../../client/api/template.service';
import {Template} from '../../../../../client/model/template';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-upload-template-modal',
  templateUrl: './upload-template-modal.component.html',
  styleUrls: ['./upload-template-modal.component.css']
})
export class UploadTemplateModalComponent implements OnInit {

  template: Template = {};
  public mode: string;

  constructor(private templateService: TemplateService,
              private dialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: Template) {
    if (data != null) {
      this.template.templateBody = this.data.templateBody;
      this.template.templateName = this.data.templateName;
      this.mode = 'edit';
    }
  }

  ngOnInit() {
  }

  save() {
    this.templateService.addTemplate(this.template)
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }


  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let choosenFile = event.target.name;
      let fr = new FileReader();
      fr.onload = (event: any) => {
        let base64 = event.target.result;
        switch (choosenFile) {
          case 'templateFile': {
            //this.template.templateFile = base64;
            break;
          }
          case 'templateThumbnail': {
            // this.template.templateThumbnail = base64;
            break;
          }
        }
      };
      fr.readAsDataURL(file);
    }
  }

}
