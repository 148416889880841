<mat-card>
  <mat-card-content>
    <mat-sidenav-container class="example-container">
      <mat-sidenav mode="side" opened>
        <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" vertical [value]="submenu">
          <mat-button-toggle value="publications" *appShowAuthed="'EMPLOYEE'" routerLink="publications">Baza miejscówek</mat-button-toggle>
          <mat-button-toggle value="publishers" *appShowAuthed="'EMPLOYEE'" routerLink="publishers">Zarządzanie wydawcami</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>
</mat-card>




