import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {PublicationsService} from '../../../client/api/publications.service';

import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {AddEditPublicationComponent} from '../add-edit-publication/add-edit-publication.component';
import {Publication} from '../../../client/model/publication';
import {PublicationImportComponent} from '../publication-import/publication-import.component';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {PublicationUploadComponent} from '../publication-upload/publication-upload.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {Publications} from '../../../client/model/publications';
import {Observable} from 'rxjs';
import {CustomDefinition, CustomGroup} from '../../orders/orders-admin-list/orders-admin-list.component';
import {map, startWith} from 'rxjs/operators';
import {_filter} from '../../domains/domains.component';
import {PublisherService} from '../../../client/api/publisher.service';
import {Publisher} from '../../../client/model/publisher';
import {SelectionModel} from '@angular/cdk/collections';


@Component({
  selector: 'app-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: ['./publication-list.component.css']
})
export class PublicationListComponent implements OnInit {
  private publications: Array<Publication>;
  dataSource: MatTableDataSource<Publication>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['select', 'domainName', 'updateDate', 'sgDays', 'category', 'time', 'location', 'dofollow', 'price', 'publisherName', 'publisherState', 'linkCount', 'linkType', 'comments', 'actions'];
  filterValue: any;

  searchForm: UntypedFormGroup = this.fb.group({
    domainGroup: '',
    category: '',
    time: '',
    location: '',
    dofollow: '',
    priceMin: '',
    priceMax: '',
    publisherGroup: '',
    linkType: ''
  });

  domainGroups: CustomGroup[] = new Array();
  publisherGroups: CustomGroup[] = new Array();
  publisherGroupOptions: Observable<CustomGroup[]>;

  private pageNo = 0;
  private pageSize = 10;
  private domain: string;
  totalPublications: number;

  //search params
  private domainName: string;
  private publisher: string;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<Publication>(this.allowMultiSelect, this.initialSelection);
  private errorMessage: any;

  constructor(private publicationsService: PublicationsService,
              private publisherService: PublisherService,
              public progres: ProgressIndicatorService,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getPublications();
    this.getPublishers();
    this.publisherGroupOptions = this.searchForm.get('publisherGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.publisherGroups))
      );

    this.dataSource = new MatTableDataSource(this.publications);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'publisher':
          return item.publisher.orderingType || item.publisher.email || '';
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  changePage($event: PageEvent) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.getPublications();
  }

  getPublications() {

    let search = '';

    const domainGroup = this.searchForm.get('domainGroup');
    if (domainGroup && domainGroup.value) {
      search = this.appendArgs(search, `domainName=="*${domainGroup.value}*"`);
    }

    const category = this.searchForm.get('category');
    if (category && category.value) {
      search = this.appendArgs(search, 'category=="%' + category.value + '%"');
    }

    const time = this.searchForm.get('time');
    if (time && time.value) {
      search = this.appendArgs(search, `time=="*${time.value}*"`);
    }

    const location = this.searchForm.get('location');
    if (location && location.value) {
      search = this.appendArgs(search, `location=="*${location.value}*"`);
    }

    const dofollow = this.searchForm.get('dofollow');
    if (dofollow && dofollow.value) {
      search = this.appendArgs(search, `dofollow=="*${dofollow.value}*"`);
    }

    const priceMin = this.searchForm.get('priceMin');
    if (priceMin && priceMin.value) {
      search = this.appendArgs(search, 'price>="' + priceMin.value + '"');
    }

    const priceMax = this.searchForm.get('priceMax');
    if (priceMax && priceMax.value) {
      search = this.appendArgs(search, 'price<="' + priceMax.value + '"');
    }

    if (this.publisher) {
      search = this.appendArgs(search, 'publisherId==' + this.publisher);
    }

    const linkType = this.searchForm.get('linkType');
    if (linkType && linkType.value) {
      search = this.appendArgs(search, 'linkType=="*' + linkType.value + '*"');
    }

    this.publicationsService.getPublications(search, this.pageNo, this.pageSize).subscribe(value => {
      this.procedResultOfPublicationSearch(value);

    });
  }

  private appendArgs(search: string, s: string) {
    if (search != null && search.length > 0) {
      search += ';';
    }
    search += s;
    return search;
  }

  private procedResultOfPublicationSearch(value: Publications) {
    this.publications = value.publications.map(pub => ({
      ...pub,
      publisherName: pub.publisher.orderingType || pub.publisher.email || ''
    }));
    this.totalPublications = value.total;
    this.dataSource = new MatTableDataSource(this.publications);
    this.dataSource.sort = this.sort;

  }

  createPublication() {
    const dialogRef = this.dialog.open(AddEditPublicationComponent, {
      width: '80%',
      data: {
        mode: 'new',
        publication: {
          publisher: {}
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPublications();
      this.progres.progress = false;
    });
  }

  edit(publication: Publication) {
    const dialogRef = this.dialog.open(AddEditPublicationComponent, {
      width: '80%',
      data: {
        mode: 'edit',
        publication: publication
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPublications();
    });
  }

  delete(element: Publication) {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.publicationsService.deletePublication(element.id).subscribe(
          value => {
            this.getPublications();
          }
        );
      }
    });
  }

  onImportPublicationClick() {
    const orderRequest = {};

    const dialogConfig = {
      width: '99%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderRequest: orderRequest
      }
    };

    const dialogRef = this.dialog.open(PublicationImportComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPublications();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onUploadClick() {
    const dialogConfig = {
      width: '50%',
      autoFocus: false,
      disableClose: true,
    };

    const dialogRef = this.dialog.open(PublicationUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPublications();
    });
  }


  resetForm() {
    this.searchForm = this.fb.group({
      domainGroup: '',
      category: '',
      time: '',
      location: '',
      dofollow: '',
      priceMin: '',
      priceMax: '',
      publisherGroup: '',
      linkType: ''
    });
    this.domainName = '';
    this.publisher = '';
  }

  setDomainName(name: string) {
    this.domainName = name;

  }

  private getDomains() {
    this.publicationsService.getPublicationsDomains()
      .subscribe(value => {
        const domains = value;
        domains.forEach(value1 => {
          const valueToAdd: CustomDefinition = {
            name: value1.name,
            id: value1.name
          };
          this.addToGroup(valueToAdd, this.domainGroups);
        });
      });
  }

  private getPublishers() {
    this.publisherService.getPublishers(0, 1000)
      .subscribe(
        value => {
          const publishers = value;
          publishers.forEach(value1 => {
            const valueToAdd: CustomDefinition = {
              name: this.getPublisherName(value1),
              id: value1.id,
              orderingType: value1.orderingType
            };
            this.addToGroup(valueToAdd, this.publisherGroups);
          });
        }
      );
  }

  private getPublisherName(publisher: Publisher) {
    return publisher.name != null ? publisher.name : publisher.email;
  }

  private addToGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  setPublisherForSearch(name: CustomDefinition) {
    this.publisher = name.id;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć zaznaczone zlecenia?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach(value => {
          this.publicationsService.deletePublication(value.id).subscribe(response => {
            this.getPublications();
          }, error => {
            this.errorMessage = <any>error;
          });
        });
        this.selection.clear();
      }
    });
  }

}
