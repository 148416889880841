import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-article-revision-preview',
  templateUrl: './article-revision-preview.component.html',
  styleUrls: ['./article-revision-preview.component.css']
})
export class ArticleRevisionPreviewComponent implements OnInit {

  content: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog) {
    this.content = data.article;
  }

  ngOnInit(): void {
  }

}
