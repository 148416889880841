<h2>Edycja powiadomień</h2>
<p>Włącz/wyłącz poszczególne powiadomienia mailowe</p>
<form>
  <div>

    <div class="full-width-container">
      <mat-checkbox name="newOrder" [(ngModel)]=config.newOrder> Nowe zlecenie
      </mat-checkbox>
    </div>
    <div class="full-width-container">
      <mat-checkbox name="orderToPublish" [(ngModel)]=config.orderToPublish *appShowAuthed="'EMPLOYEE'">Artykuł do publikacji
      </mat-checkbox>
    </div>
    <div class="full-width-container">
      <mat-checkbox name="orderToCorrect" [(ngModel)]=config.orderToCorrect>Artykuł do poprawki
      </mat-checkbox>
    </div>
    <div class="full-width-container">
      <mat-checkbox name="orderCorrected" [(ngModel)]=config.orderCorrected *appShowAuthed="'EMPLOYEE'">Artykuł poprawiony
      </mat-checkbox>
    </div>

  </div>

  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</form>
