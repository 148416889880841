import {Component, Inject, OnInit} from '@angular/core';
import {OrderService} from '../../../client/api/order.service';
import {OrderImportDefinition} from '../../../client/model/orderImportDefinition';
import {ImportOrderRequest} from '../../../client/model/importOrderRequest';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {CopywriterDetails} from '../../../client/model/copywriterDetails';
import {DomainService} from '../../../client/api/domain.service';
import {Domain} from '../../../client/model/domain';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {LinkCampaignService} from '../../../client/api/linkCampaign.service';
import {LinkCampaignItems} from '../../../client/model/linkCampaignItems';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PublicationsService} from '../../../client/api/publications.service';
import {Publication} from '../../../client/model/publication';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ArticlesListComponent} from '../../domains/edit-domain/articles/articles-list/articles-list.component';

@Component({
  selector: 'app-import-orders',
  templateUrl: './import-orders.component.html',
  styleUrls: ['./import-orders.component.css']
})
export class ImportOrdersComponent implements OnInit {

  xls = '';
  orders: ImportOrderRequest;
  errorMessage: Array<String>;
  private copywriters: Array<CopywriterDetails>;
  private domains: Array<Domain>;
  private campaigns: LinkCampaignItems;
  dataLoaded = false;
  private publications: Array<Publication>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private orderService: OrderService,
              private copywriterService: CopywriterService,
              private domainService: DomainService,
              public progres: ProgressIndicatorService,
              private linkCampaignService: LinkCampaignService,
              private publicationsService: PublicationsService,
              private dialog: MatDialog,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.orders = {
      orders: new Array<OrderImportDefinition>(),
      count: 0
    };
    this.progres.progress = true;
    this.getCopywriters();
    this.errorMessage = new Array<String>();

  }

  onSubmit() {
    this.progres.progress = true;
    this.progres.text = 'Parsowanie pliku';
    this.errorMessage.splice(0, this.errorMessage.length);
    let externalIds = [];
    const rows = this.xls.split('\n');
    rows.forEach((value, index) => {
      const fields = value.split('\t');
      if (!this.isValidCopywriter(fields[6])) {
        const indexValue = index + 1;
        this.errorMessage.push('Copywriter: \'' + fields[6] + '\' w wierszu ' + indexValue + ' nieprawidłowy, podaj poprawny login(email) ');
      }
      if (!this.isValidDomain(fields[7])) {
        const indexValue = index + 1;
        this.errorMessage.push('Domena: \'' + fields[7] + '\' w wierszu ' + indexValue + ' nieprawidłowa ');
      }
      if (fields[10] != null && !this.isValidCampaign(fields[10])) {
        const indexValue = index + 1;
        this.errorMessage.push('Kampania: \'' + fields[10] + '\' w wierszu ' + indexValue + ' nieprawidłowa ');
      }
      if (this.errorMessage.length === 0) {
        const order: OrderImportDefinition = {
          externalId: fields[0],
          type: fields[1],
          length: +fields[2],
          paraphraseUrl: fields[3],
          anchor: fields[4],
          comments: fields[5],
          author: fields[6],
          domainName: fields[7],
          autoPublish: fields[8].toLowerCase() === 'true' || fields[8].toLowerCase() === 'prawda',
          dueDate: fields[9],
          campaignId: fields[10],
          linkUrl: fields[11],
          linkAnchor: fields[12]
        };
        this.orders.orders.push(order);
        externalIds.push(order.externalId);
        this.orders.count++;
      }
    });

    localStorage.setItem('externalIds', JSON.stringify(externalIds));

    this.progres.progress = false;
    if (this.errorMessage.length === 0) {
      this.progres.progress = true;
      this.progres.text = 'Dodawanie zleceń do systemu';
      this.orderService.importOrders(this.orders).subscribe(value => {
        this.orders = {
          orders: new Array<OrderImportDefinition>(),
          count: 0
        };
        if (value.orders.length > 0) {
          let duplicated = 'Zduplikowanie zlecenia nie dodane do systemu:';
          value.orders.map(value1 => duplicated += ' ' + value1.externalId + ',');

          this.snackBar.open(duplicated, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        } else {
          this.snackBar.open('Zlecenia dodane prawidłowo bez duplikatów', 'Zamknij',
            {duration: 10000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        }

        this.dialog.closeAll();
        this.progres.progress = false;
      }, error1 => {
        this.progres.progress = false;
      });
    }
  }

  private getCopywriters() {
    this.copywriterService.getCopywriters().subscribe(value => {
      this.copywriters = value;
      this.getDomains();
    });
  }

  private isValidCopywriter(username: String) {
    return this.copywriters.filter(value => value.username === username).length === 1;
  }

  private getDomains() {
    this.domainService.getDomains().subscribe(value => {
      this.domains = value;
      this.getLinkCampaigns();
    });
  }

  private isValidDomain(domainName: string) {
    return this.domains.filter(value => value.name === domainName).length === 1
      || this.publications.filter(value => value.domainName === domainName).length >= 1;
  }

  private getLinkCampaigns() {
    this.linkCampaignService.getLinkCampaigns().subscribe(value => {
      this.campaigns = value;
      this.dataLoaded = true;
      this.getPublications();
    });
  }

  private getPublications() {
    this.publicationsService.getPublications(null, 0, 10000).subscribe(value => {
      this.publications = value.publications;
      this.progres.progress = false;
    });
  }

  private isValidCampaign(externalId: string) {
    return this.campaigns.campaigns.filter(value => value.externalId === externalId).length === 1;
  }

  private extractExternalIdFromImportOrderRequest(orders: ImportOrderRequest) {
    localStorage.setItem('externalId', orders.orders[0].externalId);
  }
}
