<mat-card>
  <div class="full-width-container">
    <p>
      <button mat-button (click)="openDialog()" matTooltip="Dodaj nową">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-button (click)="refresh()" matTooltip="Odśwież liste">
        <mat-icon>sync</mat-icon>
      </button>

      <a mat-button mat-raised-button color="primary" [href]="fileUrl" download="raport_domen.csv">Pobierz raport
      </a>
      <a mat-button mat-raised-button color="secondary" [routerLink]="['/domains/health']" *appShowAuthed="'ADMINISTRATOR'">Health check</a>

    </p>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [(ngModel)]="filterValue"
             matTooltip="Filtruj po nazwie domeny, kategorii, IP, statusie itd...">
    </mat-form-field>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Zawansowane wyszukiwanie
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row">
        <div width="25">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="isInCampaign" name="isUsed">
            <mat-radio-button value="true">Domeny używane w kampanii</mat-radio-button>
            <br/>
            <mat-radio-button value="false">Domeny NIE używane w kampanii</mat-radio-button>
          </mat-radio-group>
        </div>
        <div width="70" class="full-width-container">
          <form [formGroup]="campaignForm" class="full-width-container">
            <mat-form-field appearance="fill">
              <mat-label>Kampania linkowa</mat-label>
              <input type="text" matInput formControlName="campaignGroup" [matAutocomplete]="autoGroup">
              <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-option *ngFor="let campaign of filteredCampaigns | async" [value]="campaign.externalId" (click)="setCampaignId(campaign.id)">
                  {{ campaign.externalId }} - {{ campaign.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div fxLayout="row">
        <div width="100">
          <mat-button-toggle-group name="fontStyle" aria-label="Pokazuj domeny dedykowane" [(ngModel)]="showDedicated">
            <mat-button-toggle value="only_dedicated">tylko dedykowane</mat-button-toggle>
            <mat-button-toggle value="not_dedicated">tylko niededykowane</mat-button-toggle>
            <mat-button-toggle value="all">wszystkie</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <br/>
      <button mat-button mat-raised-button color="accent" (click)="searchDomains()">Szukaj</button>
    </mat-expansion-panel>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domena</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.name }}</p>
          <p>

            <a *ngIf="element.altName==null" href="http://{{element.name}}" rel="nofollow noreferrer noopener"
               target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
              <button mat-icon-button color="primary">
                <mat-icon>launch</mat-icon>
              </button>
            </a>
            <a *ngIf="element.altName!=null" href="http://{{element.altName}}" rel="nofollow noreferrer noopener"
               target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
              <button mat-icon-button color="primary">
                <mat-icon>launch</mat-icon>
              </button>
            </a>
            <a *ngIf="element.altName==null" href="https://dnschecker.org/?#A/{{element.name}}"
               rel="nofollow noreferrer noopener"
               target="_blank" matTooltip="Sprawdź propagacje DNS">
              <button mat-icon-button color="primary">
                <mat-icon>dns</mat-icon>
              </button>
            </a>
            <a *ngIf="element.altName!=null" href="https://dnschecker.org/?#A/{{element.altName}}"
               rel="nofollow noreferrer noopener"
               target="_blank" matTooltip="Sprawdź propagacje DNS">
              <button mat-icon-button color="primary">
                <mat-icon>dns</mat-icon>
              </button>
            </a>
            <a>
              [{{ element.countryCode != null ? element.countryCode.toUpperCase() : "-" }}]
            </a><span *ngIf="element.countryCode!=null"
                      class="flag-icon flag-icon-{{element.countryCode.toLowerCase()}}"></span>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategorie</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.categories }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategoria</th>
        <td mat-cell
            *matCellDef="let element"> {{ element.domainCategoryName != null ? element.domainCategoryName : "-" }}<br/>
          <mat-chip-list>
            <mat-chip color="accent" selected *ngIf="element.dedicated">Dedykowana</mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell
            *matCellDef="let element">{{ element.name == 'devpage.pl' ? 'Domena techniczna' : getStatusLabel(element.status) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="stats">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Statystyki</th>
        <td mat-cell *matCellDef="let element"> Artykułów:{{ element.statictics.articlesCount }}<br/>
          Zaktualizono o: {{ element.statictics.lastModifiedDate.replace('T', ' ').split('.')[0] }}<br/>
        </td>
      </ng-container>

      <ng-container matColumnDef="seo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SEO</th>
        <td mat-cell *matCellDef="let element">
          <p matTooltip="Http code" [ngClass]="{'badStatus':element.seo!=null && element.seo.status!=200 && element.seo.status!='200(http)',
          'noSslStatus':element.seo!=null && element.seo.status=='200(http)'}"
          >
            Status:{{ element.seo != null ? element.seo.status : "-" }}
          </p>
          <p
            matTooltip="ExtBackLinks/RefDomains/TrustFlow/CitationFlow">{{ element.seo != null ? element.seo.bl : "-" }}</p>
          <p matTooltip="Request unikalne / Total">R:{{ element.seo != null ? element.seo.r : "-" }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Opcje</th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button [routerLink]="['/domain', element.id]" matTooltip="Edytuj"
                  [disabled]="element.name=='devpage.pl'">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button (click)="delete(element)" matTooltip="Usuń" color="warn"
                  *appShowAuthed="'ADMINISTRATOR'">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button (click)="renew(element)" matTooltip="Reinstall" *appShowAuthed="'ADMINISTRATOR'">
            <mat-icon>restore</mat-icon>
          </button>

          <button mat-icon-button (click)="refreshSSL(element)" matTooltip="Odśwież https" color="link">
            <mat-icon>lock</mat-icon>
          </button>

          <button mat-icon-button (click)="showIP(element)" matTooltip="Pokaż IP" color="link">
            <mat-icon>dns</mat-icon>
          </button>

          <button mat-icon-button (click)="showSSL(element)" matTooltip="Detale SSL" color="link">
            <mat-icon>security</mat-icon>
          </button>

          <button mat-icon-button (click)="render(element)" matTooltip="Renderuj" color="link">
            <mat-icon>refresh</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[100, 50, 20]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>
