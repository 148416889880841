import {Component, Inject} from '@angular/core';
import {OrderAiService} from '../../../../client/api/orderai.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {CustomDefinition, CustomGroup} from '../../../orders/orders-admin-list/orders-admin-list.component';
import {map, startWith} from 'rxjs/operators';
import {DomainService} from '../../../../client/api/domain.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {_filter} from '../../../domains/domains.component';
import {OrderAiListItem} from '../../../../client/model/orderAiListItem';

@Component({
  selector: 'app-open-ainew-order',
  templateUrl: './open-ainew-order.component.html',
  styleUrls: ['./open-ainew-order.component.css']
})
export class OpenAINewOrderComponent {

  orderForm: UntypedFormGroup;
  title = 'Nowe zlecenie';

  domainGroups: CustomGroup[] = new Array();
  domainGroupOptions: Observable<CustomGroup[]>;
  private domain: string;

  constructor(private orderAiService: OrderAiService,
              private domainService: DomainService,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: OrderAiListItem) {
    this.initForm(data);
    this.getDomains();
    this.domainGroupOptions = this.orderForm.get('domainGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.domainGroups))
      );

  }


  save() {
    if (this.data != null) {
      this.orderAiService.updateOrder(this.data.id, this.orderForm.value).subscribe(value => {
        this.dialog.closeAll();
      });

    } else {
      let request = this.orderForm.value;
      request.domain = this.domain;
      this.orderAiService.processOrderRequest(request).subscribe(value => {
        this.dialog.closeAll();
      });
    }
  }

  private getDomains() {
    this.domainService.getDomains(null, null).subscribe(value => {
        const domains = value;
        domains.forEach(value1 => {
          const valueToAdd: CustomDefinition = {
            name: value1.name,
            id: value1.id
          };
          this.addToGroup(valueToAdd, this.domainGroups);
        });
      }
    );
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  private addToGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  setDomainId(name: string) {
    this.domain = name;
  }

  private initForm(data: OrderAiListItem) {
    if (data != null) {
      this.title = 'Edycja zlecenia';
      this.orderForm = this.fb.group({
        domainGroup: data.domain,
        keyword: data.keyword,
        customPrompt: data.customPrompt,
        autoPublish: data.autoPublish,
        externalId: data.externalId,
        title: data.title
      });
    } else {
      this.title = 'Nowe zlecenie';
      this.orderForm = this.fb.group({
        domainGroup: '',
        keyword: data ? data.keyword : 'Podaj słowo kluczowe',
        customPrompt: data ? data.customPrompt : this.getDefaultCustomPrompt('Podaj słowo kluczowe'),
        autoPublish: false,
        externalId: data ? data.externalId : '',
        title: data ? data.title : ''
      });
    }

    this.orderForm.get('keyword').valueChanges.subscribe(value => {
      this.orderForm.get('customPrompt').setValue(this.getDefaultCustomPrompt(value));
    });
  }

  private getDefaultCustomPrompt(keyword: string): string {
    return `Mając do dyspozycji słowo kluczowe '${keyword}', proszę o wykonanie następujących zadań:
    1. **Tytuł Wpisu (<h1>):** Stwórz przyciągający uwagę i oryginalny tytuł wpisu. Upewnij się, że tytuł zawiera słowo kluczowe.
    2. **Treść Wpisu:** Nasyć treść słowem kluczowym, zarówno w nagłówkach (<h2>) jak i w treści. Tekst musi być pogłębiony i rozbudowany oraz ogranicz ilość nagłówków do 3. Każdy nagłówek ma być pogłębiony oraz unikaj wyliczeń i punktów stosuj opisowe formy typowe dla blogów
    3. **Zakończenie:** Zakończ wpis trzema pytaniami i odpowiedziami w formie FAQ, zawierającymi słowo kluczowe.
    Wynikowy tekst zapisz w formacie HTML, zoptymalizowany pod kątem SEO.`;
  }

}
