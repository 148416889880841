<mat-dialog-content>
  <h1>{{article.title}}</h1>
  <div [innerHTML]="article.content"></div>
  <hr>
  <span>Data publikacji: {{article.publishedDate.toString().replace("T", " ").split(".")[0]}}</span><br/>
  <span>Autor: {{article.author}}</span><br/>
  <span>Link: {{article.url}}</span><br/>
  <hr>
  <h1>Komentarze: {{article.comments}}</h1>
  <table>
    <thead style="background-color: #4CAF50">
    <th>Autor</th>
    <th>Komentarz</th>
    <th>Data</th>
    </thead>
    <tr *ngFor="let comment of articleComments">
      <td style="padding:10px">{{comment.author}}</td>
      <td style="padding:10px">{{comment.content}}</td>
      <td style="padding:10px">{{comment.createdAt.replace("T", " ").split(".")[0]}}</td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button primary *ngIf="article.published == false || order.status!='PUBLISHED'" (click)="publish()">
    Publikuj
  </button>
  <button mat-button primary (click)="addComment()">Dodaj uwagi dla Copywritera</button>
  <button mat-button primary mat-dialog-close>Zamknij</button>
  <button mat-button (click)="download(article)" *ngIf="!getFile(article)"
          matTooltip="Pobierz">
    <mat-icon>file_download</mat-icon>
  </button>
  <mat-progress-bar *ngIf="showDownloadProgressBar && fileId==article.id" mode="determinate"
                    value="progressDownloadPercentage"></mat-progress-bar>
  <a mat-button [href]="getUrl(article)"
     download="{{(article.externalId!=null?article.externalId+'_':'')+article.title}}"
     *ngIf="getFile(article)" matTooltip="Zapisz">
    <mat-icon color="primary">save</mat-icon>
  </a>
</mat-dialog-actions>
