import {Component, OnInit, ViewChild} from '@angular/core';

import {CreateDomainModalComponent} from './create-domain-modal/create-domain-modal.component';
import {DomainService} from '../../client/api/domain.service';
import {Domains} from '../../client/model/domains';
import {Domain} from '../../client/model/domain';
import {ProgressIndicatorService} from '../commons/progress-indicator.service';
import {DomSanitizer} from '@angular/platform-browser';
import {LinkCampaignService} from '../../client/api/linkCampaign.service';
import {LinkCampaignItem} from '../../client/model/linkCampaignItem';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {DomainOverview} from '../../client/model/domainOverview';

export interface CampaignGroup {
  letter: string;
  names: CampaignDefinition[];
}

export interface CampaignDefinition {
  name: string;
  id: string;
}

export const _filter = (opt: CampaignDefinition[], value: string): CampaignDefinition[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.name.toLowerCase().startsWith(filterValue));
};

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  campaigns: Array<LinkCampaignItem>;

  constructor(private domainsService: DomainService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public progres: ProgressIndicatorService,
              private linkCampaignService: LinkCampaignService,
              private sanitizer: DomSanitizer,
              private authService: AuthService,
              private router: Router,
              private fb: UntypedFormBuilder) {
    if (this.authService.hasRole('COPYWRITER') && !this.authService.hasRole('ADMINISTRATOR')) {
      this.router.navigateByUrl('/copywriters');
    }
  }

  campaignForm: UntypedFormGroup = this.fb.group({
    campaignGroup: ''
  });
  campaignGroups: CampaignGroup[] = new Array();
  campaignGroupOptions: Observable<CampaignGroup[]>;
  filteredCampaigns: Observable<LinkCampaignItem[]>;

  displayedColumns: string[] = ['name', 'categories', 'category', 'status', 'stats', 'seo', 'actions'];
  errorMessage: String;
  domains: Domains;
  dataSource: MatTableDataSource<Domain>;
  fileUrl: any;
  filterValue: any;
  campaign = '';
  isInCampaign: any;
  showDedicated: any;

  DOMAIN_LIST = 'domains_list';
  DOMAIN_LIST_UPDATED = 'domains_list_updated';

  ngOnInit() {
    this.getDefaultDomains();
    this.getCampaigns();
    this.filteredCampaigns = this.campaignForm.get('campaignGroup').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterCampaigns(value))
      );

    // tslint:disable-next-line:no-non-null-assertion
    this.campaignGroupOptions = this.campaignForm.get('campaignGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
  }

  private filterCampaigns(value: string): LinkCampaignItem[] {
    if (!this.campaigns) {
      return [];
    }

    const filterValue = value.toLowerCase();
    return this.campaigns.filter(campaign =>
      (campaign.externalId && campaign.externalId.toLowerCase().includes(filterValue)) ||
      (campaign.name && campaign.name.toLowerCase().includes(filterValue))
    );
  }

  private _filterGroup(value: string): CampaignGroup[] {
    if (value) {
      return this.campaignGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return this.campaignGroups;
  }

  getReports() {
    let reportDomain = 'Domena,Kategoria\n';
    this.domains.forEach(value => reportDomain += value.name + ',' + value.domainCategoryName + '\n');
    const blobDomain = new Blob([reportDomain], {type: 'application/octet-stream'});
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobDomain));
  }


  private getDomains(campaign: string, isInCampaign?: boolean, dedicatedValue?: boolean, defaultSearch?: boolean) {
    this.progres.progress = true;
    if (defaultSearch) {
      let domains = JSON.parse(localStorage.getItem(this.DOMAIN_LIST));
      const updated = Number.parseInt(localStorage.getItem(this.DOMAIN_LIST_UPDATED));
      if (domains && updated && (updated + 300000 > Date.now())) {
        this.domains = domains;
        this.processDomains(domains);
      } else {
        this.retrieveDomains(campaign, isInCampaign, dedicatedValue, defaultSearch);
      }
      this.progres.progress = false;
    } else {
      this.retrieveDomains(campaign, isInCampaign, dedicatedValue);
    }
  }

  private retrieveDomains(campaign: string, isInCampaign: boolean, dedicatedValue: boolean, defaultSearch?: boolean) {
    this.domainsService.getDomains(campaign, isInCampaign, dedicatedValue)
      .subscribe(
        response => {
          this.processDomains(response, defaultSearch);
        },
        error => {
          this.errorMessage = <any>error;
          this.progres.progress = false;
        });
  }

  private processDomains(response: Array<DomainOverview>, defaultSearch?: boolean) {
    this.domains = response;
    if (defaultSearch) {
      localStorage.setItem(this.DOMAIN_LIST, JSON.stringify(response));
      localStorage.setItem(this.DOMAIN_LIST_UPDATED, Date.now().toString());
    }
    this.dataSource = new MatTableDataSource(this.domains);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'seo':
          return data.seo.status;
        case 'stats':
          return data.statictics.articlesCount;
        case 'ip':
          return data.ip;
        default:
          return data[sortHeaderId];
      }
    };
    if (this.filterValue) {
      this.applyFilter(this.filterValue);
    }
    this.getReports();
    this.progres.progress = false;
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateDomainModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  refresh() {
    this.progres.progress = true;
    this.retrieveDomains(null, null, this.getDedicatedValue(), true);
    this.progres.progress = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  delete(element: Domain) {
    if (confirm('Na pewno chcesz usunąć domene ' + element.name + '?')) {
      this.progres.progress = true;
      this.domainsService.deleteDomain(element.id).subscribe(value => {
        this.snackBar.open('Domena ' + element.name + 'usunięta!', 'Zamknij',
          {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        this.progres.progress = false;
        this.refresh();
      }, error1 => {
        this.progres.progress = false;
        this.snackBar.open('Nie można usunąć aktywnej domeny która posiada przypisane artykuły!', 'Zamknij',
          {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });
    }
  }

  renew(element: Domain) {
    if (confirm('Na pewno chcesz reinstalować domene ' + element.name + '?')) {
      this.progres.progress = true;
      this.domainsService.reinstallDomain(element.id).subscribe(value => {
        this.snackBar.open('Domena ' + element.name + ' w trakcie reinstalacji!', 'Zamknij',
          {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        this.progres.progress = false;
        this.refresh();
      }, error1 => {
        this.progres.progress = false;
        this.snackBar.open('Nie można usunąć reinstalować domeny! Sprawdź logi', 'Zamknij',
          {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });
    }
  }

  getStatusLabel(status: any) {
    switch (status) {
      case 'RENDERING':
        return 'Renderowanie strony w trakcie';
      case 'UPLOAD_FAILED':
        return 'Upload nieudany';
      case 'TEMPLATE_CHANGED':
        return 'Zaktualizowano szablon lub placeholder';
      case 'UPDATED':
        return 'Zaktualizowana';
      case 'RENDERING_FAILED':
        return 'Renderowanie nieudane, Sprawdź konfigurację';
      case 'SETUPED':
        return 'Zainstalowana';
      case 'LINK_ADDED':
        return 'Dodano link, publikacja w trakcie';
      case 'LINK_REMOVED':
        return 'Usunieto link, publikacja w trakcie';
      case 'ARTICLE_ADDED':
        return 'Dodano artykuł, publikacja w trakcie';
      case 'TEMPLATE_NOT_SET':
        return 'Nie ustawiono szablonu, renderowanie nie możliwe!';
      default:
        return status;

    }
  }

  refreshSSL(element: Domain) {
    this.snackBar.open('Podpinam certyfikat dla domeny: ' + element.name, 'Zamknij',
      {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    this.domainsService.setSSLForDomain(element.id).subscribe(value => {
      this.snackBar.open('Certyfikat dla domeny ' + element.name + ' podpięty!', 'Zamknij',
        {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    }, error1 => {
      this.snackBar.open('Wystąpił błąd podczas konfiguracji certyfikatu dla domeny ' + element.name + ', sprawdź logi serwera', 'Zamknij',
        {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    });
  }

  private getCampaigns() {
    this.linkCampaignService.getLinkCampaigns().subscribe({
      next: (value) => {
        this.campaigns = value.campaigns || [];
      },
      error: (err) => {
        console.error('Błąd podczas ładowania kampanii', err);
        this.campaigns = [];
      }
    });
  }

  searchDomains() {

    this.getDomains(this.campaign, this.isInCampaign, this.getDedicatedValue());
  }

  private getDedicatedValue() {
    switch (this.showDedicated) {
      case 'only_dedicated':
        return true;
      case 'not_dedicated':
        return false;
      default:
        return null;
    }
  }

  private getDefaultDomains() {
    this.getDomains(null, null, this.getDedicatedValue(), true);
  }

  setCampaignId(id: string) {
    this.campaign = id;
  }

  showIP(element: Domain) {
    this.snackBar.open('IP:' + element.ip + ' DNS:[' +
      element.dns.split(';')[0] + '\n' +
      element.dns.split(';')[1] + '\n' + 'IP:18.168.217.247], Server:[' +
      element.serverName + ']', 'Zamknij',
      {duration: 55000, panelClass: 'snack-confirm', verticalPosition: 'bottom'}
    );
  }

  showSSL(element: Domain) {
    this.snackBar.open('SSL:' + element.seo.sslDetails, 'Zamknij',
      {duration: 55000, panelClass: 'snack-confirm', verticalPosition: 'bottom'}
    );
  }

  render(element: Domain) {
    this.domainsService.rerenderDomain(element.id).subscribe(value => {
      this.snackBar.open('Renderowanie domeny ' + element.name + ' w trakcie!', 'Zamknij',
        {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    }, error1 => {
      this.snackBar.open('Wystąpił błąd podczas renderowania domeny ' + element.name + ', sprawdź logi serwera', 'Zamknij',
        {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    });
  }

}






