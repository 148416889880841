import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {OrderRequest} from '../../../client/model/orderRequest';
import {DropDownItemDTO} from '../../commons/DropDownItemDTO';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {OrderService} from '../../../client/api/order.service';
import {Tag} from '../../../client/model/tag';
import {Observable} from 'rxjs/internal/Observable';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {TagService} from '../../../client/api/tag.service';
import {map, startWith} from 'rxjs/operators';
import {DomainSearchListItem} from '../../../client/model/domainSearchListItem';
import {CopywriterSelectListItem} from '../../../client/model/copywriterSelectListItem';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {

  orderTypes: DropDownItemDTO[] = [
    new DropDownItemDTO('PARAPHRASE', 'Parafraza'),
    new DropDownItemDTO('ARTICLE', 'Artykuł'),
    new DropDownItemDTO('DESCRIPTION', 'Opis firmy, produktu i inne')
  ];

  orderRequest: OrderRequest;
  form: UntypedFormGroup;
  errorMessage: String;
  copywriters: CopywriterSelectListItem[];
  defaultCopywriterId: string;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  filteredTags: Observable<Tag[]>;
  tagCtrl = new UntypedFormControl();
  tags: Tag[] = [];
  allTags: Tag[];

  separatorKeysCodes = [ENTER, COMMA];

  selectedDomains: Array<DomainSearchListItem> = [];

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: UntypedFormBuilder,
              private orderService: OrderService,
              private copywriterService: CopywriterService,
              private tagService: TagService,
              private dialog: MatDialog,
              public progres: ProgressIndicatorService) {
    this.orderRequest = data.orderRequest;
    this.defaultCopywriterId = data.defaultCopywriterId ? data.defaultCopywriterId : '';
  }

  ngOnInit() {
    this.initForm();
    this.initCopywritersDDL();
    this.initTagsDDL();
  }

  initForm() {
    this.form = this.formBuilder.group({
      type: new UntypedFormControl(OrderRequest.TypeEnum.PARAPHRASE),
      quantity: new UntypedFormControl(this.orderRequest.quantity),
      length: new UntypedFormControl(this.orderRequest.length),
      copywriters: new UntypedFormControl([], Validators.required),
      paraphraseUrl: new UntypedFormControl(null, Validators.required),
      externalId: new UntypedFormControl(this.orderRequest.externalId),
      note: new UntypedFormControl(),
      autoPublish: new UntypedFormControl(false),
      tags: this.formBuilder.array(this.tags),
      dueDate: new UntypedFormControl((this.orderRequest.dueDate))
    });
  }

  initCopywritersDDL() {
    this.copywriterService.getCopywritersSelectList().subscribe(response => {
        this.copywriters = response;
        if (this.defaultCopywriterId) {
          this.form.get('copywriters').setValue([this.defaultCopywriterId]);
        }
      },
      error =>
        this.errorMessage = <any>error);
  }

  initTagsDDL() {
    this.tagService.getTags().subscribe(response => {
      this.allTags = response;

      this.refreshTagList();
    });
  }

  private _filter(value: any): Tag[] {
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.trim().toLowerCase();
    } else {
      filterValue = value.name.trim().toLowerCase();
    }

    let filterResult = this.allTags.filter(tag => tag.name.trim().toLowerCase().indexOf(filterValue) === 0);

    return filterResult;
  }

  private _sortTags(tag1: Tag, tag2: Tag) {
    let val1 = tag1.name.toLowerCase();
    let val2 = tag2.name.toLowerCase();

    return val1 < val2 ? -1 : val2 > val1 ? 1 : 0;
  }

  addTag(event: MatChipInputEvent): void {

    let input = event.input;
    let value = event.value.trim();

    let tag = {
      name: value
    };

    // Add our requirement
    if ((value || '').trim()) {
      const tags = this.form.get('tags') as UntypedFormArray;

      tags.push(this.formBuilder.control(tag));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    let removedTag = tags.value[index];

    if (index >= 0) {
      tags.removeAt(index);
    }

    this.allTags.push(removedTag);
    this.refreshTagList();
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {

    const tags = this.form.get('tags') as UntypedFormArray;
    tags.push(this.formBuilder.control(event.option.value));

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    let selectedTagIdx = this.allTags.indexOf(event.option.value);
    this.allTags.splice(selectedTagIdx, 1);

    this.refreshTagList();
  }

  refreshTagList() {
    let sortedTags = this.allTags.sort((tag1, tag2) => this._sortTags(tag1, tag2));
    this.allTags = sortedTags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: Tag | null) => tag ? this._filter(tag) : [])
    );
  }

  onKeyup($event) {
    if ($event.shiftKey == true && $event.keyCode == 8 && this.tagInput.nativeElement.value.length == 0) {
      const tags = this.form.get('tags') as UntypedFormArray;
      const lastTagIndex = tags.value.length - 1;
      if (lastTagIndex > -1) {
        this.removeTag(lastTagIndex);
        this.tagInput.nativeElement.focus();
      }
    }
  }

  onSubmit() {
    const isValid = this.selectedDomains.length > 0;

    if (isValid === false) {
      document.getElementById('domains-title').classList.add('error');
    }

    if (isValid && this.form.valid) {
      this.save();
    }
  }

  save() {
    this.progres.progress = true;
    this.orderRequest = this.form.value;
    this.orderRequest.copywriters = [];
    this.orderRequest.domains = [];

    let selectedCopywriters = this.form.get('copywriters') as UntypedFormArray;
    for (let id of selectedCopywriters.value) {
      this.orderRequest.copywriters.push({value: id});
    }

    for (let domain of this.selectedDomains) {
      this.orderRequest.domains.push({value: domain.id});
    }

    this.orderService.processOrderRequest(this.orderRequest).subscribe(() => {
        this.progres.progress = false;
        this.dialog.closeAll();
      }, error => {
        this.errorMessage = <any>error;
        this.progres.progress = false;
      }
    );
  }

  onOrderTypeChange($event) {
    if ($event.value == 'PARAPHRASE') {
      this.form.controls.paraphraseUrl.setValidators([Validators.required]);
    } else {
      this.form.controls.paraphraseUrl.setValue('');
      this.form.controls.paraphraseUrl.clearValidators();
    }
    this.form.controls.paraphraseUrl.updateValueAndValidity({onlySelf: true});
  }

  onDomainSelectionChange($event) {
    this.selectedDomains = $event;
  }

  get formData() {
    return <UntypedFormArray>this.form.get('tags');
  }
}
