import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../client/model/user';
import {UserService} from '../../../client/api/user.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-user-modal',
  templateUrl: './add-edit-user-modal.component.html',
  styleUrls: ['./add-edit-user-modal.component.css']
})
export class AddEditUserModalComponent implements OnInit {
  user: User;
  mode: string;
  passwordRepeat: string;

  constructor(private dialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: User,
              private userService: UserService) {
    if (this.data == null) {
      this.user = {
        active: true
      };
    } else {
      this.user = data;
      this.mode = 'edit';
    }
  }

  ngOnInit() {
  }

  save() {
    this.user.passwordRepeat = this.passwordRepeat;
    if (this.mode != 'edit') {
      this.userService.createUser(this.user).subscribe(value => {
        this.dialog.closeAll();
      });
    } else {
      this.userService.updateUser(this.user.id, this.user).subscribe(value => {
        this.dialog.closeAll();
      });
    }
  }
}
