import {Component, Input, OnInit} from '@angular/core';
import {Domain} from "../../../../../client/model/domain";

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.css']
})
export class AdvancedSettingsComponent implements OnInit {
  @Input() domain: Domain;

  constructor() {

  }

  ngOnInit() {
  }

  save() {

  }
}
