import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ServersService} from '../../../client/api/servers.service';
import {Server} from '../../../client/model/server';
import {IpAddress} from '../../../client/model/ipAddress';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-create-server-modal',
  templateUrl: './create-server-modal.component.html',
  styleUrls: ['./create-server-modal.component.css']
})
export class CreateServerModalComponent implements OnInit {

  server: Server;
  public mode: string;

  constructor(private serverService: ServersService,
              private dialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: string) {
    if (this.data != null) {
      this.serverService.getServer(this.data).subscribe(value => {
        this.server = value;
      });
      this.mode = 'edit';
    }
    this.server = {};
    this.server.ipAddresses = new Array<IpAddress>();

  }

  private replaceAll(text: string, searchValue: string, replaceValue: string): string {
    if (text.indexOf(searchValue) > 0) {
      return this.replaceAll(text.replace(searchValue, replaceValue), searchValue, replaceValue);
    }
    return text;
  }

  addNew() {
    var ip: IpAddress = {};
    this.server.ipAddresses.push(ip);
  }

  ngOnInit() {
  }

  save() {
    if (this.mode == 'edit') {
      this.serverService.updateServer(this.server.id, this.server)
        .subscribe(() => {
          this.dialog.closeAll();
          this.router.navigateByUrl('servers');
        });
    } else {
      this.serverService.addServer(this.server)
        .subscribe(() => {
          this.dialog.closeAll();
          this.router.navigateByUrl('servers');
        });
    }
  }

  delete(ip: IpAddress) {
    ip.deleted = true;
  }

}
