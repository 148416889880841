<h2 mat-dialog-title>Lista zleceń</h2>
<p>Lista zleceń dla copywritera: {{copywriter.firstName}} {{copywriter.lastName}} [{{copywriter.email}}]</p>

Zlecenia na miesiące:
<mat-button-toggle-group name="month" [(ngModel)]="month" aria-label="Zlecenia na miesiące:">
  <mat-button-toggle value="past">poprzedni</mat-button-toggle>
  <mat-button-toggle value="current">obecny</mat-button-toggle>
  <mat-button-toggle value="next">przyszły</mat-button-toggle>
</mat-button-toggle-group>

<mat-card>
  <table>
    <tr>
      <td>No.</td>
      <td>Zewnętrzne Id</td>
      <td>Termin oddania</td>
      <td>Typ zlecenia</td>
    </tr>
    <tr *ngFor="let item of getMonthValues() let i = index">
      <td>{{i + 1}}</td>
      <td>{{item.externalId}}</td>
      <td>{{item.dueDate}}</td>
      <td>{{item.type}}</td>
    </tr>
  </table>
</mat-card>


<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial>{{ copywriter.id ? 'Zapisz' : 'Dodaj' }}</button>
</mat-dialog-actions>
