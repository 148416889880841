import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../../client/model/domain';
import {ArticlesCategoryService} from '../../../../../client/api/articlesCategory.service';
import {ArticleCategories} from '../../../../../client/model/articleCategories';
import {ArticleCategoryModalComponent, ModalData} from '../article-category-modal/article-category-modal.component';
import {ArticleCategory} from '../../../../../client/model/articleCategory';
import {ProgressIndicatorService} from '../../../../commons/progress-indicator.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-article-categories-list',
  templateUrl: './article-categories-list.component.html',
  styleUrls: ['./article-categories-list.component.css']
})
export class ArticleCategoriesListComponent implements OnInit {

  @Input() domain: Domain;

  private categories: ArticleCategories;
  dataSource: MatTableDataSource<ArticleCategory>;
  displayedColumns: string[] = ['name', 'actions'];


  constructor(private articlesCategoryService: ArticlesCategoryService,
              public dialog: MatDialog,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getCategories();
  }


  openDialog() {
    const dialogRef = this.dialog.open(ArticleCategoryModalComponent,
      {
        data: new ModalData(this.domain, null)
      });

    dialogRef.afterClosed().subscribe(result => {
      this.getCategories();
    });
  }

  delete(element: ArticleCategory) {
    this.articlesCategoryService.deleteCategory(this.domain.id, element.id)
      .subscribe(() => {
        this.getCategories();
      }, error1 => {
        this.progres.progress = false;
        this.snackBar.open('Nie można usunąc kategori przypisanej do artykułu lub menu', 'Zamknij',
          {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });
  }

  edit(element) {
    const dialogRef = this.dialog.open(ArticleCategoryModalComponent, {
      data: new ModalData(this.domain, element)
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCategories();
    });
  }

  private getCategories() {
    this.articlesCategoryService.getCategories(this.domain.id).subscribe(
      response => {
        this.categories = response;
        this.dataSource = new MatTableDataSource(this.categories);
      },
      error1 => {
      });
  }

}
