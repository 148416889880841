<div>
  <div class="full-width-container" *ngIf="limit>1">
    <mat-label>{{fileListLabel}}:</mat-label>
    <div *ngFor="let item of uploadedFiles">{{item.relativePath}}/{{item.filename}}
      <button mat-button [disabled]="!canDelete" (click)="delete(item)" matTooltip="Usuń">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-button [disabled]="!canDelete" (click)="download(item)" *ngIf="fileList.get(item.id)==null"
              matTooltip="Pobierz">
        <mat-icon>file_download</mat-icon>
      </button>
      <mat-progress-bar *ngIf="showDownloadProgressBar && fileId==item.id" mode="determinate"
                        value="progressDownloadPercentage"></mat-progress-bar>
      <a mat-button [disabled]="!canDelete" [href]="getUrl(item)" download="{{item.filename}}"
         *ngIf="fileList.get(item.id)!=null" matTooltip="Zapisz">
        <mat-icon color="primary">save</mat-icon>
      </a>
      <div *ngIf="preview" class="imageThumbnail">
        <img [src]="thumbnail(item)" style="width:30%"/>
      </div>

    </div>

  </div>
  <div class="full-width-container" *ngIf="limit==1 && !preview">
    <mat-label>{{fileListLabel}}:</mat-label>
    <p *ngIf="uploadedFile!=null && uploadedFile.filename!=null">{{uploadedFile.relativePath}}/{{uploadedFile.filename}}
    </p>
  </div>
  <div *ngIf="preview && imageData!=null && limit==1">
    <img [src]="imageData" style="width:50%"> <br/>
  </div>

  <mat-card>
    <mat-label *ngIf="relativePaths !=null && relativePaths.length>1">Wybierz typ pliku:</mat-label>
    <mat-select placeholder="Typy" *ngIf="relativePaths !=null && relativePaths.length>1"
                [(value)]="relativePath">
      <mat-option *ngFor="let rPath of relativePaths" [value]="rPath[0]">
        {{rPath[1]}}
      </mat-option>
    </mat-select>


    <div *ngIf="pixabayAllowed">
      <p>Poszukaj obrazka w PIXABAY: </p>


      <button (click)="openPixabayModal()"><img src="assets/img/pixabay_logo.png" width="80px"
                                                alt="Poszukaj na Pixabay"></button>
      <p class="snack-error">
        <b>PIXABAY to zalecana opcja dodawania obrazków dla artykułów, tylko w wyjątkowych sytuacjach proszę uploadować
          obrazki z dysku, maksymalny rozmiar 1MB</b>
      </p>
    </div>

    <div class="file-drop-area">
      <span class="fake-btn">Wybierz plik</span>
      <span class="file-msg">lub przeciągnij tutaj</span>
      <input class="file-input" type="file" multiple (change)="upload($event)"
             [disabled]="uploadedFiles !=null && uploadedFiles.length>=limit">
      <mat-progress-bar *ngIf="showProgressBar" mode="determinate" value="progressPercentage"></mat-progress-bar>
    </div>

  </mat-card>
</div>
