import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomainSearchListItem} from '../../../client/model/domainSearchListItem';
import {OrderService} from '../../../client/api/order.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-domain-search',
  templateUrl: './domain-search.component.html',
  styleUrls: ['./domain-search.component.css']
})
export class DomainSearchComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['select', 'domain', 'categories', 'external'];
  domains: DomainSearchListItem[];
  dataSource: MatTableDataSource<DomainSearchListItem>

  @Input() selected = [];

  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<DomainSearchListItem> = new SelectionModel<DomainSearchListItem>();
  @Output() selectionChanged = new EventEmitter<Array<DomainSearchListItem>>();

  constructor(private orderService: OrderService) {
  }

  ngOnInit() {
    this.orderService.getDomains('true').subscribe(response => {
      this.domains = response;
      this.dataSource = new MatTableDataSource<DomainSearchListItem>(this.domains);
      this.dataSource.paginator = this.paginator;

      if (this.selected != null && this.selected.length > 0) {
        const selection = this.selected.map(value => {
          const domainIndex = this.getDomainIndexById(value.id);
          return this.domains[domainIndex];
        });
        this.initialSelection = selection;
      }

      this.selection = new SelectionModel<DomainSearchListItem>(this.allowMultiSelect, this.initialSelection);
    });
  }

  getDomainIndexById(id: string) {
    return this.domains.findIndex(value => value.id == id);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.selectionChanged.emit(this.selection.selected);
  }

  onSelectAll($event) {
    if ($event) {
      this.masterToggle();
    }
  }

  onSelectionChange($event, row) {
    if ($event) {
      this.selection.toggle(row);
    }
    this.selectionChanged.emit(this.selection.selected);
  }
}
