<h2 mat-dialog-title *ngIf="mode!='edit'">Nowy użytkownik</h2>
<h2 mat-dialog-title *ngIf="mode=='edit'">Edytuj użytkownika</h2>

<mat-dialog-content class="mat-typography">
  <form class="server_form">
    <div fxLayout="column" fxLayoutAlign=" stretch">
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Login" name="username" [(ngModel)]="user.username">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Imię" name="firstName" [(ngModel)]="user.firstName">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Nazwisko" name="lastName" [(ngModel)]="user.lastName">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Email" name="email" [(ngModel)]="user.email">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput type="password" placeholder="Hasło" name="Password"
               [(ngModel)]="user.password">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput type="password" placeholder="Powtórz hasło" name="PasswordRepeat"
               [(ngModel)]="passwordRepeat">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Telefon" name="phone" [(ngModel)]="user.phone">
      </mat-form-field>
      <mat-checkbox [(ngModel)]="user.active" name="isActive">Konto aktywne
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="user.deleted" name="deleted">Konto usunięte
      </mat-checkbox>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
