import {Component, OnInit} from '@angular/core';
import {NewCategoryComponent} from '../new-category/new-category.component';
import {DomainCategory} from '../../../client/model/domainCategory';
import {DomainCategoryService} from '../../../client/api/domaincategory.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css']
})
export class CategoriesListComponent implements OnInit {

  private categories: DomainCategory[];
  dataSource: MatTableDataSource<DomainCategory>;
  displayedColumns: string[] = ['name', 'dedicated', 'actions'];


  constructor(private categoryService: DomainCategoryService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getCategories();
  }


  openDialog() {
    const dialogRef = this.dialog.open(NewCategoryComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.getCategories();
    });
  }

  delete(element) {
    this.categoryService.deleteCategory(element.id).subscribe(value => {
      this.getCategories();
    }, error => {
      this.snackBar.open('Nie można usunąć kategorii przypisanej do domeny', 'Zamknij',
        {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    });
  }

  edit(element) {
    const dialogRef = this.dialog.open(NewCategoryComponent, {
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCategories();
    });
  }

  private getCategories() {
    this.categoryService.getCategories().subscribe(
      response => {
        this.categories = response;
        this.dataSource = new MatTableDataSource(this.categories);
      },
      error1 => {
      });
  }
}
