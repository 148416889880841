<div class="full-width-container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" multiTemplateDataRows>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
      <td mat-cell *matCellDef="let element">
        {{element.id}}
      </td>
    </ng-container>
    <ng-container matColumnDef="tag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
      <td mat-cell *matCellDef="let element">
        {{element.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdOn.replace("T", " ").split(".")[0]}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Akcje</th>
      <td mat-cell *matCellDef="let element">
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[100, 50, 20]" showFirstLastButtons></mat-paginator>
</div>
