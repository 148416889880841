<h2 mat-dialog-title>Zmiana opiekuna zlecenia</h2>

<mat-dialog-content>
  <div *ngIf="order">
  <p>Zlecenie aktualnie przypisane do: <b>{{order.orderKeeper}}</b></p>
  </div>

  <div *ngIf="!order">
    <p>Hurtowe przepisywanie zleceń do nowego opiekuna</p>
  </div>

  <p>Wybierz z listy do kogo zlecenie ma zostać przypisane:</p>
  <form [formGroup]="editForm" class="full-width-container">

  <mat-form-field class="full-width-container">
    <input type="text" matInput placeholder="Opiekun" formControlName="copywriterGroup"
           [matAutocomplete]="autoGroup" class="full-width-container">
    <mat-autocomplete #autoGroup="matAutocomplete" class="full-width-container">
      <mat-optgroup *ngFor="let group of keeperGroupOptions | async" [label]="group.letter">
        <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setKeeperId(name.id)">
          {{name.name}}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial id="saveBtn" [disabled]="inProgress" (click)="save()">Zapisz</button>
</mat-dialog-actions>
