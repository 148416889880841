import {Component, Inject} from '@angular/core';
import {ArticleDetails} from '../../../../client/model/articleDetails';
import {OrderAiListItem} from '../../../../client/model/orderAiListItem';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleService} from '../../../../client/api/article.service';
import {DomSanitizer} from '@angular/platform-browser';
import {OpenAieditComponent} from '../open-aiedit/open-aiedit.component';
import {OrderListItem} from '../../../../client/model/orderListItem';
import {OrderAiService} from '../../../../client/api/orderai.service';

@Component({
  selector: 'app-open-ai-preview',
  templateUrl: './open-ai-preview.component.html',
  styleUrls: ['./open-ai-preview.component.css']
})
export class OpenAiPreviewComponent {

  article: ArticleDetails;
  order: OrderAiListItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private dialogRef: MatDialogRef<OpenAiPreviewComponent>,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer) {
    this.article = data.article;
    this.order = data.order;
  }

  edit() {
    this.articleService.getArticle(this.article.id).subscribe(article => {
      const dialogRef = this.dialog.open(OpenAieditComponent, {
        width: '80%',
        data: {
          article: article,
            order: this.order
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(this.order);
      });
    });
  }
}
