export class UserDto {
  public id: string;
  public username: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public roles: string[];


  constructor(id: string, username: string, email: string, firstName: string, lastName: string, roles: string[]) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.roles = roles;
  }
}
