<h2 mat-dialog-title *ngIf="ad.type=='ADS'">{{title}} ogłoszenie</h2>
<h2 mat-dialog-title *ngIf="ad.type=='COMPANY'">{{title}} profil firmy</h2>


<form [formGroup]="form" class="example-form" (ngSubmit)="save()">

  <mat-dialog-content class="mat-typography">

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Tytuł" formControlName="title"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Kategoria nadrzędna" formControlName="category"
                    name="category">
          <mat-option *ngFor="let cat of getCategories()" [value]="cat.id">
            {{cat.categoryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <textarea matInput rows="10" matInput placeholder="Treść ogłoszenia" formControlName="content"></textarea>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Cena" formControlName="price"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Imię" formControlName="name"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Email" formControlName="email"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Telefon" formControlName="phone"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Adres" formControlName="address"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <app-file-upload [canDelete]="true"
                       [fileInputLabel]=""
                       [fileListLabel]="'Zdjęcia do ogłoszenia'"
                       [limit]="6"
                       [uploadedFiles]="photos"
                       [preview]="true"
                       [relativePaths]="relativePaths"
                       [isConvertToWebp]="true">
      </app-file-upload>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial id="saveBtn">{{ ad.id ? 'Zapisz' : 'Dodaj' }}</button>
  </mat-dialog-actions>
</form>

