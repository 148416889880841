<form class="example-form">
  <div class="full-width-container">
    <mat-form-field>
      <mat-select placeholder="Struktura URL:" [(value)]="domain.urlStructure">
        <mat-option value="WITH_CATEGORY">
          Z kategorią
        </mat-option>
        <mat-option value="WITHOUT_CATEGORY">
          Bez kategorii
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-checkbox [(ngModel)]="domain.showExtension" name="showExtension">Pokazuj rozszerzenia plików w adresie strony (*.html)
    </mat-checkbox>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Kod w <head>" [(ngModel)]="domain.headCode" name="headCode" rows="18"></textarea>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Kod GTM body" [(ngModel)]="domain.gtmCode" name="gtmCode" rows="18"></textarea>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Dodatkowe wpisy do pliku .htaccess" [(ngModel)]="domain.htaccess" name="htaccess" rows="18"></textarea>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Nagłówki widgetów w sidebarze (oddzielone przecinkiem)" [(ngModel)]="domain.widgetTitles" name="widgetTitles" rows="3"></textarea>
    </mat-form-field>
  </div>
  <div class="full-width-container" *ngFor="let item of domain.placeholders">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="{{item.name}}" [(ngModel)]="item.value" name="{{item.name}}"></textarea>
    </mat-form-field>
  </div>
</form>


