<mat-card *ngIf="loaded">
  <h2 mat-card-title *ngIf="id == 'new'">Nowa kampania</h2>
  <h2 mat-card-title *ngIf="id != 'new'">Edycja kampanii {{campaign.name}}</h2>
  <mat-card-content class="full-width-container">
    <mat-form-field>
      <input matInput placeholder="Nazwa kampanii" [(ngModel)]="campaign.name">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nazwa projektu" [(ngModel)]="campaign.projectName" [disabled]="true">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Zewnętrzne ID" [(ngModel)]="campaign.externalId">
    </mat-form-field>
    <mat-checkbox class="example-margin" [(ngModel)]="campaign.status">Kampania aktywna (Włączona)</mat-checkbox>
    <mat-form-field>
      <input matInput type="number" placeholder="Maksymalna ilość podmian na artykuł "
             [(ngModel)]="campaign.maxNumberOfLinksPerArticle">
    </mat-form-field>
    <div>
      <a mat-button mat-raised-button color="primary" [href]="fileUrl" download="raport.csv">Pobierz raport
      </a>
    </div>
    <div *ngIf="id != 'new'">
      <p>
        <button mat-button (click)="addNew()">
          <mat-icon>add_circle</mat-icon>
        </button>
      </p>

      <table mat-table [dataSource]="linkDataSource" matSort class="mat-elevation-z8" multiTemplateDataRows>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>URL</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <input matInput required placeholder="Url" type="text" [(ngModel)]=element.url>
              <mat-icon matSuffix>mode_edit</mat-icon>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="anchor">
          <th mat-header-cell *matHeaderCellDef>Anchor</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <input matInput placeholder="anchor" type="text" [(ngModel)]=element.anchor>
              <mat-icon matSuffix>mode_edit</mat-icon>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="replaceKey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Podmieniaj słowo</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <input matInput placeholder="podmieniaj słowo" type="text" [(ngModel)]=element.replaceKey>
              <mat-icon matSuffix>mode_edit</mat-icon>
              <p *ngIf="element.replaceKey.length<2" class="mat-error">Podaj conajmniej dwa znaki</p>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="nofollow">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>% nofollow</th>
          <td mat-cell *matCellDef="let element">
            <input matInput type="number" min="0" max="100" [(ngModel)]=element.nofollow>
          </td>
        </ng-container>
        <ng-container matColumnDef="allocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydział</th>
          <td mat-cell *matCellDef="let element">
            <app-allocation-setup [allocation]="element.allocation"
                                  (allocationUpdated)="updateAllocation(element, $event)"></app-allocation-setup>
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Linków na domene</th>
          <td mat-cell *matCellDef="let element">
            <input matInput type="number" min="1" [(ngModel)]=element.amount>
          </td>
        </ng-container>
        <ng-container matColumnDef="domains">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Artykuły</th>
          <td mat-cell *matCellDef="let element" (click)="expandedElement = element">
            {{articleLabel(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]=element.status></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="stan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Stan</th>
          <td mat-cell *matCellDef="let element" (click)="showState(element)">
            {{getStateLabel(element.state)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Usuń</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="delete(element)" matTooltip="Usuń" color="warn">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <!--Artykuły-->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="linkDisplayedColumns.length">
            <div class="full-width-container"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mat-card>
                <h2 mat-card-title>Przypisz artykuły:</h2>
                <mat-card-content>
                  <app-article-search *ngIf="element == expandedElement"
                                      (selectionChanged)="onArticleSelectionChange($event, element)"
                                      (replaceKeyWord)="element.replaceKey=$event"
                                      [selected]="element.articles"
                                      [word]="element.replaceKey"
                                      [count]="element.amount"
                                      [maxAssignments]="campaign.maxNumberOfLinksPerArticle">
                  </app-article-search>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button color="primary" mat-raised-button (click)="saveDomains(element)">Zapisz wybrane
                    artykuły
                  </button>
                </mat-card-actions>
              </mat-card>
              <mat-divider></mat-divider>
              <div>
                <h2 mat-card-title>Przypisane artykuły:</h2>
                <div>
                  <table class="mat-table">
                    <tr class="mat-row">
                      <td>Tytuł artykułu [Link]</td>
                      <td>Adres linku</td>
                      <td>Data publikacji</td>
                      <td>Stan</td>
                    </tr>
                    <tr *ngFor="let assigment of element.articles" class="mat-row">
                      <td>{{assigment.articleTitle}}
                        <a href="{{assigment.articleUrl}}" rel="nofollow noreferrer noopener"
                           target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
                          <button mat-icon-button color="primary">
                            <mat-icon>launch</mat-icon>
                          </button>
                        </a>
                      </td>
                      <td>{{assigment.linkUrl}}</td>
                      <td>{{assigment.publicationDate.replace('T', ' ').split('.')[0]}}</td>
                      <td><input type="checkbox" checked="{{assigment.published}}" disabled="true"/></td>
                      <td>
                        <button mat-icon-button (click)="deleteAssigment(element.id, assigment.assigmentId)"
                                matTooltip="Usuń przypisanie" color="warn">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="linkDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: linkDisplayedColumns;"
            class="example-element-row" [ngClass]="{'invalid': element.invalid}"
            [class.example-expanded-row]="expandedElement === element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-button [routerLink]="['/project', campaign.projectId]">Wróc</button>
    <button mat-button mat-raised-button color="secondary" (click)="saveLinkCampaign(false)" *ngIf="id != 'new'">
      Zapisz
    </button>
    <button mat-button mat-raised-button color="primary" (click)="saveLinkCampaign(true)">Zapisz i wróc do listy
    </button>
  </mat-card-actions>
</mat-card>
