<div class="content" fxLayout="column" fxLayoutAlign="center center">
  <img mat-card-image src="assets/img/logo.png" alt="logo"/>

  <form #loginForm="ngForm" id="loginForm" fxLayout="column" widthFill>
    <mat-form-field appearance="outline">
      <mat-label>
        login
      </mat-label>
      <input matInput placeholder="login" [(ngModel)]="loginCommand.login" id="login" name="login" maxlength="250"
             required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        hasło
      </mat-label>
      <input matInput type="password" placeholder="hasło" [(ngModel)]="loginCommand.password" id="password"
             name="password" maxlength="250" required>
    </mat-form-field>
    <button class="login-button mat-raised-button" mat-raised-button color="primary" (click)="onSubmit()">
      zaloguj się
    </button>
  </form>

</div>
