import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../commons/CustomValidators';
import {Copywriter} from '../../../client/model/copywriter';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {DomainCategoryService} from '../../../client/api/domaincategory.service';
import {DomainCategory} from '../../../client/model/domainCategory';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-copywriter-edit',
  templateUrl: './copywriter-edit.component.html',
  styleUrls: ['./copywriter-edit.component.css']
})
export class CopywriterEditComponent implements OnInit {

  copywriter: Copywriter;
  form: UntypedFormGroup;
  categories: DomainCategory[] = [];
  errorMessage: String;
  title: string = 'Nowy copywriter';
  editMode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: UntypedFormBuilder,
              private copywriterService: CopywriterService,
              private categoriesService: DomainCategoryService,
              private dialog: MatDialog) {
    this.copywriter = data.copywriter;
    if (this.copywriter.id) {
      this.title = `Edycja: ${this.copywriter.firstName} ${this.copywriter.lastName}`;
      this.editMode = true;
    }
  }

  ngOnInit() {
    this.initForm();
    this.getCategories();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: this.copywriter.id,
      firstName: new UntypedFormControl(this.copywriter.firstName, Validators.required),
      lastName: new UntypedFormControl(this.copywriter.lastName, Validators.required),
      phone: this.copywriter.phone,
      email: new UntypedFormControl(this.copywriter.email, Validators.email), //TODO: add unique validation on form submit or lost focus
      password: new UntypedFormControl(this.copywriter.password),
      passwordConfirmation: new UntypedFormControl(this.copywriter.password, CustomValidators.matchPassword),
      categories: [],
      note: this.copywriter.note,
      paraphraseRate: new UntypedFormControl(this.copywriter.paraphraseRate, CustomValidators.isAmount),
      articleRate: new UntypedFormControl(this.copywriter.articleRate, CustomValidators.isAmount),
      descriptionRate: new UntypedFormControl(this.copywriter.descriptionRate, CustomValidators.isAmount),
      monthlyLimit: this.copywriter.monthlyLimit
    });
  }

  getCategories() {
    this.categoriesService.getCategories().subscribe(response => {
        this.categories = response;
        this.form.get('categories').setValue(this.copywriter.categories.map(c => c.id));
      },
      error =>
        this.errorMessage = <any>error);
  }

  onSubmit() {
    if (this.form.valid) {
      this.save();
    }
  }

  save() {
    const selectedCategories = this.form.get('categories') as UntypedFormArray;

    this.copywriter = this.form.value;

    this.copywriter.categories = [];

    for (let catId of selectedCategories.value) {
      this.copywriter.categories.push({id: catId});
    }

    if (this.copywriter.id) {
      this.copywriterService.updateCopywriter(this.copywriter.id, this.copywriter).subscribe(result => {
        this.dialog.closeAll();
      }, error => {
        this.errorMessage = <any>error;
      });
    } else {
      this.copywriterService.createCopywriter(this.copywriter).subscribe(() => {
        this.dialog.closeAll();
      }, error => {
        this.errorMessage = <any>error;
      });
    }
  }
}
