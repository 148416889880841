/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArticleDetails } from '../model/articleDetails';
import { CreateLinkCampaign } from '../model/createLinkCampaign';
import { LinkAssignmentUpdateRequest } from '../model/linkAssignmentUpdateRequest';
import { LinkCampaignDetails } from '../model/linkCampaignDetails';
import { LinkCampaignItems } from '../model/linkCampaignItems';
import { LinkCampaignReport } from '../model/linkCampaignReport';
import { LinkCampaignUpdateRequest } from '../model/linkCampaignUpdateRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LinkCampaignService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add assigment to campaign
     * This can only be done by the logged in user.
     * @param linkId 
     * @param linkAssignmentUpdateRequest Articles to assign link
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAssignmentToLink(linkId: string, linkAssignmentUpdateRequest: LinkAssignmentUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addAssignmentToLink(linkId: string, linkAssignmentUpdateRequest: LinkAssignmentUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addAssignmentToLink(linkId: string, linkAssignmentUpdateRequest: LinkAssignmentUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addAssignmentToLink(linkId: string, linkAssignmentUpdateRequest: LinkAssignmentUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling addAssignmentToLink.');
        }
        if (linkAssignmentUpdateRequest === null || linkAssignmentUpdateRequest === undefined) {
            throw new Error('Required parameter linkAssignmentUpdateRequest was null or undefined when calling addAssignmentToLink.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(linkId))}/assigment`,
            linkAssignmentUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add campaign to system
     * 
     * @param createLinkCampaign Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLinkCampaign(createLinkCampaign: CreateLinkCampaign, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addLinkCampaign(createLinkCampaign: CreateLinkCampaign, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addLinkCampaign(createLinkCampaign: CreateLinkCampaign, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addLinkCampaign(createLinkCampaign: CreateLinkCampaign, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (createLinkCampaign === null || createLinkCampaign === undefined) {
            throw new Error('Required parameter createLinkCampaign was null or undefined when calling addLinkCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/campaign/links`,
            createLinkCampaign,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add link to campaign
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param articleDetails Details of the order to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addLinkToCampaign(campaignId: string, articleDetails?: ArticleDetails, observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignDetails>;
    public addLinkToCampaign(campaignId: string, articleDetails?: ArticleDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignDetails>>;
    public addLinkToCampaign(campaignId: string, articleDetails?: ArticleDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignDetails>>;
    public addLinkToCampaign(campaignId: string, articleDetails?: ArticleDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling addLinkToCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LinkCampaignDetails>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}/link`,
            articleDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete assigment from campaign
     * This can only be done by the logged in user.
     * @param linkId 
     * @param assigmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssigmentFromLink(linkId: string, assigmentId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAssigmentFromLink(linkId: string, assigmentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAssigmentFromLink(linkId: string, assigmentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAssigmentFromLink(linkId: string, assigmentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling deleteAssigmentFromLink.');
        }
        if (assigmentId === null || assigmentId === undefined) {
            throw new Error('Required parameter assigmentId was null or undefined when calling deleteAssigmentFromLink.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(linkId))}/assigment/${encodeURIComponent(String(assigmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete link campaign details
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLinkCampaign(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteLinkCampaign(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteLinkCampaign(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteLinkCampaign(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteLinkCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete link from campaign
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param linkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLinkFromCampaign(campaignId: string, linkId: string, observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignDetails>;
    public deleteLinkFromCampaign(campaignId: string, linkId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignDetails>>;
    public deleteLinkFromCampaign(campaignId: string, linkId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignDetails>>;
    public deleteLinkFromCampaign(campaignId: string, linkId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteLinkFromCampaign.');
        }
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling deleteLinkFromCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LinkCampaignDetails>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}/link/${encodeURIComponent(String(linkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get link campaign details
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkCampaignDetails(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignDetails>;
    public getLinkCampaignDetails(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignDetails>>;
    public getLinkCampaignDetails(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignDetails>>;
    public getLinkCampaignDetails(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getLinkCampaignDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LinkCampaignDetails>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get link campaign report
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkCampaignReport(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignReport>;
    public getLinkCampaignReport(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignReport>>;
    public getLinkCampaignReport(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignReport>>;
    public getLinkCampaignReport(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getLinkCampaignReport.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LinkCampaignReport>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}/report`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get link campaigns 
     * This can only be done by the logged in user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkCampaigns(observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignItems>;
    public getLinkCampaigns(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignItems>>;
    public getLinkCampaigns(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignItems>>;
    public getLinkCampaigns(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LinkCampaignItems>(`${this.configuration.basePath}/campaign/links`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update campaign to system
     * 
     * @param campaignId 
     * @param linkCampaignUpdateRequest Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLinkCampaign(campaignId: string, linkCampaignUpdateRequest: LinkCampaignUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<LinkCampaignDetails>;
    public updateLinkCampaign(campaignId: string, linkCampaignUpdateRequest: LinkCampaignUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkCampaignDetails>>;
    public updateLinkCampaign(campaignId: string, linkCampaignUpdateRequest: LinkCampaignUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkCampaignDetails>>;
    public updateLinkCampaign(campaignId: string, linkCampaignUpdateRequest: LinkCampaignUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling updateLinkCampaign.');
        }
        if (linkCampaignUpdateRequest === null || linkCampaignUpdateRequest === undefined) {
            throw new Error('Required parameter linkCampaignUpdateRequest was null or undefined when calling updateLinkCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LinkCampaignDetails>(`${this.configuration.basePath}/campaign/links/${encodeURIComponent(String(campaignId))}`,
            linkCampaignUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
