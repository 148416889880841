<div class="article-list-container">
  <p>
    <button mat-button (click)="createAd()">
      <mat-icon>add_circle</mat-icon>
      Dodaj ogłosznie
    </button>
    <button mat-button (click)="createCompany()">
      <mat-icon>add_circle</mat-icon>
      Dodaj firmę do katalogu
    </button>
  </p>
  <div class="full-width-container">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa</th>
        <td mat-cell *matCellDef="let element"> {{element.title}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
        <td mat-cell *matCellDef="let element"> {{element.type}}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategoria</th>
        <td mat-cell *matCellDef="let element"> {{element.category}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Edycja/Usuń</th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button (click)="edit(element)">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button (click)="delete(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
</div>
