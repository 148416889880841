/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { FileDto } from '../model/fileDto';
import { UploadResult } from '../model/uploadResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class FileService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download file by id
     * 
     * @param fileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFile(fileId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadFile(fileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadFile(fileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadFile(fileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling downloadFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/binary',
            'image/png'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileId))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download file by id
     * 
     * @param fileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPngFile(fileId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadPngFile(fileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadPngFile(fileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadPngFile(fileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling downloadPngFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/png'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileId))}/image`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uploads a file.
     * 
     * @param relativePath relative path of file. e.g.: /styles, /img
     * @param scaleImage image scaling
     * @param convertToWebp Convert uploaded image to WebP format
     * @param upfile The file to upload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFile(relativePath?: string, scaleImage?: boolean, convertToWebp?: boolean, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<FileDto>;
    public uploadFile(relativePath?: string, scaleImage?: boolean, convertToWebp?: boolean, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileDto>>;
    public uploadFile(relativePath?: string, scaleImage?: boolean, convertToWebp?: boolean, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileDto>>;
    public uploadFile(relativePath?: string, scaleImage?: boolean, convertToWebp?: boolean, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (relativePath !== undefined && relativePath !== null) {
            queryParameters = queryParameters.set('relativePath', <any>relativePath);
        }
        if (scaleImage !== undefined && scaleImage !== null) {
            queryParameters = queryParameters.set('scaleImage', <any>scaleImage);
        }
        if (convertToWebp !== undefined && convertToWebp !== null) {
            queryParameters = queryParameters.set('convertToWebp', <any>convertToWebp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
            formParams = formParams.append('upfile', <any>upfile) || formParams;
        }

        return this.httpClient.post<FileDto>(`${this.configuration.basePath}/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uploads a file.
     * 
     * @param publisherId uuid of publisher
     * @param upfile The file to upload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPublisherFile(publisherId?: string, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadResult>;
    public uploadPublisherFile(publisherId?: string, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadResult>>;
    public uploadPublisherFile(publisherId?: string, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadResult>>;
    public uploadPublisherFile(publisherId?: string, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (publisherId !== undefined && publisherId !== null) {
            queryParameters = queryParameters.set('publisherId', <any>publisherId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
            formParams = formParams.append('upfile', <any>upfile) || formParams;
        }

        return this.httpClient.post<UploadResult>(`${this.configuration.basePath}/upload/publishers`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
