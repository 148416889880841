<h2 mat-dialog-title>{{title}}</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div fxLayout="column" fxLayoutAlign="center stretch" class="scrollable-form-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Edycja copywritera</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <input type="hidden" formControlName="id">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput placeholder="Imię" formControlName="firstName">
              <mat-error>
                To pole jest wymagane
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput placeholder="Nazwisko" formControlName="lastName">
              <mat-error>
                To pole jest wymagane
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="email" placeholder="E-mail" formControlName="email">
              <mat-error>
                Podaj poprawny adres e-mail
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput placeholder="Telefon" formControlName="phone">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="password" placeholder="Hasło" formControlName="password">
            </mat-form-field>
          </div>
        </div>
        <div width="50">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="password" placeholder="Powtórz hasło" formControlName="passwordConfirmation">
              <mat-error>
                Niepoprawne hasło
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field>
          <mat-select placeholder="Preferowane kategorie" formControlName="categories" multiple>
            <mat-option *ngFor="let cat of categories" [value]="cat.id">
              {{cat.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="center stretch">
        <textarea placeholder="Notatka" formControlName="note" class="resizable-textarea"></textarea>
      </div>

      <div fxLayout="column" fxLayoutAlign="center stretch">
        <label>Stawki (zł netto za 1k znaków)</label>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <div width="33">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="number" placeholder="Parafrazy" formControlName="paraphraseRate">
              <mat-error>
                Podaj poprawną wartość
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div width="33">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="number" placeholder="Link building" formControlName="articleRate">
              <mat-error>
                Podaj poprawną wartość
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div width="33">
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field>
              <input matInput type="number" placeholder="Inne" formControlName="descriptionRate">
              <mat-error>
                Podaj poprawną wartość
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field class="full-width">
          <input matInput type="number" placeholder="Limit miesięczny zleceń" formControlName="monthlyLimit">
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="editMode">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Płatności</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-payments-list [copywriterId]="copywriter.id" [adminMode]="true"></app-payments-list>
      </mat-expansion-panel>
    </div>

    <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="editMode">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Powiadomienia</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-notifications-config [copywriterId]="copywriter.id" [adminMode]="true"></app-notifications-config>
      </mat-expansion-panel>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial>{{ copywriter.id ? 'Zapisz' : 'Dodaj' }}</button>
  </mat-dialog-actions>
</form>
