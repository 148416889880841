<div class="orders-admin-list-container">

  <mat-expansion-panel>
    <mat-expansion-panel-header>Wyszukiwanie</mat-expansion-panel-header>
    <form [formGroup]="searchForm" class="full-width-container">
      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Domena" formControlName="domainGroup"
               [matAutocomplete]="domainAutoGroup" class="full-width-container">
        <mat-autocomplete #domainAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of domainGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setDomainId(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Copywriter" formControlName="copywriterGroup"
               [matAutocomplete]="autoGroup" class="full-width-container">
        <mat-autocomplete #autoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of copywriterGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setCopywriterId(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Zlecający" formControlName="orderAuthorGroup"
               [matAutocomplete]="orderAutoGroup" class="full-width-container">
        <mat-autocomplete #orderAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of orderAuthorGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setOrderAutorId(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Opiekun" formControlName="orderKeeperGroup"
               [matAutocomplete]="orderKeeperAutoGroup" class="full-width-container">
        <mat-autocomplete #orderKeeperAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of orderKeeperGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setOrderKeeperId(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Zewnętrzne Id" formControlName="externalId"
               class="full-width-container">
      </mat-form-field>
      <div>
        <button width="40" mat-button mat-raised-button color="accent"
                (click)="searchOrders($event.pageSize,$event.pageIndex)">Szukaj
        </button>
        <button width="40" mat-button color="primary" (click)="resetForm()">Wyczyść</button>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>Akcje dla zaznaczonych zleceń</mat-expansion-panel-header>

    <button mat-raised-button color="primary" (click)="reassignSelected()" matTooltip="Zmień copywritera">
      Zmień copywritera
      <mat-icon>face</mat-icon>
    </button>


    <button mat-raised-button color="primary" (click)="reassignKeeperSelected()" matTooltip="Zmień opiekuna">
      Zmień opiekuna
      <mat-icon>edit</mat-icon>
    </button>

    <button mat-raised-button color="primary" (click)="approveSelected()" matTooltip="Zakceptuj zaznaczone">
      Zakceptuj zaznaczone
      <mat-icon>done</mat-icon>
    </button>

    <button mat-flat-button color="warn" (click)="deleteSelected()" *ngIf="onlyNewOrders" matTooltip="Usuń">
      Usuń
      <mat-icon>delete</mat-icon>
    </button>

    <button mat-button (click)="downloadSelected()" *ngIf="!getSelected() && onlyPublishedOrders"
            matTooltip="Pobierz">
      <mat-icon>file_download</mat-icon>
      Pobierz zaznaczone artykuły
    </button>
    <a mat-button [href]="getSelectedUrl()"
       download="artykuly.zip"
       *ngIf="getSelected()" matTooltip="Zapisz">
      <mat-icon color="primary">save</mat-icon>
      Zapisz plik
    </a>
    <mat-spinner class="center-spinner" *ngIf="showZipDownloadProgressBar"></mat-spinner>
  </mat-expansion-panel>


  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="domainName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Domena</th>

      <td mat-cell *matCellDef="let element">
        <p *ngIf="element.domainName!=null">
          {{element.domainName}}
          <a href="http://{{element.domainName}}" rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
            <button mat-icon-button color="primary">
              <mat-icon>launch</mat-icon>
            </button>
          </a>
        </p>

        <p *ngIf="element.externalDomain!=null">
          {{getExternalDomain(element.externalDomain)}}
          <a href="http://{{getExternalDomain(element.externalDomain)}}" rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
            <button mat-icon-button color="primary">
              <mat-icon>launch</mat-icon>
            </button>
          </a>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategoria</th>
      <td mat-cell *matCellDef="let element">
        {{element.categoryName}}{{getCategory(element.externalDomain)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="publisher">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Wydawca</th>
      <td mat-cell *matCellDef="let element">
        <p [innerHTML]="getPublisher(element.externalDomain)"></p>
      </td>
    </ng-container>
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cena</th>
      <td mat-cell *matCellDef="let element">
        <p matTooltip="{{getPriceDetails(element.externalDomain)}}"
           matTooltipClass="multiline-tooltip">{{getPrice(element.externalDomain)}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Słowa kluczowe</th>
      <td mat-cell *matCellDef="let element">
        <p *ngFor="let tag of element.tags">{{tag.name}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="articleTitle" *ngIf="!onlyNewOrders">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tytuł</th>
      <td mat-cell *matCellDef="let element">{{element.articleTitle}}</td>
    </ng-container>

    <ng-container matColumnDef="typeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
      <td mat-cell *matCellDef="let element">{{element.typeName}}</td>
    </ng-container>

    <ng-container matColumnDef="copywriterName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Copywriter</th>
      <td mat-cell *matCellDef="let element">{{element.copywriterName}}</td>
    </ng-container>

    <ng-container matColumnDef="createdByName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zlecono przez</th>
      <td mat-cell *matCellDef="let element">{{element.createdByName}} <br/> {{element.createdByEmail}}</td>
    </ng-container>


    <ng-container matColumnDef="orderKeeperName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Opiekun</th>
      <td mat-cell *matCellDef="let element">{{element.orderKeeperName}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{getStatusLabel(element.status)}}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zewnętrzne ID</th>
      <td mat-cell *matCellDef="let element">{{element.externalId}}</td>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data zlecenia</th>
      <td mat-cell *matCellDef="let element"
          matTooltip="{{element.createdOn.replace('T', ' ').split('.')[0]}}">{{element.createdOn.split('T')[0]}}</td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Termin oddania</th>
      <td mat-cell *matCellDef="let element">{{element.dueDate}}</td>
    </ng-container>

    <ng-container matColumnDef="articleCreatedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data oddania</th>
      <td mat-cell *matCellDef="let element"> {{element.articleCreatedOn}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-button (click)="download(element)"
                *ngIf="!onlyNewOrders && !getFile(element) && element.articleId!=null"
                matTooltip="Pobierz">
          <mat-icon>file_download</mat-icon>
        </button>
        <!--        <mat-progress-bar *ngIf="showDownloadProgressBar && fileId==element.id" mode="determinate"
                                  value="progressDownloadPercentage"></mat-progress-bar>-->
        <a mat-button [href]="getUrl(element)"
           [download]="formatFileName((element.externalId != null ? element.externalId + '_' : '') + element.articleTitle)"

           *ngIf="getFile(element)" matTooltip="Zapisz">
            <mat-icon color="primary">save</mat-icon>
        </a>

          <button mat-icon-button (click)="onPreviewClick(element)" *ngIf="!onlyNewOrders" matTooltip="Pokaż">
          <mat-icon>remove_red_eye</mat-icon>
        </button>

        <button mat-icon-button (click)="onShowOrderClick(element)" *ngIf="onlyNewOrders" matTooltip="Pokaż">
          <mat-icon>remove_red_eye</mat-icon>
        </button>

        <button mat-icon-button (click)="onEditArticleClick(element)" matTooltip="Edytuj tekst"
                *ngIf="element.articleId">
          <mat-icon>edit</mat-icon>
        </button>


        <button mat-icon-button (click)="onEditOrderClick(element)" color="primary" matTooltip="Edytuj zlecenie">
          <mat-icon>receipt</mat-icon>
        </button>

        <button mat-icon-button (click)="onEditCopywriterClick(element)" matTooltip="Zmień copywritera"
                *ngIf="!element.articleId">
          <mat-icon>face</mat-icon>
        </button>

        <button mat-icon-button (click)="onArchiveClick(element)" color="warn" *ngIf="onlyNewOrders || onlyReady "
                matTooltip="Usuń do kosza">
          <mat-icon>delete</mat-icon>
        </button>

        <button mat-icon-button (click)="onDeleteClick(element)" color="warn" *ngIf="onlyRemovedOrders"
                matTooltip="Usuń ">
          <mat-icon>remove</mat-icon>
        </button>
        <button mat-icon-button (click)="onRevertClick(element)" color="accent" *ngIf="onlyRemovedOrders"
                matTooltip="Przywróc">
          <mat-icon>undo</mat-icon>
        </button>

        <button mat-icon-button *ngIf="element.articleId.length>0" (click)="history(element)"
                matTooltip="Wersje">
          <mat-icon>history</mat-icon>
        </button>

        <a href="{{element.articleUrl}}" *ngIf="onlyPublishedOrders" target="_blank" rel="nofollow noreferrer noopener"
           matTooltip="Link">
          <button mat-icon-button>
            <mat-icon>launch</mat-icon>
          </button>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="totalOrders"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100,500]"
                 (page)="changePage($event)"
  ></mat-paginator>
</div>
