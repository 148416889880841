<div class="full-width-container">
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="bannerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Placeholder</th>
    <td mat-cell *matCellDef="let element">
      <p>{{element.bannerName}}
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="campaignName">
    <th mat-header-cell *matHeaderCellDef>Kampania</th>
    <td mat-cell *matCellDef="let element">
      <p>{{element.campaignName}}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="assignmentDate">
    <th mat-header-cell *matHeaderCellDef>Data publikacji</th>
    <td mat-cell *matCellDef="let element">
      <p>{{element.assignmentDate.replace('T', ' ').split('.')[0]}}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Edycja</th>
    <td mat-cell *matCellDef="let element">

      <button mat-icon-button [routerLink]="['/campaign/banner', element.campaignId]" matTooltip="Edytuj">
        <mat-icon>edit</mat-icon>
      </button>


    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>
