import { Component } from '@angular/core';
import {OrderAiService} from '../../../../client/api/orderai.service';

@Component({
  selector: 'app-open-aipanel',
  templateUrl: './open-aipanel.component.html',
  styleUrls: ['./open-aipanel.component.css']
})
export class OpenAIPanelComponent {


  constructor(private orderAiService: OrderAiService) {
  }

  submenu: string = 'orders';


}
