import {Component, Inject, OnInit} from '@angular/core';
import {OrderListItem} from '../../../client/model/orderListItem';
import {CustomDefinition, CustomGroup} from '../../orders/orders-admin-list/orders-admin-list.component';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {_filter} from '../../domains/domains.component';
import {OrderService} from '../../../client/api/order.service';
import {UpdateOrderRequest} from '../../../client/model/updateOrderRequest';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../client/api/user.service';
import {CreateOrderKeeper} from '../../../client/model/createOrderKeeper';

@Component({
  selector: 'app-change-order-copywriter',
  templateUrl: './change-order-keeper.component.html',
  styleUrls: ['./change-order-keeper.component.css']
})
export class ChangeOrderKeeperComponent implements OnInit {
  inProgress = false;
  order: OrderListItem;
  keeperGroups: CustomGroup[] = new Array();
  keeperGroupOptions: Observable<CustomGroup[]>;
  editForm: UntypedFormGroup = this.fb.group({
    copywriterGroup: ''
  });
  private keeperId: string;
  private orders: OrderListItem[];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService,
              private orderService: OrderService,
              private dialog: MatDialog,
              private fb: UntypedFormBuilder) {
    this.order = data.order;
    this.orders = data.orders;
  }

  ngOnInit() {
    this.getUsers();
    this.keeperGroupOptions = this.editForm.get('copywriterGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.keeperGroups))
      );
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  save() {
    this.inProgress = true;
    if (this.order) {
      const request: CreateOrderKeeper = {
        keeperId: this.keeperId
      };
      this.orderService.createOrderKeeper(this.order.id, request).subscribe(value => {
        this.dialog.closeAll();
      });
    } else {
      this.orders.forEach(value => {
        const request: CreateOrderKeeper = {
          keeperId: this.keeperId
        };
        this.orderService.createOrderKeeper(value.id, request).subscribe(value1 => {
        });
      });
      this.dialog.closeAll();
    }
  }

  private getUsers() {
    this.userService.getUsers().subscribe(value => {
      const copywriters = value;
      copywriters.forEach(value1 => {
        const valueToAdd: CustomDefinition = {
          name: value1.firstName + ' ' + value1.lastName,
          id: value1.id
        };
        this.addToGroup(valueToAdd, this.keeperGroups);
      });
    });
  }


  private addToGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  setKeeperId(id: string) {
    this.keeperId = id;
  }
}
