<mat-card>
  <mat-grid-list cols="6" rowHeight="1:1" *ngIf="loaded">
    <mat-grid-tile *ngFor="let item of templates"
                   [ngClass]="{'activeTemplate': domain.template!=null?domain.template.id == item.id:false}"
                   [matMenuTriggerFor]="menu">
      <mat-grid-tile-header>{{item.templateName}}</mat-grid-tile-header>
      <img *ngIf="item.templateThumbnail!=null && item.templateThumbnail.id!=null" [src]="getThumbnailFile(item)"
           layout-fill class="templateThumbnail"/>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setActive(item)">Wybierz</button>
        <button mat-menu-item [routerLink]="['/templates/edit', item.id]">Edytuj</button>
      </mat-menu>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
