import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DomainCategory} from '../../../client/model/domainCategory';
import {DomainCategoryService} from '../../../client/api/domaincategory.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.css']
})
export class NewCategoryComponent implements OnInit {

  protected categories: DomainCategory[] = new Array();
  public mode: string;
  private category: DomainCategory;
  protected tmpName = '';
  protected tmpValue = '';

  constructor(private categoryService: DomainCategoryService,
              private dialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: DomainCategory) {
    if (data != null && data.name.length > 0) {
      this.mode = 'edit';
      this.category = this.data;
      this.tmpName = this.category.name;
      this.tmpValue = this.category.name;
      this.categories.push(this.data);
    } else {
      var domainCategory: DomainCategory = {};
      this.category = domainCategory;
    }
  }

  ngOnInit() {
  }

  addNew() {
    let category: DomainCategory = {};
    this.categories.push(category);
  }

  save() {
    if (this.mode != 'edit') {
      this.categoryService.addCategory(this.categories).subscribe(() => {
        this.dialog.closeAll();
        this.router.navigateByUrl('categories');
      });
    } else {
      this.category.name = this.tmpValue;
      this.categoryService.updateCategory(this.category.id, this.category).subscribe(() => {
        this.dialog.closeAll();
        this.router.navigateByUrl('categories');
      });
    }
  }
}
