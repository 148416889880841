<div class="full-width-container">
  <div fxLayout="row" fxLayoutAlign="start none">
    <mat-form-field appearance="outline">
      <mat-label>Artykuły zawierające słowo:</mat-label>
      <input matInput [(ngModel)]="word" (change)="updateList()" placeholder="Artykuły zawierające słowo">
      <p *ngIf="word.length<2" class="mat-error">Podaj conajmniej dwa znaki</p>

    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Ilość artykułów per domena</mat-label>
      <input matInput [(ngModel)]="count" (change)="updateList()" type="number"
             placeholder="Ilość artykułów per domena">
    </mat-form-field>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtr">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #articleSearchSort="matSort" matSort>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onSelectAll($event)"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onSelectionChange($event, row)"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="domainName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Domena</th>
      <td mat-cell *matCellDef="let element"> {{element.domainName}}</td>
    </ng-container>

    <ng-container matColumnDef="domainCategory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kategoria domeny</th>
      <td mat-cell *matCellDef="let element"> {{element.domainCategory}}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Zewnętrzne Id</th>
      <td mat-cell *matCellDef="let element"> {{element.externalId}}</td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kategoria artykułu</th>
      <td mat-cell *matCellDef="let element"> {{element.category}}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tytuł artykułu</th>
      <td mat-cell *matCellDef="let element"> {{element.title}}
        <a href="{{element.articleUrl}}" rel="nofollow noreferrer noopener"
           target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
          <button mat-icon-button color="primary">
            <mat-icon>launch</mat-icon>
          </button>
        </a>
      </td>
    </ng-container>

<!--    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tagi</th>
      <td mat-cell *matCellDef="let element">
        {{getTags(element.tags)}}
      </td>
    </ng-container>-->

    <ng-container matColumnDef="assignedLinksCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Przypisanych linków</th>
      <td mat-cell *matCellDef="let element">
        {{element.assignedLinksCount}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
