import {Injectable} from '@angular/core';

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.headers.has("Content-Type")) {

      if (request.url.indexOf("/upload") > -1 || request.url.indexOf("/image") > -1) {
      } else {
        request = request.clone({
          setHeaders: {
            "Content-Type": `application/json`
          }
        });
      }
    }

    return next.handle(request);
  }
}
