<h2 mat-dialog-title>{{pageTitle}}</h2>

<mat-dialog-content>
  <form>
    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Język serwisu" [(ngModel)]="contactPage.countryCode"
                    [(value)]="contactPage.countryCode"
                    name="category">
          <mat-option *ngFor="let item of countries" [value]="item.iso2code">
            {{item.countryName}}[{{item.iso2code}}]
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Nazwa pliku z formularzem kontaktowym" rows="1" [(ngModel)]="contactPage.fileName"
                  name="fileName"
                  required></textarea>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Tytuł (w menu/na linkach)" rows="1" [(ngModel)]="contactPage.title"
                  name="title"></textarea>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Treść strony kontaktowej" rows="35" [(ngModel)]="contactPage.content"
                  name="content"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial id="saveBtn" [disabled]="inProgress" (click)="save()">Zapisz</button>
</mat-dialog-actions>
