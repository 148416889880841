import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publishers-panel',
  templateUrl: './publishers-panel.component.html',
  styleUrls: ['./publishers-panel.component.css']
})
export class PublishersPanelComponent implements OnInit {

  submenu = 'publications';

  constructor() { }

  ngOnInit(): void {
  }

}
