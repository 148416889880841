<mat-card>
  <mat-card-content>
    <mat-sidenav-container class="example-container">
      <mat-sidenav mode="side" opened>
        <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" vertical [value]="submenu">
          <mat-button-toggle value="orders" *appShowAuthed="'EMPLOYEE'">Zlecenia</mat-button-toggle>
          <mat-button-toggle value="todo" *appShowAuthed="'EMPLOYEE'">TODO</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-sidenav>
      <mat-sidenav-content>
        <app-open-aiorders *ngIf="group.value == 'orders'"></app-open-aiorders>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>
</mat-card>
