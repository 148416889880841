import {Component, Inject} from '@angular/core';
import {ArticleDetails} from '../../../../client/model/articleDetails';
import {OrderAiListItem} from '../../../../client/model/orderAiListItem';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleService} from '../../../../client/api/article.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OrderAiService} from '../../../../client/api/orderai.service';

@Component({
  selector: 'app-open-aiedit',
  templateUrl: './open-aiedit.component.html',
  styleUrls: ['./open-aiedit.component.css']
})
export class OpenAieditComponent {

  article: ArticleDetails;
  order: OrderAiListItem;
  orderForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private dialogRef: MatDialogRef<OpenAieditComponent>,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              private orderAiService: OrderAiService,
              private fb: UntypedFormBuilder) {
    this.article = data.article;
    this.order = data.order;
    this.initForm();
  }

  private initForm() {
    this.orderForm = new FormGroup({
      id: new FormControl({value: this.order.id, disabled: true}),
      title: new FormControl({value: this.order.title, disabled: true}),
      domain: new FormControl({value: this.order.domain, disabled: true}),
      autoPublish: new FormControl({value: this.order.autoPublish, disabled: true}),
      keyword: new FormControl({value: this.order.keyword, disabled: true}),
      comments: new FormControl({value: this.order.comments, disabled: true}),
      customPrompt: new FormControl(),
      externalId: new FormControl({value: this.order.externalId, disabled: true})
    });
  }

  save() {
    const fullCustomPrompt = `${this.article.content}\nDodaj lub wprowadź następujące poprawki do powyższego tekstu: \n${this.orderForm.get('customPrompt').value}`;

    const updatedOrder = {
      ...this.order,
      customPrompt: fullCustomPrompt
    };

    this.orderAiService.updateOrder(updatedOrder.id, updatedOrder).subscribe(value => {
      this.order.status = 'UPDATED';
      this.dialogRef.close();
    });
  }
}
