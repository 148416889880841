<div class="mat-app-background">
  <nav mat-tab-nav-bar *ngIf="this.authService.isLoggedIn()">
    <div>
      <div class="nav">
        <div>

          <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'domain'"
             routerLinkActive="active"
             routerLink="/domains">
            <mat-icon>list</mat-icon>
            Domeny
          </a>

        </div>
        <div>
          <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'projects'"
             routerLinkActive="active"
             routerLink="/projects">
            <mat-icon>local_atm</mat-icon>
            Reklamy</a>

        </div>
        <div>
          <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'publishers'"
             routerLinkActive="active"
             routerLink="/publishers/publications">
            <mat-icon>source</mat-icon>
            Wydawcy zewnętrzni</a>

        </div>
        <div>
          <a mat-menu-item mat-tab-link (click)="activeLink = 'copywriters'" [active]="activeLink == 'copywriters'"
             routerLink="/copywriters">
            <mat-icon>collections_bookmark</mat-icon>
            Copywriting</a>

        </div>
        <div>
          <a mat-menu-item mat-tab-link [matMenuTriggerFor]="menuSetup" *appShowAuthed="'EMPLOYEE'">Zarządzanie stronami
            <mat-icon>build</mat-icon>
          </a>
        </div>
        <div>
          <mat-menu #menuSetup="matMenu">
            <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'templates'"
               routerLinkActive="active"
               routerLink="/templates">
              <mat-icon>web</mat-icon>
              Szablony</a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'categories'"
               routerLinkActive="active" routerLink="/categories">
              <mat-icon>view_carousel</mat-icon>
              Kategorie
              stron</a>
            <span *appEnableFeature="'ads'">
          <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'adcategories'"
             routerLinkActive="active" routerLink="/adcategories">
            <mat-icon>view_module</mat-icon>
            Kategorie
            ogłoszeń</a>
            </span>
            <a mat-menu-item mat-tab-link *appShowAuthed="'EMPLOYEE'" (click)="activeLink = 'translations'"
               routerLinkActive="active" routerLink="/translations">
              <mat-icon>language</mat-icon>
              Tłumaczenia</a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'privacy'"
               routerLinkActive="active"
               routerLink="/internal/privacy">
              <mat-icon>subject</mat-icon>
              Polityki prywatności
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'contact-page'"
               routerLinkActive="active"
               routerLink="/internal/contact-page">
              <mat-icon>phone</mat-icon>
              Strony kontaktowe
            </a>
          </mat-menu>
        </div>
      </div>
      <div class="nav">
        <div>
          <a mat-button mat-tab-link [matMenuTriggerFor]="menu" *appShowAuthed="'ADMINISTRATOR'">Panel admina
            <mat-icon>tune</mat-icon>
          </a>
        </div>
        <div>
          <mat-menu #menu="matMenu">
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'users'"
               routerLinkActive="active"
               routerLink="/users">
              Użytkownicy
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'servers'"
               routerLinkActive="active" routerLink="/servers">
              Serwery
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'logs'"
               routerLinkActive="active"
               routerLink="/internal/logs">
              Logi
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'tags'"
               routerLinkActive="active"
               routerLink="/internal/tags">
              Tagi
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'logs'"
               routerLinkActive="active"
               routerLink="/internal/version">
              Diagnostyka
            </a>
            <a mat-menu-item mat-tab-link *appShowAuthed="'ADMINISTRATOR'" (click)="activeLink = 'logs'"
               routerLinkActive="active"
               routerLink="/internal/errors">
              Rejestr błędów
            </a>
          </mat-menu>
          <a mat-button mat-tab-link [matMenuTriggerFor]="menuUser">Witaj {{this.authService.getLogin()}}
            <mat-icon>face</mat-icon>
          </a>
        </div>
        <div>
          <mat-menu #menuUser="matMenu">
            <a mat-menu-item mat-tab-link (click)="this.loginService.logout();">
              <span>Logout ({{this.authService.getLogin()}}) </span>
              <mat-icon>exit_to_app</mat-icon>
            </a>
            <a mat-menu-item mat-tab-link
               routerLinkActive="active" routerLink="/account" (click)="activeLink = 'account'">
              Moje konto
            </a>
            <a mat-menu-item mat-tab-link
               routerLinkActive="active" routerLink="/account" (click)="activeLink = 'notifications'">
              Powiadomienia
            </a>
          </mat-menu>
        </div>
      </div>
    </div>
  </nav>


  <div class="spinner" *appProgressSpinner>
    <div class="spinner-content">
      <mat-spinner class="center-spinner"></mat-spinner>
      <span>{{progres.text}}</span>
    </div>
  </div>
  <router-outlet></router-outlet>


</div>
