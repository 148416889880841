import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ArticleDetails} from '../../../../client/model/articleDetails';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Tag} from '../../../../client/model/tag';
import {Observable} from 'rxjs/internal/Observable';
import {Copywriter} from '../../../../client/model/copywriter';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DomainSearchListItem} from '../../../../client/model/domainSearchListItem';
import {CopywriterService} from '../../../../client/api/copywriter.service';
import {OrderService} from '../../../../client/api/order.service';
import {TagService} from '../../../../client/api/tag.service';
import {map, startWith} from 'rxjs/operators';
import {OrderRequest} from '../../../../client/model/orderRequest';
import {ProgressIndicatorService} from '../../../commons/progress-indicator.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {

  @Input() article: ArticleDetails;
  @Output() newOrderCreated = new EventEmitter();

  orderRequest: OrderRequest;
  form: UntypedFormGroup;
  errorMessage: String;
  copywriters: Copywriter[];
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  filteredTags: Observable<Tag[]>;
  tagCtrl = new UntypedFormControl();
  tags: Tag[] = [];
  allTags: Tag[];

  separatorKeysCodes = [ENTER, COMMA];

  selectedDomains: Array<DomainSearchListItem> = [];

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: UntypedFormBuilder,
              private orderService: OrderService,
              private copywriterService: CopywriterService,
              private tagService: TagService,
              public progres: ProgressIndicatorService) {
  }

  ngOnInit() {
    this.initForm();
    this.initCopywritersDDL();
    this.initTagsDDL();
  }

  initForm() {
    this.form = this.formBuilder.group({
      quantity: new UntypedFormControl(),
      length: new UntypedFormControl(),
      copywriters: new UntypedFormControl([], Validators.required),
      paraphraseUrl: new UntypedFormControl(),
      note: new UntypedFormControl(),
      autoPublish: new UntypedFormControl(false),
      tags: this.formBuilder.array(this.tags)
    });
  }

  initCopywritersDDL() {
    this.copywriterService.getCopywriters().subscribe(response => {
        this.copywriters = response;
      },
      error =>
        this.errorMessage = <any>error);
  }

  initTagsDDL() {
    this.tagService.getTags().subscribe(response => {
      this.allTags = response;

      this.refreshTagList();
    });
  }

  onDomainSelectionChange($event) {
    this.selectedDomains = $event;
  }

  onSubmit() {
    const isValid = this.selectedDomains.length > 0;

    if (isValid === false) {
      document.getElementById('domains-title').classList.add('error');
    }

    if (this.form.valid) {
      this.save();
    }
  }

  get formData() {
    return <UntypedFormArray>this.form.get('tags');
  }

  save() {
    this.progres.progress = true;
    this.orderRequest = this.form.value;
    this.orderRequest.type = OrderRequest.TypeEnum.PARAPHRASE;
    this.orderRequest.copywriters = [];
    this.orderRequest.domains = [];
    this.orderRequest.paraphraseUrl = this.article.url;

    const selectedCopywriters = this.form.get('copywriters') as UntypedFormArray;
    for (const id of selectedCopywriters.value) {
      this.orderRequest.copywriters.push({value: id});
    }

    for (const domain of this.selectedDomains) {
      this.orderRequest.domains.push({value: domain.id});
    }

    this.orderService.processOrderRequest(this.orderRequest).subscribe(() => {
      this.newOrderCreated.emit();
    });
    this.progres.progress = false;
  }

  addTag(event: MatChipInputEvent): void {

    let input = event.input;
    let value = event.value;

    let tag = {
      name: value
    };

    // Add our requirement
    if ((value || '').trim()) {
      const tags = this.form.get('tags') as UntypedFormArray;

      tags.push(this.formBuilder.control(tag));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    let removedTag = tags.value[index];

    if (index >= 0) {
      tags.removeAt(index);
    }

    this.allTags.push(removedTag);
    this.refreshTagList();
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {

    const tags = this.form.get('tags') as UntypedFormArray;
    tags.push(this.formBuilder.control(event.option.value));

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    let selectedTagIdx = this.allTags.indexOf(event.option.value);
    this.allTags.splice(selectedTagIdx, 1);

    this.refreshTagList();
  }

  refreshTagList() {
    let sortedTags = this.allTags.sort((tag1, tag2) => this._sortTags(tag1, tag2));
    this.allTags = sortedTags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: Tag | null) => tag ? this._filter(tag) : [])
    );
  }

  onKeyup($event) {
    if ($event.shiftKey == true && $event.keyCode == 8 && this.tagInput.nativeElement.value.length == 0) {
      const tags = this.form.get('tags') as UntypedFormArray;
      const lastTagIndex = tags.value.length - 1;
      if (lastTagIndex > -1) {
        this.removeTag(lastTagIndex);
        this.tagInput.nativeElement.focus();
      }
    }
  }

  private _filter(value: any): Tag[] {
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.trim().toLowerCase();
    } else {
      filterValue = value.name.trim().toLowerCase();
    }

    let filterResult = this.allTags.filter(tag => tag.name.trim().toLowerCase().indexOf(filterValue) === 0);

    return filterResult;
  }

  private _sortTags(tag1: Tag, tag2: Tag) {
    let val1 = tag1.name.toLowerCase();
    let val2 = tag2.name.toLowerCase();

    return val1 < val2 ? -1 : val2 > val1 ? 1 : 0;
  }
}
