import {Component, ViewChild} from '@angular/core';
import {CreateDomainModalComponent} from '../../../domains/create-domain-modal/create-domain-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {OrderAiService} from '../../../../client/api/orderai.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {OpenAINewOrderComponent} from '../open-ainew-order/open-ainew-order.component';
import {MatTableDataSource} from '@angular/material/table';
import {OrderAiListItem} from '../../../../client/model/orderAiListItem';
import {ConfirmPopupComponent} from "../../../commons/confirm-popup/confirm-popup.component";
import {OrderListItem} from "../../../../client/model/orderListItem";
import { ArticleService } from 'src/app/client/api/article.service';
import { ArticlePreviewComponent } from 'src/app/features/articles/article-preview/article-preview.component';
import {OpenAiPreviewComponent} from '../open-ai-preview/open-ai-preview.component';

@Component({
  selector: 'app-open-aiorders',
  templateUrl: './open-aiorders.component.html',
  styleUrls: ['./open-aiorders.component.css']
})
export class OpenAIOrdersComponent {

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  private orders: OrderAiListItem[];
  dataSource: MatTableDataSource<OrderAiListItem>;

  displayedColumns: string[] = ['name', 'externalId', 'keyword', 'autoPublish', 'createdByName', 'status', 'actions'];


  constructor(private dialog: MatDialog,
              private orderAiService: OrderAiService,
              private articleService: ArticleService) {
  }

  ngAfterViewInit() {
    this.loadOrders();
  }

  onAddOrderClick() {
    const dialogRef = this.dialog.open(OpenAINewOrderComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadOrders();
    });
  }

  protected loadOrders() {
    const page = this.paginator.pageIndex;
    const size = this.paginator.pageSize;
    this.orderAiService.getOrders(page, size)
      .subscribe(data => {
          this.dataSource = new MatTableDataSource(data.orders);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          if (data.total) {
            this.paginator.length = data.total;
          }
        }, error => {
          console.error('Failed to load orders', error);
        }
      );
  }

  delete(element: OrderAiListItem) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: '80%',
        data: {
            message: 'Czy na pewno chcesz usunąć zlecenie?',
            okButtonLabel: 'Usuń'
        }
        });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            this.orderAiService.deleteOrder(element.id).subscribe(value => {
                this.loadOrders();
            });
        }});
  }

  edit(element: OrderAiListItem) {
    const dialogRef = this.dialog.open(OpenAINewOrderComponent, {
      width: '80%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadOrders();
    });

  }

  onPreviewClick(item: OrderListItem) {
    this.articleService.getArticle(item.articleId).subscribe(article => {
      const dialogRef = this.dialog.open(OpenAiPreviewComponent, {
        width: '80%',
        data: {
          article: article,
          order: item
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.published) {
          this.loadOrders();
        }
      });
    });
  }

  showError(item: OrderAiListItem) {
      this.dialog.open(ConfirmPopupComponent, {
            width: '80%',
            data: {
                message: item.errorMessage,
                okButtonLabel: 'OK'
            }
        });
      }
}
