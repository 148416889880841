import {Component, OnInit, ViewChild} from '@angular/core';
import {PublisherService} from '../../../client/api/publisher.service';
import {Publisher} from '../../../client/model/publisher';
import {MatDialog} from '@angular/material/dialog';
import {AddEditPublisherComponent} from '../add-edit-publisher/add-edit-publisher.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-publisher-list',
  templateUrl: './publisher-list.component.html',
  styleUrls: ['./publisher-list.component.css']
})
export class PublisherListComponent implements OnInit {
  publishers: Array<Publisher>;
  dataSource: MatTableDataSource<Publisher>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['name', 'email', 'phone', 'contactPerson', 'ndaSigned', 'offerLink', 'orderingType','comments', 'actions'];
  filterValue: any;

  constructor(private publisherService: PublisherService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getPublishers();
  }

  private getPublishers() {
    this.publisherService.getPublishers( 0, 5000, '').subscribe(value => {
      this.publishers = value;
      this.dataSource = new MatTableDataSource(this.publishers);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    });
  }

  createPublisher() {
    const dialogRef = this.dialog.open(AddEditPublisherComponent, {
      width: '80%',
      data: {
        mode: 'new',
        publisher: {}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPublishers();
    });
  }

  edit(publisher: Publisher) {
    const dialogRef = this.dialog.open(AddEditPublisherComponent, {
      width: '80%',
      data: {
        mode: 'edit',
        publisher: publisher
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPublishers();
    });
  }

  delete(element: Publisher) {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.publisherService.deletePublisher(element.id).subscribe(response => {
          this.getPublishers();
        }, error => {

        });
      }
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
