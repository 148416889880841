<div class="article-list-container">
  <p>
    <button mat-button (click)="onNewArticleClick()">
      <mat-icon>add_circle</mat-icon>
    </button>

    <button mat-button (click)="downloadSelected()" *ngIf="!getSelected()"
            matTooltip="Pobierz">
      <mat-icon>file_download</mat-icon>
      Pobierz zaznaczone artykuły
    </button>
    <button mat-button (click)="deleteSelected()" *ngIf="!getSelected()"
            matTooltip="Usuń">
      <mat-icon>delete</mat-icon>
      Usuń zaznaczone artykuły
    </button>
    <a mat-button [href]="getSelectedUrl()"
       download="{{domain.title+'_artykuly.zip'}}"
       *ngIf="getSelected()" matTooltip="Zapisz">
      <mat-icon color="primary">save</mat-icon>
      Zapisz plik
    </a>
    <mat-spinner class="center-spinner" *ngIf="showZipDownloadProgressBar"></mat-spinner>

    <button mat-raised-button color="primary" (click)="adjustDateForSelected()" matTooltip="Zmień date publikacji">
      Zmień datę publikacji
      <mat-icon>date_range</mat-icon>
    </button>

  </p>

  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter"
           matTooltip="Filtruj po tytule, kategorii, ID">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onSelectionChange($event, row)"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tytuł</th>
      <td mat-cell *matCellDef="let element">{{element.title}}
        <a href="{{element.url}}" rel="nofollow noreferrer noopener"
           target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
          <button mat-icon-button color="primary">
            <mat-icon>launch</mat-icon>
          </button>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="published">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Opublikowane</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [(ngModel)]="element.published" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategoria</th>
      <td mat-cell *matCellDef="let element">{{element.categoryName}}</td>
    </ng-container>

    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef>Długość</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.length}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zewnętrzne ID</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.externalId}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="publishedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data publikacji</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.publishedDate != null ? element.publishedDate.split('T')[0] : ""}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-button (click)="download(element)" *ngIf="!getFile(element)"
                matTooltip="Pobierz">
          <mat-icon>file_download</mat-icon>
        </button>
        <mat-progress-bar *ngIf="showDownloadProgressBar && fileId==element.id" mode="determinate"
                          value="progressDownloadPercentage"></mat-progress-bar>
        
          <a mat-button [href]="getUrl(element)"
             [download]="formatFileName((element.externalId != null ? element.externalId + '_' : '') + element.title)"
             *ngIf="getFile(element)" matTooltip="Zapisz">
              <mat-icon color="primary">save</mat-icon>
          </a>

          <button mat-icon-button mat-button (click)="onEditArticleClick(element)" matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button mat-button (click)="preview(element)" matTooltip="Pokaż">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button mat-icon-button *ngIf="element.articleId!=null || element.articleId!=''" (click)="history(element)"
                matTooltip="Wersje">
          <mat-icon>history</mat-icon>
        </button>

        <button mat-icon-button mat-button (click)="delete(element)" matTooltip="Usuń">
          <mat-icon>delete</mat-icon>
        </button>

        <button mat-icon-button mat-button (click)="changeDomain(element)" matTooltip="Zmień domenę">
          <mat-icon>switch_left</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="totalArticles"
                 [pageSize]="20"
                 [pageSizeOptions]="[20, 50, 100,500]"
                 (page)="changePage($event)"
                 showFirstLastButtons></mat-paginator>
</div>
