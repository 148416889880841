import {Component, OnInit, ViewChild} from '@angular/core';
import {ProjectsService} from '../../../client/api/projects.service';
import {Projects} from '../../../client/model/projects';
import {Project} from '../../../client/model/project';
import {CreateProjectComponent} from '../create-project/create-project.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  projects: Projects;
  dataSource: MatTableDataSource<Project>;
  displayedColumns: string[] = ['name', 'status', 'articleBanners', 'domainBanners', 'links', 'actions'];

  constructor(private projectsService: ProjectsService,
              private authService: AuthService,
              private router: Router,
              private dialog: MatDialog) {
    if (this.authService.hasRole('COPYWRITER') && !this.authService.hasRole('ADMINISTRATOR')) {
      this.router.navigateByUrl('/copywriters');
    }
  }

  ngOnInit() {
    this.getProjects();
  }

  private getProjects() {
    this.projectsService.getProjects().subscribe(value => {
      this.projects = value;
      this.dataSource = new MatTableDataSource(this.projects);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createProjectModal() {
    const dialogRef = this.dialog.open(CreateProjectComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.getProjects();
    });
  }

  delete(element: Project) {
    if (confirm('Na pewno chcesz usunąć projekt ' + element.name + '?')) {
      if (confirm('Operacja jest nieodwracalna, wszystkie przypisane linki i banery zostaną niezwłocznie usunięte z stron zapleczowych')) {
        this.projectsService.deleteProject(element.id).subscribe(value => {
          this.getProjects();
        }, error1 => {
          this.getProjects();
        });
      }
    }
  }

}
