import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ArticleService} from '../../../client/api/article.service';
import {TagService} from '../../../client/api/tag.service';
import {Tag} from '../../../client/model/tag';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith, switchMap} from 'rxjs/operators';
import {FileDto} from '../../../client/model/fileDto';
import {ArticleCategory} from '../../../client/model/articleCategory';
import {ArticlesCategoryService} from '../../../client/api/articlesCategory.service';
import {ArticleDetails} from '../../../client/model/articleDetails';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {AsyncSubject, Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ArticleComment} from '../../../client/model/articleComment';
import {AddCommentComponent} from '../add-comment/add-comment.component';
import {OrderListItem} from '../../../client/model/orderListItem';
import {OrderService} from '../../../client/api/order.service';
import {UpdateOrderRequest} from '../../../client/model/updateOrderRequest';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomainService} from '../../../client/api/domain.service';
import {DomainOverview} from '../../../client/model/domainOverview';
import {LinkCampaign} from '../../../client/model/linkCampaign';
import {LinkCampaignDetails} from '../../../client/model/linkCampaignDetails';
import {LinkCampaignService} from '../../../client/api/linkCampaign.service';
import {LinkAssignmentUpdateRequest} from '../../../client/model/linkAssignmentUpdateRequest';
import {Project} from '../../../client/model/project';
import {ProjectsService} from '../../../client/api/projects.service';
import {LinkCampaignItems} from '../../../client/model/linkCampaignItems';
import {MatTableDataSource} from '@angular/material/table';
import {ProjectDetails} from '../../../client/model/projectDetails';
import {LinkCampaignItem} from '../../../client/model/linkCampaignItem';

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.css']
})
export class ArticleEditComponent implements OnInit {

  private editorSubject: Subject<any> = new AsyncSubject();
  public relativePaths: Array<[string, string]> = [['img', 'Obrazki']];
  form: UntypedFormGroup;
  article: ArticleDetails;
  pageTitle: string;
  articleLength: string;
  keywords: string;
  hint: string;
  showHint = false;
  articleUrl = '';

  categories: ArticleCategory[] = [];

  // chips
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  filteredTags: Observable<Tag[]>;
  tagCtrl = new UntypedFormControl();
  tags: Tag[];
  allTags: Tag[];

  showParaphrasePanel: boolean = false;

  isValidFiles: boolean = true;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  words: number;
  character: number;
  comment: string;
  articleComments: Array<ArticleComment>;
  order: OrderListItem;
  externalDomain: null;
  domains: Observable<DomainOverview[]>;
  projects: Observable<Project[]>;

  linkCampaigns: Observable<LinkCampaignItems>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private articlesCategoryService: ArticlesCategoryService,
              private tagService: TagService,
              private dialog: MatDialog,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private progresIndicatorService: ProgressIndicatorService,
              private orderService: OrderService,
              private domainService: DomainService,
              private projectService: ProjectsService,
              private linkCampaignService: LinkCampaignService) {


    this.article = data.article;
    this.order = data.order;
    this.externalDomain = data.externalDomain;
    this.getArticleComments();
    this.showParaphrasePanel = data.showParaphrasePanel ? true : false;
    this.pageTitle = `Edycja artykułu: ${this.article.title}`;
    this.domains = this.domainService.getDomainsSummary();
    this.projects = this.projectService.getProjects();





    this.showHint = data.showHint ? data.showHint : false;
    this.hint = data.hint ? data.hint : '';
    this.articleLength = data.articleLength ? data.articleLength : '';
    this.articleUrl = data.articleUrl ? data.articleUrl : '';
    this.comment = this.article.comments;

    if (!this.article.files) {
      this.article.files = new Array<FileDto>();
    }

    this.tags = this.article.tags;
    this.keywords = this.tags.map(x => x.name.trim()).join(', ');


  }



  private getArticleComments() {
    this.articleService.getArticleComments(this.article.id).subscribe(value => this.articleComments = value);
  }

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  ngOnInit() {
    this.initForm();
    this.linkCampaigns = this.linkCampaignService.getLinkCampaigns().pipe(
        map(items => ({
          ...items,
          campaigns: items.campaigns?.sort((a, b) => (a.externalId || '').localeCompare(b.externalId || ''))
        }))
    );
    this.tagService.getTags().subscribe(response => {
      this.allTags = response;

      this.refreshTagList();

      this.form.get('domainId').valueChanges.subscribe(domainId => {
        if (domainId) {
          this.articlesCategoryService.getCategories(domainId).subscribe(categories => {
            this.categories = categories;
            this.form.get('categoryId').enable();
          });
        } else {
          this.categories = [];
          this.form.get('categoryId').disable();
        }
      });

      if (this.article.domainId) {
        this.articlesCategoryService.getCategories(this.article.domainId).subscribe(categories => {
          this.categories = categories;
          if (this.article.categoryId) {
            this.form.get('categoryId').setValue(this.article.categoryId);
          }
        });
      }
    });

    this.articlesCategoryService.getCategories(this.article.domainId).subscribe(response => {
      this.categories = response;
      if (this.article.categoryId) {
        this.form.get('categoryId').setValue(this.article.categoryId);
      }
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.article.id),
      orderType: new UntypedFormControl(null),
      title: new UntypedFormControl(this.article.title, Validators.required),
      categoryId: new UntypedFormControl(this.article.categoryId),
      content: new UntypedFormControl(this.article.content),
      author: new UntypedFormControl(this.article.author),
      externalId: new UntypedFormControl(this.article.externalId),
      tags: this.formBuilder.array(this.tags),
      publishedDate: new UntypedFormControl(this.article.publishedDate),
      domainId: new UntypedFormControl(this.article.domainId),
      linkCampaignsId: new UntypedFormControl()
    });
  }


  addTag(event: MatChipInputEvent): void {

    let input = event.input;
    let value = event.value.trim();

    let tag = {
      name: value
    };

    // Add our requirement
    if ((value || '').trim()) {
      const tags = this.form.get('tags') as UntypedFormArray;

      tags.push(this.formBuilder.control(tag));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    let removedTag = tags.value[index];
    if (index >= 0) {
      tags.removeAt(index);
    }
    this.allTags.push(removedTag);
    this.refreshTagList();
  }

  onKeyup($event) {
    if ($event.shiftKey == true && $event.keyCode == 8 && this.tagInput.nativeElement.value.length == 0) {
      const tags = this.form.get('tags') as UntypedFormArray;
      const lastTagIndex = tags.value.length - 1;
      if (lastTagIndex > -1) {
        this.removeTag(lastTagIndex);
        this.tagInput.nativeElement.focus();
      }
    }
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {

    const tags = this.form.get('tags') as UntypedFormArray;
    tags.push(this.formBuilder.control(event.option.value));

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    let selectedTagIdx = this.allTags.indexOf(event.option.value);
    this.allTags.splice(selectedTagIdx, 1);

    this.refreshTagList();
  }

  refreshTagList() {
    const sortedTags = this.getSortedTags();
    this.allTags = sortedTags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: Tag | null) => tag ? this._filter(tag) : [])
    );
  }

  private getSortedTags() {
    const sortedTags = this.allTags.sort((tag1, tag2) => this._sortTags(tag1, tag2));
    return sortedTags;
  }

  onSubmit() {
    this.isValidFiles = this.article.files && this.article.files.length > 1;
    if (this.form.valid && this.isValidFiles) {
      this.save();
    } else {
      let saveBtn = document.getElementById('saveBtn');
      saveBtn.classList.add('error');
    }
  }

  save() {
    this.progresIndicatorService.progress = true;
    let articleDTO: ArticleDetails = this.form.value;

    articleDTO.domainId = this.form.value.domainId;
    articleDTO.orderId = this.article.orderId;
    articleDTO.files = this.article.files;
    articleDTO.url = this.article.url;

    let publishedDate = new Date(articleDTO.publishedDate);
    publishedDate.setHours(9);

    articleDTO.publishedDate = publishedDate;

    this.articleService.updateArticle(articleDTO.id, articleDTO).subscribe(() => {
      if (this.form.value.linkCampaignsId != null) {
        this.linkCampaignService.addLinkToCampaign(this.form.value.linkCampaignsId, articleDTO).subscribe(() => {
          this.progresIndicatorService.progress = false;
          this.dialog.closeAll();
        });
      } else {
        this.progresIndicatorService.progress = false;
        this.dialog.closeAll();
      }
    });
  }

  private _filter(value: any): Tag[] {
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.trim().toLowerCase();
    } else {
      filterValue = value.name.trim().toLowerCase();
    }

    let filterResult = this.allTags.filter(tag => tag.name.trim().toLowerCase().indexOf(filterValue) === 0);

    return filterResult;
  }

  private _sortTags(tag1: Tag, tag2: Tag) {
    let val1 = tag1.name.toLowerCase();
    let val2 = tag2.name.toLowerCase();

    return val1 < val2 ? -1 : val2 > val1 ? 1 : 0;
  }

  get formData() {
    return <UntypedFormArray>this.form.get('tags');
  }

  onNewOrderCreated() {
    this.dialog.closeAll();
  }

  addComment() {
    const dialogConfig = {
      width: '70%',
      autoFocus: false,
      data: {
        articleId: this.article.id,
        order: this.order
      }
    };
    const dialogRef = this.dialog.open(AddCommentComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.articleService.updateArticle(this.article.id, this.article).subscribe(value => {
        this.progresIndicatorService.progress = false;
      });
    });
  }

  markAsFixed() {
    this.onSubmit();
    const updateRequest: UpdateOrderRequest = {status: 'READY'};
    this.orderService.updateOrder(this.order.id, updateRequest).subscribe(value => {
    });
  }


}
