import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {LoginService} from '../login/login.service';

import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';

import {ProgressIndicatorService} from './progress-indicator.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


  constructor(private router: Router,
              private loginService: LoginService,
              public snackBar: MatSnackBar,
              public progres: ProgressIndicatorService,
              public dialog: MatDialog) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xhr = req.clone({
      headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
    });
    return next.handle(xhr).pipe(
      catchError((err) => {
        this.progres.progress = false;
        if (err instanceof HttpErrorResponse) {
          if (err.status === 400 && err.error) {
            this.snackBar.open(err.error.message, 'Zamknij',
              {panelClass: 'snack-error', verticalPosition: 'bottom'});
          } else if (err.status === 401) {
            this.dialog.closeAll();
            this.loginService.logout();
          } else if (err.status === 404) {

          }
          if (err.status === 500) {
            this.snackBar.open('Wystąpił błąd wewnętrzny serwera, zgłoszenie zostało automatycznie zarejestrowane pod identyfikatorem: \n'
              + err.error.message + '\n\n Spróbuj ponownie później', 'Zamknij',
              {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
          }
          if (err.status === 503) {
            this.loginService.logout();
            this.snackBar.open('Nie można połączyć z serwerem', 'Zamknij',
              {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
          }
          return throwError(err);
        }
      })
    );
  }
}
