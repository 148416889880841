<mat-dialog-content>
    <h1>{{article.title}}</h1>
    <div [innerHTML]="article.content"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button primary *ngIf="order.status === 'READY' || order.status === 'ERROR'" (click)="edit()">
        Dodaj uwagi dla ChatGPT
    </button>
    <button mat-button primary mat-dialog-close>Zamknij</button>
</mat-dialog-actions>
