import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {CopywriterDetails} from '../../../client/model/copywriterDetails';

@Component({
  selector: 'app-order-list-dialog',
  templateUrl: './order-list-dialog.component.html',
  styleUrls: ['./order-list-dialog.component.css']
})
export class OrderListDialogComponent implements OnInit {
  copywriter: CopywriterDetails;
  month = 'current';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog) {
    this.copywriter = data.copywriter;
  }

  ngOnInit(): void {
  }

  getMonthValues() {
    switch (this.month) {
      case 'current':
        return this.copywriter.orderStats.currentMonthOrders;
      case 'past':
        return this.copywriter.orderStats.pastMonthOrders;
      case 'next':
        return this.copywriter.orderStats.nextMonthOrders;
    }
  }
}
