import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {ArticleEditComponent} from '../../../../articles/article-edit/article-edit.component';
import {Domain} from '../../../../../client/model/domain';
import {DomainArticlesService} from '../../../../../client/api/domainArticles.service';
import {Order} from '../../../../../client/model/order';
import {ArticleNewComponent} from '../../../../articles/article-new/article-new.component';
import {ArticleService} from '../../../../../client/api/article.service';
import {ArticleDetails} from '../../../../../client/model/articleDetails';
import {ArticlePreviewComponent} from '../../../../articles/article-preview/article-preview.component';
import {ConfirmPopupComponent} from '../../../../commons/confirm-popup/confirm-popup.component';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {SelectionModel} from '@angular/cdk/collections';
import {ArticlesDownload} from '../../../../../client/model/articlesDownload';
import {ArticlesDownloadId} from '../../../../../client/model/articlesDownloadId';
import {ProgressIndicatorService} from '../../../../commons/progress-indicator.service';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ArticleRevisionsComponent} from '../../../../articles/article-revisions/article-revisions.component';
import {EditDomainArticleModalComponent} from './edit-domain-article-modal/edit-domain-article-modal.component';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {FileUtilsService} from '../../../../services/file-utils.service';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.css']
})
export class ArticlesListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() domain: Domain;
  order: Order;
  private fileId: any;
  private fileList: Map<string, SafeResourceUrl> = new Map();
  progressDownloadPercentage: number;

  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ArticleDetails>(this.allowMultiSelect, this.initialSelection);
  private zipFile: SafeResourceUrl;
  showZipDownloadProgressBar: boolean;
  private pageNo: number = 0;
  private pageSize: number = 20;


  constructor(private domainArticleService: DomainArticlesService,
              private articleService: ArticleService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public snackBar: MatSnackBar,
              private progresIndicatorService: ProgressIndicatorService,
              private sanitizer: DomSanitizer,
              private fileUtilsService: FileUtilsService) {
  }

  displayedColumns: string[] = ['select', 'title', 'published', 'publishedDate', 'length', 'category', 'externalId', 'actions'];

  articles: ArticleDetails[];
  dataSource: MatTableDataSource<ArticleDetails>;

  showDownloadProgressBar;

  progressPercentage: number;
  canDelete: boolean;
  totalArticles: any;

  ngOnInit() {
    this.getArticles();
  }

  private getArticles() {
    this.progresIndicatorService.progress = true;

    this.domainArticleService.getDomainArticles(this.domain.id, this.pageNo, this.pageSize)
      .subscribe(
        response => {
          this.articles = response.articles;
          this.totalArticles = response.total;
          this.dataSource = new MatTableDataSource(this.articles);
          this.dataSource.sort = this.sort;
          this.progresIndicatorService.progress = false;

        });
  }


  onNewArticleClick() {
    this.domainArticleService.getArticlePrototype(this.domain.id)
      .subscribe(
        response => {
          const article = {
            domainId: response.domainId,
            orderType: 'ARTICLE',
            author: response.author,
            tags: []
          };

          const dialogConfig = {
            width: '95%',
            height: '95%',
            autoFocus: false,
            disableClose: true,
            data: {
              article: article,
              showHint: false,
              order: this.order
            }
          };

          const dialogRef = this.dialog.open(
            ArticleNewComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            this.getArticles();
          });
        });
  }

  onEditArticleClick(article: ArticleDetails) {
    this.openEditArticleDialog(article);
  }

  openEditArticleDialog(article: ArticleDetails) {
    const dialogConfig = {
      width: '95%',
      height: '95%',
      autoFocus: false,
      disableClose: true,
      data: {
        article: article,
        showParaphrasePanel: true,
        order: this.order
      }
    };

    const dialogRef = this.dialog.open(
      ArticleEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getArticles();
    });
  }

  preview(article: ArticleDetails) {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      data: {
        article: article
      }
    };
    const dialogRef = this.dialog.open(ArticlePreviewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getArticles();
    });
  }

  edit(article: ArticleDetails) {
    this.openEditArticleDialog(article);
  }

  delete(element: ArticleDetails) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: `Na pewno chcesz usunąć artykuł ${element.title}?`,
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.articleService.deleteArticle(element.id).subscribe(value => {
          this.getArticles();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  download(item: ArticleDetails) {
    this.fileId = item.id;
    this.articleService.downloadArticleAndImagesZipped(item.id, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showDownloadProgressBar = false;
            this.fileList.set(item.id, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body)));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
        }

      });

  }

  getUrl(item: any) {
    const file = this.fileList.get(item.id);
    if (file != null) {
      return file;
    }
  }

  getFile(element: any) {
    return this.fileList.get(element.id) != null;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.zipFile = null;
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  downloadSelected() {
    const articles: ArticlesDownload = {
      articles: new Array<ArticlesDownloadId>()
    };
    this.selection.selected.forEach(value => {
      const selected: ArticlesDownloadId = {
        id: value.id
      };
      articles.articles.push(selected);
    });

    this.articleService.downloadArticlesInDocx(articles, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showZipDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showZipDownloadProgressBar = false;
            this.zipFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
            this.showZipDownloadProgressBar = false;

        }
      }, error1 => {
        this.showZipDownloadProgressBar = false;
        this.snackBar.open('Nie można pobrać pliku z artykułami dla domeny ' + this.domain.name + '', 'Zamknij',
          {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });

  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: `Na pewno chcesz usunąć bezpowrotnie zaznaczone artykuły?`,
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selection.selected.forEach(value => {
            this.articleService.deleteArticle(value.id).subscribe(value1 => {
              this.getArticles();
            });
          });
        }
      }
    );

  }

  getSelected() {
    return this.zipFile != null;
  }

  getSelectedUrl() {
    if (this.zipFile != null) {
      return this.zipFile;
    }
  }

  onSelectionChange($event: MatCheckboxChange, row: ArticleDetails) {
    if ($event) {
      this.selection.toggle(row);
      this.zipFile = null;
    }
  }

  adjustDateForSelected() {
    this.progresIndicatorService.progress = true;

    const articles: ArticlesDownload = {
      articles: new Array<ArticlesDownloadId>()
    };
    this.selection.selected.forEach(value => {
      const selected: ArticlesDownloadId = {
        id: value.id
      };
      articles.articles.push(selected);
    });

    this.articleService.adjustPublicationDate(articles).subscribe(value => {
      this.getArticles();
      this.progresIndicatorService.progress = false;
    });
  }

  history(element: any) {
    const dialogRef = this.dialog.open(ArticleRevisionsComponent, {
      width: '80%',
      data: {
        article: element
      }
    });

    dialogRef.afterClosed().subscribe();
  }

  changeDomain(element: ArticleDetails) {
    const dialogRef = this.dialog.open(EditDomainArticleModalComponent, {
      width: '50%',
      height: '50%',
      data: {
        domainId: this.domain.id,
        article: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.domainArticleService.getDomainArticles(this.domain.id).subscribe(value => {
        this.dataSource.data = value.articles;
      });
    });
  }

  changePage($event: PageEvent) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.domainArticleService.getDomainArticles(this.domain.id, this.pageNo, this.pageSize).subscribe(value => {
      this.dataSource.data = value.articles;
      this.totalArticles = value.total;
    });
  }

  formatFileName(fileName: string): string {
    return this.fileUtilsService.formatFileName(fileName);
  }
}
