<div class="copywriter-order-list-container">
  <p>
    <button mat-button (click)="onAddOrderClick()">
      Dodaj nowe zlecenie
      <mat-icon>add_circle</mat-icon>
    </button>
    <button mat-button (click)="onImportOrderClick()">
      Importuj wiele zleceń
      <mat-icon>create_new_folder</mat-icon>
    </button>
  </p>

  <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" [value]="submenu">
    <mat-button-toggle value="active">Aktywne</mat-button-toggle>
    <mat-button-toggle value="published">Opublikowane</mat-button-toggle>
    <mat-button-toggle value="ready">Do publikacji</mat-button-toggle>
    <mat-button-toggle value="need_work">Do poprawki</mat-button-toggle>
    <mat-button-toggle value="all">Wszystkie</mat-button-toggle>
    <mat-button-toggle value="removed">Usunięte(Kosz)</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-card>
    <mat-card-content>
      <app-orders-admin-list #newOrders [orderStatuses]="['NEW']"
                             *ngIf="group.value == 'active'"></app-orders-admin-list>
      <app-orders-admin-list [orderStatuses]="['PUBLISHED']" *ngIf="group.value == 'published'"></app-orders-admin-list>
      <app-orders-admin-list [orderStatuses]="['READY']" *ngIf="group.value == 'ready'"></app-orders-admin-list>
      <app-orders-admin-list [orderStatuses]="['NEED_WORK']" *ngIf="group.value == 'need_work'"></app-orders-admin-list>
      <app-orders-admin-list [orderStatuses]="['NEW','READY','PUBLISHED','NEED_WORK']"
                             *ngIf="group.value == 'all'"></app-orders-admin-list>
      <app-orders-admin-list [orderStatuses]="['REMOVED']" *ngIf="group.value == 'removed'"></app-orders-admin-list>
    </mat-card-content>
  </mat-card>
</div>
