import {Component, OnInit, ViewChild} from '@angular/core';
import {DomainService} from "../../../client/api/domain.service";
import {DomainStat} from "../../../client/model/domainStat";
import {MatTableDataSource} from "@angular/material/table";
import {Domain} from "../../../client/model/domain";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-domain-health',
  templateUrl: './domain-health.component.html',
  styleUrls: ['./domain-health.component.css']
})
export class DomainHealthComponent implements OnInit {
  private domainStats: Array<DomainStat>;
  dataSource: MatTableDataSource<DomainStat>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['domainName', 'httpStatus', 'sslDetails', 'sslState', 'server', 'ip', 'whois'];


  constructor(private domainsService: DomainService,
              public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.domainsService.getDomainsStats().subscribe((data) => {
        this.domainStats = data;
        this.dataSource = new MatTableDataSource(this.domainStats);
        this.dataSource.sort = this.sort;
      }
    );
  }

  render(element: Domain) {
    this.domainsService.rerenderDomain(element.id).subscribe(value => {
      this.snackBar.open('Renderowanie domeny ' + element.name + ' w trakcie!', 'Zamknij',
        {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    }, error1 => {
      this.snackBar.open('Wystąpił błąd podczas renderowania domeny ' + element.name + ', sprawdź logi serwera', 'Zamknij',
        {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    });
  }
}
