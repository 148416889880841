import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-copywriter-order-list',
  templateUrl: './copywriter-order-list.component.html',
  styleUrls: ['./copywriter-order-list.component.css']
})
export class CopywriterOrderListComponent implements OnInit {

  submenu: string;

  constructor() {
  }

  ngOnInit() {
    this.submenu = 'active';
  }
}
