import {Component, Input, OnInit} from '@angular/core';
import {NotificationConfigsService} from '../../../client/api/notificationConfigs.service';
import {NotificationsConfig} from '../../../client/model/notificationsConfig';
import {UntypedFormGroup} from '@angular/forms';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';

@Component({
  selector: 'app-notifications-config',
  templateUrl: './notifications-config.component.html',
  styleUrls: ['./notifications-config.component.css']
})
export class NotificationsConfigComponent implements OnInit {
  config: NotificationsConfig = {};
  form: UntypedFormGroup;

  @Input() copywriterId: string;
  @Input() adminMode: boolean;

  constructor(
    private notificationConfigsService: NotificationConfigsService,
    private progressIndicatorService: ProgressIndicatorService
  ) {
  }

  ngOnInit(): void {
    this.progressIndicatorService.progress = true;
    if (this.copywriterId != null) {
      this.notificationConfigsService.getUserNotificationConfig(this.copywriterId)
        .subscribe(
          value => {
            this.config = value;
            this.progressIndicatorService.progress = false;
          }
        );
    } else {
      this.notificationConfigsService.getNotificationsConfig().subscribe(
        value => {
          this.config = value;
          this.progressIndicatorService.progress = false;
        }
      );
    }
  }

  save() {
    this.progressIndicatorService.progress = true;
    if (this.copywriterId != null) {
      this.notificationConfigsService.updateUserNotificationConfig(this.copywriterId, this.config)
        .subscribe(
          value => {
            this.ngOnInit();
            this.progressIndicatorService.progress = false;
          }
        );
    } else {
      this.notificationConfigsService.updateNotificationConfig(this.config).subscribe(
        value => {
          this.ngOnInit();
          this.progressIndicatorService.progress = false;
        });
    }
  }

}
