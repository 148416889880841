/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateOrderKeeper } from '../model/createOrderKeeper';
import { DomainSearchListItem } from '../model/domainSearchListItem';
import { ErrorResponse } from '../model/errorResponse';
import { ImportOrderRequest } from '../model/importOrderRequest';
import { ImportOrderResponse } from '../model/importOrderResponse';
import { OrderListItems } from '../model/orderListItems';
import { OrderRequest } from '../model/orderRequest';
import { UpdateOrderRequest } from '../model/updateOrderRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create order keeper
     * 
     * @param orderId 
     * @param createOrderKeeper 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrderKeeper(orderId: string, createOrderKeeper: CreateOrderKeeper, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createOrderKeeper(orderId: string, createOrderKeeper: CreateOrderKeeper, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createOrderKeeper(orderId: string, createOrderKeeper: CreateOrderKeeper, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createOrderKeeper(orderId: string, createOrderKeeper: CreateOrderKeeper, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling createOrderKeeper.');
        }
        if (createOrderKeeper === null || createOrderKeeper === undefined) {
            throw new Error('Required parameter createOrderKeeper was null or undefined when calling createOrderKeeper.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(orderId))}/keepers`,
            createOrderKeeper,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param orderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrder(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrder(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrder(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrder(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling deleteOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find orders
     * 
     * @param userId 
     * @param page 
     * @param size 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOrders(userId: string, page?: number, size?: number, search?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public findOrders(userId: string, page?: number, size?: number, search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public findOrders(userId: string, page?: number, size?: number, search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public findOrders(userId: string, page?: number, size?: number, search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling findOrders.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(userId))}/orders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get domains
     * 
     * @param withTags 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomains(withTags?: 'true' | 'false', observe?: 'body', reportProgress?: boolean): Observable<Array<DomainSearchListItem>>;
    public getDomains(withTags?: 'true' | 'false', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DomainSearchListItem>>>;
    public getDomains(withTags?: 'true' | 'false', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DomainSearchListItem>>>;
    public getDomains(withTags?: 'true' | 'false', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (withTags !== undefined && withTags !== null) {
            queryParameters = queryParameters.set('withTags', <any>withTags);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DomainSearchListItem>>(`${this.configuration.basePath}/orders/domains`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get orders
     * 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrders(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<OrderListItems>;
    public getOrders(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderListItems>>;
    public getOrders(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderListItems>>;
    public getOrders(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderListItems>(`${this.configuration.basePath}/orders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import multiple orders
     * 
     * @param importOrderRequest Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importOrders(importOrderRequest: ImportOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<ImportOrderResponse>;
    public importOrders(importOrderRequest: ImportOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportOrderResponse>>;
    public importOrders(importOrderRequest: ImportOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportOrderResponse>>;
    public importOrders(importOrderRequest: ImportOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (importOrderRequest === null || importOrderRequest === undefined) {
            throw new Error('Required parameter importOrderRequest was null or undefined when calling importOrders.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ImportOrderResponse>(`${this.configuration.basePath}/orders/import`,
            importOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param orderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permanentDeleteOrder(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public permanentDeleteOrder(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public permanentDeleteOrder(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public permanentDeleteOrder(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling permanentDeleteOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/orders/archived/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process order request
     * 
     * @param orderRequest Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processOrderRequest(orderRequest: OrderRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public processOrderRequest(orderRequest: OrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public processOrderRequest(orderRequest: OrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public processOrderRequest(orderRequest: OrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderRequest === null || orderRequest === undefined) {
            throw new Error('Required parameter orderRequest was null or undefined when calling processOrderRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/orders`,
            orderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update order
     * 
     * @param orderId 
     * @param updateOrderRequest Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling updateOrder.');
        }
        if (updateOrderRequest === null || updateOrderRequest === undefined) {
            throw new Error('Required parameter updateOrderRequest was null or undefined when calling updateOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/orders/archived/${encodeURIComponent(String(orderId))}`,
            updateOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
