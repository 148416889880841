import {Injectable} from '@angular/core';

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs/internal/Observable';
import {UUID} from './UUID';


@Injectable()
export class TraceIdInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('X-TraceId')) {
      const traceId = UUID.newUuid();
      request = request.clone({
        setHeaders: {
          'X-TraceId': traceId
        }
      });

    }

    return next.handle(request);

  }
}
