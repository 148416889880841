import {Component, OnInit} from '@angular/core';
import {Project} from '../../../client/model/project';
import {ProjectsService} from '../../../client/api/projects.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {

  project: Project;

  constructor(private projectService: ProjectsService,
              private dialog: MatDialog,
              private router: Router) {
    var project: Project = {};
    this.project = project;
  }

  ngOnInit() {
  }

  save() {
    this.projectService.addProject(this.project).subscribe(value => {
      this.dialog.closeAll();
      this.router.navigateByUrl('projects');
    });
  }
}
