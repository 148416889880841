import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {OrderListItem} from '../../../client/model/orderListItem';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Tag} from '../../../client/model/tag';
import {Observable} from 'rxjs/internal/Observable';
import {CustomDefinition, CustomGroup} from '../orders-admin-list/orders-admin-list.component';
import {_filter} from '../../domains/domains.component';
import {OrderService} from '../../../client/api/order.service';
import {UpdateOrderRequest} from '../../../client/model/updateOrderRequest';
import {CopywriterSelectListItem} from '../../../client/model/copywriterSelectListItem';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {DomainService} from "../../../client/api/domain.service";
import {PublicationsService} from "../../../client/api/publications.service";

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.component.html',
  styleUrls: ['./order-update.component.css']
})
export class OrderUpdateComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: UntypedFormBuilder,
              private domainService: DomainService,
              private orderService: OrderService,
              private matDialog: MatDialog,
              private copywriterService: CopywriterService,
              private publicationService: PublicationsService) {
    this.order = data.order;
    this.copywriters = copywriterService.getCopywritersSelectList();
  }

  get formData() {
    return <UntypedFormArray>this.form.get('tags');
  }

  order: OrderListItem;
  domainGroups: CustomGroup[] = new Array();
  domainGroupOptions: Observable<CustomGroup[]>;

  externalDomainGroups: CustomGroup[] = new Array();
  externalDomainGroupOptions: Observable<CustomGroup[]>;

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  form: UntypedFormGroup;
  tagCtrl = new UntypedFormControl();
  separatorKeysCodes = [ENTER, COMMA];
  selectable = true;
  removable = true;
  addOnBlur = false;
  filteredTags: Observable<Tag[]>;
  tags: Tag[] = [];
  allTags: Tag[];
  domains: Observable<String[]>;
  private domainId: string;
  private externalDomain: string;
  private dueDate: string;
  copywriters: Observable<Array<CopywriterSelectListItem>>;

  setDomainId(id: string) {
    this.domainId = id;
    this.resetExternalDomain();
  }

  private setExternalDomain(externalDomain: string) {
    this.externalDomain = externalDomain;
    this.resetDomainId();
  }

  ngOnInit(): void {
    this.initForm();
    this.initDomains();
    this.bootstrapDomain();
    this.bootstrapExternalDomain();

    this.copywriters.subscribe(copywriters => {
      this.setCopywriterName();
    });
  }

  private bootstrapExternalDomain() {
    // tslint:disable-next-line:no-non-null-assertion
    this.externalDomainGroupOptions = this.form.get('externalDomainGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.externalDomainGroups))
      );

    this.externalDomain = this.order.externalDomain;


    this.publicationService.getPublications(null, 0, 20000).subscribe(response => {
      const publications = response.publications;
      const externalDomain = publications.filter(value => value.id === this.order.externalDomain).pop();
      if (externalDomain != null) {
        this.form.get('externalDomainGroup')
          .setValue(externalDomain.domainName + '[' + externalDomain.publisher.name + ' - ' + externalDomain.price + ']');
      }
    });
  }



  private bootstrapDomain() {
    // tslint:disable-next-line:no-non-null-assertion
    this.domainGroupOptions = this.form.get('domainGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.domainGroups))
      );

    this.domainId = this.order.domainId;
    this.form.get('domainGroup').setValue(this.order.domainName);
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  initForm() {
    this.form = this.formBuilder.group({
      domainGroup: '',
      externalDomainGroup: '',
      externalId: new UntypedFormControl(this.order.externalId),
      dueDate: new UntypedFormControl(this.order.dueDate),
      articleTitle: new UntypedFormControl(this.order.articleTitle),
      articleUrl: new UntypedFormControl(this.order.articleUrl),
      autoPublish: new UntypedFormControl(this.order.autoPublish),
      categoryName: new UntypedFormControl(this.order.categoryName),
      copywriterName: new UntypedFormControl(this.order.copywriterId),
      length: new UntypedFormControl(this.order.length),
      note: new UntypedFormControl(this.order.note),
      orderKeeperName: new UntypedFormControl(this.order.orderKeeperName),
      type: new UntypedFormControl(this.order.type),
      tags: this.formBuilder.array(this.order.tags)
    });
  }

  onSubmit() {
    const updateOrderRequest: UpdateOrderRequest = {
      domainId: this.domainId,
      externalId: this.form.get('externalId').value,
      publicationId: this.externalDomain,
      dueDate: this.form.get('dueDate').value,
      length: this.form.get('length').value,
      tags: this.form.get('tags').value,
      note: this.form.get('note').value,
      copywriterId: this.form.get('copywriterName').value,
      autoPublish: this.form.get('autoPublish').value,
      type: this.form.get('type').value,
    };
    this.orderService.updateOrder(this.order.id, updateOrderRequest).subscribe(value => {
      this.matDialog.closeAll();
    });
  }

  private setCopywriterName() {
    if (this.form && this.copywriters) {
      this.form.get('copywriterName').setValue(this.order.copywriterId);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    const tag = {
      name: value
    };

    // Add our requirement
    if ((value || '').trim()) {
      const tags = this.form.get('tags') as UntypedFormArray;

      tags.push(this.formBuilder.control(tag));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    const removedTag = tags.value[index];

    if (index >= 0) {
      tags.removeAt(index);
    }

    this.allTags.push(removedTag);
    this.refreshTagList();
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    tags.push(this.formBuilder.control(event.option.value));

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    const selectedTagIdx = this.allTags.indexOf(event.option.value);
    this.allTags.splice(selectedTagIdx, 1);

    this.refreshTagList();
  }

  refreshTagList() {
    const sortedTags = this.allTags.sort((tag1, tag2) => this._sortTags(tag1, tag2));
    this.allTags = sortedTags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: Tag | null) => tag ? this._filter(tag) : [])
    );
  }

  private _filter(value: any): Tag[] {
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.trim().toLowerCase();
    } else {
      filterValue = value.name.trim().toLowerCase();
    }

    const filterResult = this.allTags.filter(tag => tag.name.trim().toLowerCase().indexOf(filterValue) === 0);

    return filterResult;
  }

  private _sortTags(tag1: Tag, tag2: Tag) {
    const val1 = tag1.name.toLowerCase();
    const val2 = tag2.name.toLowerCase();

    return val1 < val2 ? -1 : val2 > val1 ? 1 : 0;
  }

  onKeyup($event) {
    // tslint:disable-next-line:triple-equals
    if ($event.shiftKey == true && $event.keyCode == 8 && this.tagInput.nativeElement.value.length == 0) {
      const tags = this.form.get('tags') as UntypedFormArray;
      const lastTagIndex = tags.value.length - 1;
      if (lastTagIndex > -1) {
        this.removeTag(lastTagIndex);
        this.tagInput.nativeElement.focus();
      }
    }
  }

  private initDomains() {
    this.getDomains();
    this.getPublications();
  }

  private getDomains() {
    this.domainService.getDomains().subscribe(domains => {
      domains.forEach(domain => {
        const valueToAdd: CustomDefinition = {
          name: domain.name,
          id: domain.id
        };
        this.addToDomainGroup(valueToAdd, this.domainGroups);
      });
    });
  }



  private getPublications() {
    this.publicationService.getPublications(null, 0, 20000).subscribe(response => {
      const publications = response.publications;
      publications.forEach(publication => {
        const valueToAdd: CustomDefinition = {
          name: publication.domainName + '[' + publication.publisher.name + '-' + publication.price + ']',
          id: publication.id
        };
        this.addToDomainGroup(valueToAdd, this.externalDomainGroups);
      });
    });
  }


  private addToDomainGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  private resetExternalDomain() {
    this.externalDomain = null;
    this.form.get('externalDomainGroup').setValue(null);
  }

  private resetDomainId() {
    this.domainId = null;
    this.form.get('domainGroup').setValue(null);
  }
}
