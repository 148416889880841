import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectsService} from '../../../client/api/projects.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProjectDetails} from '../../../client/model/projectDetails';
import {DomainSearchListItem} from '../../../client/model/domainSearchListItem';
import {LinkAllocation} from '../../../client/model/linkAllocation';
import {DomainId} from '../../../client/model/domainId';
import {BannerCampaignService} from '../../../client/api/bannerCampaign.service';
import {BannerCampaignDetails} from '../../../client/model/bannerCampaignDetails';
import {BannerDefinition} from '../../../client/model/bannerDefinition';
import {BannerContentDefinition} from '../../../client/model/bannerContentDefinition';
import {EditBannerContentComponent} from '../edit-banner-content/edit-banner-content.component';
import {BannerCampaignUpdateRequest} from '../../../client/model/bannerCampaignUpdateRequest';
import {BannerCampaignStateComponent} from '../banner-campaign-state/banner-campaign-state.component';
import {ArticleBannerDefinition} from '../../../client/model/articleBannerDefinition';
import {Article} from '../../../client/model/article';
import {ArticleSearchResult} from '../../../client/model/articleSearchResult';
import {EditNapBannerContentComponent} from '../edit-nap-banner-content/edit-nap-banner-content.component';
import {NapContentDefinition} from '../../../client/model/napContentDefinition';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {BannerAssignmentUpdateRequest} from '../../../client/model/bannerAssignmentUpdateRequest';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-edit-banner-campaign',
  templateUrl: './edit-banner-campaign.component.html',
  styleUrls: ['./edit-banner-campaign.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class EditBannerCampaignComponent implements OnInit {

  public loaded: boolean;
  public id: string;
  public campaign: BannerCampaignDetails;

  @ViewChild('articleBannerSearchSort', {static: true}) articleSearchSort: MatSort;
  public articleBannerDataSource: MatTableDataSource<ArticleBannerDefinition>;
  articleBannerDisplayedColumns: string[] = ['place', 'content', 'allocation', 'articles', 'status', 'stan', 'actions'];

  fileUrl;
  domainFileUrl;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public bannerDataSource: MatTableDataSource<BannerDefinition>;
  bannerDisplayedColumns: string[] = ['place', 'content', 'allocation', 'domains', 'status', 'stan', 'actions'];

  expandedElement: BannerDefinition = null;

  public project: ProjectDetails;
  private selectedDomains: Array<DomainSearchListItem>;
  private selectedArticles: Array<ArticleSearchResult>;


  constructor(private route: ActivatedRoute,
              private bannerCampaignService: BannerCampaignService,
              private projectService: ProjectsService,
              public dialog: MatDialog,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar,
              private router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id != null && this.id != 'new') {
      this.getCampaignDetails();

    } else {
      let params = this.route.snapshot.queryParams;
      let projectId = params.projectId;
      this.projectService.getProjectDetails(projectId).subscribe(value => {
        this.project = value;
        var projectName = this.project.name;
        this.campaign = new class implements BannerCampaignDetails {
          id: string;
          banners: Array<BannerDefinition> = new Array<BannerDefinition>();
          articleBanners: Array<ArticleBannerDefinition> = new Array<ArticleBannerDefinition>();
          name: string = '';
          projectId: string = projectId;
          projectName: string = projectName;
        };
        this.loaded = true;
      });

    }
  }

  private getCampaignDetails() {
    this.progres.progress = true;
    this.bannerCampaignService.getBannerCampaignDetails(this.id).subscribe(value => {
      this.campaign = value;
      if (this.campaign.banners == null) {
        this.campaign.banners = new Array<BannerDefinition>();
      }
      this.renderDomainBannerDataSource();
      if (this.campaign.articleBanners == null) {
        this.campaign.articleBanners = new Array<ArticleBannerDefinition>();
      }
      this.renderArticleBannerDataSource();
      this.progres.progress = false;
      this.loaded = true;
      this.getReports();
    });
  }

  getReports() {
    var reportArticle = 'AdresPublikacji,AdresDocelowy,DataPublikacji\n';
    this.campaign.articleBanners.forEach(value => value.bannerAssignments.forEach(value1 => reportArticle += value1.articleUrl + ',' + value1.url + ',' + value1.publicationDate.split('T')[0] + '\n'));
    const blob = new Blob([reportArticle], {type: 'application/octet-stream'});
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    var reportDomain = 'AdresDomeny,AdresDocelowy,DataPublikacji\n';
    this.campaign.banners.forEach(value => value.bannerAssignments.forEach(value1 => reportDomain += value1.domainName + ',' + value1.url + ',' + value1.publicationDate.split('T')[0] + '\n'));
    const blobDomain = new Blob([reportDomain], {type: 'application/octet-stream'});
    this.domainFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blobDomain));
  }

  private renderDomainBannerDataSource() {
    this.bannerDataSource = new MatTableDataSource(this.campaign.banners);
    this.bannerDataSource.sort = this.sort;
    this.bannerDataSource.paginator = this.paginator;
  }

  private renderArticleBannerDataSource() {
    this.articleBannerDataSource = new MatTableDataSource(this.campaign.articleBanners);
    this.articleBannerDataSource.sort = this.articleSearchSort;
    //this.articleBannerDataSource.paginator = this.paginator;
  }

  addNew() {
    let defaultAllocation: LinkAllocation = {allocationType: 'IMMEDIATELY'};
    let bannerContent: BannerContentDefinition = {
      url: '',
      dofollow: true,
      target: 'NEW_WINDOW',
      alt: '',
      title: ''
    };
    let bannerDefinition: BannerDefinition = {
      place: 'MAIN1',
      allocation: defaultAllocation,
      domainIds: new Array<DomainId>(),
      content: bannerContent,
      status: false,
      deleted: false
    };
    this.campaign.banners.push(bannerDefinition);
    this.renderDomainBannerDataSource();
  }

  addNewArticleBanner() {
    this.progres.progress = true;
    let defaultAllocation: LinkAllocation = {allocationType: 'IMMEDIATELY'};
    let bannerContent: BannerContentDefinition = {
      url: '',
      dofollow: true,
      target: 'NEW_WINDOW',
      alt: '',
      title: ''
    };
    let napContent: NapContentDefinition = {
      bannerType: 'PRODUCT'
    };
    let articleBannerDefinition: ArticleBannerDefinition = {
      place: 'MAIN1',
      allocation: defaultAllocation,
      articles: new Array<Article>(),
      content: bannerContent,
      napcontent: napContent,
      status: false,
      deleted: false
    };
    this.bannerCampaignService.addArticleBanner(this.campaign.id, articleBannerDefinition).subscribe(value => {
      this.getCampaignDetails();
      this.progres.progress = false;
    }, error1 => {
      this.progres.progress = false;
      this.snackBar.open('Nie można dodać banneru:' + error1.error.message, 'Zamknij',
        {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    });

  }

  saveCampaign(returnToList: boolean) {
    this.progres.progress = true;
    if (this.id == 'new') {
      this.bannerCampaignService.addBannerCampaign(this.campaign).subscribe(value => {
          this.router.navigateByUrl('project/' + this.project.id);
          this.progres.progress = false;
        },
        error1 => {
          this.progres.progress = false;
          this.snackBar.open('Nie można zapisać:' + error1.error.message, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
    } else {
      this.bannerCampaignService.updateBannerCampaign(this.campaign.id, this.campaign as BannerCampaignUpdateRequest).subscribe(value => {
          if (returnToList) {
            this.router.navigateByUrl('project/' + this.campaign.projectId);
            this.progres.progress = false;
          } else {
            this.bannerCampaignService.getBannerCampaignDetails(this.id).subscribe(value => {
              this.campaign = value;
              this.renderDomainBannerDataSource();
              this.renderArticleBannerDataSource();
              this.progres.progress = false;
              this.snackBar.open('Zmiany zapisane!', 'Zamknij',
                {duration: 10000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
            });
          }
        },
        error1 => {
          this.snackBar.open('Nie można zapisać:' + error1.error.message, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
          this.progres.progress = false;

        });
    }
  }

  updateAllocation(element, $event: LinkAllocation) {
    element.allocation = $event;
  }

  onDomainSelectionChange($event) {
    this.selectedDomains = $event;
  }

  saveDomains(element) {
    this.expandedElement = null;
    var ids = this.selectedDomains
      .map(value => {
        var domainId: DomainId = {
          id: value.id
        };
        return domainId;
      });
    let index = this.campaign.banners.findIndex(value => value.id == element.id);
    element.domainIds = ids;
    element.amount = element.amountPerDomain * element.domainIds.length;
    this.campaign.banners[index] = element;
    this.renderDomainBannerDataSource();
  }

  domainLabel(element) {
    if (element.domainIds.length > 0) {
      return 'Wybrano ' + element.domainIds.length + ' domen';
    } else {
      return 'Wybierz domeny >';
    }
  }

  articleLabel(element: ArticleBannerDefinition) {
    if (element.articles != null && element.articles.length > 0) {
      return 'Wybrano ' + element.articles.length + ' artykułów';
    } else {
      return 'Wybierz artykuły >';
    }
  }

  manageNapBannerContent(content: ArticleBannerDefinition) {
    const dialogRef = this.dialog.open(EditNapBannerContentComponent, {
      id: 'napdialog',
      width: '80%',
      data: content
    });

    dialogRef.afterClosed().subscribe(result => {
      content = result;
      this.bannerDataSource = new MatTableDataSource(this.campaign.banners);
      this.bannerDataSource.sort = this.sort;
      this.bannerDataSource.paginator = this.paginator;
    });
  }

  manageBannerContent(content: BannerContentDefinition) {
    const dialogRef = this.dialog.open(EditBannerContentComponent, {
      width: '80%',
      data: content
    });

    dialogRef.afterClosed().subscribe(result => {

      this.bannerDataSource = new MatTableDataSource(this.campaign.banners);
      this.bannerDataSource.sort = this.sort;
      this.bannerDataSource.paginator = this.paginator;
    });
  }

  getState(element: BannerDefinition) {
    if (element.bannerAssignments != null) {
      let total = element.bannerAssignments.length;
      let active = element.bannerAssignments.filter(value => value.published).length;
      return active + '/' + total;
    }
    return '-/-';
  }

  showState(element: BannerDefinition) {
    const dialogRef = this.dialog.open(BannerCampaignStateComponent, {
      width: '80%',
      data: element.bannerAssignments
    });
  }

  onArticleSelectionChange($event: Array<ArticleSearchResult>) {
    this.selectedArticles = $event;
  }

  saveArticles(element: ArticleBannerDefinition) {
    this.expandedElement = null;
    var ids = this.selectedArticles
      .map(value => {
        var articleId: Article = {
          id: value.articleId
        };
        return articleId;
      });
    let index = this.campaign.articleBanners.findIndex(value => value.id == element.id);
    element.articles = ids;
    this.campaign.articleBanners[index] = element;
    var updateRequest: BannerAssignmentUpdateRequest = {
      articles: ids
    };
    this.progres.progress = true;
    this.bannerCampaignService.updateArticleBannerAssignment(this.campaign.id, element.id, updateRequest).subscribe(
      value => {
        this.progres.progress = false;
        this.getCampaignDetails();
      }, error1 => {
        this.progres.progress = false;
        this.snackBar.open('Nie można zaktualizować przypisań banneru:' + error1.error.message, 'Zamknij',
          {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      }
    );
    this.renderArticleBannerDataSource();
  }

  delete(element: ArticleBannerDefinition) {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bannerCampaignService.deleteArticleBanner(this.campaign.id, element.id).subscribe(response => {
          this.getCampaignDetails();
        }, error => {
          this.snackBar.open('Nie można usunąć banneru:' + error.error.message, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
      }
    });

  }
}
