<p (click)="startEdit()" *ngIf="canRender && !editMode">
  <span>{{label}}</span>
</p>
<p *ngIf="editMode">

  <mat-card>
    <h4 mat-card-title>Przydzielaj linki</h4>
    <mat-card-content>
      <mat-form-field>
        <mat-select [(value)]="allocation.allocationType">
          <mat-option value="IMMEDIATELY">Natychmiast</mat-option>
          <mat-option value="DAILY">N linków co X dni</mat-option>
          <mat-option value="WEEKLY">N linków co X tygodni</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="allocation.allocationType !='IMMEDIATELY'">
        <mat-form-field>
          <input matInput type="number" min="1" placeholder="ilość" [(ngModel)]="allocation.amount">
        </mat-form-field>
        <label>linków co</label>
        <br/>
        <mat-form-field>
          <input matInput type="number" min="1" placeholder="interwał" [(ngModel)]="allocation.allocationInterval">
        </mat-form-field>
        <label *ngIf="allocation.allocationType =='DAILY'">dni</label>
        <label *ngIf="allocation.allocationType !='DAILY'">tygodni(e)</label>
      </div>
    </mat-card-content>
    
    <mat-card-actions>
      <button mat-button color="primary" (click)="save()">Zapisz</button>
      <button mat-button (click)="cancel()">Anuluj</button>
    </mat-card-actions>
  </mat-card>

</p>
