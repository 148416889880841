<mat-card>
  <form class="example-form">
    <div class="example-container">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Addres email" name="contactEmail" [(ngModel)]="domain.contact">
      </mat-form-field>
    </div>
    <div class="example-container">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Tytuł strony kontaktowej" name="contactTitle" [(ngModel)]="domain.contactTitle">
      </mat-form-field>
    </div>
    <div class="example-container">
      <mat-form-field class="example-full-width">
        <textarea matInput placeholder="Formularz" name="contactForm" [(ngModel)]="domain.contactForm" rows="10"></textarea>
      </mat-form-field>
    </div>

    <div class="example-container">
      <mat-form-field class="example-full-width">
        <textarea matInput placeholder="Dane kontaktowe do sidebaru" name="contactSidebarWidget" [(ngModel)]="domain.contactSidebarWidget" rows="10"></textarea>
      </mat-form-field>
    </div>

    <button mat-button color="primary" mat-raised-button cdkFocusInitial (click)="save()">Zapisz</button>
  </form>
</mat-card>
