<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div fxLayout="row">
    <div width="40%">
      <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-form-field class="full-width">
          <input matInput type="number" placeholder="Liczba tekstów na domenę" formControlName="quantity">
          <mat-error>
            To pole jest wymagane
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput type="number" placeholder="Liczba znaków" formControlName="length">
          <mat-error>
            To pole jest wymagane
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width mat-form-field--no-underline">
          <input matInput formControlName="autoPublish" placeholder="" style="display: none">
          <mat-checkbox formControlName="autoPublish">Publikuj automatycznie</mat-checkbox>
        </mat-form-field>
      </div>
    </div>
    <div width="60%" class="next-content-column">
      <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-form-field class="full-width">
          <mat-select placeholder="Wybierz copywritera" formControlName="copywriters" multiple>
            <mat-option *ngFor="let copy of copywriters" [value]="copy.id">
              {{copy.firstName}} {{copy.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Dodatkowe wytyczne" formControlName="note" rows="6"></textarea>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-chip-list #tagsList (keyup)="onKeyup($event)">
            <mat-chip *ngFor="let tag of formData.controls; let i = index;"
                      [selectable]="selectable"
                      [removable]="removable">
              {{tag.value.name}}
              <mat-icon matChipRemove (click)="removeTag(i)" *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Słowa kluczowe"
                   #tagInput
                   [formControl]="tagCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="tagsList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="addTag($event)"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
              {{tag.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span id="domains-title">Domeny:&nbsp;</span>
        </mat-panel-title>
        <mat-panel-description>
          <span *ngFor="let domain of selectedDomains">{{domain.domainName}}&nbsp;</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-domain-search (selectionChanged)="onDomainSelectionChange($event)"></app-domain-search>
    </mat-expansion-panel>
  </div>
  <div class="spinner" *ngIf="progres.progress">
    <div class="spinner-content">
      <mat-spinner class="center-spinner" *ngIf="progres.progress"></mat-spinner>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button primary >Wyślij do napisania</button>
  </mat-dialog-actions>
</form>
