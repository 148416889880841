<p>
  <button mat-button (click)="openDialog()">
    <mat-icon>add_circle</mat-icon>
  </button>
</p>
<div class="full-width-container">
  <mat-checkbox [(ngModel)]="domain.pageable" name="showExtension">Włącz pageowanie stron kategorii dla tej domeny
  </mat-checkbox>
</div>
<mat-form-field>
  <input matInput type="number" placeholder="Liczba artykułów na stronę" [(ngModel)]="domain.pageSize" name="pageSize">
</mat-form-field>
<mat-form-field>
  <input matInput type="number" placeholder="Delta stron w pageowaniu" [(ngModel)]="domain.pageDelta" name="pageDelta">
</mat-form-field>
<mat-form-field>
  <input matInput type="text" placeholder="Kolor aktywnej strony" [(ngModel)]="domain.activePageColor"
         name="activePageColor">
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Edycja/Usuń</th>
    <td mat-cell *matCellDef="let element">

      <button mat-icon-button (click)="edit(element)">
        <mat-icon>edit</mat-icon>
      </button>


      <button mat-icon-button (click)="delete(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
