import {Component, Input, OnInit} from '@angular/core';
import {ProgressIndicatorService} from '../../../../commons/progress-indicator.service';
import {Domain} from '../../../../../client/model/domain';
import {DomainService} from '../../../../../client/api/domain.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-editcontact-form',
  templateUrl: './editcontact-form.component.html',
  styleUrls: ['./editcontact-form.component.css']
})
export class EditcontactFormComponent implements OnInit {

  @Input() domain: Domain;

  constructor(protected domainService: DomainService,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  save() {
    this.progres.progress = true;

    this.domainService.updateDomain(this.domain.id, this.domain)
      .subscribe(value => {
          this.domain = value;
          this.progres.progress = false;
          this.snackBar.open('Adres kontaktowy dla domeny ' + this.domain.name + ' zapisany!', 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        }
      );
  }
}
