<div class="full-width-container">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtr">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onSelectionChange($event, row)"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th mat-header-cell *matHeaderCellDef> Domena</th>
      <td mat-cell *matCellDef="let element"> {{element.domainName}}</td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef> Kategorie</th>
      <td mat-cell *matCellDef="let element"> {{element.categories}}</td>
    </ng-container>

<!--    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef> Tagi</th>
      <td mat-cell *matCellDef="let element">
        {{element.tags}}
      </td>
    </ng-container>-->
    <ng-container matColumnDef="external">
      <th mat-header-cell *matHeaderCellDef> Zewnętrzna</th>
      <td mat-cell *matCellDef="let element">
        {{element.external}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
