import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../client/model/domain';
import {AdsType} from '../../../client/model/adsType';
import {MatDialog} from '@angular/material/dialog';
import {AdEditModalComponent} from '../ad-edit-modal/ad-edit-modal.component';
import {DomainAd} from '../../../client/model/domainAd';
import {MatTableDataSource} from '@angular/material/table';
import {AdsService} from '../../../client/api/ads.service';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-domain-ads-list',
  templateUrl: './domain-ads-list.component.html',
  styleUrls: ['./domain-ads-list.component.css']
})
export class DomainAdsListComponent implements OnInit {

  @Input() domain: Domain;
  private ads: DomainAd[];
  dataSource: MatTableDataSource<DomainAd>;
  displayedColumns: string[] = ['title', 'type', 'category', 'actions'];


  constructor(private dialog: MatDialog,
              private adsService: AdsService) {
  }

  ngOnInit() {
    this.loadAds();
  }

  createAd() {
    this.createEntity(AdsType.ADS);
  }


  createCompany() {
    this.createEntity(AdsType.COMPANY);
  }

  private createEntity(type: AdsType) {
    const adCategory: DomainAd = {
      type: type
    };
    const dialogRef = this.dialog.open(AdEditModalComponent, {
      width: '80%',
      data: {
        ad: adCategory,
        domain: this.domain.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadAds();
    });
  }

  private loadAds() {
    this.adsService.getDomainAds(this.domain.id).subscribe(value => {
      this.ads = value;
      this.dataSource = new MatTableDataSource(this.ads);
    });
  }

  edit(element: DomainAd) {
    const dialogRef = this.dialog.open(AdEditModalComponent, {
      width: '80%',
      data: {
        ad: element,
        domain: this.domain.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadAds();
    });
  }

  delete(element: DomainAd) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.adsService.deleteAd(this.domain.id, element.id).subscribe(value => {
          this.loadAds();
        });
      }
    });
  }
}
