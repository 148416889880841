<h2 mat-dialog-title>Upload szablonu <span *ngIf="mode == 'edit'">{{template.templateName}}</span></h2>
<mat-dialog-content>

  <form class="full-width-container">

    <div class="full-width-container">
      <mat-form-field>
        <input matInput placeholder="Nazwa szablonu" name="templatename" [(ngModel)]="template.templateName">
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout strony" name="templatebody" [(ngModel)]="template.templateBody"
                      rows="10" value="{{template.templateBody}}"></textarea>
      </mat-form-field>
      <p>Musi zawierać template: "%TITLE%", "%SUFFIX%", "%META_DESCRIPTION%", "%HEAD_CODE%", "%GTM_CODE%",
        "%OWN_CSS%"</p>
    </div>
    <div class="full-width-container">
      <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout menu" name="menulayou" [(ngModel)]="template.menuLayout"
                      rows="3" value="{{template.menuLayout}}"></textarea>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="articlelayout" [(ngModel)]="template.articleLayout"
                      rows="4" value="{{template.articleLayout}}"></textarea>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      Template:
      <input type="file" name="templateFile" (change)="onFileChanged($event)">
    </div>
    <div class="full-width-container">
      Thumbnail:
      <input type="file" name="templateThumbnail" (change)="onFileChanged($event)">
    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Dodaj</button>
</mat-dialog-actions>

