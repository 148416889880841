<h2 mat-dialog-title>Aktualizacja zlecenia</h2>
<form [formGroup]="form">
  <mat-dialog-content>

    <div class="full-width-container">
      <mat-form-field>
        <input type="text" matInput placeholder="Domena" formControlName="domainGroup"
               [matAutocomplete]="domainAutoGroup" class="full-width-container">
        <mat-autocomplete #domainAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of domainGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setDomainId(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <mat-label>Typ zlecenia</mat-label>
        <mat-select placeholder="Wybierz typ" formControlName="type">
          <mat-option value="ARTICLE">Artykuł</mat-option>
          <mat-option value="DESCRIPTION">Opis</mat-option>
          <mat-option value="PARAPHRASE">Parafraza</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <input type="text" matInput placeholder="Domena zewnętrzna" formControlName="externalDomainGroup"
               [matAutocomplete]="externalDomainAutoGroup" class="full-width-container">
        <mat-autocomplete #externalDomainAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of externalDomainGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setExternalDomain(name.id)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
    </div>


    <div class="full-width-container">
      <mat-form-field>
        <input matInput type="text" placeholder="Zewnętrzne Id" formControlName="externalId">
      </mat-form-field>
    </div>


    <div class="full-width-container">
      <mat-form-field>
        <input matInput type="text" placeholder="Termin oddania (YYYY-MM-DD)" formControlName="dueDate">
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <input matInput type="text" placeholder="Długość" formControlName="length">
      </mat-form-field>
    </div>

    <div class="full-width-container">
    <mat-form-field class="full-width">
      <mat-chip-list #tagsList (keyup)="onKeyup($event)">
        <mat-chip *ngFor="let tag of formData.controls; let i = index;"
                  [selectable]="selectable"
                  [removable]="removable">
          {{tag.value.name}}
          <mat-icon matChipRemove (click)="removeTag(i)" *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Słowa kluczowe (oddzielone przecinkiem)"
               #tagInput
               [formControl]="tagCtrl"
               [matAutocomplete]="auto"
               [matChipInputFor]="tagsList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{tag.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center stretch">
      <mat-form-field class="note">
        <textarea matInput formControlName="note" disabled placeholder="Dodatkowe wytyczne" rows="6"></textarea>
      </mat-form-field>
    </div>


    <div class="full-width-container">
      <mat-form-field>
        <mat-label>Wybierz copywritera</mat-label>
        <mat-select placeholder="Wybierz copywritera" formControlName="copywriterName">
          <mat-option *ngFor="let copy of copywriters | async" [value]="copy.id">
            {{copy.firstName}} {{copy.lastName}} {{copy.categories.length > 0 ? '(' + (copy.categories.join(', ')) + ')' : ''}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>




    <div class="full-width-container">
      <mat-form-field class="full-width mat-form-field&#45;&#45;no-underline">
        <input matInput formControlName="autoPublish" placeholder="" style="display: none">
        <mat-checkbox formControlName="autoPublish">Publikuj automatycznie</mat-checkbox>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary id="saveBtn" (click)="onSubmit()">Zapisz</button>

  </mat-dialog-actions>
</form>
