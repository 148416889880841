import {Component, OnInit} from '@angular/core';


import {Router} from '@angular/router';
import {Domains} from '../../../client/model/domains';
import {Domain} from '../../../client/model/domain';
import {DomainService} from '../../../client/api/domain.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-create-domain-modal',
  templateUrl: './create-domain-modal.component.html',
  styleUrls: ['./create-domain-modal.component.css']
})
export class CreateDomainModalComponent implements OnInit {

  public domains: Domains;
  public domainList: string;

  constructor(private domainService: DomainService,
              private dialog: MatDialog,
              private router: Router) {
    this.domains = new Array();
  }

  ngOnInit() {
  }

  addNew() {
    var domain: Domain = {};
    this.domains.push(domain);
  }

  save() {
    this.domainList.split('\n')
      .forEach(value => {
        let domain: Domain = {name: value};
        this.domains.push(domain);
      });
    this.domainService.addDomains(this.domains)
      .subscribe(() => {
        this.dialog.closeAll();
        this.router.navigateByUrl('domains');
      });
  }
}
