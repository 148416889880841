import {Component, OnInit} from '@angular/core';
import {InternalsService} from '../../../client/api/internals.service';
import {InternalsResponse} from '../../../client/model/internalsResponse';
import {VERSION} from '../../../../environments/version';

@Component({
  selector: 'app-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.css']
})
export class VersionCheckComponent implements OnInit {
  version: InternalsResponse;
  private initDone: boolean;
  feVersion = VERSION;

  constructor(private internalsService: InternalsService) {
  }

  ngOnInit() {
    this.internalsService.getVersion().subscribe(value => {
      this.version = value;
      this.initDone = true;
    });
  }

  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? 0 + hours : hours;
    minutes = (minutes < 10) ? 0 + minutes : minutes;
    seconds = (seconds < 10) ? 0 + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  }

}
