<div class="orders-copywriter-list-container">

  <p>
    <button mat-button (click)="createPublication()" matTooltip="Dodaj nowego domenę">
      Dodaj nową domenę
      <mat-icon>add_circle</mat-icon>
    </button>

    <button mat-button (click)="onImportPublicationClick()">
      Importuj domeny do bazy
      <mat-icon>create_new_folder</mat-icon>
    </button>

    <button mat-button (click)="onUploadClick()">
      Uploaduj domeny do bazy
      <mat-icon>upload</mat-icon>
    </button>
  </p>

  <mat-expansion-panel>
    <mat-expansion-panel-header>Wyszukiwanie</mat-expansion-panel-header>
    <form [formGroup]="searchForm" class="full-width-container">

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Domena" formControlName="domainGroup"
               class="full-width-container">
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Kategoria" formControlName="category"
               class="full-width-container">
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Czas" formControlName="time"
               class="full-width-container">
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Lokalizacja" formControlName="location"
               class="full-width-container">
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="dofollow" formControlName="dofollow"
               class="full-width-container">
      </mat-form-field>

      <div>
        <mat-form-field class="half-width-container">
          <input type="text" matInput placeholder="Cena od" formControlName="priceMin">
        </mat-form-field>

        <mat-form-field class="half-width-container">
          <input type="text" matInput placeholder="Cena do" formControlName="priceMax">
        </mat-form-field>
      </div>
      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Wydawca" formControlName="publisherGroup"
               [matAutocomplete]="publisherAutoGroup" class="full-width-container">
        <mat-autocomplete #publisherAutoGroup="matAutocomplete" class="full-width-container">
          <mat-optgroup *ngFor="let group of publisherGroupOptions | async" [label]="group.letter">
            <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setPublisherForSearch(name)">
              {{name.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width-container">
        <input type="text" matInput placeholder="Rodzaj linków" formControlName="linkType"
               class="full-width-container">
      </mat-form-field>

      <div>
        <button width="40" mat-button mat-raised-button color="accent"
                (click)="getPublications()">Szukaj
        </button>
        <button width="40" mat-button color="primary" (click)="resetForm()">Wyczyść</button>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>Akcje dla zaznaczonych zleceń</mat-expansion-panel-header>

    <button mat-flat-button color="warn" (click)="deleteSelected()"  matTooltip="Usuń">
      Usuń
      <mat-icon>delete</mat-icon>
    </button>
  </mat-expansion-panel>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="domainName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Domena</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.domainName}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="updateDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data aktualizacji</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.updateDate}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="sgDays">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ilość dni na SG</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.sgDays}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">

      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategoria</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.category}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="time">

      <th mat-header-cell *matHeaderCellDef mat-sort-header>Czas</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.time}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">

      <th mat-header-cell *matHeaderCellDef mat-sort-header>Lokalizacja</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.location}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="dofollow">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>dofollow</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.dofollow}}</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cena</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.price}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="publisherName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="publisherName">Wydawca</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.publisher.orderingType; else showEmailOrName">
          {{ element.publisher.orderingType }}
        </ng-container>
        <ng-template #showEmailOrName>
          <a style="color: white;" href="mailto:{{element.publisher.email}}">
            {{ element.publisher.email }}
          </a>
        </ng-template>
      </td>
    </ng-container>


    <!--Magda nie wie skąd są dane w tej kolumnie. Zaciagaj z kolumny Forma zlecenia. Mówiła,
    że ona je poźniej sobie je wpisze do kolumny Forma zlecenia lub, że podeśle jakoś.-->

    <ng-container matColumnDef="publisherState">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status wydawcy</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.publisherState}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="linkCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ilość linków</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.linkCount}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="linkType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rodzaj linków</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.linkType}}</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Uwagi</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.comments}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button (click)="edit(element)" matTooltip="Edytuj"
                *appShowAuthed="'EMPLOYEE'">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="delete(element)" matTooltip="Usuń"
                *appShowAuthed="'PUBLISHER_ADMIN'" color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="totalPublications"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 100,500]"
                 (page)="changePage($event)"
  ></mat-paginator>
</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
