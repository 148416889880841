<div class="copywriter-order-list-container">
  <p>
    <button mat-button (click)="onAddOrderClick()">
      Dodaj nowe zlecenie
      <mat-icon>add_circle</mat-icon>
    </button>
      <button mat-button >
          Importuj zlecenia
          <mat-icon>create_new_folder</mat-icon>
      </button>
      <button mat-button (click)="loadOrders()">
          Odśwież listę zleceń
          <mat-icon>refresh</mat-icon>
      </button>

  </p>
</div>


<div class="full-width-container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Domena</th>
      <td mat-cell *matCellDef="let element">
        <p>
          {{element.domain}}
          {{element.externalDomain}}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zewnętrzne ID</th>
      <td mat-cell *matCellDef="let element">
        {{element.externalId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="keyword">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Słowo kluczowe</th>
      <td mat-cell *matCellDef="let element">
        {{element.keyword}}
      </td>
    </ng-container>

    <ng-container matColumnDef="autoPublish">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Automatyczna publikacja</th>
      <td mat-cell *matCellDef="let element">
        {{element.autoPublish}}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdByName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Autor</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdByName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell
          *matCellDef="let element">
        {{element.status}}
      </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button (click)="edit(element)" matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="delete(element)" matTooltip="Usuń" color="warn"
                *appShowAuthed="'ADMINISTRATOR'">
          <mat-icon>delete</mat-icon>
        </button>

          <button mat-icon-button (click)="onPreviewClick(element)" matTooltip="Podgląd"
                  *ngIf="element.status === 'READY' || element.status === 'ERROR'">
              <mat-icon>visibility</mat-icon>
          </button>
        <button mat-icon-button (click)="showError(element)" matTooltip="Pokaż błąd"
                  *ngIf="element.status === 'ERROR'">
              <mat-icon>error</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[100, 50, 20]" showFirstLastButtons></mat-paginator>
</div>
