<h2 mat-dialog-title>{{pageTitle}}</h2>

<mat-dialog-content>
  <form>
    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Język serwisu" [(ngModel)]="policy.countryCode"
                    [(value)]="policy.countryCode"
                    name="category">
          <mat-option *ngFor="let item of countries" [value]="item.iso2code">
            {{item.countryName}}[{{item.iso2code}}]
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Nazwa pliku z polityką" rows="1" [(ngModel)]="policy.fileName" name="fileName"
                  required></textarea>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Tytuł (w menu/na linkach)" rows="1" [(ngModel)]="policy.title"
                  name="title"></textarea>
      </mat-form-field>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Pasek z zgodą na cookies
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="full-width-container">
        <mat-form-field>
          <textarea matInput placeholder="Tekst na pasku z zgodą na cookies" rows="1" [(ngModel)]="policy.consentText"
                    name="consentText"></textarea>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <textarea matInput placeholder="Czytaj więcej" rows="1" [(ngModel)]="policy.consentReadMore"
                    name="consentReadMore"></textarea>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <textarea matInput placeholder="Wyrażam zgodę" rows="1" [(ngModel)]="policy.consentAgree"
                    name="consentAgree"></textarea>
        </mat-form-field>
      </div>
    </mat-expansion-panel>
    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Treść polityki" rows="35" [(ngModel)]="policy.content"
                  name="content"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial id="saveBtn" [disabled]="inProgress" (click)="save()">Zapisz</button>
</mat-dialog-actions>
