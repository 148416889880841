/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdsType = 'ADS' | 'COMPANY';

export const AdsType = {
    ADS: 'ADS' as AdsType,
    COMPANY: 'COMPANY' as AdsType
};
