import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OrderListItem} from '../../../client/model/orderListItem';
import {OrderService} from '../../../client/api/order.service';
import {ActivatedRoute} from '@angular/router';
import {Order} from '../../../client/model/order';
import {ArticleNewComponent} from '../../articles/article-new/article-new.component';
import {DomainArticlesService} from '../../../client/api/domainArticles.service';
import {ArticleService} from '../../../client/api/article.service';
import {ArticleEditComponent} from '../../articles/article-edit/article-edit.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {CustomDefinition, CustomGroup} from "../orders-admin-list/orders-admin-list.component";
import {map, startWith} from "rxjs/operators";
import {_filter} from "../../domains/domains.component";
import {UserService} from "../../../client/api/user.service";

@Component({
  selector: 'app-orders-copywriter-list',
  templateUrl: './orders-copywriter-list.component.html',
  styleUrls: ['./orders-copywriter-list.component.css']
})
export class OrdersCopywriterListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() orderStatuses: Order.StatusEnum[];

  displayedColumns: string[] = ['category', 'tags', 'length', 'type', 'createdOn', 'externalId', 'orderAuthor', 'dueDate', 'actions'];
  errorMessage: string;
  orders: OrderListItem[];
  dataSource: MatTableDataSource<OrderListItem>;
  currentUserId: string;
  totalOrders: number;
  orderAuthorGroups: CustomGroup[] = [];
  orderAuthorGroupOptions: Observable<CustomGroup[]>;
  searchForm: UntypedFormGroup;
  private authorId: string;
  private keeperId: string;

  constructor(private orderService: OrderService,
              private domainArticleService: DomainArticlesService,
              private articleService: ArticleService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private fb: UntypedFormBuilder,
              private userService: UserService) {
    this.searchForm = this.fb.group({
      orderAuthorGroup: '',
      externalId: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.currentUserId = localStorage.getItem('user_id');
    this.getOrders(10, 0);
    this.getUsers();

    this.orderAuthorGroupOptions = this.searchForm.get('orderAuthorGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.orderAuthorGroups))
      );
  }

  getOrders(size: number, page: number, search: string = '') {
    this.orderService.findOrders(this.currentUserId, page, size, `status=in=(${this.orderStatuses})${search}`).subscribe(response => {
      this.orders = response.orders;
      this.totalOrders = response.total;
      this.dataSource = new MatTableDataSource<OrderListItem>(this.orders);
      this.dataSource.sort = this.sort;
    }, error => {
      this.errorMessage = error;
    });
  }

  searchOrders(pageSize: number = 10, pageIndex: number = 0) {
    let search = '';

    if (this.authorId) {
      search += `;authorId==${this.authorId}`;
    }
    if (this.keeperId) {
      search += `;orderKeeper==${this.keeperId}`;
    }

    const externalId = this.searchForm.get('externalId');
    if (externalId && externalId.value) {
      search += `;externalId==${externalId.value}`;
    }

    this.getOrders(pageSize, pageIndex, search);
  }

  onCreateArticleClick(item: OrderListItem) {
    const domainId = item.domainId != null ? item.domainId : item.externalDomain;

    this.domainArticleService.getArticlePrototype(domainId)
      .subscribe(
        response => {
          const article = {
            orderId: item.id,
            domainId: item.domainId,
            orderType: item.type,
            author: response.author,
            tags: item.tags,
            publishedDate: item.createdOn
          };

          const dialogConfig = {
            width: '90%',
            disableClose: true,
            autoFocus: false,
            data: {
              article: article,
              externalDomain: item.externalDomain,
              hint: item.note,
              showHint: true,
              articleLength: item.length,
              articleUrl: item.articleUrl,
              paraphraseUrl: item.paraphraseUrl,
              linkAnchor: item.linkAnchor,
              order: item
            }
          };

          const dialogRef = this.dialog.open(ArticleNewComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(() => {
            this.getOrders(10, this.paginator.pageIndex);
          });
        },
        error => this.errorMessage = error
      );
  }

  onEditArticleClick(item: OrderListItem) {
    this.articleService.getArticle(item.articleId)
      .subscribe(
        response => {
          const dialogConfig = {
            width: '95%',
            height: '80%',
            autoFocus: false,
            disableClose: true,
            data: {
              article: response,
              hint: item.note,
              showHint: true,
              articleLength: item.length,
              articleUrl: item.articleUrl,
              order: item
            }
          };

          const dialogRef = this.dialog.open(ArticleEditComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(() => {
            this.getOrders(10, this.paginator.pageIndex);
          });
        },
        error => this.errorMessage = error
      );
  }

  setOrderAutorId(id: string) {
    this.authorId = id;
  }

  private getUsers() {
    this.userService.getUsers().subscribe(users => {
      users.forEach(user => {
        if (user.roles.includes('EMPLOYEE')) {
          const customDefinition: CustomDefinition = {
            name: `${user.firstName} ${user.lastName}`,
            id: user.id
          };
          this.addToGroup(customDefinition, this.orderAuthorGroups);
        }
      });
    });
  }

  private addToGroup(value: CustomDefinition, group: CustomGroup[]) {
    let groupFiltered = group.find(g => g.letter === value.name.charAt(0));
    if (!groupFiltered) {
      groupFiltered = {letter: value.name.charAt(0), names: []};
      group.push(groupFiltered);
    }
    groupFiltered.names.push(value);
  }

  resetForm() {
    this.searchForm.reset({
      orderAuthorGroup: '',
      externalId: ''
    });
    this.authorId = '';
  }

  private _filterGroup(value: string, customGroup: CustomGroup[]): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  changePage($event: PageEvent) {
    this.getOrders($event.pageSize, $event.pageIndex);
  }
}
