/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DropDownItem } from './dropDownItem';
import { Tag } from './tag';


export interface OrderRequest { 
    type?: OrderRequest.TypeEnum;
    domains?: Array<DropDownItem>;
    copywriters?: Array<DropDownItem>;
    length?: number;
    quantity?: number;
    note?: string;
    paraphraseUrl?: string;
    tags?: Array<Tag>;
    autoPublish?: boolean;
    externalId?: string;
    dueDate?: string;
}
export namespace OrderRequest {
    export type TypeEnum = 'PARAPHRASE' | 'ARTICLE' | 'DESCRIPTION';
    export const TypeEnum = {
        PARAPHRASE: 'PARAPHRASE' as TypeEnum,
        ARTICLE: 'ARTICLE' as TypeEnum,
        DESCRIPTION: 'DESCRIPTION' as TypeEnum
    };
}
