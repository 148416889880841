import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OrderListItem} from '../../../client/model/orderListItem';
import {OrderService} from '../../../client/api/order.service';
import {ActivatedRoute} from '@angular/router';
import {Order} from '../../../client/model/order';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {ArticlePreviewComponent} from '../../articles/article-preview/article-preview.component';
import {ArticleService} from '../../../client/api/article.service';
import {OrderDetailsComponent} from '../order-details/order-details.component';
import {ArticleDetails} from '../../../client/model/articleDetails';
import {ArticleEditComponent} from '../../articles/article-edit/article-edit.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {DomainService} from '../../../client/api/domain.service';
import {map, startWith} from 'rxjs/operators';
import {_filter} from '../../domains/domains.component';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {UserService} from '../../../client/api/user.service';
import {ChangeOrderCopywriterComponent} from '../../copywriters/change-order-copywriter/change-order-copywriter.component';
import {SelectionModel} from '@angular/cdk/collections';
import {ArticlesDownload} from '../../../client/model/articlesDownload';
import {ArticlesDownloadId} from '../../../client/model/articlesDownloadId';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ChangeOrderKeeperComponent} from '../../copywriters/change-order-keeper/change-order-keeper.component';
import {PublicationsService} from '../../../client/api/publications.service';
import {Publication} from '../../../client/model/publication';
import {OrderUpdateComponent} from '../order-update/order-update.component';
import {ArticleRevisionsComponent} from '../../articles/article-revisions/article-revisions.component';
import {FileUtilsService} from '../../services/file-utils.service';


export interface CustomGroup {
  letter: string;
  names: CustomDefinition[];
}

export interface CustomDefinition {
  name: string;
  id: string;
  orderingType?: string;
}

@Component({
  selector: 'app-orders-admin-list',
  templateUrl: './orders-admin-list.component.html',
  styleUrls: ['./orders-admin-list.component.css']
})
export class OrdersAdminListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() orderStatuses: Order.StatusEnum[];

  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<OrderListItem>(this.allowMultiSelect, this.initialSelection);
  zipFile: SafeResourceUrl;

  displayedColumns: string[] = ['select', 'domainName', 'categoryName', 'publisher', 'price', 'tags', 'articleTitle', 'typeName', 'copywriterName', 'createdByName', 'externalId', 'createdOn',  'dueDate', 'articleCreatedOn', 'orderKeeperName', 'status', 'actions'];
  errorMessage: String;
  orders: OrderListItem[];
  dataSource: MatTableDataSource<OrderListItem>;
  currentUserId: string;
  onlyNewOrders: boolean;
  onlyReady: boolean;
  onlyPublishedOrders: boolean;
  filterValue: any;

  fileId: any;
  private fileList: Map<string, SafeResourceUrl> = new Map();
  progressDownloadPercentage: number;

  showDownloadProgressBar;
  progressPercentage: number;
  totalOrders: any;
  searchForm: UntypedFormGroup = this.fb.group({
    domainGroup: '',
    copywriterGroup: '',
    orderAuthorGroup: '',
    orderKeeperGroup: '',
    externalId: new UntypedFormControl('')
  });

  domainGroups: CustomGroup[] = new Array();
  domainGroupOptions: Observable<CustomGroup[]>;
  copywriterGroups: CustomGroup[] = new Array();
  copywriterGroupOptions: Observable<CustomGroup[]>;
  orderAuthorGroups: CustomGroup[] = new Array();
  orderAuthorGroupOptions: Observable<CustomGroup[]>;
  orderKeeperGroups: CustomGroup[] = new Array();
  orderKeeperGroupOptions: Observable<CustomGroup[]>;
  private domainId: string;
  private copywriterId: string;
  private authorId: string;
  private keeperId: string;
  private pageNo = 0;
  private pageSize = 10;
  showZipDownloadProgressBar: boolean;
  private publications: Array<Publication>;
  private onlyRemovedOrders: boolean;

  constructor(private orderService: OrderService,
              private articleService: ArticleService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              private fb: UntypedFormBuilder,
              private domainService: DomainService,
              private copywriterService: CopywriterService,
              public snackBar: MatSnackBar,
              private userService: UserService,
              private publicationsService: PublicationsService,
              private fileUtilsService: FileUtilsService) {
  }

  ngOnInit() {
    this.getPublications();
    this.onlyNewOrders = this.orderStatuses.includes('NEW') && this.orderStatuses.length === 1;
    this.onlyRemovedOrders = this.orderStatuses.includes('REMOVED') && this.orderStatuses.length === 1;
    this.onlyReady = this.orderStatuses.includes('READY') && this.orderStatuses.length === 1;
    this.onlyPublishedOrders = this.orderStatuses.includes('PUBLISHED') && this.orderStatuses.length === 1;
    this.currentUserId = localStorage.getItem('user_id');
    this.getOrders(10, 0);
    if (this.onlyNewOrders) {
      const index = this.displayedColumns.indexOf('articleTitle');
      this.displayedColumns.splice(index, 1);
    }
    this.getDomains();
    this.domainGroupOptions = this.searchForm.get('domainGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.domainGroups))
      );
    this.getCopywriters();
    this.copywriterGroupOptions = this.searchForm.get('copywriterGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.copywriterGroups))
      );
    this.getUsers();
    this.orderAuthorGroupOptions = this.searchForm.get('orderAuthorGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.orderAuthorGroups))
      );
    this.orderKeeperGroupOptions = this.searchForm.get('orderKeeperGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.orderKeeperGroups))
      );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  getOrders(size: number, page: number, search?: string) {
    search = search != null ? search : '';
    this.orderService.findOrders(this.currentUserId, page, size, 'status=in=(' + this.orderStatuses + ')' + search).subscribe(response => {
      this.orders = response.orders;
      this.totalOrders = response.total;
      this.dataSource = new MatTableDataSource<OrderListItem>(this.orders);
      this.dataSource.sort = this.sort;
    }, error => {
      this.errorMessage = <any>error;
    });
  }

  onPreviewClick(item: OrderListItem) {
    this.articleService.getArticle(item.articleId).subscribe(response => {
      const dialogConfig = {
        width: '90%',
        autoFocus: false,
        data: {
          article: response,
          order: item
        }
      };
      const dialogRef = this.dialog.open(ArticlePreviewComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result.published) {
          this.searchOrders(this.pageSize, this.pageNo);
        }
      });
    }, error => {
      this.errorMessage = <any>error;
    });
  }

  onShowOrderClick(item: OrderListItem) {
    const dialogConfig = {
      width: '70%',
      autoFocus: false,
      data: {
        order: item
      }
    };
    const dialogRef = this.dialog.open(OrderDetailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.deleted) {
        this.searchOrders(this.pageSize, this.pageNo);
      }
    });
  }

  onDeleteClick(order) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord? (Operacja nieodwracalna)',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.permanentDeleteOrder(order.id).subscribe(response => {
          this.searchOrders(this.pageSize, this.pageNo);
        }, error => {
          this.errorMessage = <any>error;
        });
      }
    });
  }


  onEditArticleClick(orderListItem: OrderListItem) {
    this.articleService.getArticle(orderListItem.articleId).subscribe(response => {
      this.openEditArticleDialog(response, orderListItem);
    });
  }

  openEditArticleDialog(article: ArticleDetails, orderListItem: OrderListItem) {
    const dialogConfig = {
      width: '95%',
      height: '95%',
      autoFocus: false,
      disableClose: true,
      data: {
        article: article,
        showParaphrasePanel: true,
        order: orderListItem
      }
    };

    const dialogRef = this.dialog.open(
      ArticleEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  onEditOrderClick(orderListItem: OrderListItem) {
    this.openEditOrderDialog(orderListItem);
  }

  openEditOrderDialog(orderListItem: OrderListItem) {
    const dialogConfig = {
      width: '95%',
      height: '95%',
      autoFocus: false,
      disableClose: true,
      data: {
        showParaphrasePanel: true,
        order: orderListItem
      }
    };

    const dialogRef = this.dialog.open(
      OrderUpdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.searchOrders(this.paginator.pageSize, this.paginator.pageIndex);
    });
  }

  download(item: OrderListItem) {
    this.fileId = item.id;
    this.articleService.downloadArticleAndImagesZipped(item.articleId, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showDownloadProgressBar = false;
            this.fileList.set(item.id, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body)));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
        }

      });

  }

  getUrl(item: any) {
    const file = this.fileList.get(item.id);
    if (file != null) {
      return file;
    }
  }

  getArticleFileName(item: OrderListItem) {
    return item.externalId + item.articleTitle + '.docx';
  }

  getFile(element: any) {
    return this.fileList.get(element.id) != null;
  }

  changePage($event: PageEvent) {
    this.pageNo = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.searchOrders($event.pageSize, $event.pageIndex);
  }

  setDomainId(id: string) {
    this.domainId = id;
  }

  private getDomains() {
    this.domainService.getDomains(null, null).subscribe(value => {
        const domains = value;
        domains.forEach(value1 => {
          const valueToAdd: CustomDefinition = {
            name: value1.name,
            id: value1.id
          };
          this.addToGroup(valueToAdd, this.domainGroups);
        });
      }
    );
  }

  private getCopywriters() {
    this.copywriterService.getCopywritersSelectList().subscribe(value => {
      const copywriters = value;
      copywriters.forEach(value1 => {
        const valueToAdd: CustomDefinition = {
          name: value1.firstName + ' ' + value1.lastName,
          id: value1.id
        };
        this.addToGroup(valueToAdd, this.copywriterGroups);
      });
    });
  }

  private addToGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  searchOrders(pageSize?: number, pageIndex?: number) {
    let search = '';
    if (this.domainId) {
      search += ';domainId==' + this.domainId;
    }
    if (this.copywriterId) {
      search += ';copywriterId==' + this.copywriterId;
    }
    if (this.authorId) {
      search += ';authorId==' + this.authorId;
    }
    if (this.keeperId) {
      search += ';orderKeeper==' + this.keeperId;
    }

    const externalId = this.searchForm.get('externalId');
    if (externalId && externalId.value) {
      search += ';externalId==' + externalId.value;
    }
    this.getOrders(pageSize, pageIndex, search);
  }

  resetForm() {
    this.searchForm.reset({
      domainGroup: '',
      copywriterGroup: '',
      orderAuthorGroup: '',
      orderKeeperGroup: '',
      externalId: ''
    });

    this.domainId = '';
    this.copywriterId = '';
    this.authorId = '';
    this.keeperId = '';
  }

  setCopywriterId(id: string) {
    this.copywriterId = id;
  }

  setOrderAutorId(id: string) {
    this.authorId = id;
  }

  setOrderKeeperId(id: string) {
    this.keeperId = id;
  }

  private getUsers() {
    this.userService.getUsers().subscribe(value => {
      const authors = value;
      authors.forEach(value1 => {
        if (value1.roles.includes('EMPLOYEE')) {
          const valueToAdd: CustomDefinition = {
            name: value1.firstName + ' ' + value1.lastName,
            id: value1.id
          };
          this.addToGroup(valueToAdd, this.orderAuthorGroups);
          this.addToGroup(valueToAdd, this.orderKeeperGroups);
        }
      });
    });
  }

  onEditCopywriterClick(element: OrderListItem) {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      disableClose: true,
      data: {
        order: element
      }
    };

    const dialogRef = this.dialog.open(
      ChangeOrderCopywriterComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(this.pageSize, this.pageNo);
    });
  }

  reassignSelected() {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      disableClose: true,
      data: {
        orders: this.selection.selected
      }
    };

    const dialogRef = this.dialog.open(
      ChangeOrderCopywriterComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(this.pageSize, this.pageNo);
    });

  }

  reassignKeeperSelected() {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      disableClose: true,
      data: {
        orders: this.selection.selected
      }
    };

    const dialogRef = this.dialog.open(
      ChangeOrderKeeperComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(this.pageSize, this.pageNo);
    });
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć zaznaczone zlecenia?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach(value => {
          this.orderService.deleteOrder(value.id).subscribe(response => {
            this.searchOrders(this.pageSize, this.pageNo);
          }, error => {
            this.errorMessage = <any>error;
          });
        });
      }
    });
  }

  downloadSelected() {
    const articles: ArticlesDownload = {
      articles: new Array<ArticlesDownloadId>()
    };
    this.selection.selected.forEach(value => {
      if (value.articleId) {
        const selected: ArticlesDownloadId = {
          id: value.articleId
        };
        articles.articles.push(selected);
      }
    });

    this.articleService.downloadArticlesInDocx(articles, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showZipDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showZipDownloadProgressBar = false;
            this.zipFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
            this.showZipDownloadProgressBar = false;

        }
      }, error1 => {
        this.showZipDownloadProgressBar = false;
        this.snackBar.open('Nie można pobrać pliku z artykułami', 'Zamknij',
          {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });

  }

  getSelected() {
    return this.zipFile != null;
  }

  getSelectedUrl() {
    if (this.zipFile != null) {
      return this.zipFile;
    }
  }

  getPublisher(domain: string) {
    if (domain == null) {
      return '';
    }
    if (this.publications != null) {
      const publisher = this.publications.filter(value => value.id === domain)
        .pop();

      if (publisher) {
        if (publisher.publisher.offerLink === null) {
          return '<a href=\"mailto:' + publisher.publisher.email + '\">'
            + publisher.publisher.email + '</a>';
        } else {
          return '<a href=\"' + publisher.publisher.offerLink + '\" target="_blank" rel="nofollow noreferrer noopener">'
            + publisher.publisher.offerLink + '</a>';
        }
      } else {
        return '';
      }
    }
  }

  getCategory(domain: String) {
    if (domain == null) {
      return '';
    }
    if (this.publications != null) {
      const publication = this.publications.filter(value => value.id === domain)
        .pop();

      if (publication) {
        return publication.category;
      } else {
        return 'brak kategori';
      }
    }
  }

  private getPublications() {
    this.publicationsService.getPublications('', 0, 5000)
      .subscribe(value => this.publications = value.publications);
  }

  getPrice(domain: any) {
    if (domain == null) {
      return '';
    }
    if (this.publications != null) {
      const publisher = this.publications.filter(value => value.id === domain)
        .pop();

      if (publisher) {
        return publisher.price;
      } else {
        return '';
      }
    }
  }

  getPriceDetails(domain: any) {
    if (domain == null) {
      return '';
    }
    if (this.publications != null) {
      const publisher = this.publications.filter(value => value.id === domain)
        .pop();

      if (publisher) {
        return 'Uwagi: ' + (publisher.comments != null ? publisher.comments : '-') +
          ' Ilośc dni na SG: ' + (publisher.sgDays != null ? publisher.sgDays : '-') +
          ' Dofollow:' + (publisher.dofollow != null ? publisher.dofollow : '-');
      } else {
        return '';
      }
    }
  }

  getExternalDomain(externalDomain: any) {

    let publication = this.publications.filter(value =>
      value.id === externalDomain)
      .pop()
    ;
    if (publication != null) {
      return publication.domainName;
    } else {
      return 'none';
    }
  }

  getStatusLabel(status: number | string) {
    switch (status) {
      case 'NEED_WORK':
        return 'Do poprawki';
      case 'NEW':
        return 'Aktywne';
      case 'READY':
        return 'Do publikacji';
      case 'PUBLISHED':
        return 'Opublikowane';

    }
  }

  history(element: any) {
    const dialogRef = this.dialog.open(ArticleRevisionsComponent, {
      width: '80%',
      data: {
        article: '',
        articleId: element.articleId
      }
    });

    dialogRef.afterClosed().subscribe();
  }

  onArchiveClick(order) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.deleteOrder(order.id).subscribe(response => {
          this.searchOrders(this.pageSize, this.pageNo);
        }, error => {
          this.errorMessage = <any>error;
        });
      }
    });
  }

  onRevertClick(element: Order) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz przywrócić do aktywnych ten rekord?',
        okButtonLabel: 'Przywróć'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        element.status = 'NEW';
        this.orderService.updateOrder(element.id, element)
          .subscribe(response => {
            this.searchOrders(this.pageSize, this.pageNo);
          }, error => {
            this.errorMessage = <any>error;
          });
      }
    });
  }

  approveSelected() {
    this.selection.selected.forEach(value => {
      value.status = 'PUBLISHED';
      this.orderService.updateOrder(value.id, value).subscribe(response => {
        this.searchOrders(this.pageSize, this.pageNo);
      }, error => {
        this.errorMessage = <any>error;
      });
    });
  }
  formatFileName(fileName: string): string {
    return this.fileUtilsService.formatFileName(fileName);
  }

}
