<mat-card>
  <mat-card-header>Domain Health</mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="domainName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domena</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.domainName }}</p>
          <a href="http://{{element.domainName}}" rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
            <button mat-icon-button color="primary">
              <mat-icon>launch</mat-icon>
            </button>
          </a>

          <a href="https://dnschecker.org/?#A/{{element.domainName}}"
             rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Sprawdź propagacje DNS">
            <button mat-icon-button color="primary">
              <mat-icon>dns</mat-icon>
            </button>
          </a>
          <button mat-icon-button (click)="render(element)" matTooltip="Renderuj" color="link">
            <mat-icon>refresh</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="httpStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status HTTP</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.httpStatus }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="sslDetails">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SSL</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.sslDetails }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="sslState">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stan SSL</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.sslState }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="server">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serwer</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.server }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Adres IP</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.ip }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="whois">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Whois</th>
        <td mat-cell *matCellDef="let element">
          <p [innerHtml]=element.whois></p>
        </td>
      </ng-container>
      <ng-container matColumnDef="headers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nagłówki</th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.headers }}</p>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-card-content>
</mat-card>
