<mat-dialog-content class="full-width-container">
  <h2 mat-dialog-title>Zlecenie dla {{order.copywriterName}}</h2>
  <h5>Zlecono przez {{order.createdByName}}</h5>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div width="32">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field>
          <input matInput [(ngModel)]="order.typeName" disabled placeholder="Typ zlecenia">
        </mat-form-field>
      </div>
    </div>
    <div width="32">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field>
          <input matInput [(ngModel)]="order.length" disabled placeholder="Długość">
        </mat-form-field>
      </div>
    </div>
    <div width="32">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field>
          <input matInput [(ngModel)]="order.domainName" disabled placeholder="Domena">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="article-link" *ngIf="order.type == 'PARAPHRASE'">
    Link do parafrazy: <a href="{{order.paraphraseUrl}}" rel="nofollow noreferrer noopener" target="_blank" matTooltip="Link">{{order.paraphraseUrl}}</a>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <mat-form-field>
      <input matInput [(ngModel)]="keywords" disabled placeholder="Słowa kluczowe">
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <mat-form-field>
      <textarea matInput [(ngModel)]="order.note" disabled placeholder="Dodatkowe wytyczne" rows="6"></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <mat-form-field class="mat-form-field--no-underline">
      <input matInput [(ngModel)]="order.autoPublish" disabled style="display: none">
      <mat-checkbox [(ngModel)]="order.autoPublish" disabled>Publikuj automatycznie</mat-checkbox>
    </mat-form-field>
  </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onDeleteClick(order)">Usuń</button>
  <button mat-button mat-dialog-close>Zamknij</button>
</mat-dialog-actions>





