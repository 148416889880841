<h2 mat-dialog-title>Konfiguruj zawartość bannera NAP</h2>
<mat-dialog-content class="mat-typography">
  <form>
    <mat-card>
      <div class="full-width-container">
        <mat-form-field>
          <mat-select placeholder="Schema:" [(value)]="bannerContent.bannerType" [required]="true">
            <mat-option value="PRODUCT">
              Schema product
            </mat-option>
            <mat-option value="WEBSITE">
              Schema website
            </mat-option>
            <mat-option value="ORGANIZATION">
              Schema organization
            </mat-option>
            <mat-option value="LOCALBUSINESS">
              Schema localbusiness
            </mat-option>
            <mat-option value="CUSTOM">
              Custom
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
    <!--Product-->
    <mat-card *ngIf="bannerContent.bannerType == 'PRODUCT'">
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Tytuł" name="productTitle" [(ngModel)]="bannerContent.title">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Nazwa produktu" name="productName" [(ngModel)]="bannerContent.name">
        </mat-form-field>
      </div>
      Zdjęcie:
      <app-file-upload [canDelete]="true"
                       [fileInputLabel]=""
                       [fileListLabel]=""
                       [limit]="1"
                       [uploadedFile]="standardContent.image"
                       [preview]=true
                       (change)="bannerUpdate($event)"></app-file-upload>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Opis" name="productDescription" [(ngModel)]="bannerContent.description">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Cena" name="productPrice" [(ngModel)]="bannerContent.price">
        </mat-form-field>
      </div>
    </mat-card>
    <!--Website-->
    <mat-card *ngIf="bannerContent.bannerType == 'WEBSITE'">
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Tytuł" name="websiteTitle" [(ngModel)]="bannerContent.title">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Nazwa strony" name="websiteName" [(ngModel)]="bannerContent.name">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Opis strony" name="websiteDesc" [(ngModel)]="bannerContent.description">
        </mat-form-field>
      </div>
      Zdjęcie:
      <app-file-upload [canDelete]="true"
                       [fileInputLabel]=""
                       [fileListLabel]=""
                       [limit]="1"
                       [uploadedFile]="standardContent.image"
                       [preview]=true
                       (change)="bannerUpdate($event)"></app-file-upload>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Url" name="websiteUrl" [(ngModel)]="bannerContent.url">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Anchor" name="productPrice" [(ngModel)]="bannerContent.anchor">
        </mat-form-field>
      </div>
    </mat-card>
    <!--Organisation-->
    <mat-card *ngIf="bannerContent.bannerType == 'ORGANIZATION'">
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Tytuł" name="organizationTitle" [(ngModel)]="bannerContent.title">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Nazwa organizacji" name="organizationName" [(ngModel)]="bannerContent.name">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Opis organizacji" name="organizationDesc"
                 [(ngModel)]="bannerContent.description">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Ulica" name="organizationStreetAddress"
                 [(ngModel)]="bannerContent.streetAddress">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Kod pocztowy" name="organizationPostalCode"
                 [(ngModel)]="bannerContent.postalCode">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Miasto" name="organizationAddressLocality"
                 [(ngModel)]="bannerContent.addressLocality">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Telefon" name="organizationPhone" [(ngModel)]="bannerContent.telephone">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Fax" name="organizationFax" [(ngModel)]="bannerContent.faxNumber">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Email" name="organizationEmail" [(ngModel)]="bannerContent.email">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Url" name="websiteUrl" [(ngModel)]="bannerContent.url">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Anchor" name="anchor" [(ngModel)]="bannerContent.anchor">
        </mat-form-field>
      </div>
    </mat-card>
    <!--LocalBussiness-->
    <mat-card *ngIf="bannerContent.bannerType == 'LOCALBUSINESS'">
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Tytuł bloku" name="organizationTitle" [(ngModel)]="bannerContent.title">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Nazwa firmy" name="organizationName" [(ngModel)]="bannerContent.name">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Opis firmy" name="organizationDesc" [(ngModel)]="bannerContent.description">
        </mat-form-field>
      </div>
      Zdjęcie:
      <app-file-upload [canDelete]="true"
                       [fileInputLabel]=""
                       [fileListLabel]=""
                       [limit]="1"
                       [uploadedFile]="standardContent.image"
                       [preview]=true
                       (change)="bannerUpdate($event)"></app-file-upload>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Ulica" name="organizationStreetAddress"
                 [(ngModel)]="bannerContent.streetAddress">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Kod pocztowy" name="organizationPostalCode"
                 [(ngModel)]="bannerContent.postalCode">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Miasto" name="organizationAddressLocality"
                 [(ngModel)]="bannerContent.addressLocality">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Telefon" name="organizationPhone" [(ngModel)]="bannerContent.telephone">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Email" name="organizationEmail" [(ngModel)]="bannerContent.email">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Url" name="websiteUrl" [(ngModel)]="bannerContent.url">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Anchor" name="anchor" [(ngModel)]="bannerContent.anchor">
        </mat-form-field>
      </div>
    </mat-card>
    <!--Custom-->
    <mat-card *ngIf="bannerContent.bannerType == 'CUSTOM'">
      <div class="full-width-container">
        <mat-form-field>
          <textarea matInput placeholder="Custom" name="organizationTitle"
                    [(ngModel)]="bannerContent.custom"></textarea>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Anuluj</button>
      <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>

