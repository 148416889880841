import {Component, Input, OnInit} from '@angular/core';
import {DomainCategory} from '../../../../../client/model/domainCategory';

import {Domain} from '../../../../../client/model/domain';
import {DomainCategoryService} from '../../../../../client/api/domaincategory.service';
import {FileDto} from '../../../../../client/model/fileDto';
import {Country} from '../../../../../client/model/country';
import {CountryService} from '../../../../../client/api/country.service';


@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.css']
})
export class MainSettingsComponent implements OnInit {
  @Input() domain: Domain;
  categories: Array<DomainCategory>;
  countries: Array<Country>;

  constructor(private categoryService: DomainCategoryService,
              private countryService: CountryService) {
    this.categories = new Array<DomainCategory>();
  }

  ngOnInit() {
    this.getCategories();
    this.getCountries();
  }

  getCategories() {
    this.categoryService.getCategories()
      .subscribe(value => this.categories = value);
  }


  private getCountries() {
    this.countryService.getCountryList().subscribe(value => this.countries = value.countries);
  }

  logoUpdate($event: FileDto) {
    if ($event != null) {
      this.domain.logo = $event;
    }
  }

  faviconUpdate($event: FileDto) {
    if ($event != null) {
      this.domain.favicon = $event;
    }
  }
  sponsoredArticleIconUpdate($event: FileDto) {
    if ($event != null) {
      this.domain.sponsoredArticleIcon = $event;
    }
  }

  deleteLogo() {
    this.domain.logo = {};
  }

  deleteFavicon() {
    this.domain.favicon = {};
  }
  deleteSponsoredArticleIcon() {
    this.domain.favicon = {};
  }

}
