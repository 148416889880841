import {Component, Inject, OnInit} from '@angular/core';
import {PrivacyPolicy} from '../../../client/model/privacyPolicy';
import {Country} from '../../../client/model/country';
import {CountryService} from '../../../client/api/country.service';
import {PrivacyService} from '../../../client/api/privacy.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-policy',
  templateUrl: './edit-policy.component.html',
  styleUrls: ['./edit-policy.component.css']
})
export class EditPolicyComponent implements OnInit {
  pageTitle: any;
  inProgress: any;
  policy: PrivacyPolicy;
  countries: Array<Country>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private countryService: CountryService,
              private policyService: PrivacyService,
              public dialog: MatDialog) {
    this.policy = data.policy;
    this.pageTitle = data.edit ? 'Edycja' : 'Nowa polityka';
  }

  private getCountries() {
    this.countryService.getCountryList().subscribe(value => this.countries = value.countries);
  }

  ngOnInit() {
    this.getCountries();
  }

  save() {
    this.policyService.addPrivacyPolicy(this.policy.countryCode, this.policy).subscribe(value => {
      this.dialog.closeAll();
    });
  }
}
