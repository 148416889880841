import {Component, OnInit} from '@angular/core';
import {InternalsService} from '../../../client/api/internals.service';
import {ErrorsList} from '../../../client/model/errorsList';
import {ErrorDetails} from '../../../client/model/errorDetails';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-error-registry',
  templateUrl: './error-registry.component.html',
  styleUrls: ['./error-registry.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ErrorRegistryComponent implements OnInit {
  private errorList: ErrorsList;
  dataSource: MatTableDataSource<ErrorDetails>;
  displayedColumns: string[] = ['id', 'username', 'cause', 'createdOn', 'actions'];
  expandedElement: ErrorDetails = null;
  totalErrors: number;


  constructor(private internalsService: InternalsService) {
  }

  ngOnInit() {
    this.getErrors(10, '', 0);
  }

  private getErrors(size: number, sort: string, page: number) {
    this.internalsService.getErrors(size, sort, page).subscribe(value => {
      this.errorList = value;
      this.dataSource = new MatTableDataSource<ErrorDetails>(this.errorList.errors);
      this.totalErrors = value.total;
    });
  }

  changePage($event: PageEvent) {
    this.getErrors($event.pageSize, '', $event.pageIndex);
  }
}
