<div class="full-width-container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Adress gdzie opublikowano link</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.url}}
          <a href="{{element.url}}" rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
            <button mat-icon-button color="primary">
              <mat-icon>launch</mat-icon>
            </button>
          </a>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Docelowy adres</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.target}}
          <a href="{{element.target}}" rel="nofollow noreferrer noopener"
             target="_blank" matTooltip="Otwórz podgląd w nowym oknie">
            <button mat-icon-button color="primary">
              <mat-icon>launch</mat-icon>
            </button>
          </a>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="anchor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Podmieniane słowo</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.anchor}}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="campaignName">
      <th mat-header-cell *matHeaderCellDef>Kampania</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.campaignName}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="assignmentDate">
      <th mat-header-cell *matHeaderCellDef>Data publikacji</th>
      <td mat-cell *matCellDef="let element">
        <p>{{element.assignmentDate.replace('T', ' ').split('.')[0]}}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="invalid">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="!element.invalid">Poprawny</p>
        <p *ngIf="element.invalid">Uszkodzony</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Edycja</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [routerLink]="['/campaign/links', element.campaignId]" matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
