<mat-card>
  <mat-card-content>
    <mat-sidenav-container class="example-container">
      <mat-sidenav mode="side" opened>
        <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" vertical [value]="submenu">
          <mat-button-toggle value="notificationsConfig">Ustawienia powiadomień</mat-button-toggle>
          <mat-button-toggle value="myPanel">Mój panel</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-sidenav>
      <mat-sidenav-content>
        <app-notifications-config *ngIf="group.value == 'notificationsConfig'"></app-notifications-config>
        <app-edit-account *ngIf="group.value == 'myPanel'"></app-edit-account>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>
</mat-card>
