<mat-card>
  <h2 mat-card-title>Użytkownicy</h2>
  <div class="full-width-container">
    <p>
      <button mat-button (click)="addNew()">
        <mat-icon>person_add</mat-icon>
        Dodaj usera
      </button>
    </p>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa użytkownika</th>
        <td mat-cell *matCellDef="let element">
          <p>{{element.username}}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Imię</th>
        <td mat-cell *matCellDef="let element">
          <p>{{element.firstName}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwisko</th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Telefon</th>
        <td mat-cell *matCellDef="let element"> {{element.phone}}</td>
      </ng-container>

      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef>Uprawnienia</th>
        <td mat-cell *matCellDef="let element"><span [innerHTML]=printRoles(element.roles)></span></td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Konto aktywne</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.active" name="isActive" disabled="true">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="deleted">
        <th mat-header-cell *matHeaderCellDef>Konto usunięte</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.deleted" name="deleted" disabled="true">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">
          {{element.id.split('-')[0]}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Opcje</th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button (click)="edit(element)" matTooltip="Edytuj">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="editRoles(element)" matTooltip="Uprawnienia">
            <mat-icon>security</mat-icon>
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>
