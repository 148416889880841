<h2 mat-dialog-title>Nowy projekt</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="nazwa" name="projectName" [(ngModel)]="project.name">
      </mat-form-field>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Dodaj</button>
</mat-dialog-actions>
