import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PublicationsService} from '../../../client/api/publications.service';
import {Publication} from '../../../client/model/publication';
import {CustomGroup} from '../../orders/orders-admin-list/orders-admin-list.component';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import {PublisherService} from '../../../client/api/publisher.service';
import {Publisher} from '../../../client/model/publisher';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PublicationImportComponent} from '../publication-import/publication-import.component';

@Component({
  selector: 'app-add-edit-publication',
  templateUrl: './add-edit-publication.component.html',
  styleUrls: ['./add-edit-publication.component.css']
})
export class AddEditPublicationComponent implements OnInit {

  form: UntypedFormGroup;
  mode: any;
  publication: Publication;

  publisherGroups: CustomGroup[] = new Array();
  publisherGroupOptions: Observable<CustomGroup[]>;
  private publishers: Array<Publisher>;
  private publisherId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private publicationService: PublicationsService,
              private publisherService: PublisherService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              public publicationImportComponent: PublicationImportComponent) {
    this.mode = data.mode;
    this.publication = data.publication;
  }

  ngOnInit(): void {
    this.loadPublishers();
    this.initForm();
    this.bootstrapPublishers();
  }

  initForm() {
    this.form = this.formBuilder.group({
      domainName: new UntypedFormControl(this.publication.domainName),
      updateDate: new UntypedFormControl(this.publication.updateDate),
      sgDays: new UntypedFormControl(this.publication.sgDays),
      category: new UntypedFormControl(this.publication.category),
      time: new UntypedFormControl(this.publication.time),
      location: new UntypedFormControl(this.publication.location),
      dofollow: new UntypedFormControl(this.publication.dofollow),
      price: new UntypedFormControl(this.publication.price),
      publisherGroup: '',
      publisherState: new UntypedFormControl(this.publication.publisherState),
      linkCount: new UntypedFormControl(this.publication.linkCount),
      linkType: new UntypedFormControl(this.publication.linkType),
      comments: new UntypedFormControl(this.publication.comments)
    });
  }

  private loadPublishers() {
    this.publisherService.getPublishers(0, 5000, null).subscribe(value => {
      this.publishers = value;
    });
  }

  private bootstrapPublishers() {
    // tslint:disable-next-line:no-non-null-assertion
    this.publisherGroupOptions = this.form.get('publisherGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.publisherGroups))
      );

    this.publisherId = this.publication.publisher.id;
    this.form.get('publisherGroup').setValue(this.publication.publisher.email);
  }

  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  save() {
    if (this.mode !== 'edit') {
      const newPublication = this.form.value;
      newPublication.publisher = this.publicationImportComponent.createOrGetPublisher(newPublication.publisherGroup, this.publishers);
      const publicationRequest = {publications: [newPublication]};
      this.publicationService.createPublication(publicationRequest)
        .subscribe(value => {
          this.dialog.closeAll();
        });
    } else {
      const publicationToUpdate = this.form.value;
      publicationToUpdate.publisher = {};
      publicationToUpdate.publisher.id = this.publisherId;
      this.publicationService.updatePublication(this.publication.id, publicationToUpdate)
        .subscribe(value => {
          this.dialog.closeAll();
        });
    }
  }

  setPublisherId(id: string) {
    this.publisherId = id;
  }
}

export const _filter = (opt: Publisher[], value: string): Publisher[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.email.toLowerCase().indexOf(filterValue) === 0);
};
