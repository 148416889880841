import {Component, Inject, OnInit} from '@angular/core';
import {AdCategory} from '../../../client/model/adCategory';
import {AdvertisementCategoryService} from '../../../client/api/advertisementCategory.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AdsType} from '../../../client/model/adsType';


@Component({
  selector: 'app-ad-category-modal',
  templateUrl: './ad-category-modal.component.html',
  styleUrls: ['./ad-category-modal.component.css']
})
export class AdCategoryModalComponent implements OnInit {

  adCategory: AdCategory;
  types: AdsType;
  form: UntypedFormGroup;
  private categories: Array<AdCategory>;
  title: any;
  icon = new UntypedFormControl();
  iconTxt: any;
  private saveLock = false;

  constructor(private advertisementCategoryService: AdvertisementCategoryService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.adCategory = data;
    if (this.adCategory.id) {
      this.title = 'Edycja kategorii';
    } else {
      this.title = 'Nowa kategoria';
    }
    this.loadCategories();
    this.initForm(this.adCategory);
  }

  ngOnInit() {
  }

  save() {
    if (!this.saveLock) {
      this.saveLock = true;
      const adCategoryToSave: AdCategory = this.form.value;
      if (!this.adCategory.id) {
        this.advertisementCategoryService.createAdCategory(adCategoryToSave).subscribe(value => {
          this.saveLock = false;
          this.dialog.closeAll();
        });
      } else {
        this.advertisementCategoryService.updateCategory(this.adCategory.id, adCategoryToSave).subscribe(value => {
          this.saveLock = false;
          this.dialog.closeAll();
        });
      }
    }
  }

  onIconPickerSelect(newIcon: string) {
    this.icon.setValue(newIcon);
  }

  private initForm(adCategory: AdCategory) {
    this.form = this.formBuilder.group({
        categoryName: new UntypedFormControl(this.adCategory.categoryName),
        translationLabel: new UntypedFormControl(this.adCategory.translationLabel, Validators.pattern('%[A-Z]+[0-9]?[_]?[A-Z]+[0-9]?[_]?[A-Z]+[0-9]?%')),
        parentCategoryId: new UntypedFormControl(this.adCategory.parentCategoryId),
        categoryType: new UntypedFormControl(this.adCategory.categoryType),
        icon: this.icon
      }
    );
  }

  private loadCategories() {
    this.advertisementCategoryService.getAdCategories().subscribe(value =>
      this.categories = value);
  }

  getCategories() {
    if (this.categories) {
      return this.categories.filter(value => value.categoryType === this.form.get('categoryType').value);
    }
  }

  setTranslationLabel() {
    const categoryName: String = this.form.get('categoryName').value;

    if (this.isTranslationLabelBlank()) {
      this.form.get('translationLabel').setValue('%' + categoryName.toUpperCase().split(' ').join( '_') + '%');
    }
  }

  private isTranslationLabelBlank() {
    return this.form.get('translationLabel').value == null || (this.form.get('translationLabel').value != null && this.form.get('translationLabel').value.toString().length < 2);
  }
}
