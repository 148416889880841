/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Article } from './article';
import { ArticleBannerAssignments } from './articleBannerAssignments';
import { BannerContentDefinition } from './bannerContentDefinition';
import { LinkAllocation } from './linkAllocation';
import { NapContentDefinition } from './napContentDefinition';


export interface ArticleBannerDefinition { 
    id?: string;
    place?: ArticleBannerDefinition.PlaceEnum;
    content?: BannerContentDefinition;
    napcontent?: NapContentDefinition;
    allocation: LinkAllocation;
    articles?: Array<Article>;
    status?: boolean;
    bannerAssignments?: ArticleBannerAssignments;
    deleted?: boolean;
}
export namespace ArticleBannerDefinition {
    export type PlaceEnum = 'MAIN1' | 'MAIN2' | 'FOOTER' | 'SIDEBAR1' | 'SIDEBAR2' | 'ARTICLE1' | 'ARTICLE2' | 'NAP';
    export const PlaceEnum = {
        MAIN1: 'MAIN1' as PlaceEnum,
        MAIN2: 'MAIN2' as PlaceEnum,
        FOOTER: 'FOOTER' as PlaceEnum,
        SIDEBAR1: 'SIDEBAR1' as PlaceEnum,
        SIDEBAR2: 'SIDEBAR2' as PlaceEnum,
        ARTICLE1: 'ARTICLE1' as PlaceEnum,
        ARTICLE2: 'ARTICLE2' as PlaceEnum,
        NAP: 'NAP' as PlaceEnum
    };
}
