import {Component, Inject, OnInit} from '@angular/core';
import {ArticleDetails} from '../../../../../../client/model/articleDetails';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleService} from '../../../../../../client/api/article.service';
import {DomainService} from '../../../../../../client/api/domain.service';
import {Observable} from 'rxjs';
import {DomainOverview} from '../../../../../../client/model/domainOverview';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {ProgressIndicatorService} from '../../../../../commons/progress-indicator.service';
import {map} from 'rxjs/operators';



@Component({
  selector: 'app-edit-domain-article-modal',
  templateUrl: './edit-domain-article-modal.component.html',
  styleUrls: ['./edit-domain-article-modal.component.css']
})
export class EditDomainArticleModalComponent implements OnInit {
  domains: Observable<DomainOverview[]>;
  article: ArticleDetails;
  form: UntypedFormGroup;
  currentDomainId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private dialogRef: MatDialogRef<EditDomainArticleModalComponent>,
              private dialog: MatDialog,
              private domainService: DomainService,
              public progres: ProgressIndicatorService) {
    this.domains = this.domainService.getDomains();
    this.article = data.article;
    this.currentDomainId = data.domainId;
  }

  ngOnInit(): void {
    this.domains = this.domainService.getDomainsSummary().pipe(
      map(domains => domains
        .filter(domain => domain.id !== this.currentDomainId)
        .sort((a, b) => a.name.localeCompare(b.name))
      )
    );

    this.form = new FormGroup({
      domainId: new FormControl()
    });
  }


  moveArticleToDomain() {
    this.article.domainId = this.form.value.domainId;
    const articleDetails = {domainId: this.article.domainId};
    this.articleService.patchArticle(this.article.id, articleDetails).subscribe(() => {
        this.dialogRef.close();
        this.articleService.getArticles();
      },
      (error) => {
        console.error(error);
      });

    console.log(`Przeniesiono artykuł ${this.article.title} do domeny o ID ${this.article.domainId}`);
  }

}
