import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TagService} from '../../../client/api/tag.service';
import {Tag} from '../../../client/model/tag';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.css']
})
export class TagsListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private tagList: Array<Tag>;
  dataSource: MatTableDataSource<Tag>;
  displayedColumns: string[] = ['id', 'tag', 'createdAt', 'actions'];
  totalTags: any;

  constructor(private tagsService: TagService) {
  }

  ngOnInit(): void {
    this.getTags();
  }

  private getTags() {
    this.tagsService.getTags().subscribe(value => {
      this.tagList = value;
      this.dataSource = new MatTableDataSource<Tag>(this.tagList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.totalTags = this.tagList.length;
    });
  }
}
