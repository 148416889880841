/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArticleBannerDefinition } from '../model/articleBannerDefinition';
import { BannerAssignmentUpdateRequest } from '../model/bannerAssignmentUpdateRequest';
import { BannerCampaignDetails } from '../model/bannerCampaignDetails';
import { BannerCampaignUpdateRequest } from '../model/bannerCampaignUpdateRequest';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class BannerCampaignService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add article banner to campaign
     * 
     * @param campaignId 
     * @param articleBannerDefinition Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addArticleBanner(campaignId: string, articleBannerDefinition: ArticleBannerDefinition, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addArticleBanner(campaignId: string, articleBannerDefinition: ArticleBannerDefinition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addArticleBanner(campaignId: string, articleBannerDefinition: ArticleBannerDefinition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addArticleBanner(campaignId: string, articleBannerDefinition: ArticleBannerDefinition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling addArticleBanner.');
        }
        if (articleBannerDefinition === null || articleBannerDefinition === undefined) {
            throw new Error('Required parameter articleBannerDefinition was null or undefined when calling addArticleBanner.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}/articlebanner`,
            articleBannerDefinition,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add campaign to system
     * 
     * @param bannerCampaignDetails Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBannerCampaign(bannerCampaignDetails: BannerCampaignDetails, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addBannerCampaign(bannerCampaignDetails: BannerCampaignDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addBannerCampaign(bannerCampaignDetails: BannerCampaignDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addBannerCampaign(bannerCampaignDetails: BannerCampaignDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (bannerCampaignDetails === null || bannerCampaignDetails === undefined) {
            throw new Error('Required parameter bannerCampaignDetails was null or undefined when calling addBannerCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/campaign/banner`,
            bannerCampaignDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete article banner from campaign 
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param bannerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteArticleBanner(campaignId: string, bannerId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteArticleBanner(campaignId: string, bannerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteArticleBanner(campaignId: string, bannerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteArticleBanner(campaignId: string, bannerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteArticleBanner.');
        }
        if (bannerId === null || bannerId === undefined) {
            throw new Error('Required parameter bannerId was null or undefined when calling deleteArticleBanner.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}/articlebanner/${encodeURIComponent(String(bannerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete banner campaign details
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBannerCampaign(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBannerCampaign(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBannerCampaign(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBannerCampaign(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteBannerCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get banner campaign details
     * This can only be done by the logged in user.
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBannerCampaignDetails(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<BannerCampaignDetails>;
    public getBannerCampaignDetails(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BannerCampaignDetails>>;
    public getBannerCampaignDetails(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BannerCampaignDetails>>;
    public getBannerCampaignDetails(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getBannerCampaignDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BannerCampaignDetails>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update campaign assignments
     * 
     * @param campaignId 
     * @param bannerId 
     * @param bannerAssignmentUpdateRequest Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateArticleBannerAssignment(campaignId: string, bannerId: string, bannerAssignmentUpdateRequest: BannerAssignmentUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateArticleBannerAssignment(campaignId: string, bannerId: string, bannerAssignmentUpdateRequest: BannerAssignmentUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateArticleBannerAssignment(campaignId: string, bannerId: string, bannerAssignmentUpdateRequest: BannerAssignmentUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateArticleBannerAssignment(campaignId: string, bannerId: string, bannerAssignmentUpdateRequest: BannerAssignmentUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling updateArticleBannerAssignment.');
        }
        if (bannerId === null || bannerId === undefined) {
            throw new Error('Required parameter bannerId was null or undefined when calling updateArticleBannerAssignment.');
        }
        if (bannerAssignmentUpdateRequest === null || bannerAssignmentUpdateRequest === undefined) {
            throw new Error('Required parameter bannerAssignmentUpdateRequest was null or undefined when calling updateArticleBannerAssignment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}/articlebanner/${encodeURIComponent(String(bannerId))}/assignments`,
            bannerAssignmentUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update campaign to system
     * 
     * @param campaignId 
     * @param bannerCampaignUpdateRequest Campaign object that need to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBannerCampaign(campaignId: string, bannerCampaignUpdateRequest: BannerCampaignUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBannerCampaign(campaignId: string, bannerCampaignUpdateRequest: BannerCampaignUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBannerCampaign(campaignId: string, bannerCampaignUpdateRequest: BannerCampaignUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBannerCampaign(campaignId: string, bannerCampaignUpdateRequest: BannerCampaignUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling updateBannerCampaign.');
        }
        if (bannerCampaignUpdateRequest === null || bannerCampaignUpdateRequest === undefined) {
            throw new Error('Required parameter bannerCampaignUpdateRequest was null or undefined when calling updateBannerCampaign.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/campaign/banner/${encodeURIComponent(String(campaignId))}`,
            bannerCampaignUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
