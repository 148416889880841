/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArticleCampaign } from '../model/articleCampaign';
import { ArticleComment } from '../model/articleComment';
import { ArticleComments } from '../model/articleComments';
import { ArticleDetails } from '../model/articleDetails';
import { ArticleRevisions } from '../model/articleRevisions';
import { ArticlesDownload } from '../model/articlesDownload';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param articleDetails Article objects that needs to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addArticle(articleDetails: ArticleDetails, observe?: 'body', reportProgress?: boolean): Observable<ArticleDetails>;
    public addArticle(articleDetails: ArticleDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleDetails>>;
    public addArticle(articleDetails: ArticleDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleDetails>>;
    public addArticle(articleDetails: ArticleDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleDetails === null || articleDetails === undefined) {
            throw new Error('Required parameter articleDetails was null or undefined when calling addArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ArticleDetails>(`${this.configuration.basePath}/articles`,
            articleDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param articleId 
     * @param articleComment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addArticleComment(articleId: string, articleComment: ArticleComment, observe?: 'body', reportProgress?: boolean): Observable<ArticleDetails>;
    public addArticleComment(articleId: string, articleComment: ArticleComment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleDetails>>;
    public addArticleComment(articleId: string, articleComment: ArticleComment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleDetails>>;
    public addArticleComment(articleId: string, articleComment: ArticleComment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling addArticleComment.');
        }
        if (articleComment === null || articleComment === undefined) {
            throw new Error('Required parameter articleComment was null or undefined when calling addArticleComment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ArticleDetails>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/comments`,
            articleComment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adjust publicationDate by articles id
     * 
     * @param articlesDownload Articles which have to be adjusted
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adjustPublicationDate(articlesDownload: ArticlesDownload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adjustPublicationDate(articlesDownload: ArticlesDownload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adjustPublicationDate(articlesDownload: ArticlesDownload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adjustPublicationDate(articlesDownload: ArticlesDownload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articlesDownload === null || articlesDownload === undefined) {
            throw new Error('Required parameter articlesDownload was null or undefined when calling adjustPublicationDate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/binary'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/articles/publicationDatePatch`,
            articlesDownload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteArticle(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteArticle(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteArticle(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteArticle(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling deleteArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download zipped file and images by article id
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadArticleAndImagesZipped(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadArticleAndImagesZipped(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadArticleAndImagesZipped(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadArticleAndImagesZipped(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling downloadArticleAndImagesZipped.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/zip`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download file by articles id
     * 
     * @param articlesDownload Articles which have to be downloaded
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadArticlesInDocx(articlesDownload: ArticlesDownload, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadArticlesInDocx(articlesDownload: ArticlesDownload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadArticlesInDocx(articlesDownload: ArticlesDownload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadArticlesInDocx(articlesDownload: ArticlesDownload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articlesDownload === null || articlesDownload === undefined) {
            throw new Error('Required parameter articlesDownload was null or undefined when calling downloadArticlesInDocx.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/binary'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/articles/docx`,
            articlesDownload,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get article
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getArticle(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleDetails>;
    public getArticle(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleDetails>>;
    public getArticle(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleDetails>>;
    public getArticle(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling getArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleDetails>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get article comments
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getArticleComments(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleComments>;
    public getArticleComments(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleComments>>;
    public getArticleComments(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleComments>>;
    public getArticleComments(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling getArticleComments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleComments>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/comments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get article revisions
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getArticleRevisions(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleRevisions>;
    public getArticleRevisions(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleRevisions>>;
    public getArticleRevisions(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleRevisions>>;
    public getArticleRevisions(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling getArticleRevisions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleRevisions>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/revisions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get articles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getArticles(observe?: 'body', reportProgress?: boolean): Observable<Array<ArticleDetails>>;
    public getArticles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ArticleDetails>>>;
    public getArticles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ArticleDetails>>>;
    public getArticles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ArticleDetails>>(`${this.configuration.basePath}/articles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Campaign article
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignArticles(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleCampaign>;
    public getCampaignArticles(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleCampaign>>;
    public getCampaignArticles(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleCampaign>>;
    public getCampaignArticles(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling getCampaignArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleCampaign>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/campaign`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param articleId 
     * @param articleDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'body', reportProgress?: boolean): Observable<ArticleDetails>;
    public patchArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleDetails>>;
    public patchArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleDetails>>;
    public patchArticle(articleId: string, articleDetails: ArticleDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling patchArticle.');
        }
        if (articleDetails === null || articleDetails === undefined) {
            throw new Error('Required parameter articleDetails was null or undefined when calling patchArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<ArticleDetails>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}`,
            articleDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish article
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishArticle(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public publishArticle(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public publishArticle(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public publishArticle(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling publishArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}/publish`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param articleId 
     * @param articleDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'body', reportProgress?: boolean): Observable<ArticleDetails>;
    public updateArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleDetails>>;
    public updateArticle(articleId: string, articleDetails: ArticleDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleDetails>>;
    public updateArticle(articleId: string, articleDetails: ArticleDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling updateArticle.');
        }
        if (articleDetails === null || articleDetails === undefined) {
            throw new Error('Required parameter articleDetails was null or undefined when calling updateArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ArticleDetails>(`${this.configuration.basePath}/articles/${encodeURIComponent(String(articleId))}`,
            articleDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
