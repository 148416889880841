import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ArticlesCategoryService} from '../../../client/api/articlesCategory.service';
import {map, startWith} from 'rxjs/operators';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FileDto} from '../../../client/model/fileDto';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Router} from '@angular/router';
import {ArticleCategory} from '../../../client/model/articleCategory';
import {Observable} from 'rxjs/internal/Observable';
import {TagService} from '../../../client/api/tag.service';
import {Tag} from '../../../client/model/tag';
import {ArticleService} from '../../../client/api/article.service';
import {ArticleDetails} from '../../../client/model/articleDetails';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {PublicationsService} from '../../../client/api/publications.service';
import {Order} from '../../../client/model/order';

@Component({
  selector: 'app-article-new',
  templateUrl: './article-new.component.html',
  styleUrls: ['./article-new.component.css']
})
export class ArticleNewComponent implements OnInit {

  form: UntypedFormGroup;

  pageTitle: string = 'Nowy artykuł';

  article: ArticleDetails;

  categories: ArticleCategory[] = [];

  public relativePaths: Array<[string, string]> = [['img', 'Obrazki']];

  articleLength: string;
  keywords: string;
  hint: string;
  showHint: boolean = false;
  articleUrl: string = '';
  paraphraseUrl: string = '';

  // chips
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  filteredTags: Observable<Tag[]>;
  tagCtrl = new UntypedFormControl();
  tags: Tag[];
  allTags: Tag[];
  errorMessage: string;

  isValidFiles: boolean = true;
  hasImg: boolean = true;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  private linkAnchor: string;
  inProgress: boolean;
  externalDomain: string;
  order: Order;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private articlesCategoryService: ArticlesCategoryService,
              private tagService: TagService,
              private dialog: MatDialog,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private publicationService: PublicationsService,
              public progresIndicatorService: ProgressIndicatorService) {

    this.article = data.article;
    this.order = data.order;
    this.externalDomain = data.externalDomain;
    this.article.publishedDate = new Date();

    this.showHint = data.showHint ? data.showHint : false;
    this.hint = data.hint ? data.hint : '';
    this.articleLength = data.articleLength ? data.articleLength : '';
    this.articleUrl = data.articleUrl ? data.articleUrl : '';
    this.paraphraseUrl = data.paraphraseUrl ? data.paraphraseUrl : '';
    this.linkAnchor = data.linkAnchor ? data.linkAnchor : '';

    if (!this.article.files) {
      this.article.files = new Array<FileDto>();
    }

    this.tags = this.article.tags;
    this.keywords = this.tags.map(x => x.name.trim()).join(', ');
    this.pageTitle = 'Nowy artykuł';

    if (this.order != null) {
      switch (this.order.type) {
        case 'ARTICLE':
          this.pageTitle = 'Nowy artykuł';
          break;
        case 'DESCRIPTION':
          this.pageTitle = 'Nowy opis';
          break;
        case 'PARAPHRASE':
          this.pageTitle = 'Nowa parafraza';
          break;
      }
    }
  }

  ngOnInit() {
    this.initForm();

    this.tagService.getTags().subscribe(response => {
      this.allTags = response;

      this.refreshTagList();
    });

    if (this.externalDomain == null) {
      this.articlesCategoryService.getCategories(this.article.domainId).subscribe(response => {
        this.categories = response;
        if (this.article.categoryId) {
          this.form.get('categoryId').setValue(this.article.categoryId);
        }
      });
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.article.id),
      orderType: new UntypedFormControl(null),
      title: new UntypedFormControl(this.article.title, [Validators.required, Validators.maxLength(200)]),
      categoryId: new UntypedFormControl(null),
      content: new UntypedFormControl(this.article.content),
      author: new UntypedFormControl(this.article.author),
      tags: this.formBuilder.array(this.tags),
      publishedDate: new UntypedFormControl(this.article.publishedDate)
    });
  }

  addTag(event: MatChipInputEvent): void {

    let input = event.input;
    let value = event.value.trim();

    let tag = {
      name: value
    };

    // Add our requirement
    if ((value || '').trim()) {
      const tags = this.form.get('tags') as UntypedFormArray;

      tags.push(this.formBuilder.control(tag));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(index: number): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    let removedTag = tags.value[index];

    if (index >= 0) {
      tags.removeAt(index);
    }

    this.allTags.push(removedTag);
    this.refreshTagList();
  }

  onKeyup($event) {
    if ($event.shiftKey == true && $event.keyCode == 8 && this.tagInput.nativeElement.value.length == 0) {
      const tags = this.form.get('tags') as UntypedFormArray;
      const lastTagIndex = tags.value.length - 1;
      if (lastTagIndex > -1) {
        this.removeTag(lastTagIndex);
        this.tagInput.nativeElement.focus();
      }
    }
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {
    const tags = this.form.get('tags') as UntypedFormArray;
    tags.push(this.formBuilder.control(event.option.value));

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    let selectedTagIdx = this.allTags.indexOf(event.option.value);
    this.allTags.splice(selectedTagIdx, 1);

    this.refreshTagList();
  }

  refreshTagList() {
    let sortedTags = this.allTags.sort((tag1, tag2) => this._sortTags(tag1, tag2));
    this.allTags = sortedTags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: Tag | null) => tag ? this._filter(tag) : [])
    );
  }

  onSubmit() {
    const saveBtn = document.getElementById('saveBtn');
    this.isValidFiles = this.article.files && this.article.files.length > 1;
    const contentInput = this.form.controls.content;
    this.hasImg = contentInput.value != null && contentInput.value.toString().indexOf('%IMG') >= -1;
    if (this.form.valid && this.isValidFiles && this.hasImg) {
      this.inProgress = true;
      this.save();
      this.inProgress = false;

    } else {
      saveBtn.classList.add('error');
    }
  }

  save() {
    this.progresIndicatorService.progress = true;
    let articleDTO: ArticleDetails = this.form.value;
    articleDTO.domainId = this.article.domainId;
    articleDTO.externalDomain = this.externalDomain;
    articleDTO.orderId = this.article.orderId;
    articleDTO.files = this.article.files;

    let publishedDate = new Date(articleDTO.publishedDate);
    publishedDate.setHours(9);

    articleDTO.publishedDate = publishedDate;

    this.articleService.addArticle(articleDTO).subscribe(() => {
      this.progresIndicatorService.progress = false;
      this.dialog.closeAll();
    }, error => {
      this.errorMessage = <any>error;
      this.progresIndicatorService.progress = false;
    });
  }

  private _filter(value: any): Tag[] {
    let filterValue = '';

    if (typeof value === 'string') {
      filterValue = value.trim().toLowerCase();
    } else {
      filterValue = value.name.trim().toLowerCase();
    }

    let filterResult = this.allTags.filter(tag => tag.name.trim().toLowerCase().indexOf(filterValue) === 0);

    return filterResult;
  }

  private _sortTags(tag1: Tag, tag2: Tag) {
    let val1 = tag1.name.toLowerCase();
    let val2 = tag2.name.toLowerCase();

    return val1 < val2 ? -1 : val2 > val1 ? 1 : 0;
  }

  get formData() {
    return <UntypedFormArray>this.form.get('tags');
  }

}
