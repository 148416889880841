<mat-card >
  <h2 mat-card-title>Edycja {{domain.name}}</h2>
  <mat-card-content>
    <mat-sidenav-container class="example-container" *ngIf="loaded">
      <mat-sidenav mode="side" opened>
        <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" vertical [value]="submenu">
          <mat-button-toggle value="template">Szablon</mat-button-toggle>
          <mat-button-toggle value="links">Linki</mat-button-toggle>
          <mat-button-toggle value="banners">Banery</mat-button-toggle>
          <mat-button-toggle value="contact">Formularz kontaktowy</mat-button-toggle>
          <mat-button-toggle value="articles">Artykuły</mat-button-toggle>
          <mat-button-toggle *appEnableFeature="'ads'" value="ads">Ogłoszenia i katalogi firm</mat-button-toggle>
          <mat-button-toggle value="renderLog">Dziennik</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-sidenav>
      <mat-sidenav-content >
        <app-edit-template [domain]="domain" *ngIf="group.value == 'template'"></app-edit-template>
        <app-edit-links *ngIf="group.value == 'links'"></app-edit-links>
        <app-edit-banners *ngIf="group.value == 'banners'"></app-edit-banners>
        <app-editcontact-form [domain]="domain" *ngIf="group.value == 'contact'"></app-editcontact-form>
        <app-articles-list [domain]="domain" *ngIf="group.value == 'articles'"></app-articles-list>
        <app-domain-ads-list [domain]="domain" *ngIf="group.value == 'ads'"></app-domain-ads-list>
        <app-render-log [domain]="domain" *ngIf="group.value == 'renderLog'"></app-render-log>
        <br/><br/><br/><br/><br/><br/>
        <br/><br/><br/><br/><br/><br/></mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card-content>
</mat-card>
