import {Component} from '@angular/core';
import {AuthService} from './features/services/auth.service';
import {Router} from '@angular/router';
import {LoginService} from './features/login/login.service';
import {ProgressIndicatorService} from './features/commons/progress-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  activeLink: String;

  constructor(public authService: AuthService,
              private router: Router,
              protected loginService: LoginService,
              public progres: ProgressIndicatorService) {

  }
}
