<form class="example-form">
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Title" name="Title" [(ngModel)]="domain.title">
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Domena IDN" name="Domena IDN" [(ngModel)]="domain.altName">
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Suffix" name="Suffix" [(ngModel)]="domain.suffix">
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Meta description" name="MetaDescription"
                [(ngModel)]="domain.metaDescription" rows="5"></textarea>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field>
      <mat-select placeholder="Kategoria serwisu" [(ngModel)]="domain.domainCategoryId"
                  [(value)]="domain.domainCategoryId"
                  name="category">
        <mat-option *ngFor="let item of categories" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <mat-form-field>
      <mat-select placeholder="Język serwisu" [(ngModel)]="domain.countryCode"
                  [(value)]="domain.countryCode"
                  name="language">
        <mat-option *ngFor="let item of countries" [value]="item.iso2code">
          {{item.countryName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="full-width-container">
    <p>Logo:</p>
    <button mat-icon-button (click)="deleteLogo()" matTooltip="Usuń logo" color="warn"
            *ngIf="domain.logo!=null && domain.logo.id!=null">
      <mat-icon>delete</mat-icon>
    </button>
    <app-file-upload [canDelete]="true"
                     [fileInputLabel]=""
                     [fileListLabel]=""
                     [limit]="1"
                     [pixabayAllowed]="false"
                     [uploadedFile]="domain.logo"
                     [preview]="domain.logo!=null && domain.logo.id!=null"
                     (change)="logoUpdate($event)">

    </app-file-upload>
  </div>
  <div class="full-width-container">
    <p>Favicon: (PNG/JPG)</p>
    <button mat-icon-button (click)="deleteFavicon()" matTooltip="Usuń favicon" color="warn"
            *ngIf="domain.favicon!=null && domain.favicon.id!=null">
      <mat-icon>delete</mat-icon>
    </button>
    <app-file-upload [canDelete]="true"
                     [fileInputLabel]=""
                     [fileListLabel]=""
                     [limit]="1"
                     [pixabayAllowed]="false"
                     [uploadedFile]="domain.favicon"
                     [preview]="domain.favicon!=null && domain.favicon.id!=null"
                     (change)="faviconUpdate($event)"></app-file-upload>
  </div>
  <div class="full-width-container">
    <p>Ikona sponsorowanego artykułu: (PNG/JPG)</p>
    <button mat-icon-button (click)="deleteSponsoredArticleIcon()" matTooltip="Usuń ikone" color="warn"
            *ngIf="domain.sponsoredArticleIcon!=null && domain.sponsoredArticleIcon.id!=null">
      <mat-icon>delete</mat-icon>
    </button>
    <app-file-upload [canDelete]="true"
                     [fileInputLabel]=""
                     [fileListLabel]=""
                     [limit]="1"
                     [pixabayAllowed]="false"
                     [uploadedFile]="domain.sponsoredArticleIcon"
                     [preview]="domain.sponsoredArticleIcon!=null && domain.sponsoredArticleIcon.id!=null"
                     (change)="sponsoredArticleIconUpdate($event)"></app-file-upload>
  </div>
</form>
