<div class="translation-list-container">
  <form [formGroup]="searchForm" class="full-width-container">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-button (click)="createTranslation()" matTooltip="Dodaj nową" width="10">
        <mat-icon>add_circle</mat-icon>
      </button>
      <mat-form-field width="70">
        <input type="text" matInput placeholder="Szukaj " formControlName="keyword" (keyup)="searchTranslation()">
      </mat-form-field>
      <button width="10" mat-button mat-raised-button color="accent"
              (click)="searchTranslation()">Szukaj
      </button>
      <button width="10" mat-button color="primary" (click)="resetForm()">Wyczyść</button>
    </div>
  </form>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Placeholder</th>
      <td mat-cell *matCellDef="let element">
        {{element.label}}
      </td>
    </ng-container>

    <ng-container matColumnDef="language">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Język</th>
      <td mat-cell *matCellDef="let element">{{element.language}}
        <span *ngIf="element.language!=null" class="flag-icon flag-icon-{{element.language.toLowerCase()}}"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="translation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tłumaczenie</th>
      <td mat-cell *matCellDef="let element">{{element.translation}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button (click)="onTranslationEdit(element)" matTooltip="Edytuj"
                *ngIf="element">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="onDeleteClick(element)" color="warn" matTooltip="Usuń">
          <mat-icon>delete</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="total"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100,500]"
                 (page)="changePage($event)"
  ></mat-paginator>
</div>
