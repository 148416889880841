<mat-card *ngIf="projectLoaded">
  <div *ngIf="!edit"><p>Projekt: <b>{{project.name}} </b> <span class="edit" (click)="edit=true">(Edytuj nazwe)</span>
  </p>
  </div>
  <div *ngIf="edit">Projekt: <input matInput placeholder="" name="projectName" [(ngModel)]="project.name">
    <button mat-button mat-button-raised color="primary" (click)="edit=false">Zapisz</button>
  </div>
  <mat-card-content>
    <mat-card>
      <mat-card-content>
        <mat-grid-list cols="4" rowHeight="6:1">
          <mat-grid-tile>
            <mat-checkbox class="example-margin" [(ngModel)]="project.status">Aktywna (Włączona)</mat-checkbox>
          </mat-grid-tile>
          <mat-grid-tile>Przydzieleń banerów w domenach: {{getProjectDomainBanners(project)}}</mat-grid-tile>
          <mat-grid-tile>Przydzieleń banerów w artykułach: {{getProjectArticleBanners(project)}}</mat-grid-tile>
          <mat-grid-tile>Przydzieleń linków {{getProjectLinks(project)}}</mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button mat-raised-button color="primary" (click)="save()">Zapisz zmiany w projekcie</button>
      </mat-card-actions>
    </mat-card>
    <h3>Aktywne kampanie:</h3>

    <mat-card class="full-width-container">

      <h3 mat-card-title>Kampania linków:</h3>
      <mat-card-content class="full-width-container">
        <table mat-table [dataSource]="linkDataSource" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa kampanii</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="externalId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zewnętrzne ID</th>
            <td mat-cell *matCellDef="let element"> {{element.externalId}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox class="example-margin" [(ngModel)]="element.status" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="stats">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statystyki</th>
            <td mat-cell *matCellDef="let element"> {{getLinkStats(element)}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Zarządzaj</th>
            <td mat-cell *matCellDef="let element">

              <button mat-icon-button [routerLink]="['/campaign/links', element.id]">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteLinkCampaign(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="linkDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: linkDisplayedColumns;"></tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button mat-raised-button color="primary" [routerLink]="['/campaign/links', 'new']"
                [queryParams]="{projectId:project.id}">Dodaj nową kampanię linków
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="full-width-container">
      <h3 mat-card-title>Kampania banerowa:</h3>
      <mat-card-content class="full-width-container">
        <table mat-table [dataSource]="bannerDataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa kampanii</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox class="example-margin" [(ngModel)]="element.status" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="stats">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statystyki</th>
            <td mat-cell *matCellDef="let element"> {{getBannerStats(element)}}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Zarządzaj</th>
            <td mat-cell *matCellDef="let element">

              <button mat-icon-button [routerLink]="['/campaign/banner', element.id]" matTooltip="Edytuj">
                <mat-icon>edit</mat-icon>
              </button>

              <button mat-icon-button (click)="deleteBannerCampaign(element)" matTooltip="Usuń">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="bannerDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: bannerDisplayedColumns;"></tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button mat-raised-button color="primary" [routerLink]="['/campaign/banner', 'new']"
                [queryParams]="{projectId:project.id}">Dodaj nową kampanię banerowa
        </button>
      </mat-card-actions>
    </mat-card>


  </mat-card-content>
</mat-card>
