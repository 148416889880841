import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../client/api/user.service';
import {Users} from '../../../client/model/users';
import {User} from '../../../client/model/user';
import {AddEditUserModalComponent} from '../add-edit-user-modal/add-edit-user-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UserRolesComponent} from '../user-roles/user-roles.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  private users: Users;
  dataSource: MatTableDataSource<User>;
  displayedColumns: string[] = ['username', 'firstName', 'lastName', 'email', 'phone', 'roles', 'active', 'deleted', 'id', 'actions'];


  constructor(private userService: UserService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private authService: AuthService,
              private router: Router) {
    if (this.authService.hasRole('COPYWRITER') && !this.authService.hasRole('ADMINISTRATOR')) {
      this.router.navigateByUrl('/copywriters');
    }
  }

  ngOnInit() {
    this.getUsers();
  }

  private getUsers() {
    this.userService.getUsers().subscribe(value => {
      this.users = value;
      this.dataSource = new MatTableDataSource(this.users);
    });
  }

  edit(user: User) {
    const dialogRef = this.dialog.open(AddEditUserModalComponent, {
      data: user,
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }

  addNew() {
    const dialogRef = this.dialog.open(AddEditUserModalComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }

  editRoles(user: User) {
    this.userService.getUserRoles(user.id).subscribe(value => {
        const dialogRef = this.dialog.open(UserRolesComponent, {
          data: {user: user, roles: value},
          width: '80%'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.getUsers();
        });
      }
    );
  }

  printRoles(roles: string) {
    return roles.split(',').map(value => this.translateRole(value)).join('</br>');
  }

  private translateRole(value: string) {
    switch (value) {
      case 'ADMINISTRATOR':
        return 'admin';
        break;
      case 'COPYWRITER':
        return 'copywriter';
        break;
      case 'EMPLOYEE':
        return 'pracownik';
        break;
      case 'PUBLISHER_ADMIN':
        return 'admin wydawcow';
        break;
      case 'COPYWRITER_ADMIN':
        return 'admin copywriterow';
        break;
    }
  }
}
