<mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" [value]="templateMenu">
  <mat-button-toggle value="template">Wybór szablonu</mat-button-toggle>
  <mat-button-toggle value="main">Główne ustawienia</mat-button-toggle>
  <mat-button-toggle value="advanced">Ustawienia zawansowane</mat-button-toggle>
  <mat-button-toggle value="owncss">Własne CSS</mat-button-toggle>
  <mat-button-toggle value="categories">Kategorie</mat-button-toggle>
  <mat-button-toggle value="menu">Menu</mat-button-toggle>
  <mat-button-toggle *appEnableFeature="'ads'" value="ads">Ogłoszenia i katalogi</mat-button-toggle>
  <mat-button-toggle value="home">Strona główna</mat-button-toggle>
</mat-button-toggle-group>

<mat-card>
  <mat-card-content>
    <app-select-template [domain]="domain" *ngIf="group.value == 'template'"></app-select-template>
    <app-main-settings [domain]="domain" *ngIf="group.value == 'main'"></app-main-settings>
    <app-advanced-settings [domain]="domain" *ngIf="group.value == 'advanced'"></app-advanced-settings>
    <app-custom-styles-and-scripts [domain]="domain" *ngIf="group.value == 'owncss'"></app-custom-styles-and-scripts>
    <app-article-categories-list [domain]="domain" *ngIf="group.value == 'categories'"></app-article-categories-list>
    <app-menu [domain]="domain" *ngIf="group.value == 'menu'"></app-menu>
    <app-ads-domain-setup [domain]="domain" *ngIf="group.value == 'ads'"></app-ads-domain-setup>
    <app-main-page-widgets [domain]="domain" *ngIf="group.value == 'home'"></app-main-page-widgets>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="primary" mat-raised-button cdkFocusInitial (click)="save()">Zapisz</button>
    <button mat-button mat-raised-button cdkFocusInitial (click)="saveAndBackToList()">Zapisz i wróć do listy</button>
  </mat-card-actions>
</mat-card>
