<form [formGroup]="form" >
  <mat-dialog-content>
    <h4>Wybierz domenę</h4>
    <mat-form-field>
      <mat-label>Wybierz domenę</mat-label>
      <mat-select placeholder="Wybierz domenę" formControlName="domainId" [required]="true" >
        <mat-option *ngFor="let domain of domains | async" [value]="domain.id">{{ domain.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="moveArticleToDomain()">Zapisz</button>
    <button mat-button mat-dialog-close>Wyjdź</button>
  </mat-dialog-actions>
</form>
