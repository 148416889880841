<mat-card>
  <mat-card-header>
    <h2>Menu główne</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-card>
      <mat-card-header *ngIf="menuItems.length>0">
        <h3>Dostępne elementy:</h3>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-chip-list>
            <mat-chip *ngFor="let item of menuItems; let i = index" (click)="addToMenu(item)">

              {{getLabel(item)}}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <h3>Wybrane elementy:</h3>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-list class="menuItems">
          <mat-chip *ngFor="let item of getChoosenMainMenuItems(); let i = index" class="menuItems">
            <mat-icon (click)="moveLeft(item)" *ngIf="i>0" class="menuItems">chevron_left</mat-icon>
            {{getLabel(item)}}
            <mat-icon matChipRemove (click)="removeItem(item)" class="menuItems">cancel</mat-icon>
            <mat-icon (click)="moveRight(item)" *ngIf="i<getChoosenMainMenuItems().length-1" class="menuItems">chevron_right</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-card-content>
      <mat-card-footer>
        <br/>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <h2>Menu dodatkowe (dolne / mobilne) </h2>
  </mat-card-header>
  <mat-card-content>
    <mat-card>
      <mat-card-header *ngIf="footerMenuItems.length>0">
        <h3>Dostępne elementy:</h3>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-chip-list>
            <mat-chip *ngFor="let item of footerMenuItems; let i = index" (click)="addToMenu(item)">

              {{getLabel(item)}}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <h3>Wybrane elementy:</h3>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-list>
          <mat-chip *ngFor="let item of getChoosenFooterMenuItems(); let i = index">
            <mat-icon (click)="moveLeft(item)" *ngIf="i>0">chevron_left</mat-icon>
            {{getLabel(item)}}
            <mat-icon matChipRemove (click)="removeItem(item)">cancel</mat-icon>
            <mat-icon (click)="moveRight(item)" *ngIf="i<getChoosenFooterMenuItems().length-1">chevron_right</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-card-content>
      <mat-card-footer>
        <br/>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>


