<h2 mat-dialog-title *ngIf="mode=='edit'">Edytuj wydawce</h2>
<h2 mat-dialog-title *ngIf="mode=='new'">Nowy wydawca</h2>

<mat-dialog-content class="full-width-container">

  <form class="full-width-container">
    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="Nazwa" name="publisherName" [(ngModel)]="publisher.name">
    </mat-form-field>

    <mat-form-field class="full-width-container mat-form-field">
      <input matInput placeholder="Adres email" name="email" [(ngModel)]="publisher.email">
      <mat-error *ngIf="true">adres strony musi zawierac http:// lub https:/</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="Telefon" name="phone" [(ngModel)]="publisher.phone">
    </mat-form-field>

    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="Osoba kontaktowa" name="contactPerson" [(ngModel)]="publisher.contactPerson">
    </mat-form-field>

    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="" style="display: none">
      <mat-checkbox class="example-margin" placeholder="Nazwa" name="ndaSigned"  [(ngModel)]="publisher.ndaSigned">NDA podpisane</mat-checkbox>
    </mat-form-field>

    <mat-form-field class="full-width-container mat-form-field">
      <input matInput placeholder="Link do oferty" name="offerLink" [(ngModel)]="publisher.offerLink">
      <mat-error *ngIf="true">adres strony musi zawierac http:// lub https:/</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="Forma zlecania" name="orderingType" [(ngModel)]="publisher.orderingType">
    </mat-form-field>

    <mat-form-field class="full-width mat-form-field">
      <input matInput placeholder="Dodatkowe informacje (uwagi)" name="comments" [(ngModel)]="publisher.comments">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
