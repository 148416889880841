<div id="addCategory" *ngIf="mode != 'edit'">
  <h2 mat-dialog-title>Nowa kategoria</h2>
  <mat-dialog-content class="mat-typography">
    <form class="example-form">
      <div *ngFor="let category of categories; let in = index">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Nazwa kategori" name="lastname-{{in}}" [(ngModel)]="category.name">
        </mat-form-field>
      </div>
    </form>
    <button mat-button (click)="addNew()" *ngIf="mode != 'edit'">+kolejna</button>
  </mat-dialog-content>
</div>

<div id="editCategory" *ngIf="mode == 'edit'">
  <h2 mat-dialog-title>Edytuj kategorię {{tmpName}}</h2>
  <form class="example-form">
    <div>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Nazwa kategori" name="name" [(ngModel)]="tmpValue">
      </mat-form-field>
      <mat-checkbox class="example-margin" [(ngModel)]="category.dedicated" name="dedicated">Dedykowana</mat-checkbox>

    </div>
  </form>
</div>


<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
