import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ProgressIndicatorService} from '../features/commons/progress-indicator.service';

@Directive({
  selector: '[appProgressSpinner]'
})
export class ProgressSpinnerDirective implements OnInit {

  constructor(public progress: ProgressIndicatorService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  ngOnInit(): void {
    if (this.progress.progress) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
