<p>Edytuj swoje konto!</p>

<form class="server_form">
  <div fxLayout="column" fxLayoutAlign="stretch">
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="Login" name="username" [(ngModel)]="user.username" [disabled]="mode=='edit'">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="Imię" name="firstName" [(ngModel)]="user.firstName">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="Nazwisko" name="lastName" [(ngModel)]="user.lastName">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput required placeholder="Email" name="email" [(ngModel)]="user.email">
    </mat-form-field>
    <button mat-button (click)="save()">Zapisz zmiany</button>
  </div>
</form>
