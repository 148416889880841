import {Component, OnInit, ViewChild} from '@angular/core';
import {PrivacyPolicy} from '../../../client/model/privacyPolicy';
import {PrivacyService} from '../../../client/api/privacy.service';
import {EditPolicyComponent} from '../edit-policy/edit-policy.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-privacy-policy-list',
  templateUrl: './privacy-policy-list.component.html',
  styleUrls: ['./privacy-policy-list.component.css']
})
export class PrivacyPolicyListComponent implements OnInit {
  private policies: Array<PrivacyPolicy>;
  dataSource: MatTableDataSource<PrivacyPolicy>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['countryCode', 'fileName', 'title', 'actions'];

  constructor(private policyService: PrivacyService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getPolicies();
  }

  private getPolicies() {
    this.policyService.getPrivacyPolicy().subscribe(value => {
      this.policies = value.policies;
      this.dataSource = new MatTableDataSource(this.policies);
      this.dataSource.sort = this.sort;
    });
  }

  onNewClick() {
    const policy: PrivacyPolicy = {
      countryCode: 'pl'
    };
    const dialogConfig = {
      width: '90%',
      height: '80%',
      autoFocus: false,
      disableClose: true,
      data: {
        policy: policy,
        edit: false
      }
    };

    const dialogRef = this.dialog.open(
      EditPolicyComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPolicies();
    });
  }

  onEditClick(element: any) {
    const dialogConfig = {
      width: '90%',
      height: '80%',
      autoFocus: false,
      disableClose: true,
      data: {
        policy: element,
        edit: true
      }
    };

    const dialogRef = this.dialog.open(
      EditPolicyComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPolicies();
    });

  }

  delete(element: any) {

  }
}
