<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" class="example-form" (ngSubmit)="save()">

  <mat-dialog-content class="mat-typography">

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Nazwa kategorii" formControlName="categoryName" (focusout)="setTranslationLabel()"/>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Etykieta kategorii(Placeholder do tłumaczeń) np: %KATEGORIA_PIERWSZA% (bez znaków PL)"
               formControlName="translationLabel"/>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Typ kategorii:" formControlName="categoryType" [required]="true" name="type">
          <mat-option value="COMPANY">
            Firma
          </mat-option>
          <mat-option value="ADS">
            Ogłoszenie
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Kategoria nadrzędna" formControlName="parentCategoryId"
                    name="category">
          <mat-option *ngFor="let cat of getCategories()" [value]="cat.id">
            {{cat.categoryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <div [formGroup]="form">
        <label>Ikona kategorii</label>
        <div class="input-group">
          <span class="input-group-addon"><i [ngClass]="icon.value"></i></span>
          <input type="text" name="icon" class="form-control"
                 formControlName="icon"
                 [iconPicker]="iconTxt"
                 [ipPosition]="'bottom'"
                 [ipWidth]="'250px'"
                 [ipPlaceHolder]="'Wyszukaj ikonę dla kategorii'"
                 [ipFallbackIcon]="adCategory.icon"
                 (iconPickerSelect)="onIconPickerSelect($event)"/>
        </div>
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial id="saveBtn">{{ adCategory.id ? 'Zapisz' : 'Dodaj' }}</button>
  </mat-dialog-actions>
</form>
