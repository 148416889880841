import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-account-panel',
  templateUrl: './account-panel.component.html',
  styleUrls: ['./account-panel.component.css']
})
export class AccountPanelComponent implements OnInit {
  submenu = 'notificationsConfig';


  constructor() {
  }

  ngOnInit(): void {
  }

}
