import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../client/model/domain';
import {DomainService} from '../../../client/api/domain.service';
import {DomainRenderLog} from '../../../client/model/domainRenderLog';

@Component({
  selector: 'app-render-log',
  templateUrl: './render-log.component.html',
  styleUrls: ['./render-log.component.css']
})
export class RenderLogComponent implements OnInit {

  @Input() domain: Domain;
  log: DomainRenderLog;

  constructor(private domainService: DomainService) {
  }

  ngOnInit(): void {
    this.domainService.getDomainLog(this.domain.id).subscribe(value => this.log = value);
  }

}
