/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CopywriterDetails } from '../model/copywriterDetails';
import { CopywriterSelectListItem } from '../model/copywriterSelectListItem';
import { ErrorResponse } from '../model/errorResponse';
import { PaymentDTO } from '../model/paymentDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CopywriterService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create copywriter
     * 
     * @param copywriterDetails Created copywriter object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCopywriter(copywriterDetails: CopywriterDetails, observe?: 'body', reportProgress?: boolean): Observable<CopywriterDetails>;
    public createCopywriter(copywriterDetails: CopywriterDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CopywriterDetails>>;
    public createCopywriter(copywriterDetails: CopywriterDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CopywriterDetails>>;
    public createCopywriter(copywriterDetails: CopywriterDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (copywriterDetails === null || copywriterDetails === undefined) {
            throw new Error('Required parameter copywriterDetails was null or undefined when calling createCopywriter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CopywriterDetails>(`${this.configuration.basePath}/copywriters`,
            copywriterDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param copywriterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCopywriter(copywriterId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCopywriter(copywriterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCopywriter(copywriterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCopywriter(copywriterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (copywriterId === null || copywriterId === undefined) {
            throw new Error('Required parameter copywriterId was null or undefined when calling deleteCopywriter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(copywriterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download payments Excel for a given user ID
     * 
     * @param userId 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPaymentsExcel(userId: string, date: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadPaymentsExcel(userId: string, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadPaymentsExcel(userId: string, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadPaymentsExcel(userId: string, date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadPaymentsExcel.');
        }
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling downloadPaymentsExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(userId))}/paymentsExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param copywriterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCopywriter(copywriterId: string, observe?: 'body', reportProgress?: boolean): Observable<CopywriterDetails>;
    public getCopywriter(copywriterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CopywriterDetails>>;
    public getCopywriter(copywriterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CopywriterDetails>>;
    public getCopywriter(copywriterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (copywriterId === null || copywriterId === undefined) {
            throw new Error('Required parameter copywriterId was null or undefined when calling getCopywriter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CopywriterDetails>(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(copywriterId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get copywriters
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCopywriters(observe?: 'body', reportProgress?: boolean): Observable<Array<CopywriterDetails>>;
    public getCopywriters(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CopywriterDetails>>>;
    public getCopywriters(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CopywriterDetails>>>;
    public getCopywriters(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CopywriterDetails>>(`${this.configuration.basePath}/copywriters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get copywriters select list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCopywritersSelectList(observe?: 'body', reportProgress?: boolean): Observable<Array<CopywriterSelectListItem>>;
    public getCopywritersSelectList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CopywriterSelectListItem>>>;
    public getCopywritersSelectList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CopywriterSelectListItem>>>;
    public getCopywritersSelectList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CopywriterSelectListItem>>(`${this.configuration.basePath}/copywriters/selectList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get payments
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayments(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentDTO>>;
    public getPayments(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentDTO>>>;
    public getPayments(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentDTO>>>;
    public getPayments(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getPayments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PaymentDTO>>(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(userId))}/payments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param copywriterId 
     * @param copywriterDetails 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCopywriter(copywriterId: string, copywriterDetails: CopywriterDetails, observe?: 'body', reportProgress?: boolean): Observable<CopywriterDetails>;
    public updateCopywriter(copywriterId: string, copywriterDetails: CopywriterDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CopywriterDetails>>;
    public updateCopywriter(copywriterId: string, copywriterDetails: CopywriterDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CopywriterDetails>>;
    public updateCopywriter(copywriterId: string, copywriterDetails: CopywriterDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (copywriterId === null || copywriterId === undefined) {
            throw new Error('Required parameter copywriterId was null or undefined when calling updateCopywriter.');
        }
        if (copywriterDetails === null || copywriterDetails === undefined) {
            throw new Error('Required parameter copywriterDetails was null or undefined when calling updateCopywriter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CopywriterDetails>(`${this.configuration.basePath}/copywriters/${encodeURIComponent(String(copywriterId))}`,
            copywriterDetails,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
