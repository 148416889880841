import {Component, Inject, OnInit} from '@angular/core';
import {ImagesService} from '../../../client/api/images.service';
import {Image} from '../../../client/model/image';
import {PixabayImage} from '../../../client/model/pixabayImage';
import {ProgressIndicatorService} from '../progress-indicator.service';
import {FileDto} from '../../../client/model/fileDto';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-pixabay-search',
  templateUrl: './pixabay-search.component.html',
  styleUrls: ['./pixabay-search.component.css']
})
export class PixabaySearchComponent implements OnInit {

  page = 1;
  query: string;
  images: Array<Image>;
  loaded = false;
  total: number;
  pages: Array<number>;
  files: FileDto[] = new Array();
  downloaded: Image[] = new Array();
  querySize = 20;
  pageNo: number;

  pageEvent: PageEvent;

  constructor(private imageService: ImagesService,
              private dialog: MatDialog,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: FileDto[]) {
    if (data != null) {
      this.files = data;
    }
  }

  ngOnInit() {
  }

  searchImages(keyword: string) {
    this.progres.progress = true;
    this.imageService.getImages(keyword, this.page, this.querySize).subscribe(value => {
      this.progres.progress = false;
      this.images = value.images;
      this.total = value.total;
      this.pageNo = Math.floor(this.total / this.querySize);
      this.pages = new Array<number>(this.pageNo > 20 ? 20 : this.pageNo);
      this.loaded = true;
    }, error1 => {
      this.progres.progress = false;
      this.snackBar.open('Przekroczono godzinowy limit zapytań do pixabay', 'Zamknij',
        {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    });
  }

  changePage(pageNo: number) {
    this.page = pageNo;
    this.searchImages(this.query);
  }

  download(item: Image) {
    this.progres.progress = true;
    const image: PixabayImage = {
      imageUrl: item.largeImageURL
    };
    this.imageService.getPixabayImage(image).subscribe(value => {
        this.progres.progress = false;
        this.downloaded.push(item);
        this.files.push(value);
      },
      error1 => {
        this.progres.progress = false;
      });
  }

  closePixabayAndSave() {
    this.dialog.getDialogById('pixabay').close(this.files);
  }

  isOnDownloadedList(id: string) {
    return this.downloaded.filter(value => value.id === id).length > 0;
  }

  changePageSize($event: PageEvent) {
    this.pageEvent = $event;
    this.page = $event.pageIndex;
    this.querySize = $event.pageSize;
    this.searchImages(this.query);
  }
}
