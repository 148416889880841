import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Publisher} from '../../../client/model/publisher';
import {PublisherService} from '../../../client/api/publisher.service';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-add-edit-publisher',
  templateUrl: './add-edit-publisher.component.html',
  styleUrls: ['./add-edit-publisher.component.css']
})
export class AddEditPublisherComponent implements OnInit {
  mode: any;
  publisher: Publisher;
  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private publisherService: PublisherService,
              private dialog: MatDialog) {
    this.mode = data.mode;
    this.publisher = data.publisher;
  }

  ngOnInit(): void {
  }

  save() {
    if (this.mode !== 'edit') {
      this.publisherService.createPublisher(this.publisher)
        .subscribe(value => {
          this.dialog.closeAll();
        });
    } else {
      this.publisherService.updatePublisher(this.publisher.id, this.publisher)
        .subscribe(value => {
          this.dialog.closeAll();
        });
    }
  }
}
