export class DropDownItemDTO {

  public value: string;
  public text: string;

  public constructor(value: string, text: string) {
    this.value = value;
    this.text = text;
  }
}
