<mat-card>
  <h2 mat-card-title>Szablony</h2>
  <button mat-button [routerLink]="['/templates/add']">
    <mat-icon>add_circle</mat-icon>
  </button>
  <mat-grid-list cols="6" rowHeight="1:1">
    <mat-grid-tile *ngFor="let item of templates"
                   [ngClass]="{'activeTemplate': active == item.templateName}" [matMenuTriggerFor]="menu">
      <mat-grid-tile-header>{{item.templateName}} ({{item.usedNtimes}})</mat-grid-tile-header>
      <img *ngIf="item.templateThumbnail!=null && item.templateThumbnail.id!=null" [src]="getThumbnailFile(item)"
           layout-fill class="templateThumbnail"/>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/templates/edit', item.id]">Edytuj</button>
        <button mat-menu-item (click)="clone(item)">Klonuj</button>
        <button mat-menu-item (click)="delete(item)">Usuń</button>
      </mat-menu>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
