<mat-dialog-content>

  <div [innerHTML]="content"></div>

</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-button primary mat-dialog-close>Zamknij</button>
</mat-dialog-actions>
