<h2 mat-dialog-title>{{pageTitle}}</h2>
<form [formGroup]="form">
  <mat-dialog-content>


    <div>
      <div width="100%">
        <div>

          <mat-expansion-panel [expanded]="true" *ngIf="showHint">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Wytyczne:
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span>Ilość znaków: {{articleLength}}</span><br>
            <span>Słowa kluczowe: {{keywords}}</span><br>
            <span>Dodatkowe wytyczne: <div [innerHTML]="hint.replace('\n','<br/>')"></div></span><br>
            <span *ngIf="articleUrl.length > 0">URL: <a href="{{articleUrl}}" rel="nofollow noreferrer noopener"
                                                        target="_blank" matTooltip="Link">Link od artykułu</a></span>
          </mat-expansion-panel>
          <br/>

          <div>
            <input type="hidden" formControlName="id">
            <mat-form-field>
              <input matInput placeholder="Tytuł" formControlName="title" [required]="true">
              <mat-error>
                To pole jest wymagane
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-chip-list #tagsList (keyup)="onKeyup($event)">
                <mat-chip *ngFor="let tag of formData.controls; let i = index;"
                          [selectable]="selectable"
                          [removable]="removable">
                  {{tag.value.name}}
                  <mat-icon matChipRemove (click)="removeTag(i)" *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Dodaj tag"
                       #tagInput
                       [formControl]="tagCtrl"
                       [matAutocomplete]="auto"
                       [matChipInputFor]="tagsList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addTag($event)"/>
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                  {{tag.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div>
            <div>
              <mat-form-field>
                <input matInput placeholder="Autor" formControlName="author" [required]="true">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput [matDatepicker]="myDatepicker" formControlName="publishedDate"
                       placeholder="Data publikacji">
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput placeholder="Zewnetrzne ID" formControlName="externalId">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-select placeholder="Domena artykułu" formControlName="domainId">
                  <mat-option *ngFor="let domain of domains | async" [value]="domain.id">{{ domain.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-select placeholder="Kategoria" formControlName="categoryId" required name="category">
                  <mat-option *ngFor="let cat of categories" [value]="cat.id">{{ cat.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <p *ngIf="categories.length == 0 && externalDomain == null" class="mat-error">
                Nie zdefiniowano kategorii dla domeny!
              </p>
            </div>
              <div>
                  <mat-form-field>
                      <mat-select placeholder="Kampania Linków" formControlName="linkCampaignsId">
                          <mat-option *ngFor="let campaign of (linkCampaigns | async)?.campaigns" [value]="campaign.id">
                              {{ campaign.externalId }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>
        </div>
      </div>
      <div width="60" class="next-content-column" *ngIf="showParaphrasePanel">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Zleć parafrazy tekstu dla copywriterów
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-new-order [article]="article" (newOrderCreated)="onNewOrderCreated()"></app-new-order>
        </mat-expansion-panel>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch" style="margin-bottom: 10px">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header style="margin-bottom: 20px">
          <mat-panel-title style="margin-top: 20px">
            <p>
              Obrazki: (pierwszy obrazek zostanie użyty jako zdjęcie główne <br/>
              Każdy kolejny umieść wstawiając w treści <strong style="color: #70d83b;">%IMG%</strong> - pierwsze użycie
              tagu =
              wstawienie drugiego obrazka, drugie użycie tagu = wstawienie trzeciego obrazka itd...</p>
          </mat-panel-title>
          <mat-panel-description style="margin-top: 20px">
            <div class="mat-error" *ngIf="!isValidFiles">Dodaj przynajmniej 2 obrazki</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-file-upload [canDelete]="true"
                         [fileInputLabel]=""
                         [fileListLabel]=""
                         [limit]="100"
                         [uploadedFiles]="article.files"
                         [relativePaths]="relativePaths"
                         [preview]="true"
                         [isConvertToWebp]="true"></app-file-upload>
      </mat-expansion-panel>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">

      <editor
        [formControl]="form.controls.content"
        [init]="{
           base_url: '/tinymce',
           suffix: '.min',
           height: 500,
           menubar: true,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount link'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat |link| help'
   }"
      ></editor>
    </div>

    <div *ngIf="order!=null && articleComments!=null">
      <h1>Komentarze: </h1>
      <table>
        <thead style="background-color: #4CAF50">
        <th>Autor</th>
        <th>Komentarz</th>
        <th>Data</th>
        </thead>
        <tr *ngFor="let comment of articleComments">
          <td style="padding:10px">{{comment.author}}</td>
          <td style="padding:10px">{{comment.content}}</td>
          <td style="padding:10px">{{comment.createdAt.replace("T", " ").split(".")[0]}}</td>
        </tr>
      </table>
    </div>
    <button mat-button mat-raised-button color="primary" (click)="addComment()">Odpowiedz lub dodaj nowe uwagi</button>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary id="saveBtn" (click)="onSubmit()">{{ article.id ? 'Zapisz' : 'Dodaj' }}</button>
    <button *ngIf="order!=null && order.status=='NEED_WORK'" mat-button mat-raised-button id="saveFixedBtn"
            (click)="markAsFixed()">Zapisz i oznacz artykuł jako poprawiony
    </button>
  </mat-dialog-actions>
</form>
