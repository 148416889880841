import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PaymentDTO} from '../../../client/model/paymentDTO';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {PaymentService} from '../../../client/api/payment.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.css']
})
export class PaymentsListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Input() copywriterId: string;
  @Input() adminMode: boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['month', 'charactersTotal', 'netto', 'paymentTotal', 'sentToPayment', 'download'];
  errorMessage: String;
  payments: PaymentDTO[];
  dataSource: MatTableDataSource<PaymentDTO>;

  constructor(private copywriterService: CopywriterService,
              private paymentService: PaymentService) {
  }

  ngOnInit() {
    this.getPayments();
  }

  getPayments() {
    this.copywriterService.getPayments(this.copywriterId).subscribe(response => {
      this.payments = response;
      this.dataSource = new MatTableDataSource<PaymentDTO>(this.payments);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      this.errorMessage = <any>error;
    });
  }

  onChange($event, payment: PaymentDTO) {
    if ($event.checked) {
      this.paymentService.sendToPayment(payment.month, this.copywriterId).subscribe(response => {
        this.getPayments();
      }, error => this.errorMessage = <any>error);
    } else {
      this.paymentService.cancelPayment(payment.id).subscribe(response => {
      }, error => this.errorMessage = <any>error);
    }
  }

  downloadExcel(payment: PaymentDTO) {
    this.copywriterService.downloadPaymentsExcel(this.copywriterId, payment.month).subscribe(response => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `wypłata_${payment.month}.xlsx`;
      link.click();
      link.remove();
    }, error => {
      console.error('There was an error while downloading the Excel file!', error);
    });
  }

}
