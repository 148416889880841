import {Component, Inject} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../client/api/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../client/model/user';
import {UserRole} from '../../../client/model/userRole';
import {FormBuilder, FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent {
  protected roles: UserRole[];
  rolesForm: FormGroup;
  protected user: User;

  constructor(private userService: UserService,
              public dialogRef: MatDialogRef<UserRolesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder) {


    this.roles = data.roles;
    this.user = data.user;
    this.rolesForm = this.formBuilder.group({
      administrator: new FormControl(this.roles.includes(this.roles.find(value => value.name === 'ADMINISTRATOR'))),
      copywriter: new FormControl(this.roles.includes(this.roles.find(value => value.name === 'COPYWRITER'))),
      employee: new FormControl(this.roles.includes(this.roles.find(value => value.name === 'EMPLOYEE'))),
      publisher_admin: new FormControl(this.roles.includes(this.roles.find(value => value.name === 'PUBLISHER_ADMIN'))),
      copywriter_admin: new FormControl(this.roles.includes(this.roles.find(value => value.name === 'COPYWRITER_ADMIN'))),
    });
  }

  save() {
    const newRoles: Map<string, boolean> = this.rolesForm.value;
    let mappedRoles = Object.entries(newRoles).filter(([key, value]) => value === true)
      .map(([key, value]) => {
        return {name: key.toUpperCase()};
      });

    this.userService.updateUserRoles(this.user.id, mappedRoles).subscribe(value => {
      this.snackBar.open('User roles updated successfully', 'Close', {
        duration: 5000,
      });
      this.dialogRef.close();
    });
  }


  isChecked(roleName: string) {
    return this.roles.map(value => value.name).includes(roleName);
  }
}
