<p>
  <button mat-button (click)="addCategory()">
    <mat-icon>add_circle</mat-icon>
  </button>
</p>
<div>
  <h2>Kategorie ogłoszeń</h2>
  <mat-tree [dataSource]="adCategoryDataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <button mat-icon-button disabled></button>
        <i class="{{node.icon}}"> </i> &nbsp; {{node.name}} &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-icon (click)="addChild(node)">add</mat-icon>
        <mat-icon (click)="edit(node)">edit</mat-icon>
      </li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <i class="{{node.icon}}"> </i> &nbsp; {{node.name}}&nbsp;&nbsp;&nbsp;&nbsp;
          <mat-icon (click)="addChild(node)">add</mat-icon>
          <mat-icon (click)="edit(node)">edit</mat-icon>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>


<div>
  <h2>Kategorie firm</h2>
  <mat-tree [dataSource]="companyCategoryDataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <button mat-icon-button disabled></button>
        <i class="{{node.icon}}"> </i> &nbsp; {{node.name}} &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-icon (click)="addChild(node)">add</mat-icon>
        <mat-icon (click)="edit(node)">edit</mat-icon>
      </li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <i class="{{node.icon}}"> </i> &nbsp; {{node.name}}&nbsp;&nbsp;&nbsp;&nbsp;
          <mat-icon (click)="addChild(node)">add</mat-icon>
          <mat-icon (click)="edit(node)">edit</mat-icon>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
