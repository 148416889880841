import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {

  constructor() { }

  formatFileName(fileName: string): string {
    return fileName.replace(/\./g, '').replace(/\s/g, '_');
  }
}
