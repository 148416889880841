<div class="full-width-container">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="month">
    <th mat-header-cell *matHeaderCellDef> Miesiąc </th>
    <td mat-cell *matCellDef="let element"> {{element.month | date:'MM-yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="charactersTotal">
    <th mat-header-cell *matHeaderCellDef> Liczba znaków </th>
    <td mat-cell *matCellDef="let element">
      P: {{element.paraphraseCharactersTotal}}<br>
      T: {{element.articleCharactersTotal}}<br>
      O: {{element.descriptionCharactersTotal}}
    </td>
  </ng-container>

  <ng-container matColumnDef="netto">
    <th mat-header-cell *matHeaderCellDef> Netto </th>
    <td mat-cell *matCellDef="let element">
      P: {{element.paraphraseNetto | currency: ' '}} zł<br>
      T: {{element.articleNetto | currency: ' '}} zł<br>
      O: {{element.descriptionNetto | currency: ' '}} zł
    </td>
  </ng-container>

  <ng-container matColumnDef="paymentTotal">
    <th mat-header-cell *matHeaderCellDef> Łącznie netto (brutto) </th>
    <td mat-cell *matCellDef="let element"> {{element.paraphraseNetto + element.articleNetto + element.descriptionNetto | currency: ' '}} zł<br>
    ({{(element.paraphraseNetto + element.articleNetto + element.descriptionNetto) * 1.23 | currency: ' '}}) zł</td>
  </ng-container>

  <ng-container matColumnDef="sentToPayment">
    <th mat-header-cell *matHeaderCellDef> Zlecono wypłatę </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox (change)="onChange($event, element)" [checked]="element.sentToPayment" [disabled]="!adminMode">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef> Pobierz </th>
    <td mat-cell *matCellDef="let element">
      <button mat-button (click)="downloadExcel(element)">
        <mat-icon> file_download </mat-icon>
      </button>
    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[3, 5, 10]" showFirstLastButtons></mat-paginator>
</div>
