import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../../client/api/user.service';
import {AuthService} from '../../services/auth.service';
import {UserOverview} from '../../../client/model/userOverview';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {User} from '../../../client/model/user';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit {
  user: UserOverview;
  mode = 'edit';
  passwordRepeat: any;

  constructor(private userService: UserService, private authService: AuthService) {
  }

  ngOnInit(): void {
     this.userService.getUser(this.authService.getUser().id).subscribe(
      value => this.user = value
    );
  }
  save() {
    this.userService.updateUser(this.user.id, this.user).subscribe(value => {
      console.log('Zaktualizowano użytkownika');
    },
        error => console.log('Błąd podczas aktualizacji użytkownika'));
  }

}
