<mat-dialog-content>

  <div class="article-list-container">

    <h2>Wersje</h2>

    <div class="full-width-container">

      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">


        <ng-container matColumnDef="revisionNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Numer wersji</th>
          <td mat-cell *matCellDef="let element">{{element.revision}}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
          <td mat-cell *matCellDef="let element">{{element.revisionDate.replace("T", " ").split(".")[0]}}
          </td>
        </ng-container>
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Author</th>
          <td mat-cell *matCellDef="let element">{{getName(element.revisionAuthor)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Operacje</th>
          <td mat-cell *matCellDef="let element">

            <button mat-icon-button (click)="preview(element)" matTooltip="Pokaż">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button (click)="revert(element)" matTooltip="Przywróć stan">
              <mat-icon>undo</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


      </table>

    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button primary mat-dialog-close>Zamknij</button>
</mat-dialog-actions>
