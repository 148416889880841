import {Component, Input, OnInit} from '@angular/core';
import {UploadTemplateModalComponent} from '../upload-template-modal/upload-template-modal.component';
import {TemplateService} from '../../../../../client/api/template.service';
import {Template} from '../../../../../client/model/template';
import {Domain} from '../../../../../client/model/domain';
import {TemplateList} from '../../../../../client/model/templateList';
import {FileService} from '../../../../../client/api/file.service';
import {ProgressIndicatorService} from '../../../../commons/progress-indicator.service';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.css']
})
export class SelectTemplateComponent implements OnInit {

  @Input() domain: Domain;
  public templates: Template[];
  public thumbnails: Map<string, string> = new Map();
  errorMessage: String;
  loaded: boolean = false;

  constructor(public dialog: MatDialog,
              private templateService: TemplateService,
              public progres: ProgressIndicatorService,
              private fileService: FileService) {

  }

  ngOnInit() {
    this.getTemplates();
  }

  getTemplates() {
    this.progres.progress = true;
    this.templateService.getTemplates()
      .subscribe(
        response => {
          this.templates = response;
          this.progres.progress = false;
          this.templates.forEach(value => this.getThumbnail(value));
          this.loaded = true;

        },
        error => this.errorMessage = <any>error);
  }

  setActive(item: Template) {
    this.domain.template = item;
  }

  edit(item: Template) {
    const dialogRef = this.dialog.open(UploadTemplateModalComponent, {
      width: '80%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTemplates();
    });
  }

  getThumbnail(item: TemplateList) {
    if (item.templateThumbnail != null) {
      this.fileService.downloadPngFile(item.templateThumbnail.id).subscribe(value => {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.thumbnails.set(item.id, event.target.result);
        };
        reader.readAsDataURL(value);
      });
    }
  }

  getThumbnailFile(item: TemplateList) {
    return this.thumbnails.get(item.id);
  }
}
