import {Component, OnInit} from '@angular/core';
import {InternalsService} from "../../../client/api/internals.service";
import {InternalsResponse} from "../../../client/model/internalsResponse";

@Component({
  selector: 'app-logs-preview',
  templateUrl: './logs-preview.component.html',
  styleUrls: ['./logs-preview.component.css']
})
export class LogsPreviewComponent implements OnInit {
  logs: InternalsResponse = {logs:"Loading.."};
  initDone: boolean = false;

  constructor(private internalsService: InternalsService) {
  }

  ngOnInit() {
    this.internalsService.getLogs().subscribe(value => {
        this.logs = value;
        this.initDone = true;
      });
  }
}
