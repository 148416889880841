import {Component, OnInit, ViewChild} from '@angular/core';
import {Copywriter} from '../../../client/model/copywriter';
import {CopywriterEditComponent} from '../copywriter-edit/copywriter-edit.component';
import {CopywriterService} from '../../../client/api/copywriter.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {OrderEditComponent} from '../../orders/order-edit/order-edit.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {OrderListDialogComponent} from '../order-list-dialog/order-list-dialog.component';

@Component({
  selector: 'app-copywriter-list',
  templateUrl: './copywriter-list.component.html',
  styleUrls: ['./copywriter-list.component.css']
})
export class CopywriterListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['displayName', 'contact', 'note', 'addOrder', 'rates', 'activeOrdersTotal', 'actions'];
  errorMessage: String;
  copywriters: Copywriter[];
  dataSource: MatTableDataSource<Copywriter>;

  constructor(private copywriterService: CopywriterService,
              private route: ActivatedRoute, private dialog: MatDialog, private dialog2: MatDialog) {
  }

  ngOnInit() {
    this.getCopywriters();
    this.dataSource.sort = this.sort;
  }

  getCopywriters() {
    this.copywriterService.getCopywriters()
      .subscribe(response => {
          this.copywriters = response;
          this.dataSource = new MatTableDataSource<Copywriter>(this.copywriters);
        },
        error =>
          this.errorMessage = <any>error);
  }

  onNewCopywriterClick() {
    let copywriter = {
      categories: []
    };

    this.openEditCopywriterDialog(copywriter);
  }

  openEditCopywriterDialog(copywriter: Copywriter) {
    const dialogConfig = {
      width: '50%',
      panelClass: 'create-copywriter-dialog',
      data: {
        copywriter: copywriter
      }
    };

    const dialogRef = this.dialog.open(
      CopywriterEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getCopywriters();
    });
  }

  onAddOrderClick(copywriterId) {
    let orderRequest = {};

    const dialogConfig = {
      width: '90%',
      disableClose: true,
      data: {
        orderRequest: orderRequest,
        defaultCopywriterId: copywriterId
      }
    };

    const dialogRef = this.dialog2.open(OrderEditComponent, dialogConfig);
  }

  onDeleteClick(copywriter: Copywriter) {

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.copywriterService.deleteCopywriter(copywriter.id).subscribe(response => {
          this.getCopywriters();
        }, error => {
          this.errorMessage = <any>error;
        });
      }
    });
  }

  openOrderListDialog(element: any) {
    const dialogConfig = {
      width: '80%',
      height: '100%',
      panelClass: 'create-copywriter-dialog',
      data: {
        copywriter: element
      }
    };

    const dialogRef = this.dialog.open(
      OrderListDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getCopywriters();
    });
  }
}
