import {Component, OnInit} from '@angular/core';
import {DomainService} from '../../../../../client/api/domain.service';
import {ActivatedRoute} from '@angular/router';
import {DomainBanners} from '../../../../../client/model/domainBanners';
import {DomainBanner} from '../../../../../client/model/domainBanner';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-edit-banners',
  templateUrl: './edit-banners.component.html',
  styleUrls: ['./edit-banners.component.css']
})
export class EditBannersComponent implements OnInit {
  private id: string;
  private banners: DomainBanners;
  public dataSource: MatTableDataSource<DomainBanner>;
  displayedColumns: string[] = ['bannerName', 'campaignName', 'assignmentDate', 'edit'];


  constructor(private domainService: DomainService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.domainService.getDomainBanners(this.id).subscribe(value => {
      this.banners = value;
      this.dataSource = new MatTableDataSource(this.banners);
    });
  }

}
