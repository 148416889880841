<h2 mat-dialog-title>Edycja uprawnień użytkownika {{user.username}}</h2>
<mat-dialog-content>
  <form [formGroup]="rolesForm">
    <section class="example-section">
      <ul>
        <li>
          <mat-checkbox id="administrator" class="example-margin" formControlName="administrator">
            Administrator
          </mat-checkbox>
        </li>
        <li>
          <mat-checkbox id="copywriter" class="example-margin" formControlName="copywriter">
            Copywriter
          </mat-checkbox>
        </li>
        <li>
          <mat-checkbox id="employee" class="example-margin" formControlName="employee">
            Pracownik
          </mat-checkbox>
        </li>
        <li>
          <mat-checkbox id="publisher_admin" class="example-margin" formControlName="publisher_admin">
            Admin wydawców zewnętrznych
          </mat-checkbox>
        </li>
        <li>
          <mat-checkbox id="copywriter_admin" class="example-margin" formControlName="copywriter_admin">
            Admin copywriterów
          </mat-checkbox>
      </ul>
    </section>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button color="primary" (click)="save()">Zapisz</button>
  <button mat-button (click)="dialogRef.close(false)">Anuluj</button>
</mat-dialog-actions>
