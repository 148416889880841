import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BannerContentDefinition} from '../../../client/model/bannerContentDefinition';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FileDto} from '../../../client/model/fileDto';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-banner-content',
  templateUrl: './edit-banner-content.component.html',
  styleUrls: ['./edit-banner-content.component.css']
})
export class EditBannerContentComponent implements OnInit {
  public bannerContent: BannerContentDefinition;
  public relativePaths: Array<[string, string]> = [['img', 'Obrazki']];

  form: UntypedFormGroup;

  constructor(private dialog: MatDialog,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: BannerContentDefinition) {
  }

  ngOnInit() {
    if (this.data != null) {
      this.bannerContent = this.data;
    } else {
      this.bannerContent = {
        url: '',
        target: 'NEW_WINDOW',
        alt: '',
        title: '',
        dofollow: true
      };
    }
    this.initForm();
  }

  save() {
    if (this.form.valid) {
      this.dialog.closeAll();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.bannerContent.id),
      url: new UntypedFormControl(this.bannerContent.url, Validators.required),
      target: new UntypedFormControl(this.bannerContent.target, Validators.required),
      alt: new UntypedFormControl(this.bannerContent.alt, Validators.required),
      title: new UntypedFormControl(this.bannerContent.title, Validators.required),
      dofollow: new UntypedFormControl(this.bannerContent.dofollow),
      endDate: new UntypedFormControl(this.bannerContent.endDate)
    });
  }

  bannerUpdate($event: FileDto) {
    this.bannerContent.image = $event;
  }
}
