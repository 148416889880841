import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginComponent} from './features/login/login.component';
import {AuthService} from './features/services/auth.service';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './features/commons/TokenInterceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginService} from './features/login/login.service';
import {CreateDomainModalComponent} from './features/domains/create-domain-modal/create-domain-modal.component';
import {DomainsComponent} from './features/domains/domains.component';
import {EditDomainComponent} from './features/domains/edit-domain/edit-domain.component';
import {ServerListComponent} from './features/servers/server-list/server-list.component';
import {CreateServerModalComponent} from './features/servers/create-server-modal/create-server-modal.component';
import {ErrorInterceptor} from './features/commons/ErrorInterceptor';
import {EditTemplateComponent} from './features/domains/edit-domain/template/edit-template/edit-template.component';
import {EditLinksComponent} from './features/domains/edit-domain/links/edit-links/edit-links.component';
import {EditBannersComponent} from './features/domains/edit-domain/banners/edit-banners/edit-banners.component';
import {EditcontactFormComponent} from './features/domains/edit-domain/contact/editcontact-form/editcontact-form.component';
import {SelectTemplateComponent} from './features/domains/edit-domain/template/select-template/select-template.component';
import {UploadTemplateModalComponent} from './features/domains/edit-domain/template/upload-template-modal/upload-template-modal.component';
import {MainSettingsComponent} from './features/domains/edit-domain/template/main-settings/main-settings.component';
import {AdvancedSettingsComponent} from './features/domains/edit-domain/template/advanced-settings/advanced-settings.component';
import {
  CustomStylesAndScriptsComponent
} from './features/domains/edit-domain/template/custom-styles-and-scripts/custom-styles-and-scripts.component';
import {DomainService} from './client/api/domain.service';
import {BASE_PATH} from './client/variables';
import {Configuration} from './client/configuration';
import {environment} from '../environments/environment';
import {HeaderInterceptor} from './features/commons/HeaderInterceptor';
import {ServersService} from './client/api/servers.service';
import {NewCategoryComponent} from './features/categories/new-category/new-category.component';
import {CategoriesListComponent} from './features/categories/categories-list/categories-list.component';
import {DomainCategoryService} from './client/api/domaincategory.service';
import {
  ArticleCategoriesListComponent
} from './features/domains/edit-domain/template/article-categories-list/article-categories-list.component';
import {ArticlesListComponent} from './features/domains/edit-domain/articles/articles-list/articles-list.component';
import {ArticleEditComponent} from './features/articles/article-edit/article-edit.component';
import {CopywritersComponent} from './features/copywriters/copywriters.component';
import {CopywriterListComponent} from './features/copywriters/copywriter-list/copywriter-list.component';
import {CopywriterPanelComponent} from './features/copywriters/copywriter-panel/copywriter-panel.component';
import {CopywriterOrderListComponent} from './features/copywriters/copywriter-order-list/copywriter-order-list.component';
import {CopywriterEditComponent} from './features/copywriters/copywriter-edit/copywriter-edit.component';
import {
  ArticleCategoryModalComponent
} from './features/domains/edit-domain/template/article-category-modal/article-category-modal.component';
import {MenuComponent} from './features/domains/edit-domain/template/menu/menu.component';
import {EditTemplateService} from './features/domains/edit-domain/template/edit-template.service';
import {ConfirmPopupComponent} from './features/commons/confirm-popup/confirm-popup.component';
import {OrderEditComponent} from './features/orders/order-edit/order-edit.component';
import {TemplateListComponent} from './features/templates/template-list/template-list.component';
import {EditTemplateByIdComponent} from './features/templates/edit-template-by-id/edit-template-by-id.component';
import {FileUploadComponent} from './features/commons/file-upload/file-upload.component';
import {ProjectListComponent} from './features/projects/project-list/project-list.component';
import {AdminOrderListComponent} from './features/copywriters/admin-order-list/admin-order-list.component';
import {OrdersAdminListComponent} from './features/orders/orders-admin-list/orders-admin-list.component';
import {OrdersCopywriterListComponent} from './features/orders/orders-copywriter-list/orders-copywriter-list.component';
import {ShowAuthedDirective} from './directives/show-authed.directive';
import {PaymentsListComponent} from './features/copywriters/payments-list/payments-list.component';
import {CreateProjectComponent} from './features/projects/create-project/create-project.component';
import {DomainSearchComponent} from './features/orders/domain-search/domain-search.component';
import {EditProjectComponent} from './features/projects/edit-project/edit-project.component';
import {EditLinkCampaignComponent} from './features/campaign/edit-link-campaign/edit-link-campaign.component';
import {AssigLinkToDomainComponent} from './features/campaign/assig-link-to-domain/assig-link-to-domain.component';
import {AllocationSetupComponent} from './features/campaign/allocation-setup/allocation-setup.component';
import {ArticleNewComponent} from './features/articles/article-new/article-new.component';
import {NewOrderComponent} from './features/articles/article-edit/new-order/new-order.component';
import {EditBannerCampaignComponent} from './features/campaign/edit-banner-campaign/edit-banner-campaign.component';
import {EditBannerContentComponent} from './features/campaign/edit-banner-content/edit-banner-content.component';
import {LogsPreviewComponent} from './features/internals/logs-preview/logs-preview.component';
import {InternalsService} from './client/api/internals.service';
import {BannerCampaignStateComponent} from './features/campaign/banner-campaign-state/banner-campaign-state.component';
import {ArticlePreviewComponent} from './features/articles/article-preview/article-preview.component';
import {ArticleSearchComponent} from './features/campaign/article-search/article-search.component';
import {ArticleBannerSearchComponent} from './features/campaign/article-banner-search/article-banner-search.component';
import {CheckPasswordDirective} from './directives/check-password.directive';
import {UserListComponent} from './features/user/user-list/user-list.component';
import {AddEditUserModalComponent} from './features/user/add-edit-user-modal/add-edit-user-modal.component';
import {EditNapBannerContentComponent} from './features/campaign/edit-nap-banner-content/edit-nap-banner-content.component';
import {PixabaySearchComponent} from './features/commons/pixabay-search/pixabay-search.component';
import {OrderDetailsComponent} from './features/orders/order-details/order-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {LinkAssigmentStatusComponent} from './features/campaign/link-assigment-status/link-assigment-status.component';
import {ImportOrdersComponent} from './features/orders/import-orders/import-orders.component';
import {VersionCheckComponent} from './features/internals/version-check/version-check.component';
import {ErrorRegistryComponent} from './features/internals/error-registry/error-registry.component';
import {PrivacyPolicyListComponent} from './features/internals/privacy-policy-list/privacy-policy-list.component';
import {EditPolicyComponent} from './features/internals/edit-policy/edit-policy.component';
import {ContactPageListComponent} from './features/internals/contact-page-list/contact-page-list.component';
import {EditContactPageComponent} from './features/internals/edit-contact-page/edit-contact-page.component';
import {ChangeOrderCopywriterComponent} from './features/copywriters/change-order-copywriter/change-order-copywriter.component';
import {AdCategoryListComponent} from './features/ads/ad-category-list/ad-category-list.component';
import {AdCategoryModalComponent} from './features/ads/ad-category-modal/ad-category-modal.component';
import {IconPickerModule} from 'ngx-icon-picker';
import {CommonModule} from '@angular/common';
import {EnableFeatureDirective} from './directives/enable-feature.directive';
import {ProgressSpinnerDirective} from './directives/progress-spinner.directive';
import {AdsDomainSetupComponent} from './features/domains/edit-domain/template/ads-domain-setup/ads-domain-setup.component';
import {DomainAdsListComponent} from './features/ads/domain-ads-list/domain-ads-list.component';
import {AdEditModalComponent} from './features/ads/ad-edit-modal/ad-edit-modal.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {EditorModule} from '@tinymce/tinymce-angular';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatBadgeModule} from '@angular/material/badge';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatTreeModule} from '@angular/material/tree';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslationComponent} from './features/i18n/translation/translation.component';
import {TranslationModalComponent} from './features/i18n/translation-modal/translation-modal.component';
import {MainPageWidgetsComponent} from './features/domains/edit-domain/template/main-page-widgets/main-page-widgets.component';
import {AddCommentComponent} from './features/articles/add-comment/add-comment.component';
import {ChangeOrderKeeperComponent} from './features/copywriters/change-order-keeper/change-order-keeper.component';
import {AccountPanelComponent} from './features/account/account-panel/account-panel.component';
import {NotificationsConfigComponent} from './features/account/notifications-config/notifications-config.component';
import {EditAccountComponent} from './features/account/edit-account/edit-account.component';
import {PublicationListComponent} from './features/publications/publication-list/publication-list.component';
import {AddEditPublicationComponent} from './features/publications/add-edit-publication/add-edit-publication.component';
import {PublisherListComponent} from './features/publications/publisher-list/publisher-list.component';
import {AddEditPublisherComponent} from './features/publications/add-edit-publisher/add-edit-publisher.component';
import {PublishersPanelComponent} from './features/publications/publishers-panel/publishers-panel.component';
import {PublicationImportComponent} from './features/publications/publication-import/publication-import.component';
import {TagsListComponent} from './features/internals/tags-list/tags-list.component';
import {TraceIdInterceptor} from './features/commons/TraceIdInterceptor';
import {RenderLogComponent} from './features/internals/render-log/render-log.component';
import {OrderUpdateComponent} from './features/orders/order-update/order-update.component';
import {OrderListDialogComponent} from './features/copywriters/order-list-dialog/order-list-dialog.component';
import {ArticleRevisionsComponent} from './features/articles/article-revisions/article-revisions.component';
import {ArticleRevisionPreviewComponent} from './features/articles/article-revision-preview/article-revision-preview.component';
import {AuthorsListComponent} from './features/authors/authors-list/authors-list.component';
import {OpenAIOrdersComponent} from './features/internals/openai/open-aiorders/open-aiorders.component';
import { OpenAINewOrderComponent } from './features/internals/openai/open-ainew-order/open-ainew-order.component';
import { OpenAIPanelComponent } from './features/internals/openai/open-aipanel/open-aipanel.component';
import { PublicationUploadComponent } from './features/publications/publication-upload/publication-upload.component';
import { UserRolesComponent } from './features/user/user-roles/user-roles.component';
import { EditDomainArticleModalComponent } from './features/domains/edit-domain/articles/articles-list/edit-domain-article-modal/edit-domain-article-modal.component';
import { OpenAiPreviewComponent } from './features/internals/openai/open-ai-preview/open-ai-preview.component';
import { OpenAieditComponent } from './features/internals/openai/open-aiedit/open-aiedit.component';
import { DomainHealthComponent } from './features/domains/domain-health/domain-health.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DomainsComponent,
    CreateDomainModalComponent,
    EditDomainComponent,
    ServerListComponent,
    CreateServerModalComponent,
    EditTemplateComponent,
    EditLinksComponent,
    EditBannersComponent,
    EditcontactFormComponent,
    SelectTemplateComponent,
    UploadTemplateModalComponent,
    MainSettingsComponent,
    AdvancedSettingsComponent,
    CustomStylesAndScriptsComponent,
    NewCategoryComponent,
    CategoriesListComponent,
    ArticleCategoriesListComponent,
    ArticleCategoryModalComponent,
    ArticlesListComponent,
    ArticleEditComponent,
    CopywritersComponent,
    CopywriterListComponent,
    CopywriterPanelComponent,
    CopywriterOrderListComponent,
    CopywriterEditComponent,
    MenuComponent,
    ConfirmPopupComponent,
    OrderEditComponent,
    TemplateListComponent,
    EditTemplateByIdComponent,
    FileUploadComponent,
    ProjectListComponent,
    AdminOrderListComponent,
    OrdersAdminListComponent,
    OrdersCopywriterListComponent,
    ShowAuthedDirective,
    CheckPasswordDirective,
    CreateProjectComponent,
    DomainSearchComponent,
    PaymentsListComponent,
    EditProjectComponent,
    EditLinkCampaignComponent,
    AssigLinkToDomainComponent,
    AllocationSetupComponent,
    ArticleNewComponent,
    NewOrderComponent,
    EditBannerCampaignComponent,
    EditBannerContentComponent,
    LogsPreviewComponent,
    BannerCampaignStateComponent,
    ArticlePreviewComponent,
    ArticleSearchComponent,
    ArticleBannerSearchComponent,
    UserListComponent,
    AddEditUserModalComponent,
    EditNapBannerContentComponent,
    PixabaySearchComponent,
    OrderDetailsComponent,
    LinkAssigmentStatusComponent,
    ImportOrdersComponent,
    VersionCheckComponent,
    ErrorRegistryComponent,
    PrivacyPolicyListComponent,
    EditPolicyComponent,
    ContactPageListComponent,
    EditContactPageComponent,
    ChangeOrderCopywriterComponent,
    ChangeOrderKeeperComponent,
    AdCategoryListComponent,
    AdCategoryModalComponent,
    EnableFeatureDirective,
    ProgressSpinnerDirective,
    AdsDomainSetupComponent,
    DomainAdsListComponent,
    AdEditModalComponent,
    TranslationComponent,
    TranslationModalComponent,
    MainPageWidgetsComponent,
    AddCommentComponent,
    AccountPanelComponent,
    NotificationsConfigComponent,
    EditAccountComponent,
    PublicationListComponent,
    AddEditPublicationComponent,
    PublisherListComponent,
    AddEditPublisherComponent,
    PublishersPanelComponent,
    PublicationImportComponent,
    TagsListComponent,
    RenderLogComponent,
    OrderUpdateComponent,
    OrderListDialogComponent,
    ArticleRevisionsComponent,
    ArticleRevisionPreviewComponent,
    AuthorsListComponent,
    OpenAIOrdersComponent,
    OpenAINewOrderComponent,
    OpenAIPanelComponent,
    PublicationUploadComponent,
    UserRolesComponent,
    EditDomainArticleModalComponent,
    OpenAiPreviewComponent,
    OpenAieditComponent,
    DomainHealthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IconPickerModule,
    FontAwesomeModule,
    HttpClientModule,
    MatSidenavModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatButtonModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatSnackBarModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTreeModule,
    EditorModule,
    NgxPaginationModule,
    RouterModule.forRoot([
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'domains',
        component: DomainsComponent
      },
      {
        path: 'domains/health',
        component: DomainHealthComponent
      },
      {
        path: 'domain/:id',
        component: EditDomainComponent
      },
      {
        path: 'servers',
        component: ServerListComponent
      },
      {
        path: 'categories',
        component: CategoriesListComponent
      },
      {
        path: 'copywriters',
        component: CopywritersComponent
      },
      {
        path: 'templates',
        component: TemplateListComponent
      },
      {
        path: 'templates/edit/:id',
        component: EditTemplateByIdComponent
      },
      {
        path: 'templates/add',
        component: EditTemplateByIdComponent
      },
      {
        path: 'projects',
        component: ProjectListComponent
      },
      {
        path: 'project/:id',
        component: EditProjectComponent
      },
      {
        path: 'campaign/links/:id',
        component: EditLinkCampaignComponent
      },
      {
        path: 'campaign/banner/:id',
        component: EditBannerCampaignComponent
      },
      {
        path: 'internal/logs',
        component: LogsPreviewComponent
      },
      {
        path: 'internal/tags',
        component: TagsListComponent
      },
      {
        path: 'internal/version',
        component: VersionCheckComponent
      },
      {
        path: 'internal/errors',
        component: ErrorRegistryComponent
      },
      {
        path: 'internal/privacy',
        component: PrivacyPolicyListComponent
      },
      {
        path: 'internal/contact-page',
        component: ContactPageListComponent
      },
      {
        path: 'internal/openai',
        component: OpenAIPanelComponent
      },
      {
        path: 'users',
        component: UserListComponent
      },
      {
        path: 'adcategories',
        component: AdCategoryListComponent
      },
      {
        path: 'translations',
        component: TranslationComponent
      },
      {
        path: 'account',
        component: AccountPanelComponent
      },
      {
        path: 'publishers',
        component: PublishersPanelComponent,
        children: [
          {
            path: 'publishers',
            component: PublisherListComponent,
          },
          {
            path: 'publications',
            component: PublicationListComponent,
          }
          ]
      }
    ], {})
  ],
  exports: [
    ShowAuthedDirective,
  ],
  providers: [AuthService,
    LoginService,
    ServersService,
    DomainService,
    DomainCategoryService,
    EditTemplateService,
    PublicationImportComponent,
    InternalsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiEndpoint
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pl-PL'
    }],
  bootstrap: [AppComponent]
})
export class AppModule {

  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }
}
