<mat-card>
  <h2 mat-card-title *ngIf="mode=='edit'">Edycja szablonu {{template.templateName}}</h2>
  <h2 mat-card-title *ngIf="mode!='edit'">Dodawanie nowego szablonu </h2>
  <mat-card-content>
    <form class="full-width-container">

      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Nazwa szablonu" name="templatename" [(ngModel)]="template.templateName">
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-checkbox matInput [(ngModel)]="template.simpleHomePage" name="simpleHomePage">Uproszczony layout strony głównej
        </mat-checkbox>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Ilość elementow w sliderze" name="sliderItemsNumber" type="number" [(ngModel)]="template.sliderItemsNumber">
        </mat-form-field>
      </div>


      <!--Main layout-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Szablon strony
          </mat-panel-title>
          <mat-panel-description>
            Musi zawierać placeholdery: %TITLE%, %SUFFIX%, %META_DESCRIPTION%, %HEAD_CODE%, %GTM_CODE%, %OWN_CSS%
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="full-width-container">
          <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout strony" name="templatebody" [(ngModel)]="template.templateBody"
                      rows="20" value="{{template.templateBody}}"></textarea>
          </mat-form-field>
        </div>


      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Sidebar
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar widget
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara" name="sidebarWidgetLayout"
                      [(ngModel)]="template.sidebarWidgetLayout"
                      rows="10" value="{{template.sidebarWidgetLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar item
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara" name="sidebarWidgetLayoutItem"
                      [(ngModel)]="template.sidebarWidgetLayoutItem"
                      rows="10" value="{{template.sidebarWidgetLayoutItem}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar widget 2
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara 2" name="sidebarWidgetLayout2"
                      [(ngModel)]="template.sidebarWidgetLayout2"
                      rows="10" value="{{template.sidebarWidgetLayout2}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar item 2
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara" name="sidebarWidgetLayoutItem2"
                      [(ngModel)]="template.sidebarWidgetLayoutItem2"
                      rows="10" value="{{template.sidebarWidgetLayoutItem2}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar widget 3
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara" name="sidebarWidgetLayout3"
                      [(ngModel)]="template.sidebarWidgetLayout3"
                      rows="10" value="{{template.sidebarWidgetLayout3}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sidebar item 3
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu sidebara" name="sidebarWidgetLayoutItem3"
                      [(ngModel)]="template.sidebarWidgetLayoutItem3"
                      rows="10" value="{{template.sidebarWidgetLayoutItem3}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

      </mat-expansion-panel>
      <!--Home Page-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Strona główna
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!--home page layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon strony głównej
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout strony" name="homePageLayout" [(ngModel)]="template.homePageLayout"
                      rows="20" value="{{template.homePageLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!--Slider layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon slidera
            </mat-panel-title>
            <mat-panel-description>
              %SLIDER_ITEM% - zawiera placeholdery: %IMAGE1%, %LINK%, %CATEGORY%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout slidera" name="homePageSliderLayout"
                      [(ngModel)]="template.homePageSliderLayout"
                      rows="20" value="{{template.homePageSliderLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <!--trending layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon paska "Popularne artykuły"
            </mat-panel-title>
            <mat-panel-description>
              %TRENDING_ITEMS% - zawiera placeholdery: %LINK%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout" name="trendingLayout"
                      [(ngModel)]="template.trendingLayout"
                      rows="15" value="{{template.trendingLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>

      <!--Category Page-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Strona kategorii
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!--category layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon kategorii
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout kategorii" name="categoryPageLayout"
                      [(ngModel)]="template.categoryPageLayout"
                      rows="20" value="{{template.categoryPageLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!--horizontalItemLayout layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon kolumnowej miniatury artykułu
            </mat-panel-title>
            <mat-panel-description>
              %SLIDER_ITEM% - zawiera placeholdery: %IMAGE1%, %LINK%, %CATEGORY%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="horizontalItemLayout"
                      [(ngModel)]="template.horizontalItemLayout"
                      rows="20" value="{{template.horizontalItemLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <!--verticalItemLayout layout-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon wierszowej miniatur atykułu
            </mat-panel-title>
            <mat-panel-description>
              %TRENDING_ITEMS% - zawiera placeholdery: %LINK%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="verticalItemLayout"
                      [(ngModel)]="template.verticalItemLayout"
                      rows="15" value="{{template.verticalItemLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon customowego itema
            </mat-panel-title>
            <mat-panel-description>
              %TRENDING_ITEMS% - zawiera placeholdery: %LINK%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="customCategoryLayout"
                      [(ngModel)]="template.customCategoryLayout"
                      rows="15" value="{{template.customCategoryLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon slidera
            </mat-panel-title>
            <mat-panel-description>
              %TRENDING_ITEMS% - zawiera placeholdery: %LINK%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="sliderCategoryLayout"
                      [(ngModel)]="template.sliderCategoryLayout"
                      rows="15" value="{{template.sliderCategoryLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon wrapera slidera
            </mat-panel-title>
            <mat-panel-description>
              %TRENDING_ITEMS% - zawiera placeholdery: %LINK%, %TITLE%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="sliderWrapperLayout"
                      [(ngModel)]="template.sliderWrapperLayout"
                      rows="15" value="{{template.sliderWrapperLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>

      <!--Menu-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Menu
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!--main menu-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Layout menu głównego
            </mat-panel-title>
            <mat-panel-description>
              %MENU_ITEM% - dostępne placeholdery: %LINK_URL%", %LINK_HOVER%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout menu" name="menulayout" [(ngModel)]="template.menuLayout"
                      rows="10" value="{{template.menuLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <!-- side nav-->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Layout menu podrzędnego (SideNav)
            </mat-panel-title>
            <mat-panel-description>
              %SIDE_NAV_ITEM% - dostępne placeholdery: %LINK_URL%", %LINK_HOVER%
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Sidenav item" name="sidemenulayout" [(ngModel)]="template.sideNavLayout"
                      rows="10" value="{{template.sideNavLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

      </mat-expansion-panel>

      <!--Article-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Strona z artykułem
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="full-width-container">
          <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="articlelayout"
                      [(ngModel)]="template.articleLayout"
                      rows="10" value="{{template.articleLayout}}"></textarea>
          </mat-form-field>
        </div>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon nagłówka strony z artukułem
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout nagłówka strony" name="articlePageHeader"
                      [(ngModel)]="template.articlePageHeader"
                      rows="10" value="{{template.articlePageHeader}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon przycisku Kolejny artykuł
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="nextArticle"
                      [(ngModel)]="template.nextArticle"
                      rows="10" value="{{template.nextArticle}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon przycisku Poprzedni artykuł
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout artykułu" name="prevArticle"
                      [(ngModel)]="template.prevArticle"
                      rows="10" value="{{template.prevArticle}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Podobne artykuły
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout podobne artykuły" name="relatedArticlesWidgetLayout"
                      [(ngModel)]="template.relatedArticlesWidgetLayout"
                      rows="10" value="{{template.relatedArticlesWidgetLayout}}"></textarea>
            </mat-form-field>
          </div>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Podobne artykuły - szablon elementu
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="full-width-container">
              <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout podobne artykuły" name="relatedArticlesWidgetItem"
                      [(ngModel)]="template.relatedArticlesWidgetItem"
                      rows="10" value="{{template.relatedArticlesWidgetItem}}"></textarea>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-expansion-panel>
      </mat-expansion-panel>

      <!--Short article-->
      <!--      <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Layout skróconego widoku artykułu
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="full-width-container">
                <mat-form-field class="example-full-width">
                  <textarea matInput placeholder="Layout skróconego widoku artykułu" name="shortarticlelayout"
                            [(ngModel)]="template.shortArticleLayout"
                            rows="10" value="{{template.shortArticleLayout}}"></textarea>
                </mat-form-field>
              </div>
            </mat-expansion-panel>-->

      <!--Stopka-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Footer
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon stopki
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout stopki" name="footerLayout"
                      [(ngModel)]="template.footerLayout"
                      rows="10" value="{{template.footerLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon widgetu stopki
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu stopki" name="footerWidgetLayout"
                      [(ngModel)]="template.footerWidgetLayout"
                      rows="10" value="{{template.footerWidgetLayout}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Footer widget item
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu stopki" name="footerWidgetLayoutItem"
                      [(ngModel)]="template.footerWidgetLayoutItem"
                      rows="10" value="{{template.footerWidgetLayoutItem}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

      </mat-expansion-panel>
      <!--Szablony ogłoszeń i katalogów firm-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Szablony ogłoszeń i katalogów firm
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon widgetu kategorii
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu kategorii" name="adCategory"
                      [(ngModel)]="template.adCategory"
                      rows="10" value="{{template.adCategory}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon widgetu listy kategorii
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout widgetu listy kategorii" name="adCategoryList"
                      [(ngModel)]="template.adCategoryList"
                      rows="10" value="{{template.adCategoryList}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon listy ogłoszeń/firm
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout listy ogłoszeń/firm" name="adList"
                      [(ngModel)]="template.adList"
                      rows="10" value="{{template.adList}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon elementu na liście ogłoszeń
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout listy ogłoszeń/firm" name="adListItem"
                      [(ngModel)]="template.adListItem"
                      rows="10" value="{{template.adListItem}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>


        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Szablon strony z ogłoszeniem/wizytówki firmy
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="full-width-container">
            <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Layout strony z ogłoszeniem" name="adDetails"
                      [(ngModel)]="template.adDetails"
                      rows="10" value="{{template.adDetails}}"></textarea>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

      </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Miniaturka szablonu:
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-file-upload [canDelete]="true"
                         [fileInputLabel]=""
                         [fileListLabel]=thumbnailLabel
                         [limit]="1"
                         [uploadedFile]="template.templateThumbnail"
                         [preview]=true
                         (change)="thumbnailUpdate($event)"></app-file-upload>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Pliki szablonu:
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-file-upload [canDelete]="true"
                         [fileInputLabel]=""
                         [fileListLabel]=""
                         [limit]="100"
                         [uploadedFiles]="template.templateFiles"
                         [relativePaths]="relativePaths"></app-file-upload>
      </mat-expansion-panel>


    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button [routerLink]="['/templates']">Anuluj</button>
    <button mat-button color="primary" mat-raised-button cdkFocusInitial (click)="save()">Zapisz</button>
    <button mat-button mat-raised-button cdkFocusInitial (click)="saveAndBackToList()">Zapisz i wróć do listy</button>
  </mat-card-actions>
</mat-card>
