<div class="copywriter-list-container">
  <p>
    <button mat-button (click)="onNewCopywriterClick()" matTooltip="Dodaj copywritera">
      <mat-icon>add_circle</mat-icon>
    </button>
  </p>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Copywriter</th>
      <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Długość</th>
      <td mat-cell *matCellDef="let element">
        {{element.email}}
        <br>
        {{element.phone}}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Notatka</th>
      <td mat-cell *matCellDef="let element">{{element.note}}</td>
    </ng-container>

    <ng-container matColumnDef="activeOrdersTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktywnych zamówień</th>
      <td mat-cell *matCellDef="let element" o>Poprzedni miesiąc {{element.orderStats.pastMonth}}/{{element.monthlyLimit}}<br/>
        Obecny miesiąc {{element.orderStats.currentMonth}}/{{element.monthlyLimit}}<br/>
        Przyszły miesiąc {{element.orderStats.futureMonth}}/{{element.monthlyLimit}}</td>
    </ng-container>

    <ng-container matColumnDef="addOrder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dodaj zlecenie</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="onAddOrderClick(element.id)">
          <mat-icon>add_circle</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="rates">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 150px">Stawka za 1k znaków</th>
      <td mat-cell *matCellDef="let element">
        Parafrazy: {{element.paraphraseRate | currency: ' ' }}zł<br>
        Od podstaw: {{element.articleRate | currency: ' '}}zł<br>
        Opis: {{element.descriptionRate | currency: ' '}}zł
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button *appShowAuthed="['COPYWRITER_ADMIN', 'ADMINISTRATOR']" mat-icon-button (click)="openEditCopywriterDialog(element)" matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button (click)="openOrderListDialog(element)" matTooltip="Sprawdź liste zleceń">
          <mat-icon>search</mat-icon>
        </button>

        <button *appShowAuthed="['COPYWRITER_ADMIN', 'ADMINISTRATOR']" mat-icon-button (click)="onDeleteClick(element)" matTooltip="Usuń">
          <mat-icon>delete</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20,50]" showFirstLastButtons></mat-paginator>
</div>
