/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Domain } from '../model/domain';
import { DomainBanners } from '../model/domainBanners';
import { DomainLinks } from '../model/domainLinks';
import { DomainOverview } from '../model/domainOverview';
import { DomainRenderLog } from '../model/domainRenderLog';
import { Domains } from '../model/domains';
import { DomainsDTO } from '../model/domainsDTO';
import { DomainsStatList } from '../model/domainsStatList';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class DomainService {

    protected basePath = 'http://localhost:8090';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add domains to system
     * 
     * @param domainOverview Domain objects that needs to be added to the store
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDomains(domainOverview: Array<DomainOverview>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addDomains(domainOverview: Array<DomainOverview>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addDomains(domainOverview: Array<DomainOverview>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addDomains(domainOverview: Array<DomainOverview>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainOverview === null || domainOverview === undefined) {
            throw new Error('Required parameter domainOverview was null or undefined when calling addDomains.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/domains`,
            domainOverview,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDomain(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDomain(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDomain(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDomain(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling deleteDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomain(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<Domain>;
    public getDomain(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Domain>>;
    public getDomain(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Domain>>;
    public getDomain(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling getDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Domain>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainBanners(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<DomainBanners>;
    public getDomainBanners(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomainBanners>>;
    public getDomainBanners(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomainBanners>>;
    public getDomainBanners(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling getDomainBanners.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomainBanners>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/banners`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainLinks(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<DomainLinks>;
    public getDomainLinks(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomainLinks>>;
    public getDomainLinks(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomainLinks>>;
    public getDomainLinks(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling getDomainLinks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomainLinks>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/links`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainLog(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<DomainRenderLog>;
    public getDomainLog(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomainRenderLog>>;
    public getDomainLog(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomainRenderLog>>;
    public getDomainLog(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling getDomainLog.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomainRenderLog>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/renderlog`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get domains
     * 
     * @param campaignId 
     * @param isInCampaign 
     * @param showDedicated 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomains(campaignId?: string, isInCampaign?: boolean, showDedicated?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Domains>;
    public getDomains(campaignId?: string, isInCampaign?: boolean, showDedicated?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Domains>>;
    public getDomains(campaignId?: string, isInCampaign?: boolean, showDedicated?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Domains>>;
    public getDomains(campaignId?: string, isInCampaign?: boolean, showDedicated?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (isInCampaign !== undefined && isInCampaign !== null) {
            queryParameters = queryParameters.set('isInCampaign', <any>isInCampaign);
        }
        if (showDedicated !== undefined && showDedicated !== null) {
            queryParameters = queryParameters.set('showDedicated', <any>showDedicated);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Domains>(`${this.configuration.basePath}/domains`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get domains stats
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainsStats(observe?: 'body', reportProgress?: boolean): Observable<DomainsStatList>;
    public getDomainsStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomainsStatList>>;
    public getDomainsStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomainsStatList>>;
    public getDomainsStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomainsStatList>(`${this.configuration.basePath}/domains/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get domains summary
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainsSummary(observe?: 'body', reportProgress?: boolean): Observable<DomainsDTO>;
    public getDomainsSummary(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DomainsDTO>>;
    public getDomainsSummary(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DomainsDTO>>;
    public getDomainsSummary(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DomainsDTO>(`${this.configuration.basePath}/domainsSummary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reinstallDomain(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reinstallDomain(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reinstallDomain(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reinstallDomain(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling reinstallDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/renew`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rerenderDomain(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rerenderDomain(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rerenderDomain(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rerenderDomain(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling rerenderDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.patch<any>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/render`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Render all domains in system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rerenderDomains(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rerenderDomains(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rerenderDomains(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rerenderDomains(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.patch<any>(`${this.configuration.basePath}/domains/render`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSSLForDomain(domainId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setSSLForDomain(domainId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setSSLForDomain(domainId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setSSLForDomain(domainId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling setSSLForDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}/ssl`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param domainId 
     * @param domain 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDomain(domainId: string, domain: Domain, observe?: 'body', reportProgress?: boolean): Observable<Domain>;
    public updateDomain(domainId: string, domain: Domain, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Domain>>;
    public updateDomain(domainId: string, domain: Domain, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Domain>>;
    public updateDomain(domainId: string, domain: Domain, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling updateDomain.');
        }
        if (domain === null || domain === undefined) {
            throw new Error('Required parameter domain was null or undefined when calling updateDomain.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Domain>(`${this.configuration.basePath}/domains/${encodeURIComponent(String(domainId))}`,
            domain,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
