import { Injectable } from '@angular/core';
import {MenuItem} from "../../../../client/model/menuItem";

@Injectable({
  providedIn: 'root'
})
export class EditTemplateService {

  menuItems: MenuItem[];
  menuModified: boolean;

  constructor() { }
}
