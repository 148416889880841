<h2 mat-dialog-title>Konfiguruj zawartość bannera</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" (ngSubmit)="save()">

    <mat-card>
      <app-file-upload [canDelete]="true"
                       [fileInputLabel]=""
                       [fileListLabel]=""
                       [limit]="1"
                       [uploadedFile]="bannerContent.image"
                       [preview]=true
                       [relativePaths]="relativePaths"
                       [isConvertToWebp]="true"
                       (change)="bannerUpdate($event)"></app-file-upload>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Adres URL" name="url" [(ngModel)]="bannerContent.url" [required]="true" formControlName="url">
          <mat-error>
            To pole jest wymagane
          </mat-error>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <mat-select placeholder="Target:" [(value)]="bannerContent.target" formControlName="target" [required]="true" formControlName="target">
            <mat-option value="NEW_WINDOW">
              Nowe okno
            </mat-option>
            <mat-option value="SAME_WINDOW">
              To samo okno
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Alt=" name="alt" [(ngModel)]="bannerContent.alt" [required]="true" formControlName="alt">
          <mat-error>
            To pole jest wymagane
          </mat-error>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-form-field>
          <input matInput placeholder="Title=" name="title" [(ngModel)]="bannerContent.title" [required]="true" formControlName="title">
          <mat-error>
            To pole jest wymagane
          </mat-error>
        </mat-form-field>
      </div>
      <div class="full-width-container">
        <mat-checkbox [(ngModel)]="bannerContent.dofollow" name="dofollow" formControlName="dofollow">Dofollow?</mat-checkbox>
      </div>
      <div>
        <mat-form-field>
          <input matInput placeholder="Wygasa dnia" type="date" name="enddate" [(ngModel)]="bannerContent.endDate" formControlName="endDate">
        </mat-form-field>
      </div>


    </mat-card>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Anuluj</button>
      <button mat-button primary cdkFocusInitial>Zapisz</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>

