<mat-card-content class="copywriter-panel">
  <h3>Moje dane i statystyki</h3><br>

  <form name="form" (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <h2>{{copywriter.firstName}} {{copywriter.lastName}}</h2>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div width="25">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput type="email" placeholder="E-mail" [(ngModel)]="copywriter.email" required name="email">
            <mat-error>
              Podaj poprawny adres e-mail
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div width="25">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput placeholder="Telefon" [(ngModel)]="copywriter.phone" name="phone">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div width="25">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput type="password" placeholder="Hasło" [(ngModel)]="password" name="passwordA" #passwordA="ngModel" id="passwordA">
          </mat-form-field>
        </div>
      </div>
      <div width="25">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput type="password" placeholder="Powtórz hasło" [(ngModel)]="passwordConfirmation" name="passwordB" #passwordB="ngModel" id="passwordB">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div width="51">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <mat-select placeholder="Preferowane kategorie" [(ngModel)]="selectedCategories"
                        [(value)]="selectedCategories"
                        name="categories" multiple>
              <mat-option *ngFor="let cat of categories" [value]="cat.id">
                {{cat.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Stawki (zł netto za 1k znaków)</span>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <span>Parafrazy: {{copywriter.paraphraseRate | currency: ' '}} zł</span><br>
      <span>Link building: {{copywriter.articleRate | currency: ' '}} zł</span><br>
      <span>Inne: {{copywriter.descriptionRate | currency: ' '}} zł</span><br>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Statystyki</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span (click)="onGoToOrders()">Otwartych zadań: {{copywriter.activeOrdersTotal}}</span>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Płatności</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-payments-list [copywriterId]="copywriterId" [adminMode]="false"></app-payments-list>
    </mat-expansion-panel>
    <br>
    <mat-dialog-actions>
      <button mat-button color="primary" mat-raised-button cdkFocusInitial>Zapisz</button>
    </mat-dialog-actions>
    <br>
  </form>
</mat-card-content>

