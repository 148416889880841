<mat-dialog-content class="full-width-container">
  <h2 mat-dialog-title>Importuj zlecenie copywriterskie z XLS</h2>

  <form class="full-width-container">
    <mat-form-field class="full-width mat-form-field">
      <textarea rows="15" matInput placeholder="Wklej xls" [(ngModel)]="xls" name="xls">

      </textarea>
    </mat-form-field>
    <div *ngFor="let item of errorMessage">{{item}}</div>
  </form>
  <div class="spinner" *ngIf="progres.progress">
    <div class="spinner-content">
      <mat-spinner class="center-spinner" *ngIf="progres.progress"></mat-spinner>
      <span>{{progres.text}}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="onSubmit()" [disabled]="!dataLoaded">Wyślij do napisania</button>
</mat-dialog-actions>



