/**
 * DevPage
 * This is DevPage API contract
 *
 * OpenAPI spec version: 1.1.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './fileDto';


export interface BannerContentDefinition { 
    id?: string;
    image?: FileDto;
    url?: string;
    target?: BannerContentDefinition.TargetEnum;
    alt?: string;
    title?: string;
    dofollow?: boolean;
    endDate?: string;
}
export namespace BannerContentDefinition {
    export type TargetEnum = 'NEW_WINDOW' | 'SAME_WINDOW';
    export const TargetEnum = {
        NEWWINDOW: 'NEW_WINDOW' as TargetEnum,
        SAMEWINDOW: 'SAME_WINDOW' as TargetEnum
    };
}
