<h2 mat-dialog-title>Nowe domeny</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form">
<!--    <div *ngFor="let domain of domains; let in = index">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="domena" name="dname-{{in}}" [(ngModel)]="domain.name">
      </mat-form-field>
    </div>-->
    <mat-form-field class="example-full-width">
      <textarea rows="10" matInput placeholder="domeny" name="domains" [(ngModel)]="domainList"></textarea>
    </mat-form-field>
  </form>
 <!-- <button mat-button (click)="addNew()">+kolejna</button>-->

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Dodaj</button>
</mat-dialog-actions>
