import {Component, OnInit} from '@angular/core';
import {AdvertisementCategoryService} from '../../../client/api/advertisementCategory.service';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatDialog} from '@angular/material/dialog';
import {AdCategoryModalComponent} from '../ad-category-modal/ad-category-modal.component';
import {AdCategory} from '../../../client/model/adCategory';
import {AdsType} from '../../../client/model/adsType';
import {MatTreeNestedDataSource} from '@angular/material/tree';


interface CategoryNode {
  name: string;
  id: string;
  icon: string;
  type: string;
  children?: CategoryNode[];
}

@Component({
  selector: 'app-ad-category-list',
  templateUrl: './ad-category-list.component.html',
  styleUrls: ['./ad-category-list.component.css']
})
export class AdCategoryListComponent implements OnInit {

  private adCategories: CategoryNode[];
  private companyCategories: CategoryNode[];
  treeControl = new NestedTreeControl<CategoryNode>(node => node.children);
  adCategoryDataSource = new MatTreeNestedDataSource<CategoryNode>();
  companyCategoryDataSource = new MatTreeNestedDataSource<CategoryNode>();
  private rawCategory: Array<AdCategory>;

  constructor(private advertisementCategoryService: AdvertisementCategoryService,
              public dialog: MatDialog) {
  }


  ngOnInit() {
    this.loadCategories();
  }

  hasChild = (_: number, node: CategoryNode) => !!node.children && node.children.length > 0;

  private loadCategories() {
    this.adCategories = Array();
    this.companyCategories = Array();
    this.advertisementCategoryService.getAdCategories().subscribe(value => {
      this.rawCategory = value;
      value.sort((a, b) => (a.parentCategoryId > b.parentCategoryId) ? 1 : ((a.parentCategoryId < b.parentCategoryId) ? -1 : 0));

      value.forEach(categoryNode => {
          const currentCategory: CategoryNode = {
            id: categoryNode.id,
            name: categoryNode.categoryName,
            icon: categoryNode.icon,
            type: categoryNode.categoryType,
            children: Array()
          };

          if (categoryNode.categoryType === AdsType.ADS) {
            if (categoryNode.parentCategoryId == null) {
              this.adCategories.push(currentCategory);
            } else {
              const parentCategory = this.adCategories.filter(parent => parent.id === categoryNode.parentCategoryId)[0];
              if (parentCategory) {
                parentCategory.children.push(currentCategory);
              }
            }
          } else {
            if (categoryNode.parentCategoryId == null) {
              this.companyCategories.push(currentCategory);
            } else {
              const parentCategory = this.companyCategories.filter(parent => parent.id === categoryNode.parentCategoryId)[0];
              if (parentCategory) {
                parentCategory.children.push(currentCategory);
              }
            }
          }
        }
      );
      this.adCategoryDataSource.data = this.adCategories;
      this.companyCategoryDataSource.data = this.companyCategories;

    });
  }

  addCategory() {
    const adCategory: AdCategory = {
      categoryType: AdsType.ADS
    };
    const dialogRef = this.dialog.open(AdCategoryModalComponent, {
      width: '80%',
      data: adCategory
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadCategories();
    });
  }

  addChild(node: AdCategory) {
    const parent = this.getParentDetails(node.id);
    const adCategory: AdCategory = {
      categoryType: parent.categoryType,
      parentCategoryId: parent.id
    };
    const dialogRef = this.dialog.open(AdCategoryModalComponent, {
      width: '80%',
      data: adCategory
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadCategories();
    });
  }

  edit(node: AdCategory) {
    const dialogRef = this.dialog.open(AdCategoryModalComponent, {
      width: '80%',
      data: this.getParentDetails(node.id)
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadCategories();
    });
  }

  getParentDetails(id: String) {
    return this.rawCategory.find(value => value.id === id);
  }
}
