import {Component, Inject, OnInit} from '@angular/core';
import {ArticleDetails} from '../../../client/model/articleDetails';
import {FileService} from '../../../client/api/file.service';
import {ArticleService} from '../../../client/api/article.service';
import {OrderListItem} from '../../../client/model/orderListItem';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddCommentComponent} from '../add-comment/add-comment.component';
import {ArticleComment} from '../../../client/model/articleComment';

@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.css']
})
export class ArticlePreviewComponent implements OnInit {

  _jobs = 0;
  article: ArticleDetails;

  images: Array<string> = new Array();
  fileId: any;
  private fileList: Map<string, SafeResourceUrl> = new Map();
  progressDownloadPercentage: number;

  showDownloadProgressBar;
  progressPercentage: number;
  readonly order: OrderListItem;
  articleComments: Array<ArticleComment>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fileService: FileService,
              private articleService: ArticleService,
              private dialogRef: MatDialogRef<ArticlePreviewComponent>,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer) {
    this.article = data.article;
    this.order = data.order;
  }

  ngOnInit() {
    this.getImages();
    this.getComments();
  }

  publish() {
    this.articleService.publishArticle(this.article.id).subscribe(() => {
      this.dialogRef.close({published: true});
    });
  }

  getImages() {
    this.article.files.map(file => {
      this.jobs = this._jobs + 1;
      this.fileService.downloadPngFile(file.id).subscribe(response => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
          this.jobs = this._jobs - 1;
        };
        reader.readAsDataURL(response);
      });
    });
  }

  getArticleContentWithImages(): string {
    let index = 1;
    const content = this.article.content;
    let text = '<img width="50%" src="' + this.images[0] + '"/>' + content;

    while (text.indexOf('%IMG%') > -1) {
      if (index >= this.images.length) {
        break;
      }

      const img = `<img width="50%" src="${this.images[index]}" />`;
      text = text.replace('%IMG%', img);
      index = index + 1;
    }

    return text;
  }

  public set jobs(value: number) {
    if (value === 0) {
      this.article.content = this.getArticleContentWithImages();
    }
    this._jobs = value;
  }


  download(item: OrderListItem) {
    this.fileId = item.id;
    this.articleService.downloadArticleAndImagesZipped(item.id, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showDownloadProgressBar = false;
            this.fileList.set(item.id, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body)));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
        }

      });

  }

  getUrl(item: any) {
    const file = this.fileList.get(item.id);
    if (file != null) {
      return file;
    }
  }

  getFile(element: any) {
    return this.fileList.get(element.id) != null;
  }

  addComment() {
    const dialogConfig = {
      width: '90%',
      autoFocus: false,
      data: {
        articleId: this.article.id,
        order: this.order
      }
    };
    this.dialog.open(AddCommentComponent, dialogConfig);
  }

  private getComments() {
    this.articleService.getArticleComments(this.article.id).subscribe(value => {
      this.articleComments = value;
    });
  }
}
