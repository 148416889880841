import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {UserDto} from '../commons/UserDto';
import {BASE_PATH} from '../../client/variables';
import {Configuration} from '../../client/configuration';
import {MatSnackBar} from '@angular/material/snack-bar';


class LoginResponse {
  access_token: string;
  user: UserDto;
  refresh_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private CLIENT_ID: string = environment.clientId;
  private CLIENT_SECRET: string = environment.clientSecret;
  protected basePath = 'http://localhost';
  public configuration = new Configuration();

  headers = new HttpHeaders({
    'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    'Authorization': 'Basic ' + btoa(this.CLIENT_ID + ':' + this.CLIENT_SECRET)
  });
  options = {headers: this.headers};

  constructor(private http: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,
              @Optional() configuration: Configuration,
              public snackBar: MatSnackBar,
              private router: Router) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;

    } else {
      this.configuration.basePath = basePath || this.basePath;
    }

    setInterval(() => {

      if (localStorage.getItem('stayLoggedIn') != null) {
        if (Number.parseInt(localStorage.getItem('token_expires_in')) <= Date.now().valueOf()) {
          if (localStorage.getItem('stayLoggedIn') === 'true') {
            this.refreshToken();
          } else {
            this.logout();
          }
        }
      }

    }, 5000);
  }

  refreshToken() {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', localStorage.getItem('refresh_token'));

    this.http.post(`${this.configuration.basePath}/oauth/token`, params.toString(), this.options)
      .pipe(
        tap((response: LoginResponse) => {
            if (response) {
              this.extractAuthenticationResponse(response);
            }
          },
          e => {
            this.logout();
          }
        )
      ).subscribe(() => {

    });
  }

  login(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');

    return this.http.post(`${this.configuration.basePath}/oauth/token`, params.toString(), this.options)
      .pipe(
        tap((response: LoginResponse) => {
            if (response) {
              this.extractAuthenticationResponse(response);
            }
          },
          e => {
            if (e.status === 503) {
              this.logout();
              this.snackBar.open('Nie można połączyć z serwerem', 'Zamknij',
                {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
            }
          }
        )
      );
  }


  private extractAuthenticationResponse(response: LoginResponse) {
    localStorage.setItem('token', response.access_token);
    localStorage.setItem('user_id', response.user.id);
    localStorage.setItem('username', response.user.username);
    localStorage.setItem('user_email', response.user.email);
    localStorage.setItem('user_firstname', response.user.firstName);
    localStorage.setItem('user_lastname', response.user.lastName);
    localStorage.setItem('user_roles', JSON.stringify(response.user.roles));
    localStorage.setItem('refresh_token', response.refresh_token);
    localStorage.setItem('token_expires_in', (Date.now() + response.expires_in * 1000).toString());
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_login');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_name');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_expires_in');
    localStorage.removeItem('stayLoggedIn');
    this.router.navigateByUrl('/');
  }
}
