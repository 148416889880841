<h2 mat-dialog-title *ngIf="mode=='new'">Nowa publikacja</h2>
<h2 mat-dialog-title *ngIf="mode=='edit'">Edytuj publikacje</h2>
<form [formGroup]="form">

  <mat-dialog-content class="mat-typography" class="full-width-container">


    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Domena" name="domainName" required="true" formControlName="domainName">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Data aktualizacji" name="updateDate" formControlName="updateDate">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="ilość dni na SG" name="sgdays" formControlName="sgDays" type="number">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Kategoria" name="category" formControlName="category">
          </mat-form-field>
        </div>
      </div>


      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Czas" name="time" formControlName="time">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Lokalizacja" name="location" formControlName="location">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field>
            <mat-select placeholder="Dofollow" formControlName="dofollow"
                        name="dofollow">
              <mat-option value="no_info">
                brak info
              </mat-option>
              <mat-option value="follow">
                follow
              </mat-option>
              <mat-option value="no_follow">
                nofollow
              </mat-option>
              <mat-option value="sponsored">
                sponsored
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Cena" name="price" type="number" required="true" formControlName="price">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field>
            <input type="text" matInput placeholder="Wydawca" formControlName="publisherGroup"
                   [matAutocomplete]="publisherAutoGroup" class="full-width-container">
            <mat-autocomplete #publisherAutoGroup="matAutocomplete" class="example-full-width">
              <mat-optgroup *ngFor="let group of publisherGroupOptions | async" [label]="group.letter">
                <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setPublisherId(name.id)">
                  {{name.name}}
                </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>



      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Status wydawcy" name="publisherState" formControlName="publisherState">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Ilość linków" name="linkCount" formControlName="linkCount">
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field>
            <mat-select placeholder="Rodzaj linków" formControlName="linkType"
                        name="linkType">
              <mat-option value="no_info">
                brak info
              </mat-option>
              <mat-option value="brand">
                brand
              </mat-option>
              <mat-option value="brand_anchor">
                Brand, anchor
              </mat-option>
              <mat-option value="brand_url">
                Brand URL
              </mat-option>
              <mat-option value="eml">
                EML
              </mat-option>
              <mat-option value="mixed">
                Mieszane
              </mat-option>
              <mat-option value="url">
                URL
              </mat-option>
              <mat-option value="wszystkie">
                Wszystkie
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div width="100%">
        <div>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Uwagi" name="comments" formControlName="comments">
          </mat-form-field>
        </div>
      </div>

    </div>
  </mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
