import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot} from "@angular/router";
import {UserDto} from "../commons/UserDto";



@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  route: ActivatedRouteSnapshot;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.route = route;
    if (localStorage.getItem('token')) {
      return true;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        returnUrl: state.url
      }
    };
    this.router.navigate(['/'], navigationExtras);
    return false;
  }

  isLoggedIn() {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  }

  getLogin():string{
    return localStorage.getItem('username');
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getUser(): UserDto {
    const username = localStorage.getItem('username');
    const id = localStorage.getItem('user_id');
    const email = localStorage.getItem('user_email');
    const firstname = localStorage.getItem('user_firstname');
    const lastname = localStorage.getItem('user_lastname');
    const roles = JSON.parse(localStorage.getItem('user_roles'));
    const user = new UserDto(id, username, email, firstname, lastname, roles);
    return user;
  }

  hasRole(role: string): boolean {
    return JSON.parse(localStorage.getItem('user_roles')).includes(role);
  }
}
