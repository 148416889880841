import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FileService} from '../../../client/api/file.service';
import {FileDto} from '../../../client/model/fileDto';
import * as $ from 'jquery';
import {PixabaySearchComponent} from '../pixabay-search/pixabay-search.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TemplateList} from '../../../client/model/templateList';
import {HttpEvent, HttpEventType} from '@angular/common/http';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Output()
  change: EventEmitter<FileDto> = new EventEmitter<FileDto>();
  protected relativePath: string;
  private fileList: Map<string, SafeResourceUrl> = new Map();
  public thumbnails: Map<string, string> = new Map();


  get uploadedFile(): FileDto {
    return this._uploadedFile;
  }

  @Input()
  set uploadedFile(fileDto: FileDto) {
    this._uploadedFile = fileDto;
    if (this._uploadedFile != null && this._uploadedFile.id != null) {
      // tslint:disable-next-line:no-shadowed-variable
      this.fileService.downloadPngFile(this._uploadedFile.id).subscribe(value => {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageData = event.target.result;
          this.thumbnails.set(fileDto.id, event.target.result);
        };
        reader.readAsDataURL(value);
      });
    }
  }

  private _uploadedFile: FileDto;
  @Input() public uploadedFiles: FileDto[] = new Array();
  @Input() public fileListLabel: string = 'Uploadowane pliki';
  @Input() public canDelete: boolean = true;
  @Input() public pixabayAllowed: boolean = true;
  @Input() public fileInputLabel: string = 'Wybierz plik:';
  @Input() public limit: number = 3;
  @Input() public minimumUploadedFiles: number = 0;
  @Input() public preview: boolean = false;
  @Input() public relativePaths: Array<[string, string]>;
  @Input() public isConvertToWebp: boolean = false;
  public showProgressBar: boolean;
  protected progressPercentage: number;

  showDownloadProgressBar;
  progressDownloadPercentage;
  fileId;

  imageData: any;

  constructor(private fileService: FileService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    $(document).ready(function () {
      var $fileInput = $('.file-input');
      var $droparea = $('.file-drop-area');

// highlight drag area
      $fileInput.on('dragenter focus click', function () {
        $droparea.addClass('is-active');
      });

// back to normal state
      $fileInput.on('dragleave blur drop', function () {
        $droparea.removeClass('is-active');
      });
    });

    this.initializeThumbnails();
  }

  private scaleImage(relativePath: string) {
    return this.relativePath != null ? this.relativePath === 'img' : false;
  }

  public upload(event: Event) {
    const element = event.srcElement;
    const files = (element as HTMLInputElement).files;

    if (files.length > 0) {
      for (let _i = 0; _i < files.length; _i++) {

        const file = files.item(_i);
        this.fileService.uploadFile(this.relativePath, this.scaleImage(this.relativePath), this.isConvertToWebp , file, 'events', true).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.showProgressBar = true;
                break;
              case HttpEventType.Response:
                this.showProgressBar = false;
                if (this.limit == 1) {
                  this.uploadedFile = event.body;
                  this.change.emit(this.uploadedFile);
                } else {
                  this.uploadedFiles.push(event.body);
                }
                break;
              case 1: {
                if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
                  this.progressPercentage = event['loaded'] / event['total'] * 100;
                  this.progressPercentage = Math.round(this.progressPercentage);
                }
                break;
              }
            }
          },
          error => {
            console.log(error);
            this.showProgressBar = false;
          });
      }
    }
  }


  public delete(item: FileDto) {
    var index = this.uploadedFiles.indexOf(item, 0);
    this.uploadedFiles.splice(index, 1);
  }

  download(item: FileDto) {
    this.fileId = item.id;
    this.fileService.downloadFile(item.id, 'events', true).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.showDownloadProgressBar = true;
            break;
          case HttpEventType.Response:
            this.showDownloadProgressBar = false;
            this.fileList.set(item.id, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.body)));
            break;
          case 1: {
            if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.progressDownloadPercentage = event['loaded'] / event['total'] * 100;
              this.progressDownloadPercentage = Math.round(this.progressDownloadPercentage);
            }
            break;
          }
        }

        //  this.fileList.set(item.id, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(value)));
      });

  }

  getUrl(item: FileDto) {
    const file = this.fileList.get(item.id);
    if (file != null) {
      return file;
    }
  }

  openPixabayModal() {
    const dialogRef = this.dialog.open(PixabaySearchComponent, {
      id: 'pixabay',
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && (result as FileDto[]).length > 0) {
        (result as FileDto[]).forEach(value => this.uploadedFiles.push(value));
        this.uploadedFile = result[0];
      }
    });
  }

  thumbnail(item: any) {
    return this.thumbnails.get(item.id);
  }

  private initializeThumbnails() {
    this.uploadedFiles.map(file => {
      this.fileService.downloadPngFile(file.id).subscribe(value => {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageData = event.target.result;
          this.thumbnails.set(file.id, event.target.result);
        };
        reader.readAsDataURL(value);
      });
    });

  }
}
