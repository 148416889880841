<h2 mat-dialog-title *ngIf="mode!='edit'">Nowy serwer</h2>
<h2 mat-dialog-title *ngIf="mode=='edit'">Edytuj serwer</h2>

<mat-dialog-content class="mat-typography">
  <form class="server_form">
    <div fxLayout="column" fxLayoutAlign=" stretch">
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Nazwa" name="server_name" [(ngModel)]="server.name">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Address IP" name="address" [(ngModel)]="server.address">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="Port ssh" name="port" [(ngModel)]="server.port">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput required placeholder="User" name="user" [(ngModel)]="server.user">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <textarea matInput placeholder="PKEY" name="key" [(ngModel)]="server.key"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input matInput type="password" placeholder="Password" name="Password"
               [(ngModel)]="server.ftpPassword">
      </mat-form-field>
      <mat-expansion-panel class="example-full-width">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Adresy IP serwera
          </mat-panel-title>

        </mat-expansion-panel-header>
        <button mat-button (click)="addNew()">
          <mat-icon>add_circle</mat-icon>
        </button>
        <div *ngFor="let ipaddr of server.ipAddresses; let ind = index">
          <mat-form-field class="example-full-width">

            <input matInput placeholder="Adres IPv4 #{{ind}}" name="ipaddr-{{ind+1}}"
                   [(ngModel)]="ipaddr.ipAddressv4" [disabled]="ipaddr.deleted">


          </mat-form-field>
          <button mat-button (click)="delete(ipaddr)" [disabled]="ipaddr.deleted || ipaddr.inUse">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
