import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LinkAllocation} from "../../../client/model/linkAllocation";

@Component({
  selector: 'app-allocation-setup',
  templateUrl: './allocation-setup.component.html',
  styleUrls: ['./allocation-setup.component.css']
})
export class AllocationSetupComponent implements OnInit {

  @Input() allocation: LinkAllocation;
  oldOne: LinkAllocation = {};
  editMode: boolean;
  label: string = "";
  selected: string = "allocation";

  @Output() allocationUpdated = new EventEmitter<LinkAllocation>();
  canRender: boolean;

  constructor() {
  }

  ngOnInit() {
    this.updateLabel();
    this.oldOne.allocationInterval = this.allocation.allocationInterval;
    this.oldOne.allocationType = this.allocation.allocationType;
    this.oldOne.amount = this.allocation.amount
    this.canRender = true;
  }

  updateLabel() {
    switch (this.allocation.allocationType) {
      case "IMMEDIATELY":
        this.label = "natychmiast >";
        break;
      case "DAILY":
      case "WEEKLY":
        this.label = this.allocation.amount
          + " linków co " + this.allocation.allocationInterval;

        if (this.allocation.allocationType == 'DAILY') {
          this.label += " dni"
        }
        else {
          this.label += " tygodni";
        }
        break;
    }
  }

  startEdit() {
    this.editMode = true;
  }

  save() {
    this.editMode = false;
    this.allocationUpdated.emit(this.allocation);
  }

  cancel() {
    this.allocation.allocationInterval = this.oldOne.allocationInterval;
    this.allocation.allocationType = this.oldOne.allocationType;
    this.allocation.amount = this.oldOne.amount;
    this.editMode = false;

  }
}
