import {Component, OnInit} from '@angular/core';


import {CreateServerModalComponent} from '../create-server-modal/create-server-modal.component';
import {Server} from '../../../client/model/server';
import {ServersService} from '../../../client/api/servers.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomainService} from '../../../client/api/domain.service';

@Component({
  selector: 'app-server-list',
  templateUrl: './server-list.component.html',
  styleUrls: ['./server-list.component.css']
})
export class ServerListComponent implements OnInit {

  private servers: Server[];
  dataSource: MatTableDataSource<Server>;
  errorMessage: String;
  displayedColumns: string[] = ['name', 'address', 'domains', 'actions'];


  constructor(private serverService: ServersService,
              private domainService: DomainService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getServers();
  }

  getServers() {
    this.serverService.getServers().subscribe(
      response => {
        this.servers = response;
        this.dataSource = new MatTableDataSource(this.servers);
      },
      error => this.errorMessage = <any>error);
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateServerModalComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getServers();
    });
  }

  edit(serverId: string) {

    const dialogRef = this.dialog.open(CreateServerModalComponent, {
      width: '80%',
      data: serverId
    });


    dialogRef.afterClosed().subscribe(result => {
      this.getServers();
    });
  }

  delete(serverId: string) {
    this.serverService.deleteServer(serverId).subscribe(() => {
        this.getServers();
      },
      error1 => {
        this.snackBar.open('Serwer ma podpięte aktywne domeny, nie mozna usunąć', 'Zamknij',
          {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });
  }

  rerenderDomains() {
    this.domainService.rerenderDomains().subscribe(() => {
      this.snackBar.open('Domeny zostały zlecone do renderowania', 'Zamknij',
        {duration: 10000, panelClass: 'snack-success', verticalPosition: 'bottom'});
    });
  }
}
