<h1 mat-dialog-title>Wybierz zdjęcie z pixabay</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <form (submit)="searchImages(query)">
        <mat-form-field>
          <input matInput type="słowa kluczowe" [(ngModel)]="query" name="query"/>
        </mat-form-field>

        <select matNativeControl required [(ngModel)]="querySize" name="size" (change)="searchImages(query)">
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="100">100</option>
        </select>
        <button mat-button (click)="searchImages(query)" type="button">Szukaj obrazków
        </button>
      </form>
      <mat-progress-bar *ngIf="progres.progress" mode="indeterminate"></mat-progress-bar>
      <div *ngIf="loaded">
        <div width="50">
          <pagination-controls (pageChange)="changePage($event)"
                               maxSize="10"
                               previousLabel="Poprzednie"
                               nextLabel="Następne" class="my-pagination"></pagination-controls>
        </div>
        <div width="50">
          <mat-paginator [length]="total"
                         [pageSize]="querySize"
                         [pageSizeOptions]="[20,40, 100]"
                         (page)="changePageSize($event)">
          </mat-paginator>
        </div>
      </div>
      <mat-grid-list cols="5" rowHeight="1:1">
        <mat-grid-tile
          *ngFor="let item of images | paginate: { itemsPerPage: querySize, currentPage: page, totalItems: total }"
          [matMenuTriggerFor]="menu"
          [ngClass]="{'downloaded': downloaded!=null && isOnDownloadedList(item.id)}">
          <img [src]="item.previewURL"
               layout-fill class="imageThumbnail"/>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="download(item)">Wybierz</button>
          </mat-menu>
        </mat-grid-tile>
      </mat-grid-list>

      <div *ngIf="loaded">
        <div width="50">
          <pagination-controls (pageChange)="changePage($event)"
                               maxSize="10"
                               previousLabel="Poprzednie"
                               nextLabel="Następne" class="my-pagination"></pagination-controls>
        </div>
        <div width="50">
          <mat-paginator [length]="total"
                         [pageSize]="querySize"
                         [pageSizeOptions]="[20,40, 100]"
                         [showFirstLastButtons]="false"
                         (page)="changePageSize($event)">
          </mat-paginator>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button color="primary" (click)="closePixabayAndSave()">Zapisz wybrane obrazki</button>
</div>
