<form [formGroup]="form" (ngSubmit)="onSubmit()">

<mat-dialog-content class="full-width-container">
  <h2 mat-dialog-title>Nowe zlecenie copywriterskie</h2>

    <mat-form-field class="full-width">
      <input matInput formControlName="type" placeholder="" style="display: none">
      <mat-radio-group #rGroup placeholder="Typ zlecenia" formControlName="type">
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-radio-button class="type-radio" *ngFor="let type of orderTypes" radioGroup="rGroup"
                            (change)="onOrderTypeChange($event)" [value]="type.value">{{type.text}}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div width="24">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput type="number" placeholder="Liczba tekstów na domenę" formControlName="quantity">
            <mat-error>
              To pole jest wymagane
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div width="24">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <input matInput type="number" placeholder="Liczba znaków" formControlName="length">
            <mat-error>
              To pole jest wymagane
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div width="50">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field>
            <mat-select placeholder="Wybierz copywritera" formControlName="copywriters" multiple>
              <mat-option *ngFor="let copy of copywriters" [value]="copy.id">
                {{copy.firstName}} {{copy.lastName}} {{copy.categories.length > 0 ? '(' + (copy.categories.join(', ')) + ')' : ''}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span id="domains-title">Domeny:&nbsp;(rozwiń)</span>
        </mat-panel-title>
        <mat-panel-description>
          <span *ngFor="let domain of selectedDomains">{{domain.domainName}}&nbsp;</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-domain-search (selectionChanged)="onDomainSelectionChange($event)"></app-domain-search>
    </mat-expansion-panel>

    <mat-form-field class="full-width">
      <mat-chip-list #tagsList (keyup)="onKeyup($event)">
        <mat-chip *ngFor="let tag of formData.controls; let i = index;"
                  [selectable]="selectable"
                  [removable]="removable">
          {{tag.value.name}}
          <mat-icon matChipRemove (click)="removeTag(i)" *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Słowa kluczowe (oddzielone przecinkiem)"
               #tagInput
               [formControl]="tagCtrl"
               [matAutocomplete]="auto"
               [matChipInputFor]="tagsList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{tag.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="this.form.get('type').value == 'PARAPHRASE'">
      <input matInput placeholder="URL do parafrazy" formControlName="paraphraseUrl">
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Zewnętrzene Id" formControlName="externalId">
    </mat-form-field>
    <mat-form-field class="full-width">
      <textarea matInput rows="4" placeholder="Dodatkowe wytyczne" formControlName="note"></textarea>
    </mat-form-field>
    <mat-form-field class="full-width mat-form-field--no-underline">
      <input matInput formControlName="autoPublish" placeholder="" style="display: none">
      <mat-checkbox formControlName="autoPublish">Publikuj automatycznie</mat-checkbox>
    </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput formControlName="dueDate" placeholder="Termin oddania (YYYY-MM-DD)">
  </mat-form-field>
    <div class="spinner" *ngIf="progres.progress">
      <div class="spinner-content">
        <mat-spinner class="center-spinner" *ngIf="progres.progress"></mat-spinner>
      </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial>Wyślij do napisania</button>
</mat-dialog-actions>

</form>


