import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {CountryService} from '../../../client/api/country.service';
import {Country} from '../../../client/model/country';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Translation} from '../../../client/model/translation';
import {TranslationsService} from '../../../client/api/translations.service';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.css']
})
export class TranslationModalComponent implements OnInit {
  countries: Array<Country>;
  title: any;
  translation: Translation;
  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private matDialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private countryService: CountryService,
              private translationService: TranslationsService,
              private progress: ProgressIndicatorService) {
    progress.progress = true;
    this.translation = data.translation;
    if (this.translation == null) {
      this.title = 'Nowe tłumaczenie';
      this.translation = {
        language: 'pl',
        translation: '',
        label: ''
      };
    } else {
      this.title = 'Edycja';
    }

    this.form = this.formBuilder.group({
      label: new UntypedFormControl(this.translation.label, Validators.required),
      translation: new UntypedFormControl(this.translation.translation, Validators.required),
      language: new UntypedFormControl(this.translation.language, Validators.required),
    });
    progress.progress = false;

  }

  ngOnInit(): void {
    this.getCountries();
  }

  private getCountries() {
    this.countryService.getCountryList().subscribe(value => {
        this.countries = value.countries;
        this.progress.progress = false;
      }
    );
  }

  save() {
    if (this.form.valid) {
      this.progress.progress = true;
      this.translation = this.form.value;
      this.translationService.createTranslation(this.translation).subscribe(value => {
        this.progress.progress = false;
        this.matDialog.closeAll();
      });
    }

  }
}
