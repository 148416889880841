import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CopywriterService} from "../../../client/api/copywriter.service";
import {DomainCategoryService} from "../../../client/api/domaincategory.service";
import {DomainCategory} from "../../../client/model/domainCategory";
import {CopywriterDetails} from "../../../client/model/copywriterDetails";

@Component({
  selector: 'app-copywriter-panel',
  templateUrl: './copywriter-panel.component.html',
  styleUrls: ['./copywriter-panel.component.css']
})
export class CopywriterPanelComponent implements OnInit {

  @Input() copywriterId: string;
  @Output() goToOrdersClicked = new EventEmitter();

  copywriter: CopywriterDetails;
  categories: DomainCategory[];
  selectedCategories: string[] = [];
  errorMessage: String;
  passwordConfirmation: string;
  password: string;

  constructor(private copywriterService: CopywriterService,
              private categoriesService: DomainCategoryService) {
    this.copywriter = {};
  }

  ngOnInit() {
    this.getCategories();
    this.getCopywriter();
  }

  getCopywriter() {
    this.copywriterService.getCopywriter(this.copywriterId).subscribe(response => {
      this.copywriter = response;
      this.passwordConfirmation = this.copywriter.password;
      this.selectedCategories = this.copywriter.categories.map(c => c.id);
    });
  }

  getCategories() {
    this.categoriesService.getCategories().subscribe(response => {
        this.categories = response;
        this.getCopywriter();
      },
      error =>
        this.errorMessage = <any>error);
  }

  onGoToOrders() {
    this.goToOrdersClicked.emit();
  }

  onSubmit() {
    this.copywriter.categories = [];

    for(let cat of this.selectedCategories) {
      this.copywriter.categories.push({id: cat});
    }

    this.copywriter.password = this.password;
    this.copywriter.passwordConfirmation = this.passwordConfirmation;

    this.copywriterService.updateCopywriter(this.copywriterId, this.copywriter)
      .subscribe(response => {},
          error => this.errorMessage = <any>error);
  }
}
