import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ArticleService} from '../../../client/api/article.service';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {ArticleComment} from '../../../client/model/articleComment';
import {OrderService} from '../../../client/api/order.service';
import {OrderListItem} from '../../../client/model/orderListItem';
import {UpdateOrderRequest} from '../../../client/model/updateOrderRequest';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css']
})
export class AddCommentComponent implements OnInit {

  articleId: string;
  articleComment: ArticleComment = {};
  order: OrderListItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private articleService: ArticleService,
              private orderService: OrderService,
              private dialog: MatDialog,
              public progres: ProgressIndicatorService) {
    this.articleId = data.articleId;
    this.order = data.order;
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.progres.progress = true;
    this.articleService.addArticleComment(this.articleId, this.articleComment)
      .subscribe(value => {
          const updateRequest: UpdateOrderRequest = {status: 'NEED_WORK'};
          this.orderService.updateOrder(this.order.id, updateRequest).subscribe(value1 => {
            this.progres.progress = false;
            this.dialog.closeAll();
          });
        }
      )
    ;
  }
}
