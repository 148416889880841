<div class="copywriter-order-list-container">
  <mat-button-toggle-group name="submenu" #group="matButtonToggleGroup" [value]="submenu">
    <mat-button-toggle value="active">Aktywne</mat-button-toggle>
    <mat-button-toggle value="ready">Ukończone</mat-button-toggle>
    <mat-button-toggle value="need_work">Do poprawy</mat-button-toggle>
    <mat-button-toggle value="all">Wszystkie</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-card>
    <mat-card-content>
      <app-orders-copywriter-list [orderStatuses]="['NEW']"
                                  *ngIf="group.value == 'active'"></app-orders-copywriter-list>
      <app-orders-copywriter-list [orderStatuses]="['PUBLISHED', 'READY']"
                                  *ngIf="group.value == 'ready'"></app-orders-copywriter-list>
      <app-orders-copywriter-list [orderStatuses]="['NEED_WORK']"
                                  *ngIf="group.value == 'need_work'"></app-orders-copywriter-list>
      <app-orders-copywriter-list [orderStatuses]="['NEW','READY','PUBLISHED','NEED_WORK']"
                                  *ngIf="group.value == 'all'"></app-orders-copywriter-list>
    </mat-card-content>
  </mat-card>
</div>
