<form>
  <div class="full-width-container">

      <mat-checkbox matInput [(ngModel)]="domain.adsConfig.adsEnabled" name="adsEnabled">Moduł reklam aktywny
      </mat-checkbox>

      <mat-checkbox matInput [(ngModel)]="domain.adsConfig.companyCatalogueEnabled" name="companyCatalogueEnabled">
        Katalog firm aktywny
      </mat-checkbox>

      <mat-checkbox matInput [(ngModel)]="domain.adsConfig.userCanCreate" name="userCanCreate">Użytkownik na stronie
        może tworzyć wpisy
      </mat-checkbox>

      <mat-checkbox matInput [(ngModel)]="domain.adsConfig.autoPublish" name="autoPublish">Auto publikacja wpisów
      </mat-checkbox>

  </div>
</form>

