import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../../client/model/domain';

@Component({
  selector: 'app-main-page-widgets',
  templateUrl: './main-page-widgets.component.html',
  styleUrls: ['./main-page-widgets.component.css']
})
export class MainPageWidgetsComponent implements OnInit {
  @Input() domain: Domain;

  constructor() { }

  ngOnInit(): void {
  }

}
