<h2 mat-dialog-title>Uwagi do artykułu: {{article.title}}</h2>

<mat-dialog-content>
    <form [formGroup]="orderForm">

        <div class="full-width-container">
            <mat-form-field>
                <textarea matInput placeholder="Dopisz uwagi/poprawki, które mają zostać wprowadzone do tekstu." rows="10"
                  formControlName="customPrompt" name="customPrompt"></textarea>
            </mat-form-field>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial id="saveBtn" (click)="save()">Zapisz</button>
</mat-dialog-actions>
