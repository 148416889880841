import {Component, Inject, OnInit} from '@angular/core';
import {Country} from '../../../client/model/country';
import {CountryService} from '../../../client/api/country.service';

import {ContactService} from '../../../client/api/contact.service';
import {ContactPage} from '../../../client/model/contactPage';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-contact-page',
  templateUrl: './edit-contact-page.component.html',
  styleUrls: ['./edit-contact-page.component.css']
})
export class EditContactPageComponent implements OnInit {
  pageTitle: any;
  inProgress: any;
  contactPage: ContactPage;
  countries: Array<Country>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private countryService: CountryService,
              private contactService: ContactService,
              public dialog: MatDialog) {
    this.contactPage = data.contactPage;
    this.pageTitle = data.edit ? 'Edycja' : 'Nowa strona kontaktowa';
  }

  private getCountries() {
    this.countryService.getCountryList().subscribe(value => this.countries = value.countries);
  }

  ngOnInit() {
    this.getCountries();
  }

  save() {
    this.contactService.addOrUpdateContactPage(this.contactPage.countryCode, this.contactPage).subscribe(value => {
      this.dialog.closeAll();
    });
  }
}
