<h2 mat-dialog-title>{{pageTitle}}</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-dialog-content>

    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
      <input type="hidden" formControlName="id">

      <mat-expansion-panel [expanded]="true" *ngIf="showHint">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Wytyczne:
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span>Ilość znaków: {{articleLength}}</span><br>
        <span>Słowa kluczowe: {{keywords}}</span><br>
        <span>Dodatkowe wytyczne: <div [innerHTML]="hint.replace('\n','<br/>')"></div></span><br>
        <span *ngIf="order.type.toString() === 'ARTICLE' && paraphraseUrl.length > 0">
          URL do strony klienta: <a href="{{paraphraseUrl}}" target="_blank" rel="nofollow noreferrer noopener"
                                    matTooltip="Link">{{paraphraseUrl}}</a>
        </span><br>
        <span *ngIf="order.type.toString() === 'DESCRIPTION' && paraphraseUrl.length > 0">
          URL do strony klienta: <a href="{{paraphraseUrl}}" target="_blank" rel="nofollow noreferrer noopener"
                                    matTooltip="Link">{{paraphraseUrl}}</a>
        </span><br>
        <span *ngIf="order.type.toString() === 'PARAPHRASE' && paraphraseUrl.length > 0">
          URl do parafrazy: <a href="{{paraphraseUrl}}" target="_blank" rel="nofollow noreferrer noopener"
                               matTooltip="Link">{{paraphraseUrl}}</a>
        </span><br>
        <span *ngIf="linkAnchor">!!! W treści umieść zmienną głównego słowa kluczowego:: <code
          style="font-weight: 700">[link]{{linkAnchor}}[/link]</code>  </span>
      </mat-expansion-panel>

        <div>
            <mat-form-field width="100">
                <input matInput placeholder="Tytuł" [required]="true" formControlName="title">
                <mat-error *ngIf="form.get('title').hasError('required') && form.get('title').touched">
                    To pole jest wymagane
                </mat-error>
                <mat-error *ngIf="form.get('title').hasError('maxlength') && form.get('title').touched">
                    Tytuł nie może przekraczać 200 znaków
                </mat-error>
            </mat-form-field>
        </div>
      <div>
      <mat-form-field width="100">
        <mat-select placeholder="Kategoria" [required]="categories.length==0 && externalDomain==null"
                    formControlName="categoryId">
          <mat-option *ngFor="let cat of categories" [value]="cat.id">
            {{cat.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="categories.length==0 && externalDomain==null" class="mat-error">Nie zdefiniowano kategorii dla
        domeny!</p>
      </div>

      <mat-form-field>
        <mat-chip-list #tagsList (keyup)="onKeyup($event)">
          <mat-chip *ngFor="let tag of formData.controls; let i = index;"
                    [selectable]="selectable"
                    [removable]="removable">
            {{tag.value.name}}
            <mat-icon matChipRemove (click)="removeTag(i)" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="Słowa kluczowe"
                 #tagInput
                 [formControl]="tagCtrl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="tagsList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addTag($event)"/>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            {{tag.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <editor
        [formControl]="form.controls.content"
        [init]="{
           base_url: '/tinymce',
           suffix: '.min',
           height: 500,
           menubar: true,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount link'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat |link| help'
   }"
      ></editor>
      <div class="mat-error" *ngIf="!hasImg">Użyj conajmniej jeden raz <strong>%IMG%</strong></div>

      <mat-form-field>
        <input matInput placeholder="Autor" formControlName="author" [required]="true">
      </mat-form-field>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title><p>
            Obrazki: (pierwszy obrazek zostanie użyty jako zdjęcie główne <br/>
            Każdy kolejny umieść wstawiając w treści <strong style="color: #70d83b;">%IMG%</strong> - pierwsze użycie
            tagu =
            wstawienie drugiego obrazka, drugie użycie tagu = wstawienie trzeciego obrazka itd...</p>
          </mat-panel-title>
          <mat-panel-description>
            <div class="mat-error" *ngIf="!isValidFiles">Dodaj przynajmniej 2 obrazki</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-file-upload [canDelete]="true"
                         [fileInputLabel]=""
                         [fileListLabel]=""
                         [limit]="100"
                         [uploadedFiles]="article.files"
                         [relativePaths]="relativePaths"
                         [preview]="true"
                         [isConvertToWebp]="true"
        ></app-file-upload>
      </mat-expansion-panel>

      <div>
        <mat-form-field width="25%" *appShowAuthed="'EMPLOYEE'">
          <input matInput [matDatepicker]="myDatepicker" formControlName="publishedDate" placeholder="Data publikacji">
          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial id="saveBtn"
            [disabled]="inProgress">{{ article.id ? 'Zapisz' : 'Dodaj' }}</button>
  </mat-dialog-actions>
  <div class="spinner" *ngIf="progresIndicatorService!=null && progresIndicatorService.progress">
    <div class="spinner-content">
      <mat-spinner class="center-spinner" *ngIf="progresIndicatorService.progress"></mat-spinner>
    </div>
  </div>
</form>

