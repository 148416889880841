import {Component, OnInit} from '@angular/core';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {PublicationsService} from '../../../client/api/publications.service';
import {PublisherService} from '../../../client/api/publisher.service';
import {Publisher} from '../../../client/model/publisher';
import {Publication} from '../../../client/model/publication';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomDefinition, CustomGroup} from '../../orders/orders-admin-list/orders-admin-list.component';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {_filter} from '../../domains/domains.component';

@Component({
  selector: 'app-publication-import',
  templateUrl: './publication-import.component.html',
  styleUrls: ['./publication-import.component.css']
})
export class PublicationImportComponent implements OnInit {

  xls = '';
  errorMessage: Array<String> = new Array();
  dataLoaded = false;
  private publishers: Array<Publisher>;



  constructor(public progres: ProgressIndicatorService,
              private publicationsService: PublicationsService,
              private publisherService: PublisherService,
              public snackBar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getPublishers();
  }

  private getPublishers() {
    this.publisherService.getPublishers(0, 5000, '').subscribe(
      value => {
        this.publishers = value;
        this.dataLoaded = true;
      });
  }

  onSubmit() {
    this.progres.progress = true;
    this.progres.text = 'Parsowanie pliku';

    const rows = this.xls.split('\n');
    const publications = new Array();

    rows.forEach((value, index) => {
        try {

          const fields = value.split('\t');
          const publication: Publication = {
            domainName: fields[0],
            updateDate: fields[1],
            sgDays: parseFloat(fields[2]),
            category: fields[3].toLowerCase(),
            time: fields[4],
            location: fields[5].toLowerCase(),
            dofollow: fields[6],
            price: fields[7].includes('.') ? parseFloat(fields[7].split('zł')[7]) : parseFloat(fields[7]),
            publisher: this.createOrGetPublisher(fields[8], this.publishers),
            linkType: fields[9].toLowerCase(),
            comments: fields[10]
          };
          publications.push(publication);
        } catch (e) {
          this.errorMessage.push('Błąd w wierszu ' + index + ' : ' + e + ' [' + value + ' ]');
        }
      }
    );
    if (this.errorMessage.length > 0) {
      this.snackBar.open(this.errorMessage.join(', '), 'Zamknij',
        {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
    }
    this.progres.text = 'Zapisywanie do bazy';

    let publicationsRequest = {publications: publications};
    this.publicationsService.createPublication(publicationsRequest).subscribe(value1 => {
      this.progres.progress = false;
      this.dialog.closeAll();
    });

  }

  public createOrGetPublisher(publisherEmail: string, publishers: Array<Publisher>) {
    if (publishers.filter(value => value.email === publisherEmail).length === 0) {
      const publisherDto: Publisher = {
        email: publisherEmail
      };
      return publisherDto;
    } else {
      return publishers.filter(value => value.email === publisherEmail).pop();
    }
  }

}
