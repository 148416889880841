<h2 mat-dialog-title>Dodaj komentarz do zamówienia</h2>
<form class="full-width">
  <mat-dialog-content>
    <mat-form-field class="full-width">
      <textarea rows="10" matInput placeholder="Komentarz" name="comment" class="full-width"
                [(ngModel)]="articleComment.content"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary id="saveBtnAndRR" (click)="onSubmit()" *ngIf="order.status!='NEED_WORK'">Dodaj uwagi i
      odeślij do poprawy
    </button>
    <button mat-button primary id="saveBtn" (click)="onSubmit()" *ngIf="order.status=='NEED_WORK'">Dodaj uwagi
    </button>
  </mat-dialog-actions>
</form>
