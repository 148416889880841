import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ArticleCategories} from '../../../../../client/model/articleCategories';
import {ArticleCategory} from '../../../../../client/model/articleCategory';
import {ArticlesCategoryService} from '../../../../../client/api/articlesCategory.service';
import {Domain} from '../../../../../client/model/domain';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-article-category-modal',
  templateUrl: './article-category-modal.component.html',
  styleUrls: ['./article-category-modal.component.css']
})
export class ArticleCategoryModalComponent implements OnInit {


  protected categories: ArticleCategories;
  public mode: string;
  private category: ArticleCategory;
  protected tmpName: string = '';
  protected tmpValue: string = '';
  private domain: Domain;
  public warning: boolean = false;

  constructor(private articlesCategoryService: ArticlesCategoryService,
              private dialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: ModalData) {
    if (data != null) {
      this.domain = this.data.domain;
      var articleCategory: ArticleCategory = {};
      this.category = articleCategory;
    }
    if (data != null && data.category != null) {
      this.mode = 'edit';
      this.category = this.data.category;
      this.tmpName = this.category.name;
      this.tmpValue = this.category.name;
    } else {
      var articleCategory: ArticleCategory = {};
      this.category = articleCategory;
    }
    this.categories = new Array();
    this.categories.push(this.category);
  }

  ngOnInit() {
  }

  addNew() {
    let category: ArticleCategory = {};
    this.categories.push(category);
  }

  save() {
    if (this.categories.filter(value => value.name == null || value.name.length == 0).length > 0) {
      alert('Nazwa kategorii nie może być pusta!');
    } else {
      if (this.mode != 'edit') {
        this.articlesCategoryService.addCategories(this.domain.id, this.categories).subscribe(() => {
          this.dialog.closeAll();
        });
      } else {
        this.category.name = this.tmpValue;
        this.articlesCategoryService.updateCategory(this.domain.id, this.category.id, this.category).subscribe(() => {
          this.dialog.closeAll();
        });
      }
    }
  }

  deleteCategory(category: ArticleCategory) {
    let index = this.categories.indexOf(category);
    this.categories.splice(index, 1);
  }

}


export class ModalData {

  constructor(domena: Domain, category: ArticleCategory) {
    this.domain = domena;
    this.category = category;
  }

  domain?: Domain;
  category?: ArticleCategory;
}
