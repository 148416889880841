import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../../../client/api/projects.service';
import {ProjectDetails} from '../../../client/model/projectDetails';
import {BannerCampaign} from '../../../client/model/bannerCampaign';
import {LinkCampaign} from '../../../client/model/linkCampaign';
import {LinkCampaignService} from '../../../client/api/linkCampaign.service';
import {BannerCampaignService} from '../../../client/api/bannerCampaign.service';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {NapCampaign} from '../../../client/model/napCampaign';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {

  projectLoaded: boolean;
  public id: string;
  project: ProjectDetails;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  bannerDataSource: MatTableDataSource<BannerCampaign>;
  bannerDisplayedColumns: string[] = ['name', 'status', 'stats', 'actions'];

  bannerNapDataSource: MatTableDataSource<NapCampaign>;
  bannerNapDisplayedColumns: string[] = ['name', 'status', 'stats', 'actions'];

  linkDataSource: MatTableDataSource<LinkCampaign>;
  linkDisplayedColumns: string[] = ['name', 'status', 'externalId', 'stats', 'actions'];
  edit: boolean;

  constructor(private route: ActivatedRoute,
              private projectsService: ProjectsService,
              private linkCampaignService: LinkCampaignService,
              public snackBar: MatSnackBar,
              public progres: ProgressIndicatorService,
              private bannerCampaignService: BannerCampaignService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadProject();
  }

  loadProject() {
    this.projectsService.getProjectDetails(this.id).subscribe(value => {
      this.project = value;
      this.bannerDataSource = new MatTableDataSource(this.project.bannerCampaigns);
      this.bannerNapDataSource = new MatTableDataSource(this.project.napCampaigns);
      this.linkDataSource = new MatTableDataSource(this.project.linkCampaigns);
      this.bannerDataSource.sort = this.sort;
      this.bannerDataSource.paginator = this.paginator;
      this.projectLoaded = true;
    });
  }

  deleteLinkCampaign(element: LinkCampaign) {
    if (confirm('Na pewno chcesz usunąć kampanię linków ' + element.name + '?')) {
      this.linkCampaignService.deleteLinkCampaign(element.id).subscribe(value => {
        this.loadProject();
      });
    }
  }

  deleteBannerCampaign(element) {
    if (confirm('Na pewno chcesz usunąć kampanię bannerów ' + element.name + '?')) {
      this.bannerCampaignService.deleteBannerCampaign(element.id).subscribe(value => {
        this.loadProject();
      });
    }
  }

  getBannerStats(element: BannerCampaign) {
    let total = element.bannerAssignments.length + element.articleBannerAssignments.length;
    let active = element.bannerAssignments.filter(value => value.published).length;
    active += element.articleBannerAssignments.filter(value => value.published).length;
    return active + '/' + total;
  }

  getProjectDomainBanners(project: ProjectDetails) {
    let total = 0;
    let active = 0;
    this.project.bannerCampaigns.forEach(value => total += value.bannerAssignments.length != null
      ? value.bannerAssignments.length : 0);
    this.project.bannerCampaigns.forEach(value => active += value.bannerAssignments != null
      ? value.bannerAssignments.filter(value1 => value1.published).length : 0);
    return active + '/' + total;
  }

  getProjectArticleBanners(project: ProjectDetails) {
    let total = 0;
    let active = 0;
    this.project.bannerCampaigns.forEach(value => total += value.articleBannerAssignments != null
      ? value.articleBannerAssignments.length : 0);
    this.project.bannerCampaigns.forEach(value => active += value.articleBannerAssignments != null
      ? value.articleBannerAssignments.filter(value1 => value1.published).length : 0);
    return active + '/' + total;
  }

  save() {
    this.progres.progress = true;
    this.projectsService.updateProjectDetails(this.project.id, this.project).subscribe(value => {
      this.project = value;
      this.progres.progress = false;
      this.snackBar.open('Zapisano zmiany!', 'Zamknij',
        {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
    });
  }

  getProjectLinks(project: ProjectDetails) {
    let total = 0;
    let active = 0;
    this.project.linkCampaigns.forEach(value => {
      if (value.linkAssignments != null) {
        total += value.linkAssignments.length;
      }
    });
    this.project.linkCampaigns.forEach(value => {
      if (value.linkAssignments != null) {
        active += value.linkAssignments.filter(value1 => value1.published).length;
      }
    });
    return active + '/' + total;
  }

  getLinkStats(element: LinkCampaign) {
    let total = element.linkAssignments.length;
    let active = element.linkAssignments.filter(value => value.published).length;
    return active + '/' + total;
  }
}
