import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {ActivatedRoute, Params, Router} from '@angular/router';

import {LoginService} from './login.service';
import {AuthService} from '../services/auth.service';
import {ProgressIndicatorService} from '../commons/progress-indicator.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomainService} from "../../client/api/domain.service";
import {PublicationsService} from "../../client/api/publications.service";


export class LoginCommand {
  login: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {

  loginCommand = new LoginCommand();
  returnUrl = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private authService: AuthService,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar,
              private domainService: DomainService,
              private publicationsService: PublicationsService) {
  }

  ngOnInit() {
    const params: Params = this.router.routerState.snapshot.root.queryParams;
    if (params.returnUrl !== undefined && Object.keys(params).length !== 0) {
      this.returnUrl = params.returnUrl;
    }
    this.loginService.logout();
  }

  onSubmit() {
    this.progres.progress = true;
    this.loginService.login(this.loginCommand.login, this.loginCommand.password)
      .subscribe(() => {
          this.domainService.getDomains();
          this.publicationsService.getPublications(null, 0, 20000);
          this.progres.progress = false;
          if (this.returnUrl !== '') {
            this.router.navigateByUrl(this.returnUrl);
          } else if (this.authService.hasRole('COPYWRITER') || this.authService.hasRole('COPYWRITER_ADMIN') && !this.authService.hasRole('ADMINISTRATOR')) {
            this.router.navigateByUrl('/copywriters');
          } else {
            this.router.navigateByUrl('/domains');
          }
        },
        error2 => {
          this.progres.progress = false;
          this.snackBar.open('Niepoprawny login lub hasło! Spróbuj ponownie', 'Zamknij',
            {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
  }
}
