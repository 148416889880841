<p>
  <button mat-button (click)="openDialog()">
    <mat-icon>add_circle</mat-icon>
  </button>
</p>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>

  <ng-container matColumnDef="dedicated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dedykowana</th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox class="example-margin" [(ngModel)]="element.dedicated" disabled="true"></mat-checkbox>
    </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Edycja/Usuń</th>
    <td mat-cell *matCellDef="let element">

      <button mat-icon-button (click)="edit(element)">
        <mat-icon>edit</mat-icon>
      </button>


      <button mat-icon-button (click)="delete(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
