import {Component, Input, OnInit} from '@angular/core';
import {Domain} from "../../../../../client/model/domain";

@Component({
  selector: 'app-custom-styles-and-scripts',
  templateUrl: './custom-styles-and-scripts.component.html',
  styleUrls: ['./custom-styles-and-scripts.component.css']
})
export class CustomStylesAndScriptsComponent implements OnInit {
  @Input() domain: Domain;
  constructor() { }

  ngOnInit() {
  }
}
