<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" class="example-form" (ngSubmit)="save()">

  <mat-dialog-content class="mat-typography">

    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Placeholder" formControlName="label"/>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field class="example-full-width">
        <input matInput rows="10" matInput placeholder="Tłumaczenie" formControlName="translation"/>
      </mat-form-field>
    </div>
    <div class="full-width-container">
      <mat-form-field>
        <mat-select placeholder="Język tłumaczenia" formControlName="language">
          <mat-option *ngFor="let item of countries" [value]="item.iso2code.toLowerCase()">
            {{item.countryName}}[{{item.iso2code}}]
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Anuluj</button>
    <button mat-button primary cdkFocusInitial id="saveBtn">Zapisz</button>
  </mat-dialog-actions>
</form>
