import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LinkCampaignService} from '../../../client/api/linkCampaign.service';
import {LinkCampaignDetails} from '../../../client/model/linkCampaignDetails';
import {Link} from '../../../client/model/link';
import {ProjectsService} from '../../../client/api/projects.service';
import {ProjectDetails} from '../../../client/model/projectDetails';
import {LinkAllocation} from '../../../client/model/linkAllocation';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Article} from '../../../client/model/article';
import {ArticleSearchResult} from '../../../client/model/articleSearchResult';
import {LinkAssignments} from '../../../client/model/linkAssignments';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ConfirmPopupComponent} from '../../commons/confirm-popup/confirm-popup.component';
import {LinkAssignmentUpdateRequest} from '../../../client/model/linkAssignmentUpdateRequest';
import {LinkAssigmentStatusComponent} from '../link-assigment-status/link-assigment-status.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ArticleDetails} from "../../../client/model/articleDetails";

@Component({
  selector: 'app-edit-link-campaign',
  templateUrl: './edit-link-campaign.component.html',
  styleUrls: ['./edit-link-campaign.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class EditLinkCampaignComponent implements OnInit {

  public loaded: boolean;
  public id: string;
  public campaign: LinkCampaignDetails;
  public linkDataSource: MatTableDataSource<Link>;
  expandedElement: Link = null;
  linkDisplayedColumns: string[] = ['url', 'anchor', 'replaceKey', 'nofollow', 'allocation', 'amount', 'domains', 'status', 'stan', 'actions'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public project: ProjectDetails;

  fileUrl;

  private selectedArticles: Array<ArticleSearchResult>;
  private articleDetails: ArticleDetails = new class implements ArticleDetails {

  };

  constructor(private route: ActivatedRoute,
              private linkCampaignService: LinkCampaignService,
              private projectService: ProjectsService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public progres: ProgressIndicatorService,
              private router: Router,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id != null && this.id != 'new') {
      this.getCampaignDetails();

    } else {
      var params = this.route.snapshot.queryParams;
      var projectId = params.projectId;


      this.projectService.getProjectDetails(projectId).subscribe(value => {
        this.project = value;
        var projectName = this.project.name;
        this.campaign = new class implements LinkCampaignDetails {
          id: string;
          links: Array<Link> = new Array<Link>();
          maxNumberOfLinksPerArticle: number = 1;
          name: string = '';
          projectId: string = projectId;
          projectName: string = projectName;
        };
        this.loaded = true;

      });


    }
  }

  private getCampaignDetails() {
    this.progres.progress = true;
    this.linkCampaignService.getLinkCampaignDetails(this.id).subscribe(value => {
      this.campaign = value;
      this.linkDataSource = new MatTableDataSource(this.campaign.links);
      this.linkDataSource.sort = this.sort;
      this.linkDataSource.paginator = this.paginator;
      this.getReport();
      this.loaded = true;
      this.progres.progress = false;
    });
  }

  addNew() {
    this.progres.progress = true;
    this.linkCampaignService.addLinkToCampaign(this.campaign.id, this.articleDetails).subscribe(value => {
      this.progres.progress = false;
      this.getCampaignDetails();
    });
  }

  saveLinkCampaign(returnToList: boolean) {
    this.progres.progress = true;
    if (this.id == 'new') {
      this.linkCampaignService.addLinkCampaign(this.campaign).subscribe(value => {
          this.router.navigateByUrl('project/' + this.project.id);
          this.progres.progress = false;
        },
        error1 => {
          this.progres.progress = false;
          this.snackBar.open('Nie można zapisać:' + error1, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
    } else {
      this.linkCampaignService.updateLinkCampaign(this.campaign.id, this.campaign).subscribe(value => {
          if (returnToList) {
            this.router.navigateByUrl('project/' + this.campaign.projectId);
            this.progres.progress = false;
          }
          this.linkCampaignService.getLinkCampaignDetails(this.id).subscribe(value => {
            this.campaign = value;
            this.linkDataSource = new MatTableDataSource(this.campaign.links);
            this.linkDataSource.sort = this.sort;
            this.linkDataSource.paginator = this.paginator;
            this.loaded = true;
            this.progres.progress = false;
          });
        },
        error1 => {
          this.progres.progress = false;
          if (error1.error.code === 'EXTERNAL_ID_USED_BY_ANOTHER_CAMPAIGN') {
            this.snackBar.open('Nie można zapisać: Zewnętrzne ID jest już używane przez inną kampanię', 'Zamknij',
              {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
          } else {
            this.snackBar.open('Nie można zapisać:' + error1.error.error, 'Zamknij',
              {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
          }
        });
    }
  }

  updateAllocation(element, $event: LinkAllocation) {
    element.allocation = $event;
  }


  saveDomains(element: Link) {
    this.expandedElement = null;
    this.saveLinkCampaign(false);
    if (this.selectedArticles != null) {
      var articleList = this.selectedArticles.map(value => {
        var article: Article = {id: value.articleId};
        return article;
      });

      var request: LinkAssignmentUpdateRequest = {articles: articleList};
      this.progres.progress = true;

      this.linkCampaignService.addAssignmentToLink(element.id, request).subscribe(value => {
        this.progres.progress = false;
        this.getCampaignDetails();
      }, error => {
        this.progres.progress = false;
        this.snackBar.open('Nie można usunąć linku:' + error.error.message, 'Zamknij',
          {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
      });
    }
  }

  articleLabel(element: Link) {
    if (element.articles.length > 0) {
      return 'Wybrano ' + element.articles.length + ' artykułów';
    } else {
      return 'Wybierz artykuły >';
    }
  }

  onArticleSelectionChange($event: Array<ArticleSearchResult>, element: Link) {
    this.selectedArticles = $event;
  }

  getStateLabel(state
                  :
                  LinkAssignments
  ) {
    if (state) {
      let total = state.length;
      let published = state.filter(value => value.published).length;
      return published + '/' + total;
    } else {
      return '-/-';
    }
  }

  getReport() {
    this.linkCampaignService.getLinkCampaignReport(this.campaign.id).subscribe(value => {
      const blob = new Blob([value.report], {type: 'application/octet-stream'});
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    });
  }

  delete(element
           :
           Link
  ) {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć ten rekord?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progres.progress = true;
        this.linkCampaignService.deleteLinkFromCampaign(this.campaign.id, element.id).subscribe(response => {
          this.getCampaignDetails();
          this.progres.progress = false;
        }, error => {
          this.progres.progress = false;
          this.snackBar.open('Nie można usunąć linku:' + error.error.message, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
      }
    });

  }

  deleteAssigment(linkId
                    :
                    string, assigmentId
                    :
                    string
  ) {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: 'Czy na pewno chcesz usunąć to przypisanie?',
        okButtonLabel: 'Usuń'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progres.progress = true;
        this.linkCampaignService.deleteAssigmentFromLink(linkId, assigmentId).subscribe(response => {
          this.getCampaignDetails();
          this.progres.progress = false;
        }, error => {
          this.progres.progress = false;
          this.snackBar.open('Nie można usunąć linku:' + error.error.message, 'Zamknij',
            {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
      }
    });

  }

  showState(element: Link) {
    const dialogRef = this.dialog.open(LinkAssigmentStatusComponent, {
      width: '80%',
      data: element.state
    });
  }


}
