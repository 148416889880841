<mat-card *ngIf="loaded">
  <h2 mat-card-title *ngIf="id == 'new'">Nowa kampania bannerowa</h2>
  <h2 mat-card-title *ngIf="id != 'new'">Edycja kampanii {{campaign.name}}</h2>
  <mat-card-content class="full-width-container">
    <mat-form-field>
      <input matInput placeholder="Nazwa kampanii" [(ngModel)]="campaign.name">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nazwa projektu" [(ngModel)]="campaign.projectName" [disabled]="true">
    </mat-form-field>
    <mat-checkbox class="example-margin" [(ngModel)]="campaign.status">Aktywna (Włączona)</mat-checkbox>
    <div *ngIf="id != 'new'">
      <p>
        <button mat-button (click)="addNew()">
          <mat-icon>add_circle</mat-icon>
          Bannery domenowe
        </button>
        <a mat-button mat-raised-button color="primary" [href]="domainFileUrl" download="raport_domen.csv">Pobierz raport
        </a>
      </p>

      <table mat-table [dataSource]="bannerDataSource" matSort class="mat-elevation-z8" multiTemplateDataRows>
        <ng-container matColumnDef="place">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Miejsce banneru</th>
          <td mat-cell *matCellDef="let element">
            <mat-select placeholder="miejsce banneru" [(value)]=element.place>
              <mat-option value="MAIN1">
                Na głównej 1
              </mat-option>
              <mat-option value="MAIN2">
                Na głównej 2
              </mat-option>
              <mat-option value="FOOTER">
                Stopka
              </mat-option>
              <mat-option value="SIDEBAR1">
                Sidebar 1
              </mat-option>
              <mat-option value="SIDEBAR2">
                Sidebar 2
              </mat-option>
              <mat-option value="NAP">
                NAP
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>Zawartość baneru</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.place != 'NAP'" mat-button (click)="manageBannerContent(element.content)">
              Konfiguruj zawartość baneru
            </button>
            <button *ngIf="element.place == 'NAP'" mat-button (click)="manageNapBannerContent(element)">
              Konfiguruj zawartość baneru NAP
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="allocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydział</th>
          <td mat-cell *matCellDef="let element">
            <app-allocation-setup [allocation]="element.allocation"
                                  (allocationUpdated)="updateAllocation(element, $event)"></app-allocation-setup>
          </td>
        </ng-container>
        <ng-container matColumnDef="domains">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domeny</th>
          <td mat-cell *matCellDef="let element" (click)="expandedElement = element">
            {{domainLabel(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]=element.status></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="stan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Stan</th>
          <td mat-cell *matCellDef="let element" (click)="showState(element)">
            {{getState(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Usuń</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]=element.deleted></mat-checkbox>
          </td>
        </ng-container>

        <!--Domeny-->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="bannerDisplayedColumns.length">
            <div class="full-width-container"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mat-card>
                <mat-card-content>
                  <app-domain-search (selectionChanged)="onDomainSelectionChange($event)"
                                     [selected]="element.domainIds"></app-domain-search>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button color="primary" mat-raised-button (click)="saveDomains(element)">Zapisz wybrane
                    domeny
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="bannerDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: bannerDisplayedColumns;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
    <div *ngIf="id != 'new'">
      <p>
        <button mat-button (click)="addNewArticleBanner()">
          <mat-icon>add_circle</mat-icon>
          Bannery artykułów
        </button>
        <a mat-button mat-raised-button color="primary" [href]="fileUrl" download="raport_artykulow.csv">Pobierz raport
        </a>
      </p>

      <table mat-table [dataSource]="articleBannerDataSource" #articleBannerSearchSort="matSort" matSort
             class="mat-elevation-z8" multiTemplateDataRows>
        <ng-container matColumnDef="place">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Miejsce banneru</th>
          <td mat-cell *matCellDef="let element">
            <mat-select placeholder="miejsce banneru" [(value)]=element.place>
              <mat-option value="MAIN1">
                Na głównej 1
              </mat-option>
              <mat-option value="MAIN2">
                Na głównej 2
              </mat-option>
              <mat-option value="FOOTER">
                Stopka
              </mat-option>
              <mat-option value="SIDEBAR1">
                Sidebar 1
              </mat-option>
              <mat-option value="SIDEBAR2">
                Sidebar 2
              </mat-option>
              <mat-option value="ARTICLE1">
                Artykuł 1
              </mat-option>
              <mat-option value="ARTICLE2">
                Artykuł 2
              </mat-option>
              <mat-option value="NAP">
                NAP
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>Zawartość baneru</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.place != 'NAP'" mat-button (click)="manageBannerContent(element.content)">
              Konfiguruj zawartość baneru
            </button>
            <button *ngIf="element.place == 'NAP'" mat-button (click)="manageNapBannerContent(element)">
              Konfiguruj zawartość baneru NAP
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="allocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydział</th>
          <td mat-cell *matCellDef="let element">
            <app-allocation-setup [allocation]="element.allocation"
                                  (allocationUpdated)="updateAllocation(element, $event)"></app-allocation-setup>
          </td>
        </ng-container>

        <ng-container matColumnDef="articles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Artykuły</th>
          <td mat-cell *matCellDef="let element"
              (click)="element.place!=null?expandedElement = element:expandedElement=null">
            {{articleLabel(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]=element.status></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="stan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Stan</th>
          <td mat-cell *matCellDef="let element" (click)="showState(element)">
            {{getState(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Usuń</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="delete(element)" matTooltip="Usuń" color="warn">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <!--Artykuły-->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="bannerDisplayedColumns.length">
            <div class="full-width-container"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mat-card>
                <mat-card-content>
                  <app-article-banner-search (selectionChanged)="onArticleSelectionChange($event)"
                                             [selected]="element.articles"
                                             [bannerName]="element.place">
                  </app-article-banner-search>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button color="primary" mat-raised-button (click)="saveArticles(element)">Zapisz wybrane
                    artykuły
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="articleBannerDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: articleBannerDisplayedColumns;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-button [routerLink]="['/project', campaign.projectId]">Wróc</button>
    <button mat-button mat-raised-button color="secondary" (click)="saveCampaign(false)" *ngIf="id != 'new'">
      Zapisz
    </button>
    <button mat-button mat-raised-button color="primary" (click)="saveCampaign(true)">Zapisz i wróc do listy
    </button>
  </mat-card-actions>
</mat-card>
