import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../login/login.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-copywriters',
  templateUrl: './copywriters.component.html',
  styleUrls: ['./copywriters.component.css']
})
export class CopywritersComponent implements OnInit, AfterViewChecked {

  submenu: string = 'admin-orders';
  copywriterId: string = 'orders';

  constructor(private cdRef: ChangeDetectorRef,
              private authService: AuthService,
              private route: ActivatedRoute,
              private loginService: LoginService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.copywriterId = this.authService.getUser().id;
    if (this.copywriterId == null) {
      this.dialog.closeAll();
      this.loginService.logout();
    }
    this.setDefaultSubmenu();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setDefaultSubmenu() {
    if (this.authService.hasRole('ADMINISTRATOR') || this.authService.hasRole('EMPLOYEE')) {
      this.submenu = 'admin-orders';
    } else if (this.authService.hasRole('COPYWRITER')) {
      this.submenu = 'orders';
    }
  }

  onGoToOrdersClicked() {
    this.submenu = 'orders';
  }
}
