<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" multiTemplateDataRows>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
    <td mat-cell *matCellDef="let element">
      {{element.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef>Użytkownik</th>
    <td mat-cell *matCellDef="let element">
      {{element.username}}
    </td>
  </ng-container>
  <ng-container matColumnDef="cause">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cause</th>
    <td mat-cell *matCellDef="let element">
      {{element.cause}}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdOn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
    <td mat-cell *matCellDef="let element">
      {{element.createdOn.replace("T"," ").split(".")[0]}}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Detale</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="expandedElement = element" matTooltip="Więcej" color="warn">
        <mat-icon>more</mat-icon>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="full-width-container"
           [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
        <mat-card>
          {{element.stackTrace}}
        </mat-card>
        <button mat-icon-button (click)="expandedElement = null" matTooltip="Zamknij" color="warn">
          <mat-icon>close</mat-icon> Zamknij
        </button>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element">
  </tr>

  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<mat-paginator [length]="totalErrors"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 25, 100]"
               (page)="changePage($event)">
</mat-paginator>
