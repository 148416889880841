import {Component, Inject, OnInit} from '@angular/core';
import {BannerAssignments} from '../../../client/model/bannerAssignments';
import {BannerAssignment} from '../../../client/model/bannerAssignment';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-banner-campaign-state',
  templateUrl: './banner-campaign-state.component.html',
  styleUrls: ['./banner-campaign-state.component.css']
})
export class BannerCampaignStateComponent implements OnInit {
  public dataSource: MatTableDataSource<BannerAssignment>;
  displayedColumns: string[] = ['domainName', 'published', 'publicationDate', 'url'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BannerAssignments) {
    this.dataSource = new MatTableDataSource(data);
  }

  ngOnInit() {
  }

}
