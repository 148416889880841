import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Domain} from '../../../client/model/domain';
import {DomainService} from '../../../client/api/domain.service';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';


@Component({
  selector: 'app-edit-domain',
  templateUrl: './edit-domain.component.html',
  styleUrls: ['./edit-domain.component.css']
})
export class EditDomainComponent implements OnInit {
  private id: string;
  submenu: string;
  public domain: Domain;
  private errorMessage: string;
  loaded = false;

  constructor(private domainService: DomainService,
              private route: ActivatedRoute,
              public progres: ProgressIndicatorService,
              private router: Router) {
    const tmpdomain: Domain = {name: ''};
    this.domain = tmpdomain;
  }

  ngOnInit() {
    this.loaded = false;
    this.progres.progress = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.getDomain(this.id);
    this.submenu = 'articles';

  }

  getDomain(id: string) {
    this.domainService.getDomain(id).subscribe(
      response => {
        this.domain = response;
        this.progres.progress = false;
        this.loaded = true;
      },
      error => this.errorMessage = <any>error);
  }

}
