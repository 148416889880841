import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../../client/model/domain';

@Component({
  selector: 'app-ads-domain-setup',
  templateUrl: './ads-domain-setup.component.html',
  styleUrls: ['./ads-domain-setup.component.css']
})
export class AdsDomainSetupComponent implements OnInit {

  @Input() domain: Domain;

  constructor() { }

  ngOnInit() {
  }

}
