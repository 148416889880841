import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../../client/model/domain';
import {MenuItem} from '../../../../../client/model/menuItem';
import {EditTemplateService} from '../edit-template.service';
import {MenuItemsService} from '../../../../../client/api/menuItems.service';
import {environment} from '../../../../../../environments/environment';
import MenuTypeEnum = MenuItem.MenuTypeEnum;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() domain: Domain;

  menuItems: MenuItem[] = new Array();
  footerMenuItems: MenuItem[] = new Array();

  choosenMenuItems: MenuItem[] = new Array();

  constructor(protected editTemplateService: EditTemplateService,
              protected menuItemsService: MenuItemsService) {

  }


  ngOnInit() {
    this.getMenuItems();
    this.createMenuItems('MAIN', this.menuItems);
    this.createMenuItems('FOOTER', this.footerMenuItems);

    this.editTemplateService.menuItems = this.choosenMenuItems;

  }

  getChoosenFooterMenuItems(): MenuItem[] {
    var mainMenuItem = this.choosenMenuItems.filter(value => value.menuType == 'FOOTER');
    mainMenuItem.forEach((value, index) => value.order = index);
    return mainMenuItem;
  }

  getChoosenMainMenuItems(): MenuItem[] {
    const mainMenuItem = this.choosenMenuItems.filter(value => value.menuType == 'MAIN');
    mainMenuItem.forEach((value, index) => value.order = index);
    return mainMenuItem;
  }

  createMenuItems(menuType: MenuTypeEnum, menuItems: MenuItem[]) {
    const home: MenuItem = {};
    home.menuType = menuType;
    home.standardMenuItem = 'HOME';
    home.domainId = this.domain.id;
    menuItems.push(home);

    const contact: MenuItem = {};
    contact.menuType = menuType;
    contact.standardMenuItem = 'CONTACT';
    contact.domainId = this.domain.id;
    menuItems.push(contact);

    const privacyPolicy: MenuItem = {};
    privacyPolicy.menuType = menuType;
    privacyPolicy.standardMenuItem = 'PRIVACY_POLICY';
    privacyPolicy.domainId = this.domain.id;
    menuItems.push(privacyPolicy);

    if (environment.ADS_FEATURE) {
      const ads: MenuItem = {};
      ads.menuType = menuType;
      ads.standardMenuItem = 'ADS';
      ads.domainId = this.domain.id;
      menuItems.push(ads);

      const companies: MenuItem = {};
      companies.menuType = menuType;
      companies.standardMenuItem = 'COMPANIES';
      companies.domainId = this.domain.id;
      menuItems.push(companies);
    }

    this.domain.articleCategories.forEach(value => {
      const menuItem: MenuItem = {};
      menuItem.category = value;
      menuItem.menuType = menuType;
      menuItem.standardMenuItem = 'CATEGORY';
      menuItem.domainId = this.domain.id;
      menuItems.push(menuItem);
    });
  }

  getLabel(item: MenuItem) {
    if (item.category != null) {
      return item.category.name;
    }
    switch (item.standardMenuItem) {
      case 'ADS':
        return 'Ogłoszenia';
      case 'CATEGORY':
        return '';
      case 'COMPANIES':
        return 'Katalog firm';
      case 'CONTACT':
        return 'Kontakt';
      case 'HOME':
        return 'Start';
      case 'PRIVACY_POLICY':
        return 'Polityka prywatności';
      default:
        return item.standardMenuItem;
    }
  }

  getMenuItems() {
    this.menuItemsService.getMenuItems(this.domain.id).subscribe(
      value => {
        this.choosenMenuItems = value;
        this.adjustAvailableItems();
      });
  }

  adjustAvailableItems() {
    this.choosenMenuItems.forEach(choosenMenuItem => {
      choosenMenuItem.domainId = this.domain.id;
      this.menuItems.forEach((menuItem, index1) => {
        if (menuItem.standardMenuItem == choosenMenuItem.standardMenuItem && menuItem.menuType == choosenMenuItem.menuType && choosenMenuItem.standardMenuItem != 'CATEGORY') {
          this.menuItems.splice(index1, 1);
        }
        if (menuItem.category != null && choosenMenuItem.category != null && menuItem.category.name == choosenMenuItem.category.name && menuItem.menuType == choosenMenuItem.menuType) {
          this.menuItems.splice(index1, 1);
        }
      });

      this.footerMenuItems.forEach((menuItem, index1) => {
        if (menuItem.standardMenuItem == choosenMenuItem.standardMenuItem && menuItem.menuType == choosenMenuItem.menuType && choosenMenuItem.standardMenuItem != 'CATEGORY') {
          this.footerMenuItems.splice(index1, 1);
        }
        if (menuItem.category != null && choosenMenuItem.category != null && menuItem.category.name == choosenMenuItem.category.name && menuItem.menuType == choosenMenuItem.menuType) {
          this.footerMenuItems.splice(index1, 1);
        }
      });


    });

    this.menuItems.forEach((value, index) => value.order = index);

    this.footerMenuItems.forEach((value, index) => value.order = index);

  }

  addToMenu(component: MenuItem) {
    this.editTemplateService.menuModified = true;
    component.order = this.choosenMenuItems.length;
    component.domainId = this.domain.id;
    this.choosenMenuItems.push(component);
    if (component.menuType == 'MAIN') {
      var index = this.menuItems.indexOf(component);
      if (index > -1) {
        this.menuItems.splice(index, 1);
      }
    } else {
      var index = this.footerMenuItems.indexOf(component);
      if (index > -1) {
        this.footerMenuItems.splice(index, 1);
      }
    }
    this.editTemplateService.menuItems = this.choosenMenuItems;
  }

  moveLeft(item: MenuItem) {
    this.editTemplateService.menuModified = true;
    item.order--;
    const index = this.choosenMenuItems.indexOf(item);
    this.choosenMenuItems[index - 1].order++;
    this.move(this.choosenMenuItems, item, -1);
    this.editTemplateService.menuItems = this.choosenMenuItems;
  }

  moveRight(item: MenuItem) {
    this.editTemplateService.menuModified = true;
    item.order++;
    const index = this.choosenMenuItems.indexOf(item);
    this.choosenMenuItems[index + 1].order--;
    this.move(this.choosenMenuItems, item, 1);
    this.editTemplateService.menuItems = this.choosenMenuItems;
  }

  move(array, element, delta) {
    var index = array.indexOf(element);
    var newIndex = index + delta;
    if (newIndex < 0 || newIndex == array.length) {
      return;
    }
    var tmp = array[newIndex];
    array[newIndex] = array[index];
    array[index] = tmp;
  };

  removeItem(item: MenuItem) {
    this.editTemplateService.menuModified = true;
    var index = this.choosenMenuItems.indexOf(item);
    this.choosenMenuItems.splice(index, 1);
    if (item.menuType == 'MAIN') {
      this.menuItems.push(item);
    } else {
      this.footerMenuItems.push(item);
    }
    this.editTemplateService.menuItems = this.choosenMenuItems;
  }
}
