import {Component, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../../client/model/domain';
import {DomainService} from '../../../../../client/api/domain.service';
import {Router} from '@angular/router';
import {ProgressIndicatorService} from '../../../../commons/progress-indicator.service';
import {EditTemplateService} from '../edit-template.service';
import {MenuItemsService} from '../../../../../client/api/menuItems.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent implements OnInit {
  @Input() domain: Domain;
  templateMenu: string;

  constructor(protected domainService: DomainService,
              protected editTemplateService: EditTemplateService,
              protected menuItemsService: MenuItemsService,
              private router: Router,
              protected progres: ProgressIndicatorService,
              public snackBar: MatSnackBar) {
    this.templateMenu = 'template';
  }

  ngOnInit() {
  }

  save() {
    this.progres.progress = true;

    if (this.editTemplateService.menuModified) {
      this.menuItemsService.setMenuItems(this.domain.id, this.editTemplateService.menuItems)
        .subscribe();
    }

    this.domainService.updateDomain(this.domain.id, this.domain)
      .subscribe(value => {
          this.domain = value;
          this.progres.progress = false;
          this.snackBar.open('Setup dla domeny ' + this.domain.name + ' zapisany!', 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        }
      );
  }

  saveAndBackToList() {
    this.progres.progress = true;

    if (this.editTemplateService.menuModified) {
      this.menuItemsService.setMenuItems(this.domain.id, this.editTemplateService.menuItems)
        .subscribe();
    }

    this.domainService.updateDomain(this.domain.id, this.domain)
      .subscribe(value => {
          this.domain = value;
          this.progres.progress = false;

          this.router.navigateByUrl('domains');
        }
      );
  }
}
