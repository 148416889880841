import {Component, OnInit} from '@angular/core';
import {TemplateService} from '../../../client/api/template.service';
import {TemplateList} from '../../../client/model/templateList';
import {FileService} from '../../../client/api/file.service';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit {

  public templates: TemplateList[];
  public thumbnails: Map<string, string> = new Map();

  constructor(public dialog: MatDialog,
              private templateService: TemplateService,
              private fileService: FileService,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getTemplates();
  }


  getTemplates() {
    this.progres.progress = true;
    this.templateService.getTemplates()
      .subscribe(
        response => {
          this.templates = response;
          this.templates.forEach(value => this.getThumbnail(value));
          this.progres.progress = false;
        },
        error => {
          this.progres.progress = false;
          this.snackBar.open(error, 'Zamknij',
            {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
  }


  clone(item: TemplateList) {
    this.progres.progress = true;
    this.templateService.cloneTemplate(item.id).subscribe(
      value => {
        this.getTemplates();
        this.progres.progress = false;
        this.snackBar.open('Utworzono kopię szablonu:' + item.templateName, 'Zamknij',
          {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
      }
    );

  }

  delete(element: TemplateList) {
    if (confirm('Na pewno chcesz usunąć Szablon ' + element.templateName + '?')) {
      this.progres.progress = true;
      this.templateService.deleteTemplate(element.id).subscribe(value => {
          this.getTemplates();
        },
        error1 => {
          this.progres.progress = false;
          this.snackBar.open('Nie można usunąć szablony który jest w użyciu dla domen: ' + error1.error, 'Zamknij',
            {duration: 5000, panelClass: 'snack-error', verticalPosition: 'bottom'});
        });
    }
  }

  getThumbnail(item: TemplateList) {
    if (item.templateThumbnail != null) {
      this.fileService.downloadPngFile(item.templateThumbnail.id).subscribe(value => {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.thumbnails.set(item.id, event.target.result);
        };
        reader.readAsDataURL(value);
      });
    }
  }

  getThumbnailFile(item: TemplateList) {
    return this.thumbnails.get(item.id);
  }
}
