<mat-card>
  <div class="full-width-container">
    <p>
      <button mat-button (click)="createProjectModal()" matTooltip="Dodaj nowy projekt">
        <mat-icon>add_circle</mat-icon>
      </button>
    </p>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nazwa projektu</th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox class="example-margin" [(ngModel)]="element.status" [disabled]="true"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="articleBanners">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydzieleń banerów w artykułach</th>
        <td mat-cell *matCellDef="let element"> {{element.articleBanners!=null?element.articleBanners:'0/0'}}</td>
      </ng-container>

      <ng-container matColumnDef="domainBanners">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydzieleń banerów w domenach</th>
        <td mat-cell *matCellDef="let element"> {{element.domainBanners!=null?element.domainBanners:'0/0'}}</td>
      </ng-container>

      <ng-container matColumnDef="links">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Przydzieleń linków</th>
        <td mat-cell *matCellDef="let element"> {{element.articleLinks!=null?element.articleLinks:'0/0'}}
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Opcje</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [routerLink]="['/project', element.id]" matTooltip="Edytuj">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="delete(element)" matTooltip="Usuń">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>
