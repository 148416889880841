import {Component, Inject, OnInit} from '@angular/core';
import {LinkAssignments} from '../../../client/model/linkAssignments';
import {LinkAssignment} from '../../../client/model/linkAssignment';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-link-assigment-status',
  templateUrl: './link-assigment-status.component.html',
  styleUrls: ['./link-assigment-status.component.css']
})
export class LinkAssigmentStatusComponent implements OnInit {

  public dataSource: MatTableDataSource<LinkAssignment>;
  displayedColumns: string[] = ['articleUrl', 'published', 'publicationDate', 'invalid', 'url'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinkAssignments) {
    this.dataSource = new MatTableDataSource(data);
  }

  ngOnInit() {
  }

}
