import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NapContentDefinition} from '../../../client/model/napContentDefinition';
import {ArticleBannerDefinition} from '../../../client/model/articleBannerDefinition';
import {BannerContentDefinition} from '../../../client/model/bannerContentDefinition';
import {FileDto} from '../../../client/model/fileDto';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-nap-banner-content',
  templateUrl: './edit-nap-banner-content.component.html',
  styleUrls: ['./edit-nap-banner-content.component.css']
})
export class EditNapBannerContentComponent implements OnInit {
  bannerContent: NapContentDefinition;
  standardContent: BannerContentDefinition;

  form: UntypedFormGroup;

  constructor(private dialog: MatDialog,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: ArticleBannerDefinition) {
  }

  ngOnInit() {
    this.bannerContent = this.data.napcontent;
    this.standardContent = this.data.content;
    if (this.bannerContent == null) {
      this.bannerContent = {
        bannerType: 'PRODUCT',
        title: '',
        description: ''
      };
    }
    if (this.standardContent == null) {
      this.standardContent = {
        url: '',
        target: 'NEW_WINDOW',
        alt: '',
        title: '',
        dofollow: true
      };
    }
  }

  save() {
    this.data.napcontent = this.bannerContent;
    this.data.content = this.standardContent;
    this.dialog.getDialogById('napdialog').close(this.data);
  }

  bannerUpdate($event: FileDto) {
    this.standardContent.image = $event;
  }

}
