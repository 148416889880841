import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Template} from '../../../client/model/template';
import {TemplateService} from '../../../client/api/template.service';
import {FileService} from '../../../client/api/file.service';
import {FileDto} from '../../../client/model/fileDto';
import {ProgressIndicatorService} from '../../commons/progress-indicator.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-template-by-id',
  templateUrl: './edit-template-by-id.component.html',
  styleUrls: ['./edit-template-by-id.component.css']
})
export class EditTemplateByIdComponent implements OnInit {
  private id: string;
  public mode: string;
  template: Template = {};

  public relativePaths: Array<[string, string]> = [['css', 'Style'], ['img', 'Obrazki'], ['js', 'JS'], ['fonts', 'Fonty']];

  protected fileListLabel = 'Pliki szablonu';
  public thumbnailLabel = 'Miniaturka';

  constructor(private route: ActivatedRoute,
              private templateService: TemplateService,
              private fileService: FileService,
              private router: Router,
              public progres: ProgressIndicatorService,
              public snackBar: MatSnackBar) {

  }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == null) {
      this.mode = 'create';
      this.template = {
        templateName: '', templateFiles: new Array<FileDto>()

      };
    } else {
      this.templateService.getTemplate(this.id)
        .subscribe(
          value => {
            this.template = value;
            if (this.template.templateFiles == null) {
              this.template.templateFiles = new Array<FileDto>();
            }
          });
      this.mode = 'edit';
    }
  }

  save() {
    this.progres.progress = true;
    if (this.mode === 'edit') {
      this.templateService.updateTemplate(this.template.id, this.template)
        .subscribe(value => {
          this.progres.progress = false;
          this.snackBar.open('Zapisano szablon:' + this.template.templateName, 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
        });
    } else {
      this.templateService.addTemplate(this.template)
        .subscribe(value => {
          this.progres.progress = false;
          this.snackBar.open('Zapisano szablon:' + this.template.templateName, 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
          this.router.navigateByUrl('templates');
        });
    }
  }

  saveAndBackToList() {
    this.progres.progress = true;
    if (this.mode === 'edit') {
      this.templateService.updateTemplate(this.template.id, this.template)
        .subscribe(value => {
          this.progres.progress = false;
          this.snackBar.open('Zapisano szablon:' + this.template.templateName, 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
          this.router.navigateByUrl('templates');
        });
    } else {
      this.templateService.addTemplate(this.template)
        .subscribe(value => {
          this.progres.progress = false;
          this.snackBar.open('Zapisano szablon:' + this.template.templateName, 'Zamknij',
            {duration: 5000, panelClass: 'snack-confirm', verticalPosition: 'bottom'});
          this.router.navigateByUrl('templates');
        });
    }
  }

  thumbnailUpdate($event: FileDto) {
    this.template.templateThumbnail = $event;
  }
}
