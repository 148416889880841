<mat-card>
  <h2 mat-card-title>Serwery</h2>


  <div class="full-width-container">
    <p>
      <button mat-button (click)="openDialog()">
        <mat-icon>add_circle</mat-icon>
      </button>

      <button mat-button (click)="rerenderDomains()">
        <mat-icon>refresh</mat-icon>
      </button>
    </p>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>IP</th>
        <td mat-cell *matCellDef="let element"> {{element.address}}</td>
      </ng-container>

      <ng-container matColumnDef="domains">
        <th mat-header-cell *matHeaderCellDef>Domeny</th>
        <td mat-cell *matCellDef="let element"> {{element.domains}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Opcje</th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button (click)="edit(element.id)">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button (click)="delete(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>
