<div class="orders-copywriter-list-container">

  <mat-expansion-panel>
    <mat-expansion-panel-header>Wyszukiwanie</mat-expansion-panel-header>
    <form [formGroup]="searchForm" class="full-width-container">

        <mat-form-field class="full-width-container">
          <input type="text" matInput placeholder="Zlecający" formControlName="orderAuthorGroup"
                 [matAutocomplete]="orderAutoGroup" class="full-width-container">
          <mat-autocomplete #orderAutoGroup="matAutocomplete" class="full-width-container">
            <mat-optgroup *ngFor="let group of orderAuthorGroupOptions | async" [label]="group.letter">
              <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setOrderAutorId(name.id)">
                {{name.name}}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>

          <mat-form-field class="full-width-container">
            <input type="text" matInput placeholder="Zewnętrzne Id" formControlName="externalId"
                   class="full-width-container">
          </mat-form-field>

      <div>
        <button width="40" mat-button mat-raised-button color="accent"
                (click)="searchOrders($event.pageSize,$event.pageIndex)">Szukaj
        </button>
        <button width="40" mat-button color="primary" (click)="resetForm()">Wyczyść</button>
      </div>
    </form>
  </mat-expansion-panel>


  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Kategoria</th>
      <td mat-cell *matCellDef="let element">
        {{element.categoryName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Słowa kluczowe</th>
      <td mat-cell *matCellDef="let element">
        <p *ngFor="let tag of element.tags">{{tag.name}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Długość</th>
      <td mat-cell *matCellDef="let element">{{element.length}}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
      <td mat-cell *matCellDef="let element">{{element.typeName}}</td>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Utworzono</th>
      <td mat-cell *matCellDef="let element">{{element.createdOn.replace('T', ' ').split('.')[0]}}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Identyfikator zlecenia</th>
      <td mat-cell *matCellDef="let element">{{element.externalId}}</td>
    </ng-container>

    <ng-container matColumnDef="orderAuthor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zlecający</th>
      <td mat-cell *matCellDef="let element">{{element.createdByName}}<br/>{{element.createdByEmail}}</td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data oddania</th>
      <td mat-cell *matCellDef="let element">{{element.dueDate}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Opcje</th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button *ngIf="element.status == 'NEW'" (click)="onCreateArticleClick(element)"
                matTooltip="Pisz">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button *ngIf="element.status == 'READY'" (click)="onEditArticleClick(element)"
                matTooltip="Edytuj">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button *ngIf="element.status == 'NEED_WORK'" (click)="onEditArticleClick(element)"
                matTooltip="Popraw">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="totalOrders"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 (page)="changePage($event)"
  ></mat-paginator>
</div>
