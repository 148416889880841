import {UntypedFormControl} from "@angular/forms";

export class CustomValidators {
  static isAmount(control: UntypedFormControl): {[s: string]: boolean} {
    const regex = /^(\d)*(\,\d{1,2})?$/g;

    if (!regex.test(control.value)) {
      return {'invalidFormat': true};
    }
    return null;
  }

  static matchPassword(control: UntypedFormControl) {
    if (control && control.parent) {
      let password = control.parent.get('password').value;
      let confirmPassword = control.value;

      return password == confirmPassword ? null : { notSame: true };
    }
    return null;
  }
}
