import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../environments/environment';


@Directive({
  selector: '[appEnableFeature]'
})
export class EnableFeatureDirective implements OnInit {
  private feature: String;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input() set appEnableFeature(feature: String) {
    this.feature = feature;
  }

  ngOnInit() {
    if (this.isFeatureEnabled(this.feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  isFeatureEnabled(featureName: String): Boolean {

    switch (featureName) {
      case 'ads':
        return environment.ADS_FEATURE;
      case 'TEST':
        return environment.TEST_FEATURE;
      default:
        return false;
    }
  }

}
