import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DomainAd} from '../../../client/model/domainAd';
import {AdvertisementCategoryService} from '../../../client/api/advertisementCategory.service';
import {AdCategory} from '../../../client/model/adCategory';
import {CreateAddRequest} from '../../../client/model/createAddRequest';
import {AdsService} from '../../../client/api/ads.service';
import {UpdateAdRequest} from '../../../client/model/updateAdRequest';

@Component({
  selector: 'app-ad-edit-modal',
  templateUrl: './ad-edit-modal.component.html',
  styleUrls: ['./ad-edit-modal.component.css']
})
export class AdEditModalComponent implements OnInit {
  ad: DomainAd;
  form: UntypedFormGroup;
  categories: Array<AdCategory>;
  public relativePaths: Array<[string, string]> = [['img', 'Zdjęcia']];
  photos: any;
  private domainId: string;
  title: any;


  constructor(private advertisementCategoryService: AdvertisementCategoryService,
              private adsService: AdsService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ad = data.ad;
    this.domainId = data.domain;
    if (this.ad.id != null) {
      this.title = 'Edytuj';
    } else {
      this.title = 'Dodaj';
    }
    this.photos = this.ad.photos != null ? this.ad.photos : new Array();
  }

  ngOnInit() {
    this.loadCategories();
    this.initializeForm();
  }

  private initializeForm() {
    this.form = this.formBuilder.group({
      title: new UntypedFormControl(this.ad.title),
      category: new UntypedFormControl(this.ad.categoryId),
      content: new UntypedFormControl(this.ad.content),
      price: new UntypedFormControl(this.ad.price),
      name: new UntypedFormControl(this.ad.name),
      email: new UntypedFormControl(this.ad.email),
      phone: new UntypedFormControl(this.ad.phone),
      address: new UntypedFormControl(this.ad.address)
    });
  }

  private loadCategories() {
    this.advertisementCategoryService.getAdCategories().subscribe(value =>
      this.categories = value);
  }

  getCategories() {
    if (this.categories) {
      return this.categories.filter(value => value.categoryType === this.ad.type);
    }
  }

  save() {
    if (!this.ad.id) {
      const createAddRequest: CreateAddRequest = this.form.value;
      createAddRequest.photos = this.photos;
      createAddRequest.type = this.ad.type;
      this.adsService.createAd(this.domainId, createAddRequest).subscribe(value => {
        this.dialog.closeAll();
      });
    } else {
      const updateAdRequest: UpdateAdRequest = this.form.value;
      updateAdRequest.photos = this.photos;
      updateAdRequest.type = this.ad.type;
      this.adsService.updateAd(this.domainId, this.ad.id, updateAdRequest).subscribe(value => {
        this.dialog.closeAll();
      });
    }
  }
}
