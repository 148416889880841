import {Component, OnInit} from '@angular/core';
import {AuthorsService} from '../../../client/api/authors.service';
import {ArticleAuthor} from '../../../client/model/articleAuthor';

@Component({
  selector: 'app-authors-list',
  templateUrl: './authors-list.component.html',
  styleUrls: ['./authors-list.component.css']
})
export class AuthorsListComponent implements OnInit {
  authors: Array<ArticleAuthor>;

  constructor(private authorsService: AuthorsService) {
  }

  ngOnInit(): void {

    this.authorsService.getAuthors()
      .subscribe(value => this.authors = value);
  }

}
