<div id="addCategory" *ngIf="mode != 'edit'" class="full-width-container">
  <h2 mat-dialog-title>Nowa kategoria</h2>

  <mat-dialog-content class="mat-typography">
    <form class="example-form">
      <div *ngFor="let category of categories; let ind = index" class="full-width-container">
        <table>
          <tr>
            <td>
              <mat-form-field>
                <input matInput placeholder="Nazwa kategorii" name="lastname-{{ind}}" [(ngModel)]="category.name"
                       [required]="true">
              </mat-form-field>
            </td>
            <td>
              <button mat-icon-button (click)="deleteCategory(category)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </form>
    <button mat-button (click)="addNew()" *ngIf="mode != 'edit'">+kolejna</button>
  </mat-dialog-content>
</div>

<div id="editCategory" *ngIf="mode == 'edit'">
  <h2 mat-dialog-title>Edytuj kategorię {{tmpName}}</h2>
  <form class="example-form">
    <div>
      <mat-form-field class="full-width-container">
        <input matInput placeholder="Nazwa kategorii" name="name" [(ngModel)]="tmpValue">
      </mat-form-field>
    </div>
  </form>
</div>
<h3 *ngIf="warning">Nazwa kategorii nie może być pusta</h3>
<p class="alert">Zmiany zostaną automatycznie zapisane w konfiguracji domeny!</p>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial (click)="save()">Zapisz</button>
</mat-dialog-actions>
