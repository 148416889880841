import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FileService} from '../../../client/api/file.service';
import {CustomDefinition, CustomGroup} from '../../orders/orders-admin-list/orders-admin-list.component';
import {Publisher} from '../../../client/model/publisher';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {PublisherService} from '../../../client/api/publisher.service';
import {_filter} from '../../domains/domains.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadResult} from '../../../client/model/uploadResult';

@Component({
  selector: 'app-publication-upload',
  templateUrl: './publication-upload.component.html',
  styleUrls: ['./publication-upload.component.css']
})
export class PublicationUploadComponent {

  public showProgressBar: boolean;
  protected progressPercentage: number;
  protected message: UploadResult;

  publisherGroups: CustomGroup[] = new Array();
  publisherGroupOptions: Observable<CustomGroup[]>;

  searchForm: UntypedFormGroup = this.fb.group({
    publisherGroup: ''
  });
  private publishers: Array<Publisher>;
  private publisher: string;

  constructor(public dialogRef: MatDialogRef<PublicationUploadComponent>,
              private fb: FormBuilder,
              private publisherService: PublisherService,
              private snackBar: MatSnackBar,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    this.getPublishers();
    this.publisherGroupOptions = this.searchForm.get('publisherGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value, this.publisherGroups))
      );
  }

  public upload(event: Event) {
    const element = event.srcElement;
    const files = (element as HTMLInputElement).files;

    if (files.length > 0) {
      for (let _i = 0; _i < files.length; _i++) {

        const file = files.item(_i);
        this.fileService.uploadPublisherFile(this.publisher, file, 'events', true).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.showProgressBar = true;
                break;
              case HttpEventType.Response:
                this.showProgressBar = false;
                this.message = event.body;
                if (this.message.messages.length > 0) {
                  this.snackBar.open('Ilość wierszy z błędami:' + this.message.messages.length, 'Zamknij',
                    {duration: 10000, panelClass: 'snack-error', verticalPosition: 'bottom'});
                }
                this.dialogRef.close();
                break;
              case 1: {
                if (Math.round(this.progressPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
                  this.progressPercentage = event['loaded'] / event['total'] * 100;
                  this.progressPercentage = Math.round(this.progressPercentage);
                }
                break;
              }
            }
          },
          error => {
            console.log(error);
            this.showProgressBar = false;
          });
      }
    }
  }

  private getPublishers() {
    this.publisherService.getPublishers(0, 5000, '').subscribe(
      value => {
        this.publishers = value;
        this.publishers.forEach(value1 => {
            const valueToAdd: CustomDefinition = {
              name: this.getPublisherName(value1),
              id: value1.id
            };
            this.addToGroup(valueToAdd, this.publisherGroups);
          }
        );
      });
  }

  private addToGroup(value1: CustomDefinition, group: any) {
    let groupFiltered = group.filter(value => value.letter === value1.name.charAt(0))[0];
    if (!groupFiltered) {
      groupFiltered = {
        letter: value1.name.charAt(0),
        names: new Array()
      };
      group.push(groupFiltered);
    }
    const definition = {
      name: value1.name,
      id: value1.id
    };
    groupFiltered.names.push(definition);
  }

  private getPublisherName(publisher: Publisher) {
    return publisher.name != null ? publisher.name : publisher.email;
  }


  private _filterGroup(value: string, customGroup: any): CustomGroup[] {
    if (value) {
      return customGroup
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return customGroup;
  }

  setPublisher(definition: CustomDefinition) {
    this.publisher = definition.id;

  }
}
