import {Component, OnInit} from '@angular/core';
import {DomainService} from '../../../../../client/api/domain.service';
import {ActivatedRoute} from '@angular/router';
import {DomainLinks} from '../../../../../client/model/domainLinks';

import {DomainLink} from '../../../../../client/model/domainLink';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-edit-links',
  templateUrl: './edit-links.component.html',
  styleUrls: ['./edit-links.component.css']
})
export class EditLinksComponent implements OnInit {
  private id: string;
  private links: DomainLinks;
  public dataSource: MatTableDataSource<DomainLink>;
  displayedColumns: string[] = ['url', 'target', 'anchor', 'campaignName', 'assignmentDate', 'invalid', 'edit'];


  constructor(private domainService: DomainService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.domainService.getDomainLinks(this.id).subscribe(value => {
      this.links = value;
      this.dataSource = new MatTableDataSource(this.links);

    });
  }

}
