<h2 mat-dialog-title>{{title}} na tekst AI</h2>

<mat-dialog-content>
  <form [formGroup]="orderForm">

    <mat-form-field class="full-width-container">
      <input type="text" matInput placeholder="Domena" formControlName="domainGroup"
             [matAutocomplete]="domainAutoGroup" class="full-width-container">
      <mat-autocomplete #domainAutoGroup="matAutocomplete" class="full-width-container">
        <mat-optgroup *ngFor="let group of domainGroupOptions | async" [label]="group.letter">
          <mat-option *ngFor="let name of group.names" [value]="name.name" (click)="setDomainId(name.name)">
            {{name.name}}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>

    <div class="full-width-container">
      <mat-form-field>
        <input matInput placeholder="Zewnętrzne ID" name="externalId" formControlName="externalId">
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <input matInput placeholder="Tytuł" name="title" formControlName="title">
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <input matInput placeholder="Słowo klucz" name="keyword" formControlName="keyword">
      </mat-form-field>
    </div>

    <div class="full-width-container">
      <mat-form-field>
        <textarea matInput placeholder="Opis tekstu do wygenerowanie przez AI" rows="10"
                  formControlName="customPrompt" name="customPrompt" readonly></textarea>
      </mat-form-field>
    </div>

    <mat-form-field class="full-width mat-form-field--no-underline">
      <input matInput formControlName="autoPublish" placeholder="" style="display: none" *appShowAuthed="['ADMINISTRATOR', 'EMPLOYEE']">
      <mat-checkbox formControlName="autoPublish">Publikuj automatycznie</mat-checkbox>
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Anuluj</button>
  <button mat-button primary cdkFocusInitial id="saveBtn" (click)="save()">Zapisz</button>
</mat-dialog-actions>
